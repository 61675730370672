// @flow
import React from 'react';
import type { Node } from 'react';
import { FormGroup, Label, Input, Row, Col } from 'reactstrap';
import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';
import type { Account } from '../../../models/accounts/Account';
import type { MyReelForm } from '../../../models/my_reels/MyReelForm';
import type { ImageForm } from '../../../models/images/ImageForm';
import { Content, Text, List, ListItem } from '../../commons/native-base';
import { ListContent, ListActions } from '../../commons/list';
import { SelectMakerModal } from '../../makers/SelectMakerModal';
import { SelectSeriesModal } from '../../series/SelectSeriesModal';
import { SelectProductModal } from '../../products/SelectProductModal';
import ProductHelper from '../../../helpers/ProductHelper';
import InputListItem from '../../commons/InputListItem';
import { isNumberString } from '../../../lib/StringUtils';
import { ImageList } from '../../commons/ImageList';

const MOMENT_FORMAT = 'YYYY-MM-DD';

type Props = {
  form: MyReelForm,
  account: Account,
  onChangeForm: (form: MyReelForm) => void,
  displayAxiosError: (error: any) => void,
};

type State = {
  form: MyReelForm,
  visibleMakerModal: boolean,
  visibleSeriesModal: boolean,
  visibleProductModal: boolean,
  visibleLineMakerModal: boolean,
  visibleLineSeriesModal: boolean,
  visibleLineProductModal: boolean,
  startDateFocused: boolean,
  endDateFocused: boolean,
  reelThreadDateFocused: boolean,
};

export class FormComp extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      form: props.form,
      visibleMakerModal: false,
      visibleSeriesModal: false,
      visibleProductModal: false,
      visibleLineMakerModal: false,
      visibleLineSeriesModal: false,
      visibleLineProductModal: false,
      startDateFocused: false,
      endDateFocused: false,
      reelThreadDateFocused: false,
    };
  }

  // https://flow.org/en/docs/types/utilities/#toc-shape
  // version次第でPartialに移行すること
  static getDerivedStateFromProps(nextProps: Props): $Shape<State> {
    return {
      form: nextProps.form,
    };
  }

  showAlert(text: string) {
    window.confirm(text);
  }

  onClickSeries() {
    if (this.state.form.maker == null) {
      this.showAlert('メーカーを選択してください');
    } else {
      this.setState({ visibleSeriesModal: true });
    }
  }

  onClickProduct() {
    if (this.state.form.maker == null || this.state.form.series == null) {
      this.showAlert('メーカーとシリーズを選択して下さい');
    } else {
      this.setState({ visibleProductModal: true });
    }
  }

  onClickLineSeries() {
    if (this.state.form.line_maker == null) {
      this.showAlert('メーカーを選択してください');
    } else {
      this.setState({ visibleLineSeriesModal: true });
    }
  }

  onClickLineProduct() {
    if (
      this.state.form.line_maker == null ||
      this.state.form.line_series == null
    ) {
      this.showAlert('メーカーとシリーズを選択して下さい');
    } else {
      this.setState({ visibleLineProductModal: true });
    }
  }

  onRemoveImage(image: ImageForm, index: number) {
    const { onChangeForm } = this.props;
    const { form } = this.state;

    if (image.id != null) {
      form.images[index]._destroy = true;
    } else {
      form.images.splice(index, 1);
    }

    onChangeForm(form);
  }

  onAppendImage(acceptedFiles: Array<any>) {
    const { onChangeForm } = this.props;
    const { form } = this.state;

    form.images.push({
      id: null,
      image: acceptedFiles[0],
      url: URL.createObjectURL(acceptedFiles[0]),
      _destroy: false,
    });

    onChangeForm(form);
  }

  render(): Node {
    const { account, onChangeForm } = this.props;
    const {
      form,
      startDateFocused,
      endDateFocused,
      reelThreadDateFocused,
    } = this.state;

    return (
      <Content>
        <h4>リール</h4>
        <List style={{ backgroundColor: '#fff' }}>
          <ListItem>
            <ListContent
              onClick={() => this.setState({ visibleMakerModal: true })}
              style={{ cursor: 'pointer' }}
            >
              <Row>
                <Col xs={4}>メーカー</Col>
                <Col xs={8}>
                  {form.maker != null && form.maker.name != null ? (
                    <Text>{form.maker.name}</Text>
                  ) : (
                    <Text className="text-muted">メーカーを選択</Text>
                  )}
                </Col>
              </Row>
            </ListContent>
            <ListActions>
              <i
                onClick={() => {
                  onChangeForm({
                    ...form,
                    maker: null,
                    series: null,
                    reel: null,
                  });
                }}
                className="listview-actions-item ion-ios-close-outline"
              />
            </ListActions>
          </ListItem>
          <ListItem>
            <ListContent
              onClick={() => this.onClickSeries()}
              style={{ cursor: 'pointer' }}
            >
              <Row>
                <Col xs={4}>シリーズ</Col>
                <Col xs={8}>
                  {form.series ? (
                    <Text>{form.series.name}</Text>
                  ) : (
                    <Text className="text-muted">シリーズを選択</Text>
                  )}
                </Col>
              </Row>
            </ListContent>
            <ListActions>
              <i
                onClick={() => {
                  onChangeForm({
                    ...form,
                    series: null,
                    reel: null,
                  });
                }}
                className="listview-actions-item ion-ios-close-outline"
              />
            </ListActions>
          </ListItem>
          <ListItem>
            <ListContent
              onClick={() => this.onClickProduct()}
              style={{ cursor: 'pointer' }}
            >
              <Row>
                <Col xs={4}>モデル</Col>
                <Col xs={8}>
                  {form.reel != null && form.reel.name != null ? (
                    <Text>{form.reel.name}</Text>
                  ) : (
                    <Text className="text-muted">モデルを選択</Text>
                  )}
                </Col>
              </Row>
            </ListContent>
            <ListActions>
              <i
                onClick={() => {
                  onChangeForm({
                    ...form,
                    reel: null,
                  });
                }}
                className="listview-actions-item ion-ios-close-outline"
              />
            </ListActions>
          </ListItem>

          {ProductHelper.enableFreeInputReel(form) && (
            <InputListItem
              label={null}
              style={{ textAlign: 'left', paddingLeft: 0 }}
              placeholder="リール名などを自由に入力"
              value={form.name}
              clearable={true}
              onChangeText={text => {
                onChangeForm({
                  ...form,
                  name: text,
                });
              }}
            />
          )}

          <ListItem>
            <ListContent>
              <Row>
                <Col xs={4}>使用開始日</Col>
                <Col xs={8}>
                  <SingleDatePicker
                    id="get_date"
                    date={form.get_date}
                    onDateChange={date => {
                      onChangeForm({
                        ...form,
                        get_date: date,
                      });
                    }}
                    focused={startDateFocused}
                    onFocusChange={({ focused }) =>
                      this.setState({ startDateFocused: focused })
                    }
                    numberOfMonths={1}
                    displayFormat={MOMENT_FORMAT}
                    transitionDuration={0}
                    isOutsideRange={() => false}
                    placeholder="使用開始日を指定"
                  />
                </Col>
              </Row>
            </ListContent>
            <ListActions>
              <i
                onClick={() => {
                  onChangeForm({
                    ...form,
                    get_date: null,
                  });
                }}
                className="listview-actions-item ion-ios-close-outline"
              />
            </ListActions>
          </ListItem>
          <ListItem>
            <ListContent>
              <Row>
                <Col xs={4}>使用終了日</Col>
                <Col xs={8}>
                  <SingleDatePicker
                    id="lost_date"
                    date={form.lost_date}
                    onDateChange={date =>
                      onChangeForm({
                        ...form,
                        lost_date: date,
                      })
                    }
                    focused={endDateFocused}
                    onFocusChange={({ focused }) =>
                      this.setState({ endDateFocused: focused })
                    }
                    numberOfMonths={1}
                    displayFormat={MOMENT_FORMAT}
                    transitionDuration={0}
                    isOutsideRange={() => false}
                    placeholder="使用終了日を指定"
                  />
                </Col>
              </Row>
            </ListContent>
            <ListActions>
              <i
                onClick={() => {
                  onChangeForm({
                    ...form,
                    lost_date: null,
                  });
                }}
                className="listview-actions-item ion-ios-close-outline"
              />
            </ListActions>
          </ListItem>
        </List>

        <FormGroup
          style={{
            borderTop: '1px solid #f0f0f0',
            paddingTop: 16,
            paddingBottom: 12,
          }}
        >
          <Label>メモ</Label>
          <Input
            type="textarea"
            placeholder="このリールに関するメモを入力"
            value={form.memo}
            onChange={e => {
              this.props.onChangeForm({
                ...form,
                memo: e.target.value,
              });
            }}
          />
        </FormGroup>

        <FormGroup className="mb-30">
          <Label>画像</Label>
          <ImageList
            images={this.state.form.images}
            onRemoveImage={(image, index) => this.onRemoveImage(image, index)}
            onAppendImage={acceptedFiles => this.onAppendImage(acceptedFiles)}
          />
        </FormGroup>

        <h4>ライン</h4>
        <List>
          <ListItem>
            <ListContent
              onClick={() => this.setState({ visibleLineMakerModal: true })}
              style={{ cursor: 'pointer' }}
            >
              <Row>
                <Col xs={4}>メーカー</Col>
                <Col xs={8}>
                  {form.line_maker ? (
                    <Text>{form.line_maker.name}</Text>
                  ) : (
                    <Text className="text-muted">メーカーを選択</Text>
                  )}
                </Col>
              </Row>
            </ListContent>
            <ListActions>
              <i
                onClick={() => {
                  onChangeForm({
                    ...form,
                    line_maker: null,
                    line_series: null,
                    line: null,
                  });
                }}
                className="listview-actions-item ion-ios-close-outline"
              />
            </ListActions>
          </ListItem>
          <ListItem>
            <ListContent
              onClick={() => this.onClickLineSeries()}
              style={{ cursor: 'pointer' }}
            >
              <Row>
                <Col xs={4}>シリーズ</Col>
                <Col xs={8}>
                  {form.line_series ? (
                    <Text>{form.line_series.name}</Text>
                  ) : (
                    <Text className="text-muted">シリーズを選択</Text>
                  )}
                </Col>
              </Row>
            </ListContent>
            <ListActions>
              <i
                onClick={() => {
                  onChangeForm({
                    ...form,
                    line_series: null,
                    line: null,
                  });
                }}
                className="listview-actions-item ion-ios-close-outline"
              />
            </ListActions>
          </ListItem>

          <ListItem>
            <ListContent
              onClick={() => this.onClickLineProduct()}
              style={{ cursor: 'pointer' }}
            >
              <Row>
                <Col xs={4}>強度</Col>
                <Col xs={8}>
                  {form.line != null && form.line.name != null ? (
                    <Text>{form.line.name}</Text>
                  ) : (
                    <Text className="text-muted">強度を指定</Text>
                  )}
                </Col>
              </Row>
            </ListContent>
            <ListActions>
              <i
                onClick={() => {
                  onChangeForm({
                    ...form,
                    line: null,
                  });
                }}
                className="listview-actions-item ion-ios-close-outline"
              />
            </ListActions>
          </ListItem>
          {ProductHelper.enableFreeInputLine(form) && (
            <InputListItem
              label={null}
              style={{ textAlign: 'left', paddingLeft: 0 }}
              placeholder="ライン名などを自由に入力"
              value={form.line_name}
              clearable={true}
              onChangeText={text => {
                onChangeForm({
                  ...form,
                  line_name: text,
                });
              }}
            />
          )}
          <InputListItem
            label="巻いた長さ"
            type="number"
            placeholder="m"
            value={form.reel_length}
            clearable={true}
            onChangeText={text => {
              onChangeForm({
                ...form,
                reel_length: isNumberString(text) ? parseInt(text) : null,
              });
            }}
          />
          <ListItem>
            <ListContent>
              <Row>
                <Col xs={4}>巻いた日</Col>
                <Col xs={8}>
                  <SingleDatePicker
                    id="reel_thread_date"
                    date={form.reel_thread_date}
                    onDateChange={date =>
                      onChangeForm({
                        ...form,
                        reel_thread_date: date,
                      })
                    }
                    focused={reelThreadDateFocused}
                    onFocusChange={({ focused }) =>
                      this.setState({ reelThreadDateFocused: focused })
                    }
                    numberOfMonths={1}
                    displayFormat={MOMENT_FORMAT}
                    transitionDuration={0}
                    isOutsideRange={() => false}
                    placeholder="巻いた日を指定"
                  />
                </Col>
              </Row>
            </ListContent>
            <ListActions>
              <i
                onClick={() => {
                  onChangeForm({
                    ...form,
                    lost_date: null,
                  });
                }}
                className="listview-actions-item ion-ios-close-outline"
              />
            </ListActions>
          </ListItem>
        </List>

        <SelectMakerModal
          visible={this.state.visibleMakerModal}
          productType="Reel"
          account={account}
          selectedMaker={form.maker}
          onApply={maker => {
            onChangeForm({
              ...form,
              maker: maker,
              series:
                form.maker && form.maker.id !== maker.id ? null : form.series,
              reel: form.maker && form.maker.id !== maker.id ? null : form.reel,
            });
            this.setState({ visibleMakerModal: false });
          }}
          onClose={() => this.setState({ visibleMakerModal: false })}
        />
        {form.maker != null && (
          <SelectSeriesModal
            visible={this.state.visibleSeriesModal}
            maker={form.maker}
            productType="Reel"
            account={account}
            onApply={series => {
              onChangeForm({
                ...form,
                series: series,
                reel:
                  form.series && form.series.id !== series.id
                    ? null
                    : form.reel,
              });
              this.setState({ visibleSeriesModal: false });
            }}
            onClose={() => this.setState({ visibleSeriesModal: false })}
            displayAxiosError={error => this.props.displayAxiosError(error)}
          />
        )}
        {form.maker != null && form.series != null && (
          <SelectProductModal
            visible={this.state.visibleProductModal}
            type="Reel"
            maker={form.maker}
            series={form.series}
            account={account}
            onApply={product => {
              onChangeForm({
                ...form,
                reel: product,
              });
              this.setState({ visibleProductModal: false });
            }}
            onClose={() => this.setState({ visibleProductModal: false })}
          />
        )}
        <SelectMakerModal
          visible={this.state.visibleLineMakerModal}
          productType="Line"
          account={account}
          selectedMaker={form.line_maker}
          onApply={maker => {
            onChangeForm({
              ...form,
              line_maker: maker,
              line_series:
                form.line_maker && form.line_maker.id !== maker.id
                  ? null
                  : form.line_series,
              line:
                form.line_maker && form.line_maker.id !== maker.id
                  ? null
                  : form.line,
            });
            this.setState({ visibleLineMakerModal: false });
          }}
          onClose={() => this.setState({ visibleLineMakerModal: false })}
        />
        {form.line_maker != null && (
          <SelectSeriesModal
            visible={this.state.visibleLineSeriesModal}
            maker={form.line_maker}
            productType="Line"
            account={account}
            onApply={series => {
              onChangeForm({
                ...form,
                line_series: series,
                line:
                  form.line_series && form.line_series.id !== series.id
                    ? null
                    : form.line,
              });
              this.setState({ visibleLineSeriesModal: false });
            }}
            onClose={() => this.setState({ visibleLineSeriesModal: false })}
            displayAxiosError={error => this.props.displayAxiosError(error)}
          />
        )}
        {form.line_maker != null && form.line_series != null && (
          <SelectProductModal
            visible={this.state.visibleLineProductModal}
            type="Line"
            maker={form.line_maker}
            series={form.line_series}
            account={account}
            onApply={product => {
              onChangeForm({
                ...form,
                line: product,
              });
              this.setState({ visibleLineProductModal: false });
            }}
            onClose={() => this.setState({ visibleLineProductModal: false })}
          />
        )}
      </Content>
    );
  }
}
