// @flow
import React from 'react';
import type { Review } from '../../../models/products/reviews/Review';

type Props = {
  review: Review,
};

export default function Voters(props: Props) {
  const { review } = props;

  return <div>{`${review.cached_votes_up}件`}</div>;
}
