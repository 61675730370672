// @flow
import type { Field } from '../models/fields/Field';
import * as types from './types';

export function updateMyFields(myFields: Array<Field>): any {
  return {
    type: types.UPDATE_MY_FIELDS,
    payload: myFields,
  };
}

export function updateMyField(myField: Field): any {
  return {
    type: types.UPDATE_MY_FIELD,
    payload: myField,
  };
}
