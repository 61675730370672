// @flow
import React from 'react';
import styled from 'styled-components';
import type { Node } from 'react';

const Link = styled.a`
  &:hover {
    cursor: pointer;
  }
`;

type Props = {
  className: string,
  onClick: () => void,
  children: Node,
};

function NavLink({ className, children, onClick }: Props) {
  return (
    <Link className={className} onClick={onClick}>
      {children}
    </Link>
  );
}

NavLink.defaultProps = {
  className: '',
};

export default NavLink;
