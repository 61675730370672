// @flow
import React from 'react';

type Props = {
  name: string,
};

export function Icon({ name, ...props }: Props) {
  return <i className={`fa fa-${name}`} {...props} />;
}
