// @flow
import React from 'react';
import type { Node } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import * as linkHeaderParser from 'linkheader-parser/dist/linkheader-parser-node';
import DateRangeView from '../../../results/DateRangeView';
import Loader from '../../../commons/Loader';
import ResultCard from '../../../results/ResultCard';
import ResultClient from '../../../../models/results/ResultClient';
import { WEATHERS, ORDERS } from '../../../../models/results/Result';
import type { Result } from '../../../../models/results/Result';
import $ from 'jquery';
import type { Prefecture } from '../../../../models/prefectures/Prefecture';
import type { Region } from '../../../../models/regions/Region';
import type { Fish } from '../../../../models/fishes/Fish';
import AreaClient from '../../../../models/areas/AreaClient';
import FourmSpArticleUnderTitle from '../../../advertisements/FourmSpArticleUnderTitle';
import FourmSpArticleInArticle1st from '../../../advertisements/FourmSpArticleInArticle1st';
import AppFDL from '../../../advertisements/AppFDL';
import { sendUaReplaceState } from '../../../../lib/sendUaReplaceState';

type Props = {
  prefecture: Prefecture,
  region: Region,
  fish: Fish,
  mobile: boolean,
  fdl: string,
};

type State = {
  results: Array<Result>,
  order: any,
  weathers: Array<any>,
  weathersPrev: Array<any>,
  startOn: ?string,
  endOn: ?string,
  isLoading: boolean,
  page: number,
  hasNextPage: boolean,
  typingTimeout: number,
  evaluatedAdvertisements: boolean,
};

export default class IndexView extends React.Component<Props, State> {
  resultClient: ResultClient = new ResultClient();
  canCloseWeathersDropdown: boolean = false;
  areaClient: AreaClient = new AreaClient();

  constructor(props: Props) {
    super(props);

    const page = Number(new URL(window.location.href).searchParams.get('page'));
    this.state = {
      results: [],
      order: ORDERS[0],
      weathers: WEATHERS,
      weathersPrev: WEATHERS,
      startOn: null,
      endOn: null,
      isLoading: true,
      page: page > 0 ? page : 1,
      hasNextPage: true,
      typingTimeout: 0,
      evaluatedAdvertisements: false,
      areas: [],
    };
  }

  componentDidMount() {
    this.fetchResults();

    $('#weathers_dropdown').on({
      'shown.bs.dropdown': () => {
        this.canCloseWeathersDropdown = true;
      },
      click: e => {
        const target = $(e.target);
        this.canCloseWeathersDropdown = target.hasClass('closable');
      },
      'hide.bs.dropdown': () => {
        return this.canCloseWeathersDropdown;
      },
    });
  }

  fetchResults() {
    const { prefecture, fish } = this.props;
    const { page, order, startOn, endOn } = this.state;
    const weathers = this.state.weathers
      .filter(w => w.checked)
      .map(w => w.name);

    this.resultClient
      .fetchByPrefecture(
        page,
        order.key,
        weathers,
        startOn,
        endOn,
        null,
        prefecture.id,
        fish.id
      )
      .then(response => {
        const hasNextPage =
          response.headers &&
          response.headers.hasOwnProperty('link') &&
          linkHeaderParser.parse(response.headers.link).hasOwnProperty('next');
        this.setState({
          results: [...this.state.results, ...response.data].filter(
            (r, i, self) => self.map(r2 => r2.id).indexOf(r.id) === i
          ),
          hasNextPage: hasNextPage,
          isLoading: false,
        });
        this.evaluateAdvertisements();
      })
      .catch(error => {
        this.setState(
          {
            hasNextPage: false,
            isLoading: false,
          },
          () => {
            if (process.env.NODE_ENV === 'production') {
              window.location.replace('/500.html');
            }
          }
        );
        console.warn(error);
      });
  }

  evaluateAdvertisements() {
    if (window.gpt != null && !this.state.evaluatedAdvertisements) {
      this.setState({
        evaluatedAdvertisements: true,
      });
      window.gpt.evaluate();
    }
  }

  onLoadMore() {
    if (!this.state.isLoading && this.state.hasNextPage) {
      this.setState(
        {
          page: this.state.page + 1,
          isLoading: true,
        },
        () => {
          this.fetchResults();
          const url = new URL(location.href);
          url.searchParams.set('page', String(this.state.page));
          sendUaReplaceState(`${url.pathname}${url.search}`);
        }
      );
    }
  }

  onDatesChange(startOn: ?string, endOn: ?string) {
    this.setState(
      {
        startOn: startOn,
        endOn: endOn,
        results: [],
        page: 1,
        isLoading: true,
        hasNextPage: true,
      },
      () => this.fetchResults()
    );
  }

  onClickOrder(event: any, order: any) {
    event.preventDefault();

    if (this.state.order.key !== order.key) {
      this.setState(
        {
          order: order,
          results: [],
          page: 1,
          isLoading: true,
          hasNextPage: true,
        },
        () => this.fetchResults()
      );
    }
  }

  onChangeWeather(weather: any) {
    const nextState = this.state.weathersPrev.map(w => {
      return {
        key: w.key,
        name: w.name,
        checked: w.key === weather.key ? !weather.checked : w.checked,
      };
    });

    this.setState({ weathersPrev: nextState });
  }

  onCancelWeather(event: any) {
    event.preventDefault();
    this.setState({ weathersPrev: this.state.weathers });
  }

  onApplyWeather(event: any) {
    event.preventDefault();
    this.setState(
      {
        weathers: this.state.weathersPrev,
        results: [],
        page: 1,
        isLoading: true,
        hasNextPage: true,
      },
      () => this.fetchResults()
    );
  }

  renderWeatherButton(): Node {
    const { weathers } = this.state;
    const checkedWeathers = weathers.filter(w => w.checked);

    if (checkedWeathers.length === 0) {
      return (
        <button className="btn btn-light mr-10" data-toggle="dropdown">
          天気
        </button>
      );
    } else {
      return (
        <button className="btn btn-success mr-10" data-toggle="dropdown">
          {checkedWeathers.map(w => w.name).join(' ')}
        </button>
      );
    }
  }

  render(): Node {
    const { prefecture, region, fish } = this.props;
    const { results, weathersPrev } = this.state;
    let adIndex = 0;

    const resultsList = results.reduce((list, result, i) => {
      list.push(<ResultCard key={result.id} result={result} />);

      if (this.props.mobile && [3].includes(i)) {
        list.push(
          <div className="col-12" key={adIndex++}>
            <FourmSpArticleUnderTitle />
          </div>
        );
      } else if (this.props.mobile && [7].includes(i)) {
        list.push(
          <div className="col-12" key={adIndex++}>
            <FourmSpArticleInArticle1st />
          </div>
        );
      } else if (this.props.mobile && [11].includes(i)) {
        list.push(
          <div className="col-12 mt-20 mb-50" key={adIndex++}>
            <AppFDL fdl={this.props.fdl} />
          </div>
        );
      }

      return list;
    }, []);

    return (
      <div>
        <div className="breadcrumb-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">トップ</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/regions">地方から探す</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href={`/regions/${region.id}`}>{region.name}</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href={`/prefectures/${prefecture.id}`}>
                      {prefecture.name}の釣り場情報
                    </a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href={`/prefectures/${prefecture.id}/catches`}>
                      {prefecture.name}の釣果
                    </a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href={`/prefectures/${prefecture.id}/fishes/${fish.id}`}>
                      {prefecture.name}で釣れた
                      {fish.name}
                      の釣り・釣果情報
                    </a>
                  </li>
                  <li className="breadcrumb-item">
                    {prefecture.name}で釣れた
                    {fish.name}
                    の釣果
                  </li>
                </ol>
              </div>
              <div className="col-12">
                <h1 className="mr-10" style={{ display: 'inline' }}>
                  {prefecture.name}
                  で釣れた
                  {fish.name}
                  の釣果
                </h1>
                <span className="dropdown" id="weathers_dropdown">
                  {this.renderWeatherButton()}
                  <div className="dropdown-menu p-20">
                    <div className="mb-20">
                      {weathersPrev.map(weather => {
                        return (
                          <div className="form-check" key={weather.key}>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={weather.checked}
                              onChange={() => this.onChangeWeather(weather)}
                            />
                            <label className="form-check-label">
                              {weather.name}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                    <div className="clearfix">
                      <div className="pull-left">
                        <a
                          href=""
                          className="btn btn-light btn-sm closable"
                          onClick={e => this.onCancelWeather(e)}
                        >
                          キャンセル
                        </a>
                      </div>
                      <div className="pull-right">
                        <a
                          href=""
                          className="btn btn-primary btn-sm closable"
                          onClick={e => this.onApplyWeather(e)}
                        >
                          適用
                        </a>
                      </div>
                    </div>
                  </div>
                </span>
                <span style={{ display: 'inline-block' }}>
                  <DateRangeView
                    orientation={this.props.mobile ? 'vertical' : 'horizontal'}
                    withFullScreenPortal={this.props.mobile}
                    onDatesChange={(startDate, endDate) =>
                      this.onDatesChange(startDate, endDate)
                    }
                  />
                </span>
                <span className="dropdown">
                  <button className="btn btn-light" data-toggle="dropdown">
                    {this.state.order.name}
                  </button>
                  <ul className="dropdown-menu">
                    {ORDERS.map(order => {
                      return (
                        <li key={order.key}>
                          <a
                            href=""
                            className="dropdown-item"
                            onClick={e => this.onClickOrder(e, order)}
                          >
                            {order.name}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="container mt-30 mb-60">
          <h2>
            {prefecture.name}の{fish.name}の釣果情報
          </h2>
          <InfiniteScroll
            pageStart={0}
            loadMore={() => this.onLoadMore()}
            hasMore={this.state.hasNextPage}
            loader={<Loader />}
            className="row"
          >
            {resultsList}
          </InfiniteScroll>
        </div>
      </div>
    );
  }
}
