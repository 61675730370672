// @flow
import React from 'react';
import GoogleMap from 'google-map-react';
import { STYLE_OPTIONS } from './GoogleMaps';

type Props = {
  zoom: number,
  lat: number,
  lng: number,
  height: ?number,
  apiKey: string,
};

class LiteMap extends React.Component<Props> {
  mapRef: GoogleMap;

  static defaultProps = {
    height: 160,
  };

  componentDidUpdate(prevProps: Props) {
    if (prevProps.lat !== this.props.lat || prevProps.lng !== this.props.lng) {
      this.mapRef.map_.panTo(
        new window.google.maps.LatLng(this.props.lat, this.props.lng)
      );
    }
  }

  render() {
    const { zoom, lat, lng, height, apiKey } = this.props;
    return (
      <div style={{ flex: 1, height: height }}>
        <GoogleMap
          bootstrapURLKeys={{ key: apiKey }}
          defaultCenter={[lat, lng]}
          defaultZoom={zoom}
          options={{
            maxZoom: 13,
            styles: STYLE_OPTIONS,
          }}
          ref={ref => (this.mapRef = ref)}
        />
      </div>
    );
  }
}

export default LiteMap;
