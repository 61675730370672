// @flow
import React from 'react';
import styled from 'styled-components';
import type { Venue } from '../../../models/venues/Venue';
import VenueHelper from '../../../helpers/VenueHelper';

import { default as ObservatoryImage } from '../../../assets/images/views/venues/observatory.png';
import { default as PortImage } from '../../../assets/images/views/venues/port.png';

const Marker = styled.img`
  &:hover {
    cursor: pointer;
  }
`;

type Props = {
  venue: Venue,
  onClick: () => void,
};

export default function VenueMarker({ venue, onClick }: Props) {
  if (VenueHelper.isLand(venue)) {
    return (
      <Marker
        src={ObservatoryImage}
        lat={venue.lat}
        lng={venue.lng}
        width={32}
        height={32}
        onClick={onClick}
      />
    );
  } else {
    return (
      <Marker
        src={PortImage}
        lat={venue.lat}
        lng={venue.lng}
        width={32}
        height={32}
        onClick={onClick}
      />
    );
  }
}
