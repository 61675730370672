// @flow
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import * as linkHeaderParser from 'linkheader-parser/dist/linkheader-parser-node';
import AreaClient from '../../../models/areas/AreaClient';
import Loader from '../../commons/Loader';
import type { Area } from '../../../models/areas/Area';
import { displayMessage } from '../../commons/messages';
import { csrfToken } from '../../../helpers/fetch';

type Props = {};

type State = {
  areas: Array<Area>,
  isLoading: boolean,
  page: number,
  hasNextPage: boolean,
};

export default class IndexView extends React.Component<Props, State> {
  areaClient = new AreaClient();

  constructor(props: Props) {
    super(props);

    this.state = {
      areas: [],
      isLoading: true,
      page: 1,
      hasNextPage: true,
    };
  }

  componentDidMount() {
    this.fetchFollowings();
  }

  fetchFollowings() {
    const { page } = this.state;

    this.areaClient
      .fetchMyAreas(page)
      .then(response => {
        const hasNextPage =
          response.headers &&
          response.headers.link &&
          linkHeaderParser.parse(response.headers.link).hasOwnProperty('next');
        this.setState({
          areas: [...this.state.areas, ...response.data].filter(
            (a, i, self) => self.map(a2 => a2.id).indexOf(a.id) === i
          ),
          hasNextPage: hasNextPage,
          isLoading: false,
        });
      })
      .catch(error => {
        this.setState({ isLoading: false });
        console.warn(error);
      });
  }

  onLoadMore = () => {
    if (!this.state.isLoading && this.state.hasNextPage) {
      this.setState(
        {
          page: this.state.page + 1,
          isLoading: true,
        },
        () => this.fetchFollowings()
      );
    }
  };

  onClickRemove(areaId: number) {
    if (window.confirm('お気に入りから削除しますか?')) {
      this.areaClient
        .stopFollowing(areaId, csrfToken())
        .then(() => {
          this.setState({
            areas: this.state.areas.filter(a => a.id !== areaId),
          });
          displayMessage('お気に入りから削除しました');
        })
        .catch(error => {
          console.warn(error);
        });
    }
  }

  render() {
    const { areas } = this.state;

    return (
      <div className="listview bordered">
        <InfiniteScroll
          pageStart={0}
          loadMore={this.onLoadMore}
          hasMore={this.state.hasNextPage}
          loader={<Loader key={0} />}
        >
          {areas.map(area => (
            <div key={area.id} className="listview-item">
              <div className="listview-content media">
                {area.latest_result_square_photo_url != null && (
                  <a href={`/areas/${area.id}`}>
                    <img
                      src={area.latest_result_square_photo_url}
                      width={48}
                      height={48}
                      alt={area.name}
                      className="rounded-circle mr-3"
                    />
                  </a>
                )}
                <div className="media-body">
                  <a href={`/areas/${area.id}`}>
                    <h6 className="mt-0">{area.name}</h6>
                  </a>
                </div>
              </div>
              <div className="listview-actions">
                <i
                  onClick={() => this.onClickRemove(area.id)}
                  className="listview-actions-item ion-ios-close-outline"
                />
              </div>
            </div>
          ))}
        </InfiniteScroll>
      </div>
    );
  }
}
