// @flow
import AxiosClient from '../AxiosClient';
import type { AxiosPromise } from 'axios';
import type { Maker, MakerForm } from './Maker';
import type { Account } from '../accounts/Account';

export default class MakerClient extends AxiosClient {
  fetchAll(productType: ?string): AxiosPromise<Array<Maker>> {
    return this.buildClient().get('/api/v2/makers/index_without_others.json', {
      params: {
        product_type: productType,
      },
    });
  }

  create(
    form: MakerForm,
    account: Account,
    token: string
  ): AxiosPromise<Maker> {
    return this.buildTokenAuthClient(account, token).post(`/makers.json`, {
      maker: { ...form },
    });
  }
}
