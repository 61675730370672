// @flow
import React from 'react';
import type { Review } from '../../../models/products/reviews/Review';
import moment from 'moment';
import type { Account } from '../../../models/accounts/Account';
import ReviewSocialComp from './ReviewSocialComp';
import Comments from './comments/Comments';
import { default as UserNoImage } from '../../../assets/images/views/users/no-image.png';

type Props = {
  review: Review,
  account: Account,
  token: string,
};

type State = {
  review: Review,
  commentsVisible: boolean,
  focusCommentForm: boolean,
};

export default class ResultTimelineItem extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      review: props.review,
      commentsVisible: false,
      focusCommentForm: false,
    };
  }

  render() {
    const { account, token } = this.props;
    const { review, commentsVisible, focusCommentForm } = this.state;

    return (
      <div className="results timeline card mb-60">
        <div className="card-header">
          <div className="media">
            <div className="pull-left">
              <a
                href={`/users/${review.user.id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={review.user.thumb_url}
                  className="rounded-circle"
                  width={60}
                  alt="Image Not Found"
                  onError={e => (e.target.src = UserNoImage)}
                />
              </a>
            </div>
            <div className="media-body">
              <h6 className="media-heading">
                <a
                  href={`/users/${review.user.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {review.user.name}
                </a>
                {review.user.is_official && (
                  <i className="fa fa-check-square text-approved ml-5" />
                )}
              </h6>
              <div>{review.product.full_name}をレビューしました</div>
              <small className="date">
                {moment(review.updated_at).format('YYYY年MM月DD日 HH:mm')}
              </small>
            </div>
          </div>
          <div className="mb-10 mt-10">
            <strong>{review.title}</strong>
          </div>
        </div>
        <a
          href={`/tackles/${review.product.id}/reviews/${review.id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="thumbnail">
            <img src={review.thumb_url} alt={review.product.full_name} />
          </div>
        </a>
        <div className="card-body">
          <div className="comment text-muted pt-2">
            {review.content_summary}
          </div>
          <ReviewSocialComp
            review={review}
            voteAccessible={true}
            isSignedIn={true}
            account={account}
            token={token}
            onToggleVoted={(voted, cached_votes_up) => {
              this.setState({
                review: {
                  ...review,
                  voted: voted,
                  cached_votes_up: cached_votes_up,
                },
              });
            }}
            onClickComments={focusedInput => {
              this.setState({
                commentsVisible: true,
                focusCommentForm: focusedInput,
              });
            }}
          />
        </div>
        {commentsVisible && (
          <div className="card-footer">
            <Comments
              review={review}
              token={token}
              focusForm={focusCommentForm}
              userSignedIn={true}
            />
          </div>
        )}
      </div>
    );
  }
}
