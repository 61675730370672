// @flow
import React from 'react';
import { Input } from 'reactstrap';

type Props = {
  placeholder: string,
  onChangeText: string => void,
};

export default function SearchBar({
  placeholder,
  onChangeText,
  ...props
}: Props) {
  return (
    <Input
      type="text"
      name="search"
      placeholder={placeholder}
      onChange={e => onChangeText(e.target.value)}
      {...props}
    />
  );
}
