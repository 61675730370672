// @flow
import moment from 'moment';

export function katakanaToHiragana(val: string): string {
  return val.replace(/[\u30a1-\u30f6]/g, match => {
    const chr = match.charCodeAt(0) - 0x60;
    return String.fromCharCode(chr);
  });
}

export function hiraganaToKatagana(val: string): string {
  return val.replace(/[\u3041-\u3096]/g, match => {
    const chr = match.charCodeAt(0) + 0x60;
    return String.fromCharCode(chr);
  });
}

export function hankana2zenkana(val: string): string {
  const kanaMap = {
    ｶﾞ: 'ガ',
    ｷﾞ: 'ギ',
    ｸﾞ: 'グ',
    ｹﾞ: 'ゲ',
    ｺﾞ: 'ゴ',
    ｻﾞ: 'ザ',
    ｼﾞ: 'ジ',
    ｽﾞ: 'ズ',
    ｾﾞ: 'ゼ',
    ｿﾞ: 'ゾ',
    ﾀﾞ: 'ダ',
    ﾁﾞ: 'ヂ',
    ﾂﾞ: 'ヅ',
    ﾃﾞ: 'デ',
    ﾄﾞ: 'ド',
    ﾊﾞ: 'バ',
    ﾋﾞ: 'ビ',
    ﾌﾞ: 'ブ',
    ﾍﾞ: 'ベ',
    ﾎﾞ: 'ボ',
    ﾊﾟ: 'パ',
    ﾋﾟ: 'ピ',
    ﾌﾟ: 'プ',
    ﾍﾟ: 'ペ',
    ﾎﾟ: 'ポ',
    ｳﾞ: 'ヴ',
    ﾜﾞ: 'ヷ',
    ｦﾞ: 'ヺ',
    ｱ: 'ア',
    ｲ: 'イ',
    ｳ: 'ウ',
    ｴ: 'エ',
    ｵ: 'オ',
    ｧ: 'ァ',
    ｨ: 'ィ',
    ｩ: 'ゥ',
    ｪ: 'ェ',
    ｫ: 'ォ',
    ｶ: 'カ',
    ｷ: 'キ',
    ｸ: 'ク',
    ｹ: 'ケ',
    ｺ: 'コ',
    ｻ: 'サ',
    ｼ: 'シ',
    ｽ: 'ス',
    ｾ: 'セ',
    ｿ: 'ソ',
    ﾀ: 'タ',
    ﾁ: 'チ',
    ﾂ: 'ツ',
    ﾃ: 'テ',
    ﾄ: 'ト',
    ﾅ: 'ナ',
    ﾆ: 'ニ',
    ﾇ: 'ヌ',
    ﾈ: 'ネ',
    ﾉ: 'ノ',
    ﾊ: 'ハ',
    ﾋ: 'ヒ',
    ﾌ: 'フ',
    ﾍ: 'ヘ',
    ﾎ: 'ホ',
    ﾏ: 'マ',
    ﾐ: 'ミ',
    ﾑ: 'ム',
    ﾒ: 'メ',
    ﾓ: 'モ',
    ﾔ: 'ヤ',
    ﾕ: 'ユ',
    ﾖ: 'ヨ',
    ﾗ: 'ラ',
    ﾘ: 'リ',
    ﾙ: 'ル',
    ﾚ: 'レ',
    ﾛ: 'ロ',
    ﾜ: 'ワ',
    ｦ: 'ヲ',
    ﾝ: 'ン',
    ｯ: 'ッ',
    ｬ: 'ャ',
    ｭ: 'ュ',
    ｮ: 'ョ',
    ッ: 'ツ',
    ャ: 'ヤ',
    ュ: 'ユ',
    ョ: 'ヨ',
    '｡': '。',
    '､': '、',
    ｰ: 'ー',
    '｢': '「',
    '｣': '」',
    '･': '・',
  };

  const reg = new RegExp('(' + Object.keys(kanaMap).join('|') + ')', 'g');

  return val
    .replace(reg, c => kanaMap[c])
    .replace(/ﾞ/g, '゛')
    .replace(/ﾟ/g, '゜')
    .replace('(', '（')
    .replace(')', '）');
}

export function getUrlLocationProps(url: string): any {
  // $FlowFixMe
  const host = url.match(/^https?:\/{2,}(.*?)(?:\/|\?|#|$)/)[1];
  const hostEndPos = url.indexOf(host) + host.length + 1;
  let pos = url.indexOf('?', hostEndPos);
  const search = pos < 0 ? null : url.substring(pos + 1);
  // pos = pos < 0
  //   ? url.indexOf('&', hostEndPos)
  //   : pos;
  const pathname =
    pos < 0 ? url.substring(hostEndPos) : url.substring(hostEndPos, pos);
  return { host, pathname, search };
}

export function withoutLineFeed(val: ?string = '') {
  return val != null ? val.replace(/\r?\n/g, ' ') : '';
}

export function formatted(...rest: any) {
  const loop = function(f, i, args) {
    return i < args.length
      ? loop(f.replace(new RegExp('%' + i + '\\$s', 'g'), args[i]), i + 1, args) : f; // eslint-disable-line
  };
  return loop(rest[0], 1, rest);
}

export function camelize(text: string): string {
  return text
    .split('_')
    .map(w => w.replace(/./, m => m.toUpperCase()))
    .join('');
}

export function date(text: ?string): string {
  return text != null ? moment(text).format('YYYY年MM月DD日') : '';
}

export function isNumberString(s: string): boolean {
  return /([0-9]+|Infinity)$/.test(s);
}
