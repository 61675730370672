// @flow
import { csrfToken } from '../../helpers/fetch';
import AxiosClient from '../AxiosClient';
import type { AxiosPromise } from 'axios';

export default class AuthClient extends AxiosClient {
  create(email: string, password: string): AxiosPromise<Object> {
    return this.buildClient(csrfToken()).post(
      '/api/v2/authentication_codes/registrations',
      {
        registration: {
          email: email,
          password: password,
        },
      }
    );
  }

  resend(email: string): AxiosPromise<Object> {
    return this.buildClient(csrfToken()).post(
      '/api/v2/authentication_codes/registrations/resend',
      {
        resend: {
          email: email,
        },
      }
    );
  }

  confirmation(code: string): AxiosPromise<Object> {
    return this.buildClient(csrfToken()).post(
      '/api/v2/authentication_codes/registrations/confirmation',
      {
        withCredentials: true,
        confirmation: {
          authentication_code: code,
        },
      }
    );
  }
}
