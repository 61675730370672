// @flow
import JQueryView, { on } from '../../../common/views/JQueryView';

declare var $: any;

export default class SearchFormView extends JQueryView {
  constructor(el: string) {
    super(el);
  }

  @on('click #keyword_clear')
  onClickKeywordClear(event: any) {
    event.preventDefault();
    $('.keyword_clear').val('');
  }

  @on('click #price_clear')
  onClickPriceClear(event: any) {
    event.preventDefault();
    $('#q_price_gteq').val('');
    $('#q_price_lteq').val('');
  }
}
