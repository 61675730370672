// @flow

import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import CloseIcon from '../../assets/images/views/layouts/close.png';
import AuthForms from './AuthForms';

const AuthModalPortal = ({ visible, setVisible, children }) => {
  const el = useRef(document.createElement('div'));

  useEffect(() => {
    const currentEl = el.current;
    const modalRoot = document.querySelector('main');

    if (modalRoot) {
      modalRoot.appendChild(currentEl);

      return () => {
        modalRoot.removeChild(currentEl);
      };
    }
  }, []);

  return ReactDOM.createPortal(
    <section data-scope-path="application/views/layouts/auth_modal_potal">
      <div className={`${visible ? 'auth-modal-open' : 'auth-modal-close'}`}>
        {/* ブラウザが非表示のログインフォームに反応するのを回避するためにvisibleで制御 */}
        <div className="auth-modal-body">{visible && children}</div>
        <div
          className="auth-modal-background"
          onClick={() => setVisible(false)}
        />
      </div>
    </section>,
    el.current
  );
};

const AuthModal = (): React$Element<'section'> => {
  const [visible, setVisible] = useState(false);

  const modalContent = (
    <AuthModalPortal visible={visible} setVisible={setVisible}>
      <section data-scope-path="application/views/layouts/auth_modal_header">
        <h2 className="auth-modal-title">ログイン / 新規登録</h2>
        <img
          src={CloseIcon}
          className="auth-modal-close-trigger"
          onClick={() => setVisible(false)}
        />
      </section>
      <AuthForms />
    </AuthModalPortal>
  );

  return (
    <section data-scope-path="application/views/layouts/auth_modal_trigger">
      <button onClick={() => setVisible(true)}>ログイン / 新規登録</button>
      {modalContent}
    </section>
  );
};

export default AuthModal;
