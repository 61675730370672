// @flow
import * as types from './types';
import type { MyLure } from '../models/my_lures/MyLure';

export function updateMyLures(myLures: Array<MyLure>): any {
  return {
    type: types.UPDATE_MY_LURES,
    payload: myLures,
  };
}

export function updateMyLure(myLure: MyLure): any {
  return {
    type: types.UPDATE_MY_LURE,
    payload: myLure,
  };
}
