// @flow
import React from 'react';
import { history } from '../../../application/views/my/IndexView';
import HeaderMobileMenuModal from './HeaderMobileMenuModal';

type Props = {
  userId: ?number,
  path: string,
  fdl: string,
};

type State = {
  isOpenMenu: boolean,
};

export default class HeaderMobileMenu extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isOpenMenu: false,
    };
  }

  toggleMenu() {
    this.setState(prevState => ({
      isOpenMenu: !prevState.isOpenMenu,
    }));
  }

  onClickLink(e: any, path: string) {
    e.preventDefault();
    this.setState({ isOpenMenu: false }, () => history.push(path));
  }

  render(): React$Node {
    const { userId, path, fdl } = this.props;
    const { isOpenMenu } = this.state;

    return (
      <React.Fragment>
        <button
          className="navbar-toggler"
          type="button"
          onClick={() => this.toggleMenu()}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <HeaderMobileMenuModal
          userId={userId}
          path={path}
          fdl={fdl}
          isOpen={isOpenMenu}
          onClose={() => this.toggleMenu()}
          onClickLink={(e, path) => this.onClickLink(e, path)}
        />
      </React.Fragment>
    );
  }
}
