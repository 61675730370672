// @flow
import { compose, createStore, applyMiddleware, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import reducers from '../reducers';

export default (props: any) => {
  const { account, mobile, settings, railsEnv } = props;
  const initialState = {
    account,
    railsContext: {
      railsEnv,
      mobile,
      settings,
    },
  };
  const reducer = combineReducers(reducers);
  const composedStore = compose(applyMiddleware(thunkMiddleware));

  return composedStore(createStore)(
    reducer,
    initialState,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );
};
