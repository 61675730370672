// @flow
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import _ from 'lodash';
import * as linkHeaderParser from 'linkheader-parser/dist/linkheader-parser-node';
import { Container, TabPane } from 'reactstrap';
import Loader from '../commons/Loader';
import ResultCard from './ResultCard';
import ResultClient from '../../models/results/ResultClient';
import PrefectureCard from '../prefectures/PrefectureCard';
import PrefectureClient from '../../models/prefectures/PrefectureClient';
import FourmPcArticleBillboard from '../advertisements/FourmPcArticleBillboard';
import FourmSpArticleUnderTitle from '../advertisements/FourmSpArticleUnderTitle';
import FourmSpArticleInArticle1st from '../advertisements/FourmSpArticleInArticle1st';
import AppFDL from '../advertisements/AppFDL.js';
import type { Result } from '../../models/results/Result';
import type { Prefecture } from '../../models/prefectures/Prefecture';

export const RESULTS_TAB = 'results_tab';

type Props = {
  keyword: ?string,
  mobile: boolean,
  order: any,
  weathers: Array<any>,
  startOn: ?string,
  endOn: ?string,
  fdl: string,
};

type State = {
  results: Array<Result>,
  isLoading: boolean,
  keyword: ?string,
  order: any,
  weathers: Array<any>,
  startOn: ?string,
  endOn: ?string,
  page: number,
  hasNextPage: boolean,
  typingTimeout: any,
  evaluatedAdvertisements: boolean,
  prefectures: Array<Prefecture>,
};

export default class ResultsTabPane extends React.Component<Props, State> {
  resultClient = new ResultClient();
  prefectureClient = new PrefectureClient();

  constructor(props: Props) {
    super(props);

    this.state = {
      results: [],
      isLoading: true,
      keyword: props.keyword,
      order: { ...props.order },
      weathers: _.cloneDeep(props.weathers),
      startOn: props.startOn,
      endOn: props.endOn,
      page: 1,
      hasNextPage: true,
      typingTimeout: 0,
      evaluatedAdvertisements: false,
      prefectures: [],
    };
  }

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    if (ResultsTabPane.shouldFetchResults(nextProps, prevState)) {
      return {
        results: [],
        prefectures: [],
        keyword: nextProps.keyword,
        order: { ...nextProps.order },
        weathers: _.cloneDeep(nextProps.weathers),
        startOn: nextProps.startOn,
        endOn: nextProps.endOn,
        page: 1,
        isLoading: true,
      };
    }

    return null;
  }

  static shouldFetchResults(props: Props, state: State): boolean {
    const { keyword, order, weathers, startOn, endOn } = props;
    const keywordChanged = keyword !== state.keyword;
    const orderChanged = order.name !== state.order.name;
    const weatherChanged =
      weathers.find((w, i) => w.checked !== state.weathers[i].checked) != null;
    const termChanged = startOn !== state.startOn || endOn !== state.endOn;
    return keywordChanged || orderChanged || weatherChanged || termChanged;
  }

  componentDidMount() {
    this.fetchResults();
    this.fetchPrefectures();
  }

  componentDidUpdate(nextProps: Props) {
    if (ResultsTabPane.shouldFetchResults(nextProps, this.state)) {
      this.fetchResults();
      this.fetchPrefectures();
    }
  }

  fetchResults() {
    const { order, keyword, startOn, endOn } = this.props;
    const { page } = this.state;
    const weathers = this.props.weathers
      .filter(w => w.checked)
      .map(w => w.name);

    this.resultClient
      .fetchAll(page, order.key, keyword, weathers, startOn, endOn, null, null)
      .then(response => {
        const hasNextPage =
          response.headers &&
          response.headers.hasOwnProperty('link') &&
          linkHeaderParser.parse(response.headers.link).hasOwnProperty('next');
        this.setState({
          results: [...this.state.results, ...response.data].filter(
            (r, i, self) => self.map(r2 => r2.id).indexOf(r.id) === i
          ),
          hasNextPage: hasNextPage,
          isLoading: false,
        });

        if (this.props.mobile) {
          this.evaluateAdvertisements();
        }
      })
      .catch(error => {
        this.setState(
          {
            hasNextPage: false,
            isLoading: false,
          },
          () => {
            if (process.env.NODE_ENV === 'production') {
              window.location.replace('/500.html');
            }
          }
        );
        console.warn(error);
      });
  }

  fetchPrefectures() {
    const { keyword } = this.props;

    this.prefectureClient
      .fetchAll(keyword)
      .then(response => {
        this.setState({
          prefectures: [...this.state.prefectures, ...response.data].filter(
            (r, i, self) => self.map(r2 => r2.id).indexOf(r.id) === i
          ),
        });
      })
      .catch(error => {
        console.warn(error);
      });
  }

  evaluateAdvertisements() {
    if (window.gpt != null && !this.state.evaluatedAdvertisements) {
      this.setState({
        evaluatedAdvertisements: true,
      });
      window.gpt.evaluate();
    }
  }

  onLoadMore() {
    if (!this.state.isLoading && this.state.hasNextPage) {
      this.setState(
        {
          page: this.state.page + 1,
          isLoading: true,
        },
        () => this.fetchResults()
      );
    }
  }

  render() {
    const { results, prefectures } = this.state;
    let adIndex = 0;

    const resultsList = results.reduce((list, result, i) => {
      list.push(<ResultCard key={result.id} result={result} />);

      if (this.props.mobile && [3].includes(i)) {
        list.push(
          <div className="col-12" key={adIndex++}>
            <FourmSpArticleUnderTitle />
          </div>
        );
      } else if (this.props.mobile && [7].includes(i)) {
        list.push(
          <div className="col-12" key={adIndex++}>
            <FourmSpArticleInArticle1st />
          </div>
        );
      } else if (this.props.mobile && [11].includes(i)) {
        list.push(
          <div className="col-12 mt-20 mb-50" key={adIndex++}>
            <AppFDL fdl={this.props.fdl} />
          </div>
        );
      }

      return list;
    }, []);

    return (
      <TabPane tabId={RESULTS_TAB}>
        <Container className="mb-50">
          {!this.props.mobile && (
            <div className="col-12 mb-50">
              <FourmPcArticleBillboard />
            </div>
          )}

          {prefectures.length > 0 && (
            <div>
              <h2>都道府県から探す</h2>
              <div className="col-12 mb-50 prefectures">
                <div className="carousel">
                  <div className="carousel-wrap">
                    {prefectures.map(prefecture => {
                      return (
                        <PrefectureCard
                          key={prefecture.id}
                          prefecture={prefecture}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          )}

          {prefectures.length > 0 && <h2>釣果速報</h2>}

          <InfiniteScroll
            pageStart={0}
            loadMore={() => this.onLoadMore()}
            hasMore={this.state.hasNextPage}
            loader={<Loader key={0} />}
            className="row"
            style={{ paddingBottom: '100px' }}
          >
            {resultsList}
          </InfiniteScroll>
        </Container>
      </TabPane>
    );
  }
}
