// @flow
import AxiosClient from '../../AxiosClient';
import type { AxiosPromise } from 'axios';
import type { Comment } from './Comment';

export default class CommentClient extends AxiosClient {
  fetchAll(resultId: number, limit: ?number): AxiosPromise<Array<Comment>> {
    return this.buildClient().get(`/api/v2/results/${resultId}/comments.json`, {
      params: {
        limit: limit,
      },
    });
  }

  post(
    resultId: number,
    commentId: ?number,
    body: string,
    token: string
  ): AxiosPromise<Comment> {
    return this.buildClient(token).post(
      `/api/v2/results/${resultId}/comments/create_for_web.json`,
      {
        comment: {
          comment_id: commentId,
          body: body,
        },
      }
    );
  }

  destroy(
    resultId: number,
    commentId: number,
    token: string
  ): AxiosPromise<Comment> {
    return this.buildClient(token).delete(
      `/api/v2/results/${resultId}/comments/${commentId}.json`
    );
  }
}
