// @flow
import * as types from './types';
import type { MyReel } from '../models/my_reels/MyReel';

export function updateMyReels(myReels: Array<MyReel>): any {
  return {
    type: types.UPDATE_MY_REELS,
    payload: myReels,
  };
}

export function updateMyReel(myReel: MyReel): any {
  return {
    type: types.UPDATE_MY_REEL,
    payload: myReel,
  };
}
