// @flow
import { UPDATE_MY_FIELDS } from '../actions/types';
import type { Field } from '../models/fields/Field';

export const initialState = [];

export default (
  state: Array<Field> = initialState,
  action: any
): Array<Field> => {
  if (action.type === UPDATE_MY_FIELDS) {
    return action.payload;
  } else {
    return state;
  }
};
