// @flow
import Gpt from '../application/utils/Gpt';

window.gpt = new Gpt();

window.views = {
  products: {
    SearchFormView: require('../application/views/products/SearchFormView.js')
      .default,
    ShowView: require('../application/views/products/ShowView.js').default,
    reviews: {
      FormView: require('../application/views/products/reviews/FormView.js')
        .default,
    },
    rods: {
      FormView: require('../application/views/products/rods/FormView.js')
        .default,
    },
    reels: {
      FormView: require('../application/views/products/reels/FormView.js')
        .default,
    },
  },
  results: {
    IndexView: require('../application/views/results/IndexView.js').default,
    ShowView: require('../application/views/results/ShowView.js').default,
  },
  areas: {
    ShowView: require('../application/views/areas/ShowView.js').default,
  },
  prefectures: {
    ShowView: require('../application/views/prefectures/ShowView.js').default,
  },
  venues: {
    ShowView: require('../application/views/venues/ShowView.js').default,
  },
  mzEvents: {
    FormView: require('../application/views/mz_events/FormView.js').default,
    images: {
      results: {
        FormView: require('../application/views/mz_events/images/results/FormView.js')
          .default,
      },
    },
  },
  my: {
    followings: {
      IndexView: require('../application/views/my/followings/IndexView.js')
        .default,
    },
    areas: {
      IndexView: require('../application/views/my/areas/IndexView.js').default,
    },
  },
};

// for react-rails
// react-railsを使うための設定なのでESLintとFlowのルールを無視する
// $FlowFixMe
var componentRequireContext = require.context('application/views', true);
var ReactRailsUJS = require('react_ujs');
// eslint-disable-next-line react-hooks/rules-of-hooks
ReactRailsUJS.useContext(componentRequireContext);
