// @flow
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';

import { FlatList, ActivityIndicator } from '../../commons/react-native';
import { View } from '../../commons/native-base';
import MyReelClient from '../../../models/my_reels/MyReelClient';
import type { MyReel } from '../../../models/my_reels/MyReel';
import { updateMyReels } from '../../../actions/myReels';
import OrderModal from './OrderModal';
import MyReelListItem from './MyReelListItem';

import type { BaseProps } from '../../../../application';
import type { Account } from '../../../models/accounts/Account';
import { displayAxiosError } from '../../commons/Toast';

type Props = BaseProps & {
  account: Account,
  myRods: Array<MyReel>,
  updateMyReels: (myReels: Array<MyReel>) => void,
};

type State = {
  isLoading: boolean,
  sortState: string,
  myReels: Array<MyReel>,
  modalVisible: boolean,
  isRefreshing: boolean,
};

class IndexView extends React.Component<Props, State> {
  reelClient = new MyReelClient();

  constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: true,
      sortState: 'maker',
      myReels: [],
      modalVisible: false,
      isRefreshing: false,
    };
  }

  componentDidMount() {
    this.fetchMyReels();
  }

  // componentWillReceiveProps(nextProps: BaseProps) {
  //   this.setState({
  //     isLoading: false,
  //     isRefreshing: false,
  //     myReels: this.orderedMyReels(
  //       this.state.sortState,
  //       nextProps.screenProps.myReels
  //     ),
  //   });
  // }

  fetchMyReels() {
    const { account, updateMyReels } = this.props;

    this.reelClient
      .fetchAll(account)
      .then(response => {
        this.setState(
          { myReels: response.data, isLoading: false, isRefreshing: false },
          () => updateMyReels(response.data)
        );
      })
      .catch(error => {
        this.setState({ isLoading: false, isRefreshing: false });
        displayAxiosError(error);
      });
  }

  orderedMyReels(
    sortType: string,
    myReels: Array<MyReel> = this.state.myReels
  ): Array<MyReel> {
    switch (sortType) {
      /*eslint indent: ["error", 2, { "SwitchCase": 1 }]*/
      case 'updatedAsc':
        return myReels.sort((a, b) => {
          if (a.updated_at == null) {
            return 1;
          } else if (b.updated_at == null) {
            return -1;
          } else if (a.updated_at < b.updated_at) {
            return -1;
          } else if (a.updated_at > b.updated_at) {
            return 1;
          } else {
            return 0;
          }
        });
      case 'updatedDesc':
        return myReels.sort((a, b) => {
          if (a.updated_at == null) {
            return 1;
          } else if (b.updated_at == null) {
            return -1;
          } else if (a.updated_at < b.updated_at) {
            return 1;
          } else if (a.updated_at > b.updated_at) {
            return -1;
          } else {
            return 0;
          }
        });
      case 'getdateAsc':
        return myReels.sort((a, b) => {
          if (a.get_date == null) {
            return 1;
          } else if (b.get_date == null) {
            return -1;
          } else if (b.get_date && a.get_date < b.get_date) {
            return -1;
          } else if (b.get_date && a.get_date > b.get_date) {
            return 1;
          } else {
            return 0;
          }
        });
      case 'getdateDesc':
        return myReels.sort((a, b) => {
          if (a.get_date == null) {
            return 1;
          } else if (b.get_date == null) {
            return -1;
          } else if (b.get_date && a.get_date < b.get_date) {
            return 1;
          } else if (b.get_date && a.get_date > b.get_date) {
            return -1;
          } else {
            return 0;
          }
        });
      default:
        return myReels.sort((a, b) => {
          if (
            a.maker &&
            b.maker &&
            a.maker.name.toLowerCase() < b.maker.name.toLowerCase()
          ) {
            return -1;
          } else if (
            a.maker &&
            b.maker &&
            a.maker.name.toLowerCase() > b.maker.name.toLowerCase()
          ) {
            return 1;
          } else {
            return 0;
          }
        });
    }
  }

  renderFooter = () => {
    const { isLoading, isRefreshing } = this.state;

    if (isRefreshing) {
      return null;
    }

    if (isLoading) {
      return (
        <View
          style={{
            paddingVertical: 20,
            borderTopWidth: 1,
            borderColor: '#CED0CE',
          }}
        >
          <ActivityIndicator animating size="large" />
        </View>
      );
    } else {
      return (
        <Row style={{ paddingTop: 20 }}>
          <Col md={6}>
            <Link to="/my/reels/new" className="btn btn-block btn-primary">
              リールを登録する
            </Link>
          </Col>
        </Row>
      );
    }
  };

  handleRefresh = () => {
    this.setState(
      {
        myReels: [],
        isRefreshing: true,
        isLoading: false,
      },
      () => {
        this.fetchMyReels();
      }
    );
  };

  render() {
    const { history } = this.props;
    const { myReels, modalVisible, sortState, isRefreshing } = this.state;

    return (
      <React.Fragment>
        <View style={{ flex: 1, overflow: 'hidden' }}>
          <FlatList
            data={myReels}
            extraData={sortState}
            renderItem={item => (
              <MyReelListItem key={item.id} history={history} myReel={item} />
            )}
            ListFooterComponent={this.renderFooter}
            onRefresh={this.handleRefresh}
            refreshing={isRefreshing}
          />
        </View>
        <OrderModal
          visible={modalVisible}
          type={sortState}
          onApply={type => {
            this.setState({
              myReels: this.orderedMyReels(type),
              sortState: type,
              modalVisible: false,
            });
          }}
          onClose={() => this.setState({ modalVisible: false })}
        />
      </React.Fragment>
    );
  }
}

export default connect(
  state => ({
    account: state.account,
    myReels: state.myReels,
  }),
  dispatch => bindActionCreators({ updateMyReels }, dispatch)
)(IndexView);
