// @flow
import React from 'react';
import * as linkHeaderParser from 'linkheader-parser/dist/linkheader-parser-node';
import { Col, Container, TabPane } from 'reactstrap';
import type { User } from '../../models/users/User';
import UserClient from '../../models/users/UserClient';
import InfiniteScroll from 'react-infinite-scroller';
import Loader from '../commons/Loader';
import UserCard from '../users/UserCard';
import styled from 'styled-components';

const Link = styled.a`
  &:hover,
  &:focus {
    text-decoration: none;
  }
`;

export const USERS_TAB = 'users_tab';

type Props = {
  keyword: ?string,
  active: boolean,
};

type State = {
  users: Array<User>,
  keyword: ?string,
  isLoading: boolean,
  page: number,
  hasNextPage: boolean,
};

export default class UsersTabPane extends React.Component<Props, State> {
  userClient = new UserClient();

  constructor(props: Props) {
    super(props);

    this.state = {
      users: [],
      keyword: props.keyword,
      isLoading: true,
      page: 1,
      hasNextPage: true,
    };
  }

  componentDidMount() {
    setTimeout(() => this.fetchUsers(), 2000);
  }

  componentDidUpdate() {
    const { active, keyword } = this.props;
    // タブがアクティブになったタイミングで、キーワードが変更されていた場合にfetchする
    if (active && this.state.keyword !== keyword) {
      this.setState(
        {
          users: [],
          keyword: keyword,
          page: 1,
          isLoading: true,
        },
        () => this.fetchUsers()
      );
    }
  }

  fetchUsers() {
    const { keyword } = this.props;
    const { page, users } = this.state;

    this.userClient
      .searchFromResults(page, keyword)
      .then(response => {
        const hasNextPage =
          response.headers &&
          response.headers.hasOwnProperty('link') &&
          linkHeaderParser.parse(response.headers.link).hasOwnProperty('next');

        this.setState({
          users: [...users, ...response.data].filter(
            (u, i, self) => self.map(u2 => u2.id).indexOf(u.id) === i
          ),
          hasNextPage: hasNextPage,
          isLoading: false,
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  onLoadMore = () => {
    if (!this.state.isLoading && this.state.hasNextPage) {
      this.setState(
        {
          page: this.state.page + 1,
          isLoading: true,
        },
        () => this.fetchUsers()
      );
    }
  };

  onClick() {
    // $FlowFixMe
    gtag('event', 'click', {
      event_category: 'ToUser',
      event_label: 'users_tab_pane',
    });
  }

  render() {
    const { users, hasNextPage } = this.state;

    return (
      <TabPane tabId={USERS_TAB} className="pt-40">
        <Container>
          <InfiniteScroll
            pageStart={0}
            loadMore={() => this.onLoadMore()}
            hasMore={hasNextPage}
            loader={<Loader key={0} />}
            className="row"
            style={{ paddingBottom: '100px' }}
          >
            {users.map(user => (
              <Col key={user.id} xs="6" md="2">
                <Link
                  href={`/users/${user.id}`}
                  className="body-color"
                  onClick={this.onClick.bind(this)}
                  // eslint-disable-next-line react/jsx-no-target-blank
                  target="_blank"
                >
                  <UserCard key={user.id} user={user} />
                </Link>
              </Col>
            ))}
          </InfiniteScroll>
        </Container>
      </TabPane>
    );
  }
}
