// @flow
import React from 'react';
import styled from 'styled-components';
import FacebookIcon from '../../assets/images/views/meisters/facebook_icon.png';
import TwitterIcon from '../../assets/images/views/meisters/twitter_icon.png';

const meistersPath = '/meisters';
const meisterMembersPath = '/meisters/members';

type Props = {
  open: boolean,
};

function HeaderNavigationSpRoot(props: Props) {
  // ReactOnRailsの不具合で直接Hooksを使ってるコンポーネントをexportできない
  return <HeaderNavigationSp {...props} />;
}

function HeaderNavigationSp({ open }: Props) {
  return (
    <Bacnground open={open}>
      <Container open={open}>
        <NavLinkContainer>
          <NavLink
            href={meistersPath}
            active={meistersPath === window.location.pathname}
          >
            トップ
          </NavLink>
          <NavLink
            href={meisterMembersPath}
            active={meisterMembersPath === window.location.pathname}
          >
            アングラーズマイスター 一覧
          </NavLink>
        </NavLinkContainer>

        <ShareOnContainer>
          <span>SHARE ON</span>
          <ShareIconContainer>
            <ShareIcon
              href={encodeURI(
                `https://twitter.com/intent/tweet?text=${document.title}&url=${window.location.href}&hashtags=ANGLERS_jp`
              )}
              target="_blank"
              rel="nofollow noreferrer"
            >
              <img src={TwitterIcon} alt="ツイッターシェアアイコン" />
            </ShareIcon>
            <ShareIcon
              href={encodeURI(
                `https://www.facebook.com/sharer/sharer.php.php?&url=${window.location.href}`
              )}
              target="_blank"
              rel="nofollow noreferrer"
            >
              <img src={FacebookIcon} alt="フェイスブックシェアアイコン" />
            </ShareIcon>
          </ShareIconContainer>
        </ShareOnContainer>
      </Container>
    </Bacnground>
  );
}

const Bacnground = styled.div`
  margin-bottom: -100vh;
  height: 100vh;
  width: 100%;
  background: ${props => (props.open ? 'rgba(0, 0, 0, 0.5)' : 'transparent')};
  display: ${props => (props.open ? 'block' : 'none')};
`;

const Container = styled.div`
  background: #202021;
  color: white;
  width: 100%;
  display: ${props => (props.open ? 'block' : 'none')};
  pointer-events: ${props => (props.open ? 'initial' : 'none')};

  a {
    color: white;
    text-decoration: none;
  }

  * {
    line-height: 1;
  }
`;

const NavLinkContainer = styled.div`
  padding: 0 62px;
  width: 100%;
`;

const NavLink = styled.a`
  display: block;
  font-size: 15px;
  font-weight: bold;
  padding: 42px 0 0 0;
  opacity: ${props => (props.active ? 0.4 : 1)};
`;

const ShareOnContainer = styled.div`
  font-size: 12px;
  font-weight: bold;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 32px 62px 43px;
  width: 100%;

  span {
    opacity: 0.4;
  }
`;

const ShareIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ShareIcon = styled.a`
  margin-left: 20px;
  width: 30px;
  height: 30px;

  img {
    height: 100%;
    width: 100%;
  }
`;

export default HeaderNavigationSpRoot;
