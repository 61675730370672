// @flow
import * as types from './types';
import type { Position } from '../models/positions/Position';

export function updatePosition(position: Position) {
  return {
    type: types.UPDATE_POSITION,
    payload: position,
  };
}
