// @flow
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';

import { FlatList, ActivityIndicator } from '../../commons/react-native';
import { View } from '../../commons/native-base';
import MyRodClient from '../../../models/my_rods/MyRodClient';
import { updateMyRods } from '../../../actions/myRods';
import OrderModal from './OrderModal';
import MyRodListItem from './MyRodListItem';

import type { BaseProps } from '../../../../application';
import type { Account } from '../../../models/accounts/Account';
import type { MyRod } from '../../../models/my_rods/MyRod';
import { displayAxiosError } from '../../commons/Toast';

type Props = BaseProps & {
  account: Account,
  myRods: Array<MyRod>,
  updateMyRods: (myRods: Array<MyRod>) => void,
};

type State = {
  isLoading: boolean,
  sortState: string,
  myRods: Array<MyRod>,
  modalVisible: boolean,
  isRefreshing: boolean,
};

class IndexView extends React.Component<Props, State> {
  rodClient = new MyRodClient();

  constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: true,
      sortState: 'maker',
      myRods: [],
      modalVisible: false,
      isRefreshing: false,
    };
  }

  componentDidMount() {
    this.fetchMyRods();
  }

  // componentWillReceiveProps(nextProps: BaseProps) {
  //   this.setState({
  //     isLoading: false,
  //     isRefreshing: false,
  //     myRods: this.orderedMyRods(
  //       this.state.sortState,
  //       nextProps.screenProps.myRods
  //     ),
  //   });
  // }

  fetchMyRods() {
    const { account, updateMyRods } = this.props;

    this.rodClient
      .fetchAll(account)
      .then(response => {
        this.setState(
          { myRods: response.data, isLoading: false, isRefreshing: false },
          () => updateMyRods(response.data)
        );
      })
      .catch(error => {
        this.setState({ isLoading: false, isRefreshing: false });
        displayAxiosError(error);
      });
  }

  orderedMyRods(
    sortType: string,
    myRods: Array<MyRod> = this.state.myRods
  ): Array<MyRod> {
    switch (sortType) {
      /*eslint indent: ["error", 2, { "SwitchCase": 1 }]*/
      case 'updatedAsc':
        return myRods.sort((a, b) => {
          if (a.updated_at == null) {
            return 1;
          } else if (b.updated_at == null) {
            return -1;
          } else if (a.updated_at < b.updated_at) {
            return -1;
          } else if (a.updated_at > b.updated_at) {
            return 1;
          } else {
            return 0;
          }
        });
      case 'updatedDesc':
        return myRods.sort((a, b) => {
          if (a.updated_at == null) {
            return 1;
          } else if (b.updated_at == null) {
            return -1;
          } else if (a.updated_at < b.updated_at) {
            return 1;
          } else if (a.updated_at > b.updated_at) {
            return -1;
          } else {
            return 0;
          }
        });
      case 'getdateAsc':
        return myRods.sort((a, b) => {
          if (a.get_date == null) {
            return 1;
          } else if (b.get_date == null) {
            return -1;
          }
          if (b.get_date && a.get_date < b.get_date) {
            return -1;
          } else if (b.get_date && a.get_date > b.get_date) {
            return 1;
          } else {
            return 0;
          }
        });
      case 'getdateDesc':
        return myRods.sort((a, b) => {
          if (a.get_date == null) {
            return 1;
          } else if (b.get_date == null) {
            return -1;
          } else if (b.get_date && a.get_date < b.get_date) {
            return 1;
          } else if (b.get_date && a.get_date > b.get_date) {
            return -1;
          } else {
            return 0;
          }
        });
      default:
        return myRods.sort((a, b) => {
          // $FlowFixMe
          if (
            a.maker &&
            b.maker &&
            a.maker.name.toLowerCase() < b.maker.name.toLowerCase()
          ) {
            return -1;
            // $FlowFixMe
          } else if (
            a.maker &&
            b.maker &&
            a.maker.name.toLowerCase() > b.maker.name.toLowerCase()
          ) {
            return 1;
          } else {
            return 0;
          }
        });
    }
  }

  renderFooter = () => {
    const { isRefreshing, isLoading } = this.state;

    if (isRefreshing) {
      return null;
    }

    if (isLoading) {
      return (
        <View
          style={{
            paddingVertical: 20,
            borderTopWidth: 1,
            borderColor: '#CED0CE',
          }}
        >
          <ActivityIndicator animating size="large" />
        </View>
      );
    } else {
      return (
        <Row style={{ paddingTop: 20 }}>
          <Col md={6}>
            <Link to="/my/rods/new" className="btn btn-block btn-primary">
              ロッドを登録する
            </Link>
          </Col>
        </Row>
      );
    }
  };

  handleRefresh = () => {
    this.setState(
      {
        myRods: [],
        isRefreshing: true,
        isLoading: false,
      },
      () => {
        this.fetchMyRods();
      }
    );
  };

  render() {
    const { history } = this.props;
    const { myRods, modalVisible, sortState, isRefreshing } = this.state;

    return (
      <React.Fragment>
        <View style={{ flex: 1, overflow: 'hidden' }}>
          <FlatList
            data={myRods}
            extraData={sortState}
            renderItem={item => (
              <MyRodListItem key={item.id} history={history} myRod={item} />
            )}
            ListFooterComponent={this.renderFooter}
            onRefresh={this.handleRefresh}
            refreshing={isRefreshing}
          />
        </View>
        <OrderModal
          visible={modalVisible}
          type={sortState}
          onApply={type => {
            this.setState({
              myRods: this.orderedMyRods(type),
              sortState: type,
              modalVisible: false,
            });
          }}
          onClose={() => this.setState({ modalVisible: false })}
        />
      </React.Fragment>
    );
  }
}

export default connect(
  state => ({
    account: state.account,
    myRods: state.myRods,
  }),
  dispatch => bindActionCreators({ updateMyRods }, dispatch)
)(IndexView);
