// @flow
import React from 'react';
import { Button, Form, Input } from 'reactstrap';
import CommentClient from '../../../models/results/comments/CommentClient';
import type { Comment } from '../../../models/results/comments/Comment';
import type { Result } from '../../../models/results/Result';
import { displayAxiosError, displayMessage } from '../../commons/messages';

type Props = {
  visible: boolean,
  parent: ?Comment,
  result: Result,
  token: string,
  focus: boolean,
  onFinishPosting: (comment: Comment) => void,
};

type State = {
  comment: string,
};

export default class CommentForm extends React.Component<Props, State> {
  input: Input;
  commentClient = new CommentClient();

  constructor(props: Props) {
    super(props);

    this.state = {
      comment: '',
    };
  }

  componentDidMount() {
    if (this.props.focus) {
      // console.log('componentDidMount', this.input);
      this.input.focus();
    }
  }

  onClickPost() {
    const { parent, result, token } = this.props;
    const { comment } = this.state;

    this.commentClient
      .post(result.id, parent != null ? parent.id : null, comment, token)
      .then(response => {
        this.setState({ comment: '' });
        // $FlowFixMe
        this.props.onFinishPosting(response.data);
        displayMessage('コメントしました');
      })
      .catch(error => {
        if (error.response.status === 400) {
          displayMessage(error.response.data.message);
        } else if (error.response.status === 422) {
          displayMessage(error.response.data.base[0]);
        } else {
          displayAxiosError(error);
        }
      });
  }

  render() {
    const { visible, parent } = this.props;
    const { comment } = this.state;

    if (!visible) {
      return null;
    }

    return (
      <div className="comment-form">
        <Form>
          <div className="input-group">
            <Input
              id={parent != null ? `comment_${parent.id}` : 'comment'}
              ref={input => (this.input = input)}
              type="textarea"
              value={comment}
              placeholder={
                parent != null
                  ? `${parent.user.name}さんに返信`
                  : 'ナイスフィッシュ！などをコメントしてみよう'
              }
              onChange={e => this.setState({ comment: e.target.value })}
            />
            <Button
              id={parent != null ? `comment_send_${parent.id}` : 'comment_send'}
              outline
              color="primary"
              onClick={() => this.onClickPost()}
            >
              送信
            </Button>
          </div>
        </Form>
      </div>
    );
  }
}
