// @flow
import React, { useState, useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import NoImage from '../../assets/images/views/ships/noimage.png';
import UpIcon from '../../assets/images/views/ships/up.png';
import NextIcon from '../../assets/images/views/ships/next.png';
import PrevIcon from '../../assets/images/views/ships/prev.png';
import ShipClient from '../../models/ships/ShipClient';
import type { ShipWithDetails } from '../../models/ships/Ship.js';
import styled from 'styled-components';
import { viewPc, viewSp } from '../../lib/Mixins';
import FollowButton from '../../views/commons/buttons/FollowButton.js';
import GamesIcon from '../../assets/images/views/ships/games_icon.png';
import PhoneNumberIcon from '../../assets/images/views/ships/phone_number.png';
import AddressIcon from '../../assets/images/views/ships/address.png';

type Props = {
  ship: ShipWithDetails,
  isSignedIn: boolean,
  token: string,
};

function ShipCardRoot(props: Props) {
  // ReactOnRailsの不具合で直接Hooksを使ってるコンポーネントをexportできない
  return <ShipCard {...props} />;
}

function ShipCard({ ship, isSignedIn, token }: Props) {
  const [isFollowing, setIsFollowing] = useState(ship.following);
  // ローディングアニメーション(ぐるぐる)にしたい場合はstateで管理した方が良い
  const isSending = useRef(false);

  function onFollow() {
    if (isSending.current) return;

    isSending.current = true;
    new ShipClient()
      .follow(ship.id, token)
      .then(() => {
        window.notify(`${ship.name}をフォローしました`, 'success');
        setIsFollowing(true);
      })
      .catch(() => {
        window.notify(`${ship.name}のフォローが失敗しました`, 'danger');
      })
      .finally(() => {
        isSending.current = false;
      });
  }

  function onUnFollow() {
    if (isSending.current) return;

    isSending.current = true;
    new ShipClient()
      .stopFollow(ship.id, token)
      .then(() => {
        window.notify(`${ship.name}のフォローを解除しました`, 'success');
        setIsFollowing(false);
      })
      .catch(() => {
        window.notify(`${ship.name}のフォロー解除に失敗しました`, 'danger');
      })
      .finally(() => {
        isSending.current = false;
      });
  }

  // https://github.com/akiran/react-slick/issues/1195#issuecomment-390383615
  /* eslint-disable */
  // $FlowFixMe
  const SlickButtonFix = ({ currentSlide, slideCount, children, ...props }) => (
    <span {...props}>{children}</span>
  );
  /* eslint-enable */

  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    nextArrow: (
      <SlickButtonFix>
        <img src={NextIcon} />
      </SlickButtonFix>
    ),
    prevArrow: (
      <SlickButtonFix>
        <img src={PrevIcon} />
      </SlickButtonFix>
    ),
  };

  return (
    <Container>
      <SlickCarousel href={`/ships/${ship.id}`} target="_blank" rel="noopener">
        <Slider {...settings}>
          {ship.thumbnails &&
            ship.thumbnails.length > 0 &&
            ship.thumbnails.map((image, index) => (
              <img key={index} src={image} />
            ))}
          {ship.thumbnails && ship.thumbnails.length === 0 && (
            <img src={NoImage} />
          )}
        </Slider>
      </SlickCarousel>
      <Detail>
        <ShipShowLink href={`/ships/${ship.id}`} target="_blank" rel="noopener">
          {(ship.has_entered_game || ship.had_entered_game) && (
            <Games src={GamesIcon} />
          )}
          <Prefecture>
            {ship.prefecture.name}
            {ship.port_name && <>・{ship.port_name}</>}
          </Prefecture>
          <ShipName>{ship.name}</ShipName>
          <PhoneNumberAndAddress>
            {ship.phone_number && (
              <PhoneNumberAndAddressItem>
                <img src={PhoneNumberIcon} />
                <div>{ship.phone_number}</div>
              </PhoneNumberAndAddressItem>
            )}
            <PhoneNumberAndAddressItem>
              <img src={AddressIcon} />
              <div>{ship.address}</div>
            </PhoneNumberAndAddressItem>
          </PhoneNumberAndAddress>
          {ship.fishes && ship.fishes.length > 0 && (
            <Fishes>
              {ship.fishes.map(fish => (
                <Fish key={fish.id}>
                  {fish.name}
                  <img src={UpIcon} />
                </Fish>
              ))}
            </Fishes>
          )}
        </ShipShowLink>
        <FollowContainer>
          <div>
            <strong>{ship.followers_size}</strong>
            <span>名がフォロー中</span>
          </div>
          <FollowButton
            isSignedIn={isSignedIn}
            isFollowing={isFollowing}
            onPress={() => (isFollowing ? onUnFollow() : onFollow())}
          />
        </FollowContainer>
      </Detail>
    </Container>
  );
}

const Container = styled.div`
  background-color: white;
  border-radius: 12px;
  box-shadow: 0px 0px 12px #0000001f;
  display: flex;
  align-items: center;
  padding: 24px;

  ${viewSp} {
    flex-direction: column;
    padding: 0;
  }
`;

const eyecatchSizePc = '280px';
const arrowSize = '24px';
const SlickCarousel = styled.a`
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  min-height: 187px;
  height: 187px;
  min-width: ${eyecatchSizePc};
  width: ${eyecatchSizePc};
  z-index: 1;

  ${viewSp} {
    border-radius: 12px 12px 0 0;
    min-height: 60vw;
    height: 60vw;
    width: 100%;
  }

  img {
    object-fit: cover;
    height: auto;
    width: 100%;
  }

  .slick-slider {
    height: 100%;
    width: 100%;

    div,
    img {
      height: 100%;
      width: 100%;
    }
  }

  .slick-arrow {
    cursor: pointer;
    position: absolute;
    top: calc(50% - ${arrowSize} / 2);
    transform: translateX(-50%);
    z-index: 999;

    img {
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 50%;

      ${viewSp} {
        display: none;
      }
    }

    &::before {
      display: none;
    }
  }

  .slick-prev,
  .slick-next {
    height: ${arrowSize};
    width: ${arrowSize};

    img {
      height: 100%;
      width: 100%;
    }
  }

  .slick-prev {
    left: 7.5%;
  }

  .slick-next {
    right: calc(7.5% - ${arrowSize});
  }

  .slick-dots {
    margin: 0;
    padding: 0;
    display: flex !important;
    justify-content: center;
    position: absolute;
    bottom: 12px;
    left: 50%;
    transform: translateX(-50%);

    button {
      display: none;
    }

    li {
      background-color: white;
      border-radius: 50%;
      margin: 0 3px;
      position: relative;
      list-style: none;
      opacity: 0.5;
      height: 6px;
      width: 6px;

      &.slick-active {
        opacity: 1;
      }
    }
  }
`;

const Detail = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 40px;
  width: calc(100% - 40px - ${eyecatchSizePc});

  ${viewSp} {
    margin-left: 0;
    padding: 16px;
    width: 100%;
  }
`;

const ShipShowLink = styled.a`
  display: flex;
  flex-direction: column;
  color: #2b2b2b;

  &:hover {
    color: #2b2b2b;
    text-decoration: none;
  }
`;

const Games = styled.img`
  margin-bottom: 12px;
  width: 157.5px;

  ${viewSp} {
    margin-bottom: 9px;
  }
`;

const Prefecture = styled.div`
  color: #a0a0a0;
  font-size: 16px;
  line-height: 1;

  ${viewSp} {
    font-size: 12px;
  }

  ${viewPc} {
    margin-top: 7px;
  }
`;

const ShipName = styled.div`
  font-weight: bold;
  line-height: 1;

  ${viewPc} {
    font-size: 22px;
    margin-top: 12px;
  }

  ${viewSp} {
    font-size: 18px;
    margin-top: 8px;
  }
`;

const PhoneNumberAndAddress = styled.div`
  display: flex;
  font-size: 14px;

  ${viewSp} {
    flex-direction: column;
  }
`;

const PhoneNumberAndAddressItem = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 10px;
  line-height: 1;

  ${viewSp} {
    padding-left: 0;
    margin-left: 0;
  }

  ${viewPc} {
    &:nth-child(2) {
      padding-left: 8px;
      margin-left: 8px;

      ${viewSp} {
        padding-left: 0;
        margin-left: 0;
      }

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        border-right: 1px solid #e5e5e5;
        height: 13px;
        width: 1px;
      }
    }
  }

  img {
    margin-right: 4px;
    height: 18px;
    width: 18px;

    ${viewSp} {
      height: 16px;
      width: 16px;
    }
  }
`;

const Fishes = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;
  overflow: auto;
  line-height: 1;

  ${viewSp} {
    margin-top: 11.5px;
  }
`;

const Fish = styled.div`
  border: 1px solid #0000001a;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
  height: 28px;
  margin-left: 8px;
  padding: 0 8px;
  white-space: nowrap;

  ${viewSp} {
    font-size: 10px;
  }

  &:first-child {
    margin-left: 0;
  }

  img {
    width: 15px;

    ${viewSp} {
      width: 12px;
    }
  }
`;

const FollowContainer = styled.div`
  border-top: 1px solid #e9ecef;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  padding-top: 11px;
  line-height: 1;

  ${viewSp} {
    margin-top: 11.5px;
    padding-top: 12.5px;
  }

  strong {
    font-size: 22px;
    margin-right: 4px;

    ${viewSp} {
      font-size: 18px;
      margin-right: 5px;
    }
  }

  span {
    font-size: 14px;

    ${viewSp} {
      font-size: 12px;
    }
  }
`;

export default React.memo<Props>(ShipCardRoot);
