// @flow
import AxiosClient from '../AxiosClient';
import type { Axios, AxiosPromise } from 'axios';
import type { Fish } from './Fish';

export default class FishClient extends AxiosClient {
  searchFromResults(page: number, keyword: ?string): AxiosPromise<Array<Fish>> {
    return this.buildFishClient().get(
      '/api/v2/fishes/search_from_results.json',
      {
        params: {
          page: page,
          keyword: keyword,
        },
      }
    );
  }

  // TODO: search_from_resultsでも良いかも
  fetchAllByArea(areaId: number): AxiosPromise<Array<Fish>> {
    return this.buildFishClient().get(`/api/v2/areas/${areaId}/fishes.json`);
  }

  // private methods

  buildFishClient(): Axios {
    return this.buildClient();
  }
}
