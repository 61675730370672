// @flow
import React from 'react';
import Loader from '../Loader';

export function ActivityIndicator(): React$Node {
  return (
    <div className="text-center mt-30 mb-30" style={{ width: '100%' }}>
      <Loader />
    </div>
  );
}
