// @flow
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Col, Row } from 'reactstrap';
import type { BaseProps } from '../../../../application';
import type { MyReel } from '../../../models/my_reels/MyReel';
import type { Account } from '../../../models/accounts/Account';
import type { Maker } from '../../../models/makers/Maker';
import type { Series } from '../../../models/series/Series';
import type { Product } from '../../../models/products/Product';
import { ActivityIndicator } from '../../commons/react-native';
import { List, ListItem, View } from '../../commons/native-base';
import { ListContent } from '../../commons/list';
import { updateMyReels } from '../../../actions/myReels';
import { displayAxiosError, displayMessage } from '../../commons/Toast';
import MyReelClient from '../../../models/my_reels/MyReelClient';
import { date } from '../../../lib/StringUtils';
import ProductHelper from '../../../helpers/ProductHelper';

type Props = BaseProps & {
  account: Account,
  myReels: Array<MyReel>,
  updateMyReels: (myReels: Array<MyReel>) => void,
};

type State = {
  id: ?number,
  myReel: ?MyReel,
  loading: boolean,
};

class ShowView extends React.Component<Props, State> {
  myReelClient = new MyReelClient();

  constructor(props: Props) {
    super(props);

    const myReel = props.location.state && props.location.state.myReel;

    this.state = {
      id: myReel != null ? myReel.id : parseInt(props.match.params.id),
      myReel: myReel,
      loading: myReel == null,
    };
  }

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    const { myReels } = nextProps;
    const myReelId = prevState.myReel ? prevState.myReel.id : null;

    if (!prevState.loading) {
      if (myReels != null && myReels.length > 0 && myReelId != null) {
        const myReel = myReels.find(r => r.id == myReelId);

        if (myReel != null) {
          return {
            myReel: myReel,
          };
        }
      }
    }
    return null;
  }

  componentDidMount() {
    const { account } = this.props;
    const { id, myReel } = this.state;

    if (myReel == null && id != null) {
      this.myReelClient
        .fetchMyReel(id, account)
        .then(response => {
          this.setState({
            loading: false,
            myReel: response.data,
          });
        })
        .catch(error => {
          this.setState({ loading: false });

          if (error.response.status !== 404) {
            displayAxiosError(error);
          }
        });
    }
  }

  onClickMaker(maker: ?Maker) {
    if (maker != null && !maker.other) {
      window.location.href = `/makers/${maker.id}`;
    }
  }

  onClickSeries(series: ?Series, type: string) {
    if (series != null && !series.other) {
      window.location.href = `/series/${series.id}/${type}`;
    }
  }

  onClickProduct(product: ?Product) {
    if (product != null && product.use_in_tackle_db) {
      window.location.href = `/tackles/${ProductHelper.pathFromType(product.type)}/${product.id}`;
    }
  }

  onClickEdit(myReel: MyReel) {
    this.props.history.push(`/my/reels/${myReel.id}/edit`, { myReel: myReel });
  }

  onClickDelete(myReel: MyReel) {
    const { history, account, myReels, updateMyReels } = this.props;

    this.setState({ loading: true });
    this.myReelClient
      .delete(myReel.id, account)
      .then(() => {
        displayMessage('リールを削除しました');
        updateMyReels([...myReels.filter(r => r.id !== myReel.id)]);
        history.goBack();
      })
      .catch(error => {
        displayAxiosError(error);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  render() {
    const { myReel, loading } = this.state;

    if (loading) {
      return (
        <View
          style={{
            paddingVertical: 20,
            borderTopWidth: 1,
            borderColor: '#CED0CE',
          }}
        >
          <ActivityIndicator animating size="large" />
        </View>
      );
    } else if (myReel != null) {
      return (
        <React.Fragment>
          <h4 style={{ paddingBottom: 16, borderBottom: '1px solid #f0f0f0' }}>
            リール
          </h4>
          {myReel.images.length > 0 && (
            <Row className="mb-30">
              {myReel.images.map(image => (
                <Col key={image.id} lg={4}>
                  <a href={image.image.url}>
                    <img src={image.image.thumb.url} className="img-fluid" />
                  </a>
                </Col>
              ))}
            </Row>
          )}
          <List style={{ backgroundColor: '#fff', marginBottom: 20 }}>
            <ListItem
              style={{ cursor: 'pointer' }}
              onClick={() => this.onClickMaker(myReel.maker)}
            >
              <ListContent>
                <Row>
                  <Col xs={4}>メーカー</Col>
                  <Col xs={8}>{myReel.maker ? myReel.maker.name : ''}</Col>
                </Row>
              </ListContent>
            </ListItem>
            <ListItem
              style={{ cursor: 'pointer' }}
              onClick={() => this.onClickSeries(myReel.series, 'reel')}
            >
              <ListContent>
                <Row>
                  <Col xs={4}>シリーズ</Col>
                  <Col xs={8}>{myReel.series ? myReel.series.name : ''}</Col>
                </Row>
              </ListContent>
            </ListItem>
            {myReel.reel != null && !myReel.reel.other && (
              <ListItem>
                <ListContent>
                  <Row
                    className="mb-3"
                    style={{ cursor: 'pointer' }}
                    onClick={() => this.onClickProduct(myReel.reel)}
                  >
                    <Col xs={4}>モデル</Col>
                    <Col xs={8}>{myReel.reel.name}</Col>
                  </Row>
                  <Row>
                    <Col xs={4}></Col>
                    <Col xs={8} lg={6}>
                      {myReel.product_review_id != null && (
                        <Button
                          block
                          color="outline-warning"
                          onClick={() => {
                            // $FlowFixMe
                            window.location.href = `/tackles/${myReel.reel.id}/reviews/${myReel.product_review_id}/edit`;
                          }}
                        >
                          レビューを更新する
                        </Button>
                      )}
                      {myReel.product_review_id == null && (
                        <Button
                          block
                          color="warning"
                          onClick={() => {
                            window.location.href = `/tackles/${myReel.reel.id}/reviews/new`;
                          }}
                        >
                          レビューを投稿する
                        </Button>
                      )}
                    </Col>
                  </Row>
                </ListContent>
              </ListItem>
            )}
            {myReel.reel != null && myReel.reel.other && !!myReel.name && (
              <ListItem>
                <ListContent>
                  <Row>
                    <Col xs={4}></Col>
                    <Col xs={8}>{myReel.name}</Col>
                  </Row>
                </ListContent>
              </ListItem>
            )}
            <ListItem>
              <ListContent>
                <Row>
                  <Col xs={4}>使用開始日</Col>
                  <Col xs={8}>{date(myReel.get_date)}</Col>
                </Row>
              </ListContent>
            </ListItem>
            <ListItem>
              <ListContent>
                <Row>
                  <Col xs={4}>使用終了日</Col>
                  <Col xs={8}>{date(myReel.lost_date)}</Col>
                </Row>
              </ListContent>
            </ListItem>
            <ListItem>
              <ListContent>
                <Row>
                  <Col xs={4}>メモ</Col>
                  <Col xs={8} style={{ whiteSpace: 'pre-line' }}>
                    {myReel.memo}
                  </Col>
                </Row>
              </ListContent>
            </ListItem>
          </List>

          <h4 style={{ paddingBottom: 16, borderBottom: '1px solid #f0f0f0' }}>
            ライン
          </h4>
          <List>
            <ListItem
              style={{ cursor: 'pointer' }}
              onClick={() => this.onClickMaker(myReel.line_maker)}
            >
              <ListContent>
                <Row>
                  <Col xs={4}>メーカー</Col>
                  <Col xs={8}>
                    {myReel.line_maker ? myReel.line_maker.name : ''}
                  </Col>
                </Row>
              </ListContent>
            </ListItem>
            <ListItem
              style={{ cursor: 'pointer' }}
              onClick={() => this.onClickSeries(myReel.line_series, 'line')}
            >
              <ListContent>
                <Row>
                  <Col xs={4}>シリーズ</Col>
                  <Col xs={8}>
                    {myReel.line_series ? myReel.line_series.name : ''}
                  </Col>
                </Row>
              </ListContent>
            </ListItem>
            {myReel.line != null && !myReel.line.other && (
              <ListItem>
                <ListContent>
                  <Row
                    className="mb-3"
                    style={{ cursor: 'pointer' }}
                    onClick={() => this.onClickProduct(myReel.line)}
                  >
                    <Col xs={4}>強度</Col>
                    <Col xs={8}>{myReel.line ? myReel.line.name : ''}</Col>
                  </Row>
                  <Row>
                    <Col xs={4}></Col>
                    <Col xs={8} lg={6}>
                      {myReel.line_review_id != null && (
                        <Button
                          block
                          color="outline-warning"
                          onClick={() => {
                            // $FlowFixMe
                            window.location.href = `/tackles/${myReel.line.id}/reviews/${myReel.line_review_id}/edit`;
                          }}
                        >
                          レビューを更新する
                        </Button>
                      )}
                      {myReel.line_review_id == null && (
                        <Button
                          block
                          color="warning"
                          onClick={() => {
                            // $FlowFixMe
                            window.location.href = `/tackles/${myReel.line.id}/reviews/new`;
                          }}
                        >
                          レビューを投稿する
                        </Button>
                      )}
                    </Col>
                  </Row>
                </ListContent>
              </ListItem>
            )}
            {myReel.line != null && myReel.line.other && !!myReel.line_name && (
              <ListItem>
                <ListContent>
                  <Row>
                    <Col xs={4}></Col>
                    <Col xs={8}>{myReel.line_name}</Col>
                  </Row>
                </ListContent>
              </ListItem>
            )}
            <ListItem>
              <ListContent>
                <Row>
                  <Col xs={4}>巻いた長さ</Col>
                  <Col xs={8}>
                    {myReel.reel_length ? `${myReel.reel_length}m` : ''}
                  </Col>
                </Row>
              </ListContent>
            </ListItem>
            <ListItem>
              <ListContent>
                <Row>
                  <Col xs={4}>巻いた日</Col>
                  <Col xs={8}>{date(myReel.reel_thread_date)}</Col>
                </Row>
              </ListContent>
            </ListItem>
          </List>
          <Row className="mt-4">
            <Col sm={3} xs={6}>
              <Button
                block
                color="primary"
                onClick={() => this.onClickEdit(myReel)}
              >
                編集する
              </Button>
            </Col>
            <Col sm={3} xs={6}>
              <Button
                block
                color="outline-primary"
                onClick={() => this.onClickDelete(myReel)}
              >
                削除する
              </Button>
            </Col>
          </Row>
        </React.Fragment>
      );
    } else {
      return <div>リールが見つかりませんでした</div>;
    }
  }
}

export default connect(
  state => ({
    account: state.account,
    myReels: state.myReels,
  }),
  dispatch => bindActionCreators({ updateMyReels }, dispatch)
)(ShowView);
