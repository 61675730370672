// @flow
import React from 'react';
import Header from '../layouts/Header';
import ResultsBody from './ResultsBody';

type Props = {
  keyword: ?string,
  mobile: boolean,
  logo: string,
  logoIcon: string,
  userId: number,
  userName: string,
  headerFdl: string,
  inResultsFdl: string,
};

type State = {
  keyword: string,
  keywordInput: string,
  typingTimeout: any,
};

export default class IndexView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const initialKeyword = props.keyword != null ? props.keyword : '';

    this.state = {
      keyword: initialKeyword,
      keywordInput: initialKeyword,
      typingTimeout: 0,
      evaluatedAdvertisements: false,
    };
  }

  onChangeKeyword(keywordInput: string) {
    if (this.state.typingTimeout > 0) {
      clearTimeout(this.state.typingTimeout);
    }

    this.setState({
      keywordInput: keywordInput,
      typingTimeout: setTimeout(() => {
        history.pushState(null, '', `/catches?keyword=${keywordInput}`);
        this.setState({
          keyword: keywordInput,
        });
      }, 1500),
    });
  }

  render() {
    const { mobile } = this.props;
    const { keyword, keywordInput } = this.state;

    return (
      <div>
        <Header
          mobile={this.props.mobile}
          active="results"
          logo={this.props.logo}
          logoIcon={this.props.logoIcon}
          fdl={this.props.headerFdl}
          userId={this.props.userId}
          userName={this.props.userName}
        >
          <form className="form-inline" onSubmit={e => e.preventDefault()}>
            <div className="input-group">
              <input
                type="text"
                className="form-control border-right-0"
                value={keywordInput}
                onChange={e => this.onChangeKeyword(e.target.value)}
                placeholder="キーワードで絞り込む"
              />
              <span className="input-group-append">
                <div className="input-group-text bg-transparent">
                  <i className="fa fa-search" />
                </div>
              </span>
            </div>
          </form>
        </Header>
        <ResultsBody
          mobile={mobile}
          keyword={keyword}
          fdl={this.props.inResultsFdl}
        />
      </div>
    );
  }
}
