// @flow
import * as React from 'react';

type Props = {
  children: React.Node,
};

export function ListContent({ children, ...props }: Props) {
  return <div className="listview-content" {...props}>{children}</div>;
}
