// @flow
export function validateNumber(
  val: ?number,
  min: ?number,
  max: ?number,
  notNull?: ?boolean
): Array<string> {
  const errors = [];

  if ((notNull && val == null) || (val != null && isNaN(val))) {
    errors.push('数値を入力してください');
  }

  if (val != null && max != null && val > max) {
    errors.push(`${max}以下の値にしてください`);
  }

  if (val != null && min != null && val < min) {
    errors.push(`${min}以上の値にしてください`);
  }

  return errors;
}

export function floor(val: number, digit: number = 100, suffix: ?string = '') {
  if (val <= digit) {
    return val;
  }

  return Math.floor(val / digit) * digit + '' + (suffix || '');
}

export function yen(price: number) {
  const parts = price.toString().split('.');
  parts[0] = parts[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
  return '¥ ' + parts.join('.');
  // return Number(price).toLocaleString('ja-JP', {
  //   style: 'currency',
  //   currency: 'JPY',
  // });
}
