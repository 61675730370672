// @flow
import React from 'react';
import type { Node } from 'react';

type Props = {
  className: string,
  children: Node,
};

function NavItem({ className, children }: Props) {
  return <li className={className}>{children}</li>;
}

NavItem.defaultProps = {
  className: '',
};

export default NavItem;
