// @flow

import React, { useEffect, useRef } from 'react';
import useConfirm from './SignUpConfirmForm/useConfirm';
import ResendForm from './SignUpConfirmForm/ResendForm';

const SignUpConfirmForm = (): React$Element<'section'> => {
  const input1Ref = useRef(null);
  const input2Ref = useRef(null);
  const input3Ref = useRef(null);
  const input4Ref = useRef(null);
  const input5Ref = useRef(null);
  const input6Ref = useRef(null);

  const {
    code1,
    setCode1,
    code2,
    setCode2,
    code3,
    setCode3,
    code4,
    setCode4,
    code5,
    setCode5,
    code6,
    setCode6,
    isError,
    isSubmitting,
    handleSubmit,
  } = useConfirm();

  const handleChange = (isComposing, value, setValue, nextInputRef = null) => {
    // 入力値が半角数字のみかどうかチェックし、条件に一致する場合のみ状態を更新
    if (/^[0-9]+$/.test(value)) {
      setValue(value.slice(0, 1));

      // nextInputRefがnullではない場合はフォーカスを移動
      if (nextInputRef) {
        // ブラウザ次第で全角入力時の動作が不安定なので、フォーカス移動は半角時のみ
        if (isComposing) return;

        nextInputRef.focus();
      }
    }
  };

  const handleKeyDown = (key, setValue, prevInputRef = null) => {
    if (key === 'Backspace') {
      setValue ? setValue('') : null;

      if (prevInputRef) {
        prevInputRef.focus();
      }
    }
  };

  const handlePaste = text => {
    const charactersArray = text.split('');
    setCode1(charactersArray[0]);
    setCode2(charactersArray[1]);
    setCode3(charactersArray[2]);
    setCode4(charactersArray[3]);
    setCode5(charactersArray[4]);
    setCode6(charactersArray[5]);
  };

  useEffect(() => {
    if (
      code1 !== '' &&
      code2 !== '' &&
      code3 !== '' &&
      code4 !== '' &&
      code5 !== '' &&
      code6 !== '' &&
      isSubmitting === false
    ) {
      handleSubmit();
    }

    if (
      code1 === '' &&
      code2 === '' &&
      code3 === '' &&
      code4 === '' &&
      code5 === '' &&
      code6 === ''
    ) {
      if (input1Ref.current) {
        input1Ref.current.focus();
      }
    }
  }, [code1, code2, code3, code4, code5, code6, isSubmitting, handleSubmit]);

  useEffect(() => {
    // header_navigationの中を空にする
    const headerNavigation = document.querySelector('#header_navigation');
    if (headerNavigation) {
      headerNavigation.innerHTML = '';
    }
  }, []);

  return (
    <section data-scope-path="application/components/users/sign_up_confirm_form">
      <section className="form">
        <div className="inputs">
          <input
            ref={input1Ref}
            inputMode="numeric"
            value={code1}
            onChange={e =>
              handleChange(
                e.nativeEvent.isComposing,
                e.target.value,
                setCode1,
                input2Ref.current
              )
            }
            onKeyDown={e => handleKeyDown(e.key, setCode1, null)}
            onPaste={e => handlePaste(e.clipboardData.getData('text'))}
          />
          <input
            ref={input2Ref}
            inputMode="numeric"
            maxLength="1"
            value={code2}
            onChange={e =>
              handleChange(
                e.nativeEvent.isComposing,
                e.target.value,
                setCode2,
                input3Ref.current
              )
            }
            onKeyDown={e => handleKeyDown(e.key, setCode2, input1Ref.current)}
            onPaste={e => handlePaste(e.clipboardData.getData('text'))}
          />
          <input
            ref={input3Ref}
            inputMode="numeric"
            maxLength="1"
            value={code3}
            onChange={e =>
              handleChange(
                e.nativeEvent.isComposing,
                e.target.value,
                setCode3,
                input4Ref.current
              )
            }
            onKeyDown={e => handleKeyDown(e.key, setCode3, input2Ref.current)}
            onPaste={e => handlePaste(e.clipboardData.getData('text'))}
          />
          <input
            ref={input4Ref}
            inputMode="numeric"
            maxLength="1"
            value={code4}
            onChange={e =>
              handleChange(
                e.nativeEvent.isComposing,
                e.target.value,
                setCode4,
                input5Ref.current
              )
            }
            onKeyDown={e => handleKeyDown(e.key, setCode4, input3Ref.current)}
            onPaste={e => handlePaste(e.clipboardData.getData('text'))}
          />
          <input
            ref={input5Ref}
            inputMode="numeric"
            maxLength="1"
            value={code5}
            onChange={e =>
              handleChange(
                e.nativeEvent.isComposing,
                e.target.value,
                setCode5,
                input6Ref.current
              )
            }
            onKeyDown={e => handleKeyDown(e.key, setCode5, input4Ref.current)}
            onPaste={e => handlePaste(e.clipboardData.getData('text'))}
          />
          <input
            ref={input6Ref}
            inputMode="numeric"
            maxLength="1"
            value={code6}
            onChange={e =>
              handleChange(
                e.nativeEvent.isComposing,
                e.target.value,
                setCode6,
                null
              )
            }
            onKeyDown={e => handleKeyDown(e.key, setCode6, input5Ref.current)}
            onPaste={e => handlePaste(e.clipboardData.getData('text'))}
          />
        </div>
        {isError ? (
          <p className="description error-message">
            認証コードが通りませんでした。
            <br />
            <br />
            コード確認の上もう一度お試しいただくか
            <br />
            下記のフォームより再送信ください。
          </p>
        ) : (
          <p className="description">
            ご登録されたメールアドレスに
            <br />
            6桁の認証番号を送信しました。
            <br />
            <br />
            登録を完了させるために、
            <br className="for-pc" />
            コードを入力してください。
          </p>
        )}
      </section>

      <ResendForm isParentSubmitting={isSubmitting} />
    </section>
  );
};

export default SignUpConfirmForm;
