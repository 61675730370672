// @flow
import { useState } from 'react';
import AuthClient from '../../../models/auth/AuthClient';

const useCreate = (): {
  email: string,
  setEmail: (email: string) => void,
  password: string,
  setPassword: (password: string) => void,
  emailErrors: string[],
  passwordErrors: string[],
  agreement: boolean,
  setAgreement: (agree: boolean) => void,
  isSubmitting: boolean,
  handleSubmit: () => Promise<void>,
} => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailErrors, setEmailErrors] = useState([]);
  const [passwordErrors, setPasswordErrors] = useState([]);
  const [agreement, setAgreement] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async () => {
    if (!agreement) return;

    setIsSubmitting(true);

    return await new AuthClient()
      .create(email, password)
      .then(() => {
        // React側で画面を切り替えたい気持ちもあるが、ルーティングはRailsに任せる
        window.location.href = '/users/sign_up?step=confirm';
      })
      .catch(e => {
        if (e.response.data.email) {
          setEmailErrors(e.response.data.email);
        }
        if (e.response.data.password) {
          setPasswordErrors(e.response.data.password);
        }
        if (!e.response.data.email && !e.response.data.password) {
          window.notify(
            '不明なエラーが発生しました。時間をおいて再度お試しください。',
            'danger'
          );
        }
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return {
    email,
    setEmail,
    password,
    setPassword,
    emailErrors,
    passwordErrors,
    agreement,
    setAgreement,
    isSubmitting,
    handleSubmit,
  };
};

export default useCreate;
