// @flow
import React from 'react';
import styled from 'styled-components';
import type { Result } from '../../models/results/Result.js';
import { viewSp } from '../../lib/Mixins.js';
import ResultListItem from './ResultListItem.js';

type Props = {
  results: Array<Result>,
};

function ResultList({ results }: Props) {
  const amari = results.length % 5;
  const dummySize = amari === 0 ? 0 : 5 - amari;

  return (
    <Container>
      {results.map(result => (
        <Item key={result.id}>
          <ResultListItem result={result} />
        </Item>
      ))}
      {dummySize > 0 && (
        <>
          {[...Array(dummySize)].map((dummy, index) => (
            <Item key={index} />
          ))}
        </>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Item = styled.div`
  height: inherit;
  min-width: calc(20% - 20px);
  width: calc(20% - 20px);

  ${viewSp} {
    min-width: calc(50% - 7.5px);
    width: calc(50% - 7.5px);
  }
`;

export default React.memo<Props>(ResultList);
