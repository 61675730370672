// @flow
import JQueryView from '../../../common/views/JQueryView';
import { STYLE_OPTIONS } from '../commons/GoogleMaps';
import type { Area } from '../../models/areas/Area';
import TideGraph from '../venues/TideGraph';
import moment from 'moment';

export default class ShowView extends JQueryView {
  area: Area;
  zoomLevel: number;
  radius: number;
  venueId: ?number;
  tideTime: ?string;

  constructor(
    area: Area,
    zoomLevel: number,
    radius: number,
    venueId: ?number,
    tideTime: ?string
  ) {
    super('.content');
    this.area = area;
    this.zoomLevel = zoomLevel;
    this.radius = radius;
    this.venueId = venueId;
    this.tideTime = tideTime;
    this.render();
  }

  renderGoogleMap() {
    const latLng = new window.google.maps.LatLng(this.area.lat, this.area.lng);

    const map = new window.google.maps.Map(
      document.getElementById('area_map'),
      {
        zoom: this.zoomLevel,
        center: latLng,
        mapTypeControl: false,
        streetViewControl: false,
        mapTypeControlOptions: {
          style: window.google.maps.MapTypeControlStyle.DEFAULT,
        },
        mapTypeId: window.google.maps.MapTypeId.ROADMAP,
      }
    );

    map.mapTypes.set(
      'angelers',
      new window.google.maps.StyledMapType(STYLE_OPTIONS, { name: 'angelers' })
    );
    map.setMapTypeId('angelers');

    new window.google.maps.Circle({
      map: map,
      center: latLng,
      radius: this.radius,
      strokeColor: '#ed6c63',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#ed6c63',
      fillOpacity: 0.35,
    });
  }

  renderTideGraph() {
    if (this.venueId != null && this.tideTime != null) {
      const graph = new TideGraph(this.venueId, '#tide_graph');
      graph.show(moment(this.tideTime));
    }
  }

  render() {
    // this.renderGoogleMap();
    this.renderTideGraph();
  }
}
