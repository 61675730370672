// @flow
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import type { Position } from '../../../models/positions/Position';
import type { FieldForm } from '../../../models/fields/FieldForm';
import { updatePosition } from '../../../actions/positions';
import type { Venue } from '../../../models/venues/Venue';
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import LocationModal from './LocationModal';
import { M_EDIT } from '../../../models/fields/FieldForm';
import MapModal from '../venues/MapModal';
import LiteMap from '../../commons/LiteMap';

type Props = {
  form: FieldForm,
  position: Position,
  apiKey: string,
  onChangeForm: (form: FieldForm) => void,
  updatePosition: (position: Position) => void,
};

type State = {
  form: FieldForm,
  position: Position,
  visibleVenuesMapModal: boolean,
  visibleFieldLocationModal: boolean,
};

class FormComp extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      form: props.form,
      position: props.position,
      visibleVenuesMapModal: false,
      visibleFieldLocationModal: false,
    };
  }

  static getDerivedStateFromProps(nextProps: Props) {
    return {
      form: nextProps.form,
    };
  }

  componentDidMount() {
    const { updatePosition } = this.props;

    navigator.geolocation.getCurrentPosition(
      position => {
        this.setState({ position: ((position: any): Position) });
        updatePosition(((position: any): Position));
      },
      error => {
        console.warn(error);
      },
      // @see https://github.com/facebook/react-native/issues/12996#issuecomment-324238609
      // $FlowFixMe
      {
        enableHighAccuracy: true,
        timeout: 20000,
        // maximumAge: 1000,
      }
    );
  }

  onPressVenueClear() {
    const { form } = this.state;

    this.setState(
      {
        form: {
          ...form,
          venue: null,
        },
      },
      () => {
        this.props.onChangeForm({
          ...form,
          venue: null,
        });
      }
    );
  }

  onPressVenueMap() {
    this.setState({ visibleVenuesMapModal: true });
  }

  onApplyFieldLocationModal(marker: any) {
    const { form } = this.state;

    this.setState(
      {
        form: {
          ...form,
          lat: marker.latitude,
          lng: marker.longitude,
        },
        visibleFieldLocationModal: false,
      },
      () => {
        this.props.onChangeForm({
          ...form,
          lat: marker.latitude,
          lng: marker.longitude,
        });
      }
    );
  }

  onApplyVenuesMapModal(venue: Venue) {
    const { form } = this.state;

    this.setState(
      {
        form: {
          ...form,
          venue: venue,
        },
        visibleVenuesMapModal: false,
      },
      () => {
        this.props.onChangeForm({
          ...form,
          venue: venue,
        });
      }
    );
  }

  buildVenuesMapPosition(): Position {
    const { form } = this.state;
    // 観測所未選択、且つ正確な位置も未選択の時は、現在地を中心としてマップ表示する
    // 観測所未選択、且つ正確な位置が未設定、且つ現在地の取得も出来なかった場合は、日本の中心を中心としてマップ表示
    let coords = {
      latitude: this.props.position.coords.latitude,
      longitude: this.props.position.coords.longitude,
    };

    if (form.venue != null) {
      // 観測所選択済み、且つ正確な位置が設定されている時は、選択時の観測所のピン位置を中心としてマップ表示する
      // 観測所選択済み、且つ正確な位置は未設定の時は、選択時の観測所のピン位置を中心としてマップ表示する
      coords = {
        latitude: form.venue.lat,
        longitude: form.venue.lng,
      };
    } else {
      // 観測所未選択、且つ正確な位置が設定されている時は、正確な位置を中心としてマップ表示する
      if (form.lat != null && form.lng != null) {
        coords = {
          latitude: form.lat,
          longitude: form.lng,
        };
      }
    }

    return { coords: coords };
  }

  render() {
    const { apiKey } = this.props;
    const { form } = this.state;
    const position: Position = {
      coords: {
        latitude:
          form.lat != null && form.lng != null
            ? form.lat
            : this.props.position.coords.latitude,
        longitude:
          form.lat != null && form.lng != null
            ? form.lng
            : this.props.position.coords.longitude,
      },
    };

    return (
      <React.Fragment>
        <FormGroup>
          <Label>フィールド名</Label>
          <Input
            placeholder="好きなフィールド名を入力"
            value={form.name}
            onChange={e => {
              this.props.onChangeForm({
                ...form,
                name: e.target.value,
              });
            }}
          />
        </FormGroup>
        <FormGroup>
          <Label>位置</Label>
          {form.lat != null && form.lng != null && (
            <div className="mb-2">
              <LiteMap
                lat={form.lat}
                lng={form.lng}
                zoom={12}
                apiKey={apiKey}
              />
            </div>
          )}
          <Row>
            <Col md={6}>
              <Button
                block
                color="primary"
                onClick={() =>
                  this.setState({ visibleFieldLocationModal: true })
                }
              >
                位置を設定する
              </Button>
            </Col>
          </Row>
        </FormGroup>
        <FormGroup className="mb-40">
          <Label>観測所</Label>
          {form.venue != null && <div className="mb-2">{form.venue.name}</div>}
          <Row>
            <Col md={6}>
              <Button
                block
                color="primary"
                onClick={() => this.setState({ visibleVenuesMapModal: true })}
              >
                観測所を設定する
              </Button>
            </Col>
          </Row>
        </FormGroup>
        <LocationModal
          mode={M_EDIT}
          lat={form.lat}
          lng={form.lng}
          position={position}
          apiKey={apiKey}
          visible={this.state.visibleFieldLocationModal}
          onApply={marker => this.onApplyFieldLocationModal(marker)}
          onClose={() => this.setState({ visibleFieldLocationModal: false })}
        />
        <MapModal
          visible={this.state.visibleVenuesMapModal}
          position={this.buildVenuesMapPosition()}
          onApply={venue => this.onApplyVenuesMapModal(venue)}
          onClose={() => this.setState({ visibleVenuesMapModal: false })}
        />
      </React.Fragment>
    );
  }
}

export default connect(
  state => ({
    position: state.position,
    apiKey: state.railsContext.settings.googleMapsApiKey,
  }),
  dispatch => bindActionCreators({ updatePosition }, dispatch)
)(FormComp);
