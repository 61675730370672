// @flow
import AxiosClient from '../AxiosClient';
import type { AxiosPromise } from 'axios';
import type { Magazine } from './Magazine';

export default class MagazineClient extends AxiosClient {
  fetchRecentHotMagazines(): AxiosPromise<Array<Magazine>> {
    return this.buildClient().get(`/api/v2/magazines/recent_hots.json`);
  }

  fetchHotMagazines(): AxiosPromise<Array<Magazine>> {
    return this.buildClient().get(`/api/v2/magazines/hots.json`);
  }
}
