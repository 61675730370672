// @flow
import React from 'react';
import type { Node } from 'react';

type Props = {
  className: string,
  children: Node,
};

function TabNav({ className, children }: Props) {
  return <ul className={`tab-nav ${className}`}>{children}</ul>;
}

TabNav.defaultProps = {
  className: '',
};

export default TabNav;
