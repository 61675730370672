// @flow
import JQueryView from '../../../common/views/JQueryView';

declare var MasterSlider: any;

export default class ShowView extends JQueryView {
  constructor() {
    super('.content');

    const slider = new MasterSlider();
    slider.control('arrows');
    slider.control('thumblist', {
      autohide: false,
      dir: 'h',
      arrows: false,
      align: 'bottom',
      width: 127,
      height: 137,
      margin: 5,
      space: 5,
    });
    slider.setup('masterslider', {
      layout: 'fillwidth',
      autoHeight: 'true',
      space: 5,
      view: 'scale',
      loop: true,
    });
  }
}
