// @flow
import AxiosClient from '../AxiosClient';
import type { AxiosPromise } from 'axios';
import type { User } from './User';

export default class UserClient extends AxiosClient {
  fetchFollowings(page: number, id: number): AxiosPromise<Array<User>> {
    return this.buildClient().get(`/api/v2/users/${id}/followings/users.json`, {
      params: {
        page: page,
      },
    });
  }

  follow(id: number, token: string): AxiosPromise<any> {
    return this.buildClient(token).post(`/api/v2/users/${id}/follow.json`);
  }

  stopFollowing(id: number, token: string): AxiosPromise<any> {
    return this.buildClient(token).delete(
      `/api/v2/users/${id}/stop_following.json`
    );
  }

  searchFromResults(page: number, keyword: ?string): AxiosPromise<Array<User>> {
    return this.buildClient().get('/api/v2/users/search_from_results.json', {
      params: {
        page: page,
        keyword: keyword,
      },
    });
  }

  fetchBlocks(page: number, token: string): AxiosPromise<Array<User>> {
    return this.buildClient(token).get(`/api/v2/my/users/blocks.json`, {
      params: {
        page: page,
      },
    });
  }

  stopBlocking(id: number, token: string): AxiosPromise<any> {
    return this.buildClient(token).delete(`/api/v2/my/users/${id}/block.json`);
  }
}
