// @flow

import React from 'react';

import useResend from './ResendForm/useResend';
import AlertIcon from '../../../assets/images/views/users/alert.png';

const ResendForm = ({
  isParentSubmitting,
}: {
  isParentSubmitting: boolean,
}): React$Element<'section'> => {
  const {
    email,
    setEmail,
    emailErrors,
    isSubmitting,
    handleSubmit,
  } = useResend({
    isParentSubmitting: isParentSubmitting,
  });

  const isEmailError = emailErrors.length > 0;

  return (
    <section data-scope-path="application/components/users/sign_up_confirm_form/resend_form">
      <p>
        <span>認証コードが5分待っても</span>
        <span>届かない等の際には</span>
        <br />
        <span>お手数おかけしますが、</span>
        <span>もう一度送信ください。</span>
      </p>

      <div className={`input-with-icon ${isEmailError ? 'error_color' : ''}`}>
        <input
          type="email"
          placeholder="メールアドレス"
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        <i className="fa fa-envelope"></i>
      </div>
      {isEmailError &&
        emailErrors.map((error, index) => (
          <div key={index} className="error_message">
            <img src={AlertIcon} />
            {error}
          </div>
        ))}

      <button
        type="submit"
        onClick={() => handleSubmit()}
        disabled={isSubmitting}
        style={{
          cursor: isSubmitting ? 'none' : 'pointer',
          opacity: isSubmitting ? 0.5 : 1,
        }}
      >
        認証コードをもう一度送る
      </button>
    </section>
  );
};

export default ResendForm;
