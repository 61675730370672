// @flow
import React from 'react';
import { Row, Col } from 'reactstrap';
import Voters from './Voters';
import ReviewClient from '../../../models/products/reviews/ReviewClient';
import { displayAxiosError, displayMessage } from '../../commons/messages';
import type { Account } from '../../../models/accounts/Account';
import type { Review } from '../../../models/products/reviews/Review';
import ShareModal from './ShareModal';

type Props = {
  review: Review,
  voteAccessible: boolean,
  isSignedIn: boolean,
  account: Account,
  token: string,
  onToggleVoted: (voted: boolean, cached_votes_up: number) => void,
  onClickComments: (focusedInput: boolean) => void,
};

type State = {
  voting: boolean,
  commentsVisible: boolean,
  shareModalVisible: boolean,
};

export default class ReviewSocialComp extends React.Component<Props, State> {
  reviewClient = new ReviewClient();

  constructor(props: Props) {
    super(props);

    this.state = {
      voting: false,
      commentsVisible: false,
      shareModalVisible: false,
    };
  }

  upVote() {
    const { review, account, token } = this.props;

    this.setState({ voting: true });

    this.reviewClient
      .upVote(review.id, review.product.id, account, token)
      .then(response => {
        this.props.onToggleVoted(
          response.data.voted,
          review.cached_votes_up + 1
        );
        displayMessage('いいね！しました');
      })
      .catch(error => {
        if (error.response.status === 400) {
          displayMessage(error.response.data.message);
        } else {
          displayAxiosError(error);
        }
      })
      .finally(() => this.setState({ voting: false }));
  }

  downVote() {
    const { review, account, token } = this.props;

    this.setState({ voting: true });

    this.reviewClient
      .downVote(review.id, review.product.id, account, token)
      .then(response => {
        this.props.onToggleVoted(
          response.data.voted,
          response.data.cached_votes_up
        );
        displayMessage('いいね!を取り消しました');
      })
      .catch(error => {
        if (error.response.status === 400) {
          displayMessage(error.response.data.message);
        } else {
          displayAxiosError(error);
        }
      })
      .finally(() => this.setState({ voting: false }));
  }

  render() {
    const { review } = this.props;
    const { voting, shareModalVisible } = this.state;
    const validCount =
      (review.cached_votes_up != null && review.cached_votes_up > 0) ||
      (review.comments_count != null && review.comments_count > 0);

    return (
      <div>
        {validCount && (
          <div className="d-flex justify-content-between border-bottom pt-10 pb-10">
            <div>
              {review.cached_votes_up != null && review.cached_votes_up > -1 && (
                <div className="d-flex flex-row align-items-center">
                  <span className="fa-stack mr-2">
                    <i className="fa fa-circle fa-stack-2x text-primary" />
                    <i className="fa fa-thumbs-o-up fa-stack-1x text-light" />
                  </span>
                  <Voters review={review} />
                </div>
              )}
            </div>
            {review.comments_count != null && review.comments_count > 0 && (
              <div
                className="comments-link float-right"
                onClick={() => this.props.onClickComments(false)}
              >
                {`コメント${review.comments_count}件`}
              </div>
            )}
          </div>
        )}

        {validCount && <div />}

        <Row className="pt-10 pb-10">
          <Col className={review.voted ? 'text-primary' : ''}>
            <div
              className="d-flex flex-row align-items-center justify-content-center like"
              onClick={() => (review.voted ? this.downVote() : this.upVote())}
            >
              {!voting && (
                <div>
                  <i
                    className={
                      review.voted
                        ? 'fa fa-thumbs-up mr-1'
                        : 'fa fa-thumbs-o-up mr-1'
                    }
                  />
                  いいね！
                </div>
              )}
              {voting && (
                <div className="text-primary">
                  <i className="fa fa-spinner fa-spin" />
                </div>
              )}
            </div>
          </Col>
          <Col>
            <div
              className="d-flex flex-row align-items-center justify-content-center comments-link"
              onClick={() => this.props.onClickComments(true)}
            >
              <i className="fa fa-comment-o mr-1" />
              コメントする
            </div>
          </Col>
          <Col>
            <div
              className="d-flex flex-row align-items-center justify-content-center share-link"
              onClick={() => this.setState({ shareModalVisible: true })}
            >
              <i className="fa fa-share-alt mr-1" />
              シェア
            </div>
          </Col>
        </Row>
        <ShareModal
          review={review}
          visible={shareModalVisible}
          onClose={() => this.setState({ shareModalVisible: false })}
        />
      </div>
    );
  }
}
