// @flow
import React, { useState, useEffect } from 'react';
import ResultClient from '../../../models/results/ResultClient';
import ResultListWithAds from '../../results/ResultListWithAds';
import type { Area } from '../../../models/areas/Area';
import type { Fish } from '../../../models/fishes/Fish';
import type { Result } from '../../../models/results/Result';

type Props = {
  area: Area,
  fish: Fish,
  mobile: boolean,
  fdl: string,
};

// page数固定のcomponentです。(1ページ目のコンテンツが取得されます)
function ResultListRoot(props: Props) {
  // ReactOnRailsの不具合で直接Hooksを使ってるコンポーネントをexportできない
  return <ResultList {...props} />;
}

function ResultList({ area, fish, mobile, fdl }: Props) {
  const [results, setResults] = useState<Array<Result>>([]);
  const resultClient = new ResultClient();

  useEffect(() => {
    function fetchResults() {
      resultClient
        .fetchAll(
          1,
          'created_at',
          null,
          null,
          null,
          null,
          fish.id,
          area.id,
          null
        )
        .then(response => {
          setResults(
            [...results, ...response.data].filter(
              (r, i, self) => self.map(r2 => r2.id).indexOf(r.id) === i
            )
          );
        })
        .catch(error => {
          if (process.env.NODE_ENV === 'production') {
            window.location.replace('/500.html');
          }
          console.warn(error);
        });
    }

    fetchResults();
  }, [area, fish.id]);

  if (results.length === 0) {
    return (
      <>
        <div className="not_found">
          <h2>
            {area.name}の{fish.name}の釣果速報は
            <br />
            見つかりませんでした。
          </h2>
          <p>
            ぜひ、記念すべき1件目の釣果を投稿してみませんか?
            <br />
            アプリから釣果の投稿ができます。
          </p>
        </div>
      </>
    );
  }

  return (
    <>
      <ResultListWithAds results={results} mobile={mobile} fdl={fdl} />
      <a
        href={`/areas/${area.id}/fishes/${fish.id}/catches`}
        className="btn btn-lg btn-block btn-outline-primary"
      >
        釣果情報をすべて見る
      </a>
    </>
  );
}

export default ResultListRoot;
