// @flow
import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import moment from 'moment';
import type { Result } from '../../models/results/Result';
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
} from 'react-share';

type Props = {
  result: Result,
  visible: boolean,
  onClose: () => void,
};

export default function ShareModal(props: Props) {
  const { result, visible } = props;
  const url = `https://anglers.jp/catches/${result.id}`;
  const title = `${result.user.name}さんの${moment(result.caught_at).format(
    'M月D日'
  )}の釣果`;
  const tags: Array<string> = [];

  tags.push('#アングラーズ');
  tags.push('#ANGLERS');
  tags.push('#釣果記録');
  tags.push('#みんなの釣果');
  if (result.fish && result.fish.id != null && result.fish.id !== 0) {
    tags.push(`#${result.fish.name}`);
  }

  return (
    <Modal isOpen={visible} toggle={() => props.onClose()}>
      <ModalHeader toggle={() => props.onClose()}>シェア</ModalHeader>
      <ModalBody>
        <div className="d-flex">
          <FacebookShareButton url={url} quote={title} className="mr-2">
            <FacebookIcon size={32} round={true} />
          </FacebookShareButton>
          <TwitterShareButton url={url} title={title} hashtags={tags}>
            <TwitterIcon size={32} round={true} />
          </TwitterShareButton>
        </div>
      </ModalBody>
    </Modal>
  );
}
