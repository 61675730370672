// @flow
import moment from 'moment';
import type { Achievement } from '../achievements/Achievement';

export const REMOVED_USER_ID = 0;

export type User = {
  id: number,
  name: string,
  is_official: boolean,
  is_meister: boolean,
  is_ship_captain: boolean,
  thumb_url: ?string,
  mini_url: ?string,
  profile_comment: ?string,
  profile_gyoshu: ?string,
  profile_field: ?string,
  following: boolean,
  followers_size: number,
  selected_achievement: ?Achievement,
};

export type UserWithDetails = User & {
  publish_results_size: number,
  followings_size: number,
  following_areas_size: number,
  following_ships_size: number,
  fishing_count: number,
  image_url: ?string,
  header_image_url: ?string,
  fishing_started_on: ?string,
  followed_by: boolean,
  achievements: Array<Achievement>,
  allow_notify_votes: boolean,
  allow_notify_follows: boolean,
  myself: boolean,
  yearly_fishinglogs_size: number,
  blocking: boolean,
  blocked: boolean,
  social_twitter_url: string,
  social_instagram_url: string,
  social_facebook_url: string,
  social_tiktok_url: string,
  social_youtube_url: string,
  social_other_url: string,
};

export type UserWithPrivacy = UserWithDetails & {
  birth_on: ?moment,
  gender: number,
  facebook_name: ?string,
  twitter_name: ?string,
  apple_name: ?string,
  image_type: ?string,
  facebook_image_url: ?string,
  twitter_image_url: ?string,
  badge_count: number,
  notified_count: number,
  notified_groups_count: number,
};
