// @flow

import React from 'react';
import FacebookIcon from '../../assets/images/views/layouts/facebook.png';
import TwitterIcon from '../../assets/images/views/layouts/twitter_x.png';
import AppleIcon from '../../assets/images/views/layouts/apple.png';
import { csrfToken } from '../../helpers/fetch';

const AuthForms = (): React$Element<'section'> => {
  return (
    <section data-scope-path="application/views/layouts/auth_forms">
      <a href="/users/auth/facebook" data-method="post">
        <button className="auth-facebook">
          <img src={FacebookIcon} />
          Facebookアカウントを利用
        </button>
      </a>

      <a href="/users/auth/twitter" data-method="post">
        <button className="auth-twitter">
          {/* これだけ素材が小さいのでpaddingで調整 */}
          <img src={TwitterIcon} style={{ padding: '4px' }} />
          Xアカウントを利用
        </button>
      </a>

      <a href="/users/auth/apple" data-method="post">
        <button className="auth-apple">
          <img src={AppleIcon} />
          Apple IDを利用
        </button>
      </a>

      <section className="auth-email">
        <h3>メールアドレスでログイン</h3>
        <form action="/users/sign_in" method="post">
          <div className="input-with-icon">
            <input
              type="text"
              name="account[email]"
              placeholder="メールアドレス"
            />
            <i className="fa fa-envelope"></i>
          </div>
          <div className="input-with-icon">
            <input
              type="password"
              name="account[password]"
              placeholder="パスワード"
              autoComplete="current-password"
            />
            <i className="fa fa-lock"></i>
          </div>
          <div className="remember-me">
            <input
              type="checkbox"
              id="remember_me"
              name="account[remember_me]"
            />
            <label name="account[remember_me]">ログインしたままにする</label>
          </div>
          <input type="hidden" name="authenticity_token" value={csrfToken()} />
          <button type="submit">ログイン</button>
        </form>
      </section>

      <section className="forgot-password">
        <a href="/users/password/new">パスワードを忘れた方はこちら</a>
      </section>

      <a href="/users/sign_up" className="signup">
        新規登録
      </a>
    </section>
  );
};

export default AuthForms;
