// @flow
import JQueryView, { on } from '../../../common/views/JQueryView';

declare var $: any;

export default class FormView extends JQueryView {
  image: string;

  constructor(image: string) {
    // super('#form_view');
    super('.mz_events.show');
    this.image = image;
    this.render();
  }

  @on('change #event_image_image')
  onChangeImage(event: SyntheticInputEvent<EventTarget>) {
    if (!event.target.files.length) {
      return;
    }

    let file = $(event.target).prop('files')[0];
    let fr = new FileReader();
    fr.onload = () => {
      this.image = String(fr.result);
      this.render();
    };
    fr.readAsDataURL(file);
  }

  render() {
    if (this.image != '') {
      $('#preview_image').css('background-image', `url(${this.image})`);
      $('#preview_image-wrap').show();
      $('.btn--next')
        .removeClass('disabled btn-outline-secondary')
        .addClass('btn-primary shadow-sm');
    } else {
      $('.btn--next')
        .removeClass('btn-primary shadow-sm')
        .addClass('disabled btn-outline-secondary');
    }
  }
}
