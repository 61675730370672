// @flow
import AxiosClient from '../../AxiosClient';
import type { AxiosPromise } from 'axios';
import type { Account } from '../../accounts/Account';
import type { Review } from './Review';

export default class ReviewClient extends AxiosClient {
  fetchMine(account: Account): AxiosPromise<Array<Review>> {
    return this.buildTokenAuthClient(account).get(`/my/product_reviews.json`);
  }

  delete(id: number, account: Account, token: string): AxiosPromise<any> {
    return this.buildTokenAuthClient(account, token).delete(
      `/my/product_reviews/${id}.json`
    );
  }

  upVote(
    id: number,
    productId: number,
    account: Account,
    token: string
  ): AxiosPromise<any> {
    return this.buildTokenAuthClient(account, token).post(
      `/products/${productId}/reviews/${id}/vote.json`
    );
  }

  downVote(
    id: number,
    productId: number,
    account: Account,
    token: string
  ): AxiosPromise<any> {
    return this.buildTokenAuthClient(account, token).delete(
      `/products/${productId}/reviews/${id}/vote.json`
    );
  }
}
