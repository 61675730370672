// @flow
import { transform } from 'lodash';
import * as linkHeaderParser from 'linkheader-parser/dist/linkheader-parser-node';

export function deepMapKeys(obj: Object, mapFunc: Function): Object {
  return transform(obj, (r, v, k) => {
    const value = v instanceof Object ? deepMapKeys(v, mapFunc) : v;
    r[mapFunc(k)] = value;
  });
}

export function hasNextPageByHeaders(headers: Object): boolean {
  return (
    headers &&
    headers.hasOwnProperty('link') &&
    linkHeaderParser.parse(headers.link).hasOwnProperty('next')
  );
}
