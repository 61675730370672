// @flow
import React from 'react';
import type { Advertisement } from '../../models/advertisements/Advertisement';

type Props = {
  advertisement: Advertisement,
};

export default class ResultsAdvertisementCard extends React.Component<Props> {
  onClick(ad: Advertisement) {
    if (ad.url != null) {
      // $FlowFixMe
      gtag('event', 'click', {
        event_category: 'Advertisement',
        event_label: ad.url,
      });
    }

    // $FlowFixMe
    clickAdvertisement(ad.id);
  }

  render() {
    const ad = this.props.advertisement;
    const url = ad.url != null ? new URL(ad.url) : null;

    if (ad.image_size == 'LARGE_BANNER' && ad.image_url != null) {
      return (
        <div className="advertisements mb-40 mt-40">
          <a
            href={ad.url}
            onClick={this.onClick.bind(this, ad)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="large_banner">
              <img src={ad.image_url} className="banner_image" />
            </div>
          </a>
        </div>
      );
    } else {
      if (ad.provider == 'Amazon') {
        return (
          <div className="listview lv-lg advertisements mb-40 mt-40">
            <a
              href={ad.url}
              onClick={this.onClick.bind(this, ad)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="lv-body large_banner">
                <div className="media row">
                  <div className="text-center col-4 mb-10">
                    {ad.image_url != null && (
                      <img src={ad.image_url} className="lv-img-xlg" />
                    )}
                  </div>
                  <div className="media-body col-8">
                    {ad.title != null && (
                      <b className="media-heading">{ad.title}</b>
                    )}
                    <div className="text-muted">
                      {ad.price != null && <span>¥ {ad.price}〜</span>}
                    </div>
                    <div className="text-muted">
                      {url != null && <small className="url">{url.host}</small>}
                      <p>
                        <small>Amazonで買う</small>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        );
      } else {
        return (
          <div className="listview lv-lg advertisements mb-40 mt-40">
            <a
              href={ad.url}
              onClick={this.onClick.bind(this, ad)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="lv-body large_banner">
                <div className="media row">
                  <div className="text-center col-4 mb-10">
                    {ad.thumbnail_url != null && (
                      <img src={ad.thumbnail_url} className="lv-img-xlg" />
                    )}
                  </div>
                  <div className="media-body col-8">
                    {ad.title != null && (
                      <b className="media-heading">{ad.title}</b>
                    )}
                    <div className="text-muted">
                      {ad.text != null && <span>{ad.text}</span>}
                    </div>
                    <div className="text-muted">
                      <small>プロモーション</small>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        );
      }
    }
  }
}
