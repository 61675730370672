// @flow
import React from 'react';
import { useDropzone } from 'react-dropzone';
import type { FormImage } from '../../models/common/FormImage';

type Props = {
  width: number,
  height: number,
  image: FormImage,
  onChange: FormImage => void,
};

export default function ImageDropzone({
  width,
  height,
  image,
  onChange,
}: Props) {
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    multiple: false,
    onDrop: acceptedFiles => {
      onChange({
        file: acceptedFiles[0],
        url: URL.createObjectURL(acceptedFiles[0]),
        remove: false,
      });
    },
  });

  return (
    <div
      style={{
        position: 'relative',
        width: width,
        height: height,
        border: '1px solid #ccc',
        color: '#ccc',
      }}
    >
      <div
        {...getRootProps()}
        className="d-flex flex-column justify-content-center text-center"
        style={{ width: '100%', height: '100%', cursor: 'pointer' }}
      >
        <input {...getInputProps()} />
        <i className="fa fa-2x fa-plus" />
      </div>
      {!image.remove && image.url != null && (
        <>
          <div style={{ position: 'absolute', top: 0 }}>
            <img src={image.url} width={width} height={height} />
          </div>
          <i
            className="ion-ios-close"
            style={{
              position: 'absolute',
              top: -12,
              right: 0,
              fontSize: 32,
              color: '#fff',
            }}
          />
          <i
            className="ion-ios-close-outline"
            style={{
              position: 'absolute',
              top: -12,
              right: 0,
              fontSize: 32,
              cursor: 'pointer',
            }}
            onClick={() => onChange({ url: null, file: null, remove: true })}
          />
        </>
      )}
    </div>
  );
}
