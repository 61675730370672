// @flow
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Col, Row } from 'reactstrap';
import type { BaseProps } from '../../../../application';
import type { Account } from '../../../models/accounts/Account';
import type { MyRod } from '../../../models/my_rods/MyRod';
import type { Maker } from '../../../models/makers/Maker';
import type { Series } from '../../../models/series/Series';
import type { Product } from '../../../models/products/Product';
import { ActivityIndicator } from '../../commons/react-native';
import { List, ListItem, View } from '../../commons/native-base';
import { ListContent } from '../../commons/list';
import { updateMyRods } from '../../../actions/myRods';
import { displayAxiosError, displayMessage } from '../../commons/Toast';
import MyRodClient from '../../../models/my_rods/MyRodClient';
import { date } from '../../../lib/StringUtils';

type Props = BaseProps & {
  account: Account,
  myRods: Array<MyRod>,
  updateMyRods: (myRods: Array<MyRod>) => void,
};

type State = {
  id: ?number,
  myRod: ?MyRod,
  loading: boolean,
};

class ShowView extends React.Component<Props, State> {
  rodClient = new MyRodClient();

  constructor(props: Props) {
    super(props);

    const myRod = props.location.state && props.location.state.myRod;

    this.state = {
      id: myRod != null ? myRod.id : parseInt(props.match.params.id),
      myRod: myRod,
      loading: myRod == null,
    };
  }

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    const { myRods } = nextProps;
    const myRodId = prevState.myRod ? prevState.myRod.id : null;

    if (!prevState.loading) {
      if (myRods != null && myRods.length > 0 && myRodId != null) {
        const myRod = myRods.find(r => r.id == myRodId);

        if (myRod != null) {
          return {
            myRod: myRod,
          };
        }
      }
    }
    return null;
  }

  componentDidMount() {
    const { account } = this.props;
    const { id, myRod } = this.state;

    if (myRod == null && id != null) {
      this.rodClient
        .fetchMyRod(id, account)
        .then(response => {
          this.setState({
            loading: false,
            myRod: response.data,
          });
        })
        .catch(error => {
          this.setState({ loading: false });

          if (error.response.status !== 404) {
            displayAxiosError(error);
          }
        });
    }
  }

  onClickMaker(maker: ?Maker) {
    if (maker != null && !maker.other) {
      window.location.href = `/makers/${maker.id}`;
    }
  }

  onClickSeries(series: ?Series) {
    if (series != null && !series.other) {
      window.location.href = `/series/${series.id}/rod`;
    }
  }

  onClickProduct(product: ?Product) {
    if (product != null && product.use_in_tackle_db) {
      window.location.href = `/tackles/rods/${product.id}`;
    }
  }

  onClickEdit(myRod: MyRod) {
    this.props.history.push(`/my/rods/${myRod.id}/edit`, { myRod: myRod });
  }

  onClickDelete(myRod: MyRod) {
    const { history, account, myRods, updateMyRods } = this.props;

    if (!window.confirm('削除しますか？')) {
      return;
    }

    this.setState({ loading: true });
    this.rodClient
      .delete(myRod.id, account)
      .then(() => {
        displayMessage('ロッドを削除しました');
        updateMyRods([...myRods.filter(r => r.id !== myRod.id)]);
        history.goBack();
      })
      .catch(error => {
        displayAxiosError(error);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  render() {
    const { myRod, loading } = this.state;

    if (loading) {
      return (
        <View
          style={{
            paddingVertical: 20,
            borderTopWidth: 1,
            borderColor: '#CED0CE',
          }}
        >
          <ActivityIndicator animating size="large" />
        </View>
      );
    } else if (myRod != null) {
      return (
        <React.Fragment>
          {myRod.images.length > 0 && (
            <Row className="mb-30">
              {myRod.images.map(image => (
                <Col key={image.id} lg={4}>
                  <a href={image.image.url}>
                    <img src={image.image.thumb.url} className="img-fluid" />
                  </a>
                </Col>
              ))}
            </Row>
          )}
          <List style={{ backgroundColor: '#fff', marginBottom: 20 }}>
            <ListItem
              style={{ cursor: 'pointer' }}
              onClick={() => this.onClickMaker(myRod.maker)}
            >
              <ListContent>
                <Row>
                  <Col xs={4}>メーカー</Col>
                  <Col xs={8}>{myRod.maker ? myRod.maker.name : ''}</Col>
                </Row>
              </ListContent>
            </ListItem>
            <ListItem
              style={{ cursor: 'pointer' }}
              onClick={() => this.onClickSeries(myRod.series)}
            >
              <ListContent>
                <Row>
                  <Col xs={4}>シリーズ</Col>
                  <Col xs={8}>{myRod.series ? myRod.series.name : ''}</Col>
                </Row>
              </ListContent>
            </ListItem>
            {myRod.product != null && !myRod.product.other && (
              <ListItem>
                <ListContent>
                  <Row
                    style={{ cursor: 'pointer' }}
                    className="mb-3"
                    onClick={() => this.onClickProduct(myRod.product)}
                  >
                    <Col xs={4}>モデル</Col>
                    <Col xs={8}>{myRod.product ? myRod.product.name : ''}</Col>
                  </Row>
                  <Row>
                    <Col xs={4}></Col>
                    <Col xs={8} lg={6}>
                      {myRod.product_review_id != null && (
                        <Button
                          block
                          color="outline-warning"
                          onClick={() => {
                            // $FlowFixMe
                            window.location.href = `/tackles/${myRod.product.id}/reviews/${myRod.product_review_id}/edit`;
                          }}
                        >
                          レビューを更新する
                        </Button>
                      )}
                      {myRod.product_review_id == null && (
                        <Button
                          block
                          color="warning"
                          onClick={() => {
                            window.location.href = `/tackles/${myRod.product.id}/reviews/new`;
                          }}
                        >
                          レビューを投稿する
                        </Button>
                      )}
                    </Col>
                  </Row>
                </ListContent>
              </ListItem>
            )}
            {myRod.product != null && myRod.product.other && !!myRod.name && (
              <ListItem>
                <ListContent>
                  <Row>
                    <Col xs={4}></Col>
                    <Col xs={8}>{myRod.name}</Col>
                  </Row>
                </ListContent>
              </ListItem>
            )}
            <ListItem>
              <ListContent>
                <Row>
                  <Col xs={4}>使用開始日</Col>
                  <Col xs={8}>{date(myRod.get_date)}</Col>
                </Row>
              </ListContent>
            </ListItem>
            <ListItem>
              <ListContent>
                <Row>
                  <Col xs={4}>使用終了日</Col>
                  <Col xs={8}>{date(myRod.lost_date)}</Col>
                </Row>
              </ListContent>
            </ListItem>
            <ListItem>
              <ListContent>
                <Row>
                  <Col xs={4}>メモ</Col>
                  <Col xs={8} style={{ whiteSpace: 'pre-line' }}>
                    {myRod.memo}
                  </Col>
                </Row>
              </ListContent>
            </ListItem>
          </List>
          <Row>
            <Col sm={3} xs={6}>
              <Button
                block
                color="primary"
                onClick={() => this.onClickEdit(myRod)}
              >
                編集する
              </Button>
            </Col>
            <Col sm={3} xs={6}>
              <Button
                block
                color="outline-primary"
                onClick={() => this.onClickDelete(myRod)}
              >
                削除する
              </Button>
            </Col>
          </Row>
        </React.Fragment>
      );
    } else {
      return <div>ロッドが見つかりませんでした</div>;
    }
  }
}

export default connect(
  state => ({
    account: state.account,
    myRods: state.myRods,
  }),
  dispatch => bindActionCreators({ updateMyRods }, dispatch)
)(ShowView);
