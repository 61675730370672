// @flow
import React from 'react';
import type { Node } from 'react';

type Props = {};

export default class FourmSpArticleInArticle1st extends React.Component<Props> {
  render(): Node {
    const adId = 'div-gpt-ad-sp_article_inarticle_1st';
    const adPath =
      '/83555300,21796408308/anglers/anglers/sp_article_inarticle_1st';
    const adDimensions =
      '[[300, 600], [300, 250], [336, 280], "fluid", [320, 180], [320, 250], [320, 50], [320, 100], [1, 1]]';

    return (
      <div className="advertisements">
        <div className="text-center mb-30 mt-30">
          <div
            id={adId}
            className="gpt-ad"
            data-gpt-div-id={adId}
            data-gpt-path={adPath}
            data-gpt-dimensions={adDimensions}
          />
        </div>
      </div>
    );
  }
}
