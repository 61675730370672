// @flow
import React, { useCallback, useState } from 'react';
import SearchIcon from '../../assets/images/views/ships/search_icon.png';
import PrefectureIcon from '../../assets/images/views/ships/prefecture.png';
import FishIcon from '../../assets/images/views/ships/fish.png';
import ArrowIcon from '../../assets/images/views/ships/arrow.png';
import SelectPrefectureModal from './SelectPrefectureModal.js';
import SelectFishModal from './SelectFishModal.js';
import type { Region } from '../../models/regions/Region.js';
import type { FishWithDetails } from '../../models/fishes/Fish';
import styled from 'styled-components';
import { viewSp } from '../../lib/Mixins';

type Props = {
  regions: Array<Region>,
  fishes: Array<FishWithDetails>,
};

function SearchFormRoot(props: Props) {
  // ReactOnRailsの不具合で直接Hooksを使ってるコンポーネントをexportできない
  return <SearchForm {...props} />;
}

function SearchForm({ regions, fishes }: Props) {
  const [formKeyword, setFormKeyword] = useState('');
  const [formPrefectures, setFormPrefectures] = useState([]);
  const [formFishes, setFormFishes] = useState([]);
  const [visiblePrefectureModal, setVisiblePrefectureModal] = useState(false);
  const [visibleFishModal, setVisibleFishModal] = useState(false);

  const onApplyPrefectures = useCallback(prefectures => {
    setFormPrefectures(prefectures);
    setVisiblePrefectureModal(false);
  }, []);

  const onApplyFishes = useCallback(fishes => {
    setFormFishes(fishes);
    setVisibleFishModal(false);
  }, []);

  return (
    <>
      <SearchBar>
        <InputContainer>
          <img src={SearchIcon} />
          <input
            placeholder="釣船・船宿名などを入力"
            type="text"
            name="keyword"
            value={formKeyword}
            onChange={e => setFormKeyword(e.target.value)}
          />
          <input
            type="hidden"
            name="prefecture_id_in"
            value={formPrefectures.map(prefecture => prefecture.id)}
          />
          <input
            type="hidden"
            name="fishes_id_in"
            value={formFishes.map(fish => fish.id)}
          />
        </InputContainer>

        <ModalButtonContainer>
          <Button onClick={() => setVisiblePrefectureModal(true)}>
            <ButtonInnerContainer>
              <ButtonIcon src={PrefectureIcon} />
              <ButtonTextContainer>
                <small>都道府県</small>
                <ButtonText>
                  {formPrefectures.map(prefecture => prefecture.name).join('/')}
                  {formPrefectures.length === 0 && <>指定なし</>}
                </ButtonText>
              </ButtonTextContainer>
            </ButtonInnerContainer>
            <ButtonArrow src={ArrowIcon} />
          </Button>

          <Button onClick={() => setVisibleFishModal(true)}>
            <ButtonInnerContainer>
              <ButtonIcon src={FishIcon} />
              <ButtonTextContainer>
                <small>釣れる魚種</small>
                <ButtonText>
                  {formFishes.map(fish => fish.name).join('/')}
                  {formFishes.length === 0 && <>指定なし</>}
                </ButtonText>
              </ButtonTextContainer>
            </ButtonInnerContainer>
            <ButtonArrow src={ArrowIcon} />
          </Button>
        </ModalButtonContainer>

        <SubmitButton type="submit">検索する</SubmitButton>
      </SearchBar>

      <SelectPrefectureModal
        regions={regions}
        visible={visiblePrefectureModal}
        onApply={onApplyPrefectures}
        onClose={setVisiblePrefectureModal}
        initialPrefectures={formPrefectures}
      />

      <SelectFishModal
        fishes={fishes}
        visible={visibleFishModal}
        onApply={onApplyFishes}
        onClose={setVisibleFishModal}
        initialFishes={formFishes}
      />
    </>
  );
}

const SearchBar = styled.div`
  background-color: white;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  height: 80px;
  padding: 16px;
  width: 100%;
  z-index: 1;

  ${viewSp} {
    flex-direction: column;
    padding: 14px;
    height: 192px;
    width: 100%;
  }
`;

const InputContainer = styled.div`
  position: relative;

  img {
    top: 50%;
    left: 11px;
    position: absolute;
    transform: translateY(-50%);
    width: 28px;

    ${viewSp} {
      width: 24px;
    }
  }

  input {
    background-color: #e5f1f8;
    border: none;
    border-radius: 8px;
    padding-left: 50px;
    height: 52px;
    width: 480px;

    ${viewSp} {
      padding-left: 43px;
      height: 46px;
      width: 100%;
    }

    &::placeholder {
      color: #6badd7;
      font-size: 16px;

      ${viewSp} {
        font-size: 15px;
      }
    }

    &:-ms-input-placeholder {
      color: #6badd7;
      font-size: 16px;

      ${viewSp} {
        font-size: 15px;
      }
    }
  }
`;

const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Button = styled.div`
  border: 1.5px solid #e5f1f8;
  border-radius: 8px;
  color: #0877bc;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  margin-left: 10px;
  height: 52px;
  width: 175px;

  ${viewSp} {
    font-size: 13px;
    margin-left: 0;
    margin-top: 10px;
    height: 46px;
    width: calc(50% - 5px);
  }
`;

const ButtonInnerContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  small {
    font-size: 10px;
    font-weight: bold;
    opacity: 0.6;

    ${viewSp} {
      font-size: 9px;
    }
  }
`;

const ButtonIcon = styled.img`
  margin-left: 10px;
  margin-right: 7px;
  height: 28px;
  width: 28px;

  ${viewSp} {
    height: 24px;
    width: 24px;
  }
`;

const ButtonTextContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  line-height: 1;
`;

const ButtonText = styled.div`
  margin-top: 6px;
  max-width: 95px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${viewSp} {
    font-size: 13px;
    max-width: 20vw;
    margin-top: 4px;
  }
`;

const ButtonArrow = styled.img`
  margin-left: 7px;
  margin-right: 11px;
  height: 12px;
  width: 12px;
`;

const SubmitButton = styled.button`
  background-color: #0877bc;
  border: none;
  border-radius: 24px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  margin-left: 10px;
  height: 52px;
  width: 132px;

  ${viewSp} {
    border-radius: 22px;
    margin-left: 0;
    margin-top: 14px;
    height: 48px;
    width: 100%;
  }
`;

export default SearchFormRoot;
