// @flow
import React from 'react';
import { Button, Form, Input } from 'reactstrap';
import CommentClient from '../../../../models/products/reviews/comments/CommentClient';
import type { Comment } from '../../../../models/products/reviews/comments/Comment';
import type { Review } from '../../../../models/products/reviews/Review';
import { displayAxiosError, displayMessage } from '../../../commons/messages';

type Props = {
  visible: boolean,
  parent: ?Comment,
  review: Review,
  token: string,
  focus: boolean,
  onFinishPosting: (comment: Comment) => void,
};

type State = {
  comment: string,
};

export default class CommentForm extends React.Component<Props, State> {
  input: Input;
  commentClient = new CommentClient();

  constructor(props: Props) {
    super(props);

    console.log('CommentForm', props.review);

    this.state = {
      comment: '',
    };
  }

  componentDidMount() {
    if (this.props.focus) {
      this.input.focus();
    }
  }

  onClickPost() {
    const { parent, review, token } = this.props;
    const { comment } = this.state;

    this.commentClient
      .post(review, parent != null ? parent.id : null, comment, token)
      .then(response => {
        this.setState({ comment: '' });
        this.props.onFinishPosting(response.data);
        displayMessage('コメントしました');
      })
      .catch(error => {
        if (error.response.status === 400) {
          displayMessage(error.response.data.message);
        } else {
          displayAxiosError(error);
        }
      });
  }

  render() {
    const { visible, parent } = this.props;
    const { comment } = this.state;

    if (!visible) {
      return null;
    }

    return (
      <div className="comment-form">
        <Form>
          <div className="input-group">
            <Input
              ref={input => (this.input = input)}
              type="textarea"
              value={comment}
              placeholder={
                parent != null
                  ? `${parent.user.name}さんに返信`
                  : 'ナイスレビュー！などをコメントしてみよう'
              }
              onChange={e => this.setState({ comment: e.target.value })}
            />
            <Button outline color="primary" onClick={() => this.onClickPost()}>
              送信
            </Button>
          </div>
        </Form>
      </div>
    );
  }
}
