// @flow
import moment from 'moment';
import AxiosClient from '../AxiosClient';
import type { AxiosPromise } from 'axios';
import type { Venue } from './Venue';
import type { VenueWithConditions } from './VenueWithConditions';

export default class VenueClient extends AxiosClient {
  fetchAll(): AxiosPromise<Array<Venue>> {
    return this.buildClient().get('/api/v2/venues.json');
  }

  fetchVenueWithConditions(
    id: number,
    datetime: moment
  ): AxiosPromise<VenueWithConditions> {
    return this.buildClient().get(
      `/api/v2/venues_with_conditions/${id}.json?observeymd=${datetime.format(
        'YYYYMMDD'
      )}&observehour=${datetime.hours()}`
    );
  }
}
