// @flow
import React, { useEffect } from 'react';
import { Input } from 'reactstrap';
import SummernoteClient from '../../models/summernotes/SummernoteClient';

declare var $: any;

type Props = {
  content: ?string,
  klassName: string,
  placeholder: string,
  invalid: boolean,
  onChange: (content: string) => void,
};

export default function Summernote({
  content,
  placeholder,
  klassName,
  onChange,
}: Props) {
  const client = new SummernoteClient();

  useEffect(() => {
    $(`.${klassName}`).summernote({
      height: 160,
      lang: 'ja-JP',
      placeholder: placeholder,
      toolbar: [
        ['style', ['style']],
        ['font', ['bold', 'underline', 'clear']],
        ['color', ['color']],
        ['para', ['ul', 'ol', 'paragraph']],
        ['insert', ['link', 'picture', 'video']],
        ['view', ['fullscreen', 'codeview']],
      ],
      popover: {
        image: [],
        link: [],
        air: [],
      },
      callbacks: {
        onImageUpload: files => {
          client
            .create(files[0])
            .then(response => {
              $(`.${klassName}`).summernote('insertImage', response.data.url);
            })
            .catch(() => {
              alert('画像のアップロードに失敗しました');
            });
        },
        onChange: onChange,
      },
    });
  }, []);

  return (
    <Input
      type="textarea"
      className={klassName}
      defaultValue={content != null ? content : ''}
    />
  );
}

Summernote.defaultProps = {
  placeholder: '',
  invalid: false,
};
