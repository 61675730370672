// @flow
import moment from 'moment';
import AxiosClient from '../AxiosClient';
import type { Result } from './Result';
import type { Axios, AxiosPromise } from 'axios';

export default class ResultClient extends AxiosClient {
  fetchAll(
    page: number,
    order: string,
    keyword: ?string,
    weathers: ?Array<any>,
    startOn: ?string,
    endOn: ?string,
    fishId: ?number,
    areaId: ?number,
    prefectureId: ?number,
    regionId: ?number
  ): AxiosPromise<Array<Result>> {
    return this.buildResultClient().get('/api/v2/results.json', {
      params: {
        page: page,
        order: order,
        keyword: keyword,
        weathers: weathers,
        start_on: startOn,
        end_on: endOn,
        fish_id: fishId,
        area_id: areaId,
        prefecture_id: prefectureId,
        region_id: regionId,
      },
    });
  }

  fetchByPrefecture(
    page: number,
    order: string,
    weathers: ?Array<any>,
    startOn: ?string,
    endOn: ?string,
    regionId: ?number,
    prefectureId: ?number,
    fishId: ?number
  ): AxiosPromise<Array<Result>> {
    return this.buildResultClient().get('/api/v2/results.json', {
      params: {
        page: page,
        order: order,
        weathers: weathers,
        start_on: startOn,
        end_on: endOn,
        region_id: regionId,
        prefecture_id: prefectureId,
        fish_id: fishId,
      },
    });
  }

  fetchByMaker(
    page: number,
    order: string,
    weathers: ?Array<any>,
    startOn: ?string,
    endOn: ?string,
    makerId: number,
    type: string
  ): AxiosPromise<Array<Result>> {
    const params: Object = {
      page: page,
      order: order,
      weathers: weathers,
      start_on: startOn,
      end_on: endOn,
    };

    /*eslint indent: ["error", 2, { "SwitchCase": 1 }]*/
    switch (type) {
      case 'Rod':
        params.rod_maker_id = makerId;
        break;
      case 'Reel':
        params.reel_maker_id = makerId;
        break;
      case 'Line':
        params.line_maker_id = makerId;
        break;
      default:
        params.lure_maker_id = makerId;
        break;
    }

    return this.buildResultClient().get('/api/v2/results.json', {
      params: params,
    });
  }

  fetchBySeries(
    page: number,
    order: string,
    weathers: ?Array<any>,
    startOn: ?string,
    endOn: ?string,
    seriesId: number,
    type: string
  ): AxiosPromise<Array<Result>> {
    const params: Object = {
      page: page,
      order: order,
      weathers: weathers,
      start_on: startOn,
      end_on: endOn,
    };

    /*eslint indent: ["error", 2, { "SwitchCase": 1 }]*/
    switch (type) {
      case 'Rod':
        params.rod_series_id = seriesId;
        break;
      case 'Reel':
        params.reel_series_id = seriesId;
        break;
      case 'Line':
        params.line_series_id = seriesId;
        break;
      default:
        params.lure_series_id = seriesId;
        break;
    }

    return this.buildResultClient().get('/api/v2/results.json', {
      params: params,
    });
  }

  fetchByProduct(
    page: number,
    order: string,
    weathers: ?Array<any>,
    startOn: ?string,
    endOn: ?string,
    productId: number,
    type: string
  ): AxiosPromise<Array<Result>> {
    const params: Object = {
      page: page,
      order: order,
      weathers: weathers,
      start_on: startOn,
      end_on: endOn,
    };

    /*eslint indent: ["error", 2, { "SwitchCase": 1 }]*/
    switch (type) {
      case 'Rod':
        params.rod_product_id = productId;
        break;
      case 'Reel':
        params.reel_product_id = productId;
        break;
      case 'Line':
        params.line_product_id = productId;
        break;
      default:
        params.lure_product_id = productId;
        break;
    }

    return this.buildResultClient().get('/api/v2/results.json', {
      params: params,
    });
  }

  fetchByShip(
    page: number,
    shipId: number,
    isCrew: boolean
  ): AxiosPromise<Array<Result>> {
    return this.buildResultClient().get(
      `/api/v2/ships/${shipId}/catches.json`,
      {
        params: {
          page: page,
          is_crew: isCrew,
        },
      }
    );
  }

  upVote(resultId: number, token: string): AxiosPromise<any> {
    return this.buildClient(token).post(
      `/api/v2/results/${resultId}/vote.json`
    );
  }

  downVote(resultId: number, token: string): AxiosPromise<any> {
    return this.buildClient(token).delete(
      `/api/v2/results/${resultId}/vote.json`
    );
  }

  // private methods

  buildResultClient(): Axios {
    const client = this.buildClient();

    client.interceptors.response.use(response => {
      if (response.data instanceof Array) {
        response.data.forEach(result => {
          if (result.caught_at != null) {
            result.caught_at = moment(result.caught_at);
          }

          if (result.published_at != null) {
            result.published_at = moment(result.data.published_at);
          }
        });
      }

      return response;
    });

    return client;
  }
}
