// @flow
import React from 'react';

type Props = {};

export default class FourmPcArticleBillboard extends React.Component<Props> {
  render() {
    const adId = 'div-gpt-ad-pc_article_billboard';
    const adPath = '/83555300,21796408308/anglers/anglers/pc_article_billboard';
    const adDimensions =
      '[[980, 250], [980, 90], "fluid", [970, 250], [728, 90], [1, 1], [970, 90]]';

    return (
      <div className="advertisements">
        <div className="text-center mb-30 mt-30">
          <div
            id={adId}
            className="gpt-ad"
            data-gpt-div-id={adId}
            data-gpt-path={adPath}
            data-gpt-dimensions={adDimensions}
          />
        </div>
      </div>
    );
  }
}
