// @flow

import React from 'react';
import FacebookIcon from '../../assets/images/views/layouts/facebook.png';
import AppleIcon from '../../assets/images/views/layouts/apple.png';
import AlertIcon from '../../assets/images/views/users/alert.png';
import SignUpIcon from '../../assets/images/views/users/sign_up.png';
import useCreate from './SignUpForm/useCreate';

const SignUpForm = (): React$Element<'section'> => {
  const {
    email,
    setEmail,
    password,
    setPassword,
    emailErrors,
    passwordErrors,
    agreement,
    setAgreement,
    isSubmitting,
    handleSubmit,
  } = useCreate();

  const isEmailError = emailErrors.length > 0;
  const isPasswordError = passwordErrors.length > 0;

  return (
    <section data-scope-path="application/components/users/sign_up_form">
      <a href="/users/auth/facebook" data-method="post">
        <button className="auth-facebook">
          <img src={FacebookIcon} />
          Facebookアカウントを利用
        </button>
      </a>

      <a href="/users/auth/apple" data-method="post">
        <button className="auth-apple">
          <img src={AppleIcon} />
          Apple IDを利用
        </button>
      </a>

      <section className="auth-email">
        <h3>
          <img src={SignUpIcon} />
          <div>メールアドレスで新規登録</div>
        </h3>

        <div className={`input-with-icon ${isEmailError ? 'error_color' : ''}`}>
          <input
            type="email"
            placeholder="メールアドレス"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
          <i className="fa fa-envelope"></i>
        </div>
        {isEmailError &&
          emailErrors.map((error, index) => (
            <div key={index} className="error_message">
              <img src={AlertIcon} />
              {error}
            </div>
          ))}

        <div
          className={`input-with-icon ${isPasswordError ? 'error_color' : ''}`}
        >
          <input
            type="password"
            placeholder="パスワードの設定"
            autoComplete="current-password"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
          <i className="fa fa-lock"></i>
        </div>
        {isPasswordError &&
          passwordErrors.map((error, index) => (
            <div key={index} className="error_message">
              <img src={AlertIcon} />
              {error}
            </div>
          ))}

        <section className="agreement">
          <input
            type="checkbox"
            className="checkbox"
            checked={agreement}
            onChange={e => setAgreement(e.target.checked)}
          />
          <span className="agreement-text">
            <a href="/terms" target="_blank">
              利用規約
            </a>
            および
            <a href="/terms/privacy_policy" target="_blank">
              プライバシーポリシー
            </a>
            に同意する
          </span>
        </section>

        <button
          type="submit"
          onClick={() => handleSubmit()}
          disabled={!agreement || isSubmitting}
          style={{
            cursor: !agreement || isSubmitting ? 'none' : 'pointer',
            opacity: !agreement || isSubmitting ? 0.5 : 1,
          }}
        >
          認証コードを送信
        </button>
      </section>
    </section>
  );
};

export default SignUpForm;
