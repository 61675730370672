// @flow
import type { AxiosPromise } from 'axios';
import CamelAxiosClient from '../CamelAxiosClient';

export default class SummernoteClient extends CamelAxiosClient {
  create(file: File): AxiosPromise<any> {
    const data = new FormData();
    data.append('summernote[image]', file);
    return this.buildClient().post('/summernotes', data);
  }
}
