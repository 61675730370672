// @flow
import JQueryView, { on } from '../../../../../common/views/JQueryView';

declare var $: any;

export default class FormView extends JQueryView {
  image: string;

  constructor(image: string) {
    super('#form_view');
    this.image = image;
    this.render();

    // invalid-feedbackエレメントがform-group内部に置けなかったので
    this.$el.find('.invalid-feedback.outside').each((i, e) => {
      $(e).appendTo($(e).prev());
    });

    $('[data-toggle="tooltip"]').tooltip();

    window.addEventListener('load', this.onLoad);
  }

  onLoad() {
    const form = $('.needs-validation')[0];

    form.addEventListener(
      'submit',
      event => {
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }
        form.classList.add('was-validated');
      },
      false
    );
  }

  @on('change #result_photos_attributes_0_image')
  onChangeImage(event: SyntheticInputEvent<EventTarget>) {
    if (!event.target.files.length) {
      return;
    }

    let file = $(event.target).prop('files')[0];
    let fr = new FileReader();
    fr.onload = () => {
      this.image = String(fr.result);
      this.render();
    };
    fr.readAsDataURL(file);
  }

  render() {
    if (this.image != '') {
      $('#preview_image').css('background-image', `url(${this.image})`);
      $('#preview_image-wrap').show();
      $('input[type="submit"]').removeClass('disabled');
    }
  }
}
