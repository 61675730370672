// @flow
import React from 'react';

type Props = {
  weathers: Array<any>,
};

export default function WeatherButton({ weathers }: Props) {
  const checkedWeathers = weathers.filter(w => w.checked);

  if (checkedWeathers.length === 0) {
    return (
      <button className="btn btn-light mr-10" data-toggle="dropdown">
        天気
      </button>
    );
  } else {
    return (
      <button className="btn btn-success mr-10" data-toggle="dropdown">
        {checkedWeathers.map(w => w.name).join(' ')}
      </button>
    );
  }
}
