// @flow
import React from 'react';
import type { Product } from '../../models/products/Product';
import { ActivityIndicator, View } from '../commons/react-native';

type Props = {
  filteredProducts: Array<Product>,
  isLoading: boolean,
  makerName: ?string,
  seriesName: ?string,
};

export function SelectFooter({
  filteredProducts,
  isLoading,
  makerName,
  seriesName,
}: Props): React$Node {
  function buildContactLink() {
    return (
      <a
        href={`/contact/tacklebox?maker_name=${
          makerName ? makerName : ''
        }&series_name=${seriesName ? seriesName : ''}`}
        className="btn btn-outline-primary btn-block"
        target="_blank"
        rel="noopener noreferrer"
      >
        モデルを登録する
      </a>
    );
  }

  if (isLoading) {
    return (
      <View
        style={{
          paddingVertical: 20,
          borderTopWidth: 1,
          borderColor: '#CED0CE',
        }}
      >
        <ActivityIndicator />
      </View>
    );
  } else {
    if (filteredProducts.length === 0) {
      return (
        <View
          style={{
            flex: 1,
            alignItems: 'center',
            paddingVertical: 20,
          }}
        >
          <div className="mb-4">モデルは見つかりませんでした</div>
          {buildContactLink()}
        </View>
      );
    } else {
      return <div className="mt-4">{buildContactLink()}</div>;
    }
  }
}
