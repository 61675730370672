// @flow
import React from 'react';
import { Row, Col } from 'reactstrap';

import { Icon, Body, Right, Text, ListItem } from '../../commons/native-base';
import ProductHelper from '../../../helpers/ProductHelper';
import { date } from '../../../lib/StringUtils';

import type { RouterHistory } from 'react-router-dom';
import type { MyReel } from '../../../models/my_reels/MyReel';

type Props = {
  history: RouterHistory,
  myReel: MyReel,
};

export default class MyReelListItem extends React.PureComponent<Props> {
  onClickMyReel(myReel: MyReel) {
    this.props.history.push(`/my/reels/${myReel.id}`, { myReel: myReel });
  }

  render() {
    const { myReel } = this.props;

    return (
      <ListItem
        style={{ backgroundColor: '#fff', cursor: 'pointer' }}
        onClick={() => this.onClickMyReel(myReel)}
      >
        <Body style={{ flex: 3 }}>
          <Text>{ProductHelper.reel_details_without_maker(myReel)}</Text>
          <Row>
            <Col>
              <Text className="text-muted">
                {myReel.maker && myReel.maker ? myReel.maker.name : ''}
              </Text>
            </Col>
            <Col>
              <Text className="text-muted">
                {date(myReel.get_date)}
                {myReel.get_date != null || myReel.lost_date != null
                  ? ' 〜 '
                  : ''}
                {date(myReel.lost_date)}
              </Text>
            </Col>
          </Row>
          <Text className="text-muted">
            {ProductHelper.line_details_without_maker(myReel)}
          </Text>
          <Text className="text-muted">
            {myReel.line_maker && myReel.line_maker
              ? myReel.line_maker.name
              : ''}
          </Text>
        </Body>
        <Right>
          <Icon name="arrow-forward" />
        </Right>
      </ListItem>
    );
  }
}
