// @flow
import React from 'react';
import ReactQRCode from 'react-qr-code';

type Props = {
  url: string,
};

function QrCodeRoot(props: Props) {
  // ReactOnRailsの不具合で直接Hooksを使ってるコンポーネントをexportできない
  return <QrCode {...props} />;
}

function QrCode({ url }: Props) {
  return (
    <ReactQRCode
      value={url}
      style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
      viewBox={`0 0 256 256`}
    />
  );
}

export default QrCodeRoot;
