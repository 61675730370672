// @flow
import React from 'react';
import { ModalBody } from 'reactstrap';
import type { Maker } from '../../models/makers/Maker';
import type { Series } from '../../models/series/Series';
import type { Product } from '../../models/products/Product';
import { hankana2zenkana, hiraganaToKatagana } from '../../lib/StringUtils';
import { FlatList } from '../commons/react-native';
import { Text, Body, ListItem } from '../commons/native-base';
import SearchBar from '../commons/SearchBar';
import ProductClient from '../../models/products/ProductClient';
import ProductHelper from '../../helpers/ProductHelper';
import { displayAxiosError } from '../commons/messages';
import { SelectFooter } from './SelectFooter';

// const Immutable = require('immutable');

type Props = {
  maker: Maker,
  series: Series,
  type: string,
  onApply: (product: Product) => void,
};

type State = {
  products: Array<Product>,
  filteredProducts: Array<Product>,
  isLoading: boolean,
  typingTimeout: any,
};

export class SelectBody extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      products: [],
      filteredProducts: [],
      isLoading: true,
      typingTimeout: 0,
    };
  }

  // shouldComponentUpdate(nextProps: Props, nextState: State) {
  //   const diffProps = this.props.productType === nextProps.productType;

  //   const diffState =
  //     this.state.isLoading === nextState.isLoading &&
  //     this.state.typingTimeout === nextState.typingTimeout &&
  //     Immutable.is(
  //       Immutable.fromJS(this.state.makers),
  //       Immutable.fromJS(nextState.makers)
  //     ) &&
  //     Immutable.is(
  //       Immutable.fromJS(this.state.filteredMakers),
  //       Immutable.fromJS(nextState.filteredMakers)
  //     );

  //   return !(diffProps && diffState);
  // }

  componentDidMount() {
    const { maker, series, type } = this.props;

    if (maker != null && series != null) {
      new ProductClient()
        .fetchAllByMakerAndSeries(
          ProductHelper.pathFromType(type),
          maker.id,
          series.id
        )
        .then(response => {
          this.setState({
            products: response.data,
            filteredProducts: response.data,
            isLoading: false,
          });
        })
        .catch(error => {
          this.setState({ isLoading: false });
          displayAxiosError(error);
        });
    }
  }

  onChangeFilter(text: string) {
    if (this.state.typingTimeout > 0) {
      clearTimeout(this.state.typingTimeout);
    }

    const value = hiraganaToKatagana(text);

    const filteredProducts = this.state.products.filter(m => {
      const name_jp = hankana2zenkana(hiraganaToKatagana(m.name_jp || ''));
      return (
        m.name_en.toLowerCase().indexOf(value.toLowerCase()) >= 0 ||
        m.name_jp.toLowerCase().indexOf(value.toLowerCase()) >= 0 ||
        name_jp.toLowerCase().indexOf(hankana2zenkana(value)) >= 0
      );
    });

    this.setState({
      typingTimeout: setTimeout(() => {
        this.setState({
          filteredProducts: filteredProducts,
        });
      }, 1000),
    });
  }

  render(): React$Node {
    const { onApply } = this.props;
    const { filteredProducts, isLoading } = this.state;

    return (
      <ModalBody className="mb-60">
        <SearchBar
          onChangeText={text => this.onChangeFilter(text)}
          placeholder="キーワードで絞り込み"
          style={{ marginBottom: 20 }}
        />
        <FlatList
          keyboardShouldPersistTaps={'handled'}
          data={filteredProducts}
          renderItem={item => (
            <ListItem
              key={item.id}
              style={{ backgroundColor: '#fff', cursor: 'pointer' }}
              onClick={() => onApply(item)}
            >
              <Body>
                <Text>{item.name}</Text>
              </Body>
            </ListItem>
          )}
          keyExtractor={item => item.id.toString()}
          ListFooterComponent={() => (
            <SelectFooter
              filteredProducts={filteredProducts}
              isLoading={isLoading}
              makerName={this.props.maker.name}
              seriesName={this.props.series.name}
            />
          )}
        />
      </ModalBody>
    );
  }
}
