// @flow
import { useState } from 'react';
import AuthClient from '../../../models/auth/AuthClient';

const useConfirm = (): {
  code1: string,
  setCode1: (code1: string) => void,
  code2: string,
  setCode2: (code2: string) => void,
  code3: string,
  setCode3: (code3: string) => void,
  code4: string,
  setCode4: (code4: string) => void,
  code5: string,
  setCode5: (code5: string) => void,
  code6: string,
  setCode6: (code6: string) => void,
  isError: boolean,
  isSubmitting: boolean,
  handleSubmit: () => Promise<void>,
} => {
  const [code1, setCode1] = useState('');
  const [code2, setCode2] = useState('');
  const [code3, setCode3] = useState('');
  const [code4, setCode4] = useState('');
  const [code5, setCode5] = useState('');
  const [code6, setCode6] = useState('');
  const [isError, setIsError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async () => {
    setIsSubmitting(true);

    return await new AuthClient()
      .confirmation(
        String(code1) +
          String(code2) +
          String(code3) +
          String(code4) +
          String(code5) +
          String(code6)
      )
      .then(response => {
        const location = response.headers ? response.headers['location'] : null;
        if (location) {
          window.location.href = location;
        } else {
          console.error('Location header is missing');
          window.location.href = '/';
        }
      })
      .catch(e => {
        console.log(e);
        setIsError(true);
      })
      .finally(() => {
        setCode1('');
        setCode2('');
        setCode3('');
        setCode4('');
        setCode5('');
        setCode6('');
        setIsSubmitting(false);
      });
  };

  return {
    code1,
    setCode1,
    code2,
    setCode2,
    code3,
    setCode3,
    code4,
    setCode4,
    code5,
    setCode5,
    code6,
    setCode6,
    isError,
    isSubmitting,
    handleSubmit,
  };
};

export default useConfirm;
