// @flow
export const NOT_SET = '未入力';

export const UNIT = {
  size: 'cm',
  weight: 'g',
  tempC: '℃',
  windSpeedMps: 'm/s',
  pressure: 'hPa',
  suion: '℃',
  suishin: 'm',
  tana: 'm',
  suii: 'm',
  zenuryo: 'mm',
  hosuiryo: '㎥/s',
};

export default class ResultHelper {
  static size(result: any) {
    return result.size != null ? `${result.size}${UNIT.size}` : '';
  }

  static weight(result: any) {
    return result.weight != null ? `${result.weight}${UNIT.weight}` : '';
  }

  static fiscount(result: any) {
    return result.fiscount != null ? `${result.fiscount}匹` : '';
  }

  static weather(result: any) {
    return result.weather != null ? result.weather : '';
  }

  static tempc(result: any) {
    return result.tempc != null ? `${result.tempc}${UNIT.tempC}` : '';
  }

  static winddir16point(result: any) {
    return result.winddir16point != null ? result.winddir16point : '';
  }

  static winddir16pointjp(result: any) {
    return result.winddir16pointjp != null ? result.winddir16pointjp : '';
  }

  static windspeedmps(result: any) {
    return result.windspeedmps != null
      ? `${result.windspeedmps}${UNIT.windSpeedMps}`
      : '';
  }

  static pressure(result: any) {
    return result.pressure != null ? `${result.pressure}${UNIT.pressure}` : '';
  }

  static weathers(result: any) {
    return `${ResultHelper.weather(result)}  ${ResultHelper.tempc(
      result
    )}  ${ResultHelper.winddir16pointjp(result)}  ${ResultHelper.windspeedmps(
      result
    )}  ${ResultHelper.pressure(result)}`;
  }

  static suion(result: any) {
    return result.suion != null ? `${result.suion}${UNIT.suion}` : '';
  }

  static suishin(result: any) {
    return result.suishin != null ? `${result.suishin}${UNIT.suishin}` : '';
  }

  static tana(result: any) {
    return result.tana != null ? `${result.tana}${UNIT.tana}` : '';
  }

  static suii(result: any) {
    return result.suii != null ? `${result.suii}${UNIT.suii}` : '';
  }

  static zenuryo(result: any) {
    return result.zenuryo != null ? `${result.zenuryo}${UNIT.zenuryo}` : '';
  }

  static hosuiryo(result: any) {
    return result.hosuiryo != null ? `${result.hosuiryo}${UNIT.hosuiryo}` : '';
  }

  static tide(result: any) {
    return result.tide != null ? `${result.tide}㎝` : '';
  }

  static shionm(result: any) {
    return result.tide != null ? result.shionm : '';
  }

  static venue_name(result: any) {
    return result.venue != null ? result.venue.name : '';
  }

  static field_name(result: any) {
    return result.fieldnm != null ? result.fieldnm : NOT_SET;
  }

  static lure_details(result: any) {
    return result.lure_details || NOT_SET;
  }

  static isMemo(result: any) {
    return result.fiscount < 1;
  }
}
