// @flow
import React from 'react';
import * as linkHeaderParser from 'linkheader-parser/dist/linkheader-parser-node';
import { Col, Container, TabPane } from 'reactstrap';
import type { Fish } from '../../models/fishes/Fish';
import FishClient from '../../models/fishes/FishClient';
import InfiniteScroll from 'react-infinite-scroller';
import Loader from '../commons/Loader';
import FishCard from '../fishes/FishCard';
import styled from 'styled-components';

const Link = styled.a`
  &:hover,
  &:focus {
    text-decoration: none;
  }
`;

export const FISHES_TAB = 'fishes_tab';

type Props = {
  keyword: ?string,
  active: boolean,
};

type State = {
  fishes: Array<Fish>,
  keyword: ?string,
  isLoading: boolean,
  page: number,
  hasNextPage: boolean,
};

export default class FishesTabPane extends React.Component<Props, State> {
  fishClient = new FishClient();

  constructor(props: Props) {
    super(props);

    this.state = {
      fishes: [],
      keyword: props.keyword,
      isLoading: true,
      page: 1,
      hasNextPage: true,
    };
  }

  componentDidMount() {
    setTimeout(() => this.fetchFishes(), 2000);
  }

  componentDidUpdate() {
    const { active, keyword } = this.props;
    // タブがアクティブになったタイミングで、キーワードが変更されていた場合にfetchする
    if (active && this.state.keyword !== keyword) {
      this.setState(
        {
          fishes: [],
          keyword: keyword,
          page: 1,
          isLoading: true,
        },
        () => this.fetchFishes()
      );
    }
  }

  fetchFishes() {
    const { keyword } = this.props;
    const { page, fishes } = this.state;

    this.fishClient
      .searchFromResults(page, keyword)
      .then(response => {
        const hasNextPage =
          response.headers &&
          response.headers.hasOwnProperty('link') &&
          linkHeaderParser.parse(response.headers.link).hasOwnProperty('next');

        this.setState({
          fishes: [...fishes, ...response.data],
          hasNextPage: hasNextPage,
          isLoading: false,
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  onLoadMore = () => {
    if (!this.state.isLoading && this.state.hasNextPage) {
      this.setState(
        {
          page: this.state.page + 1,
          isLoading: true,
        },
        () => this.fetchFishes()
      );
    }
  };

  onClick() {
    // $FlowFixMe
    gtag('event', 'click', {
      event_category: 'ToFish',
      event_label: 'fishes_tab_pane',
    });
  }

  render() {
    const { fishes, hasNextPage } = this.state;

    return (
      <TabPane tabId={FISHES_TAB} className="pt-40">
        <Container>
          <InfiniteScroll
            pageStart={0}
            loadMore={() => this.onLoadMore()}
            hasMore={hasNextPage}
            loader={<Loader key={0} />}
            className="row"
            style={{ paddingBottom: '100px' }}
          >
            {fishes.map(fish => (
              <Col key={fish.id} xs="6" md="3">
                <Link
                  href={`/fishes/${fish.id}`}
                  className="body-color"
                  onClick={this.onClick.bind(this)}
                  target="_blank"
                >
                  <FishCard key={fish.id} fish={fish} />
                </Link>
              </Col>
            ))}
          </InfiniteScroll>
        </Container>
      </TabPane>
    );
  }
}
