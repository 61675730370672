// @flow
import React from 'react';
import CommentClient from '../../../models/results/comments/CommentClient';
import CommentForm from './CommentForm';
import CommentItem from './CommentItem';
import { displayAxiosError } from '../../commons/messages';
import type { Result } from '../../../models/results/Result';
import type { Comment } from '../../../models/results/comments/Comment';

type Props = {
  result: Result,
  token: string,
  focusForm: boolean,
  userSignedIn: boolean,
};

type State = {
  comments: Array<Comment>,
};

export default class Comments extends React.Component<Props, State> {
  commentClient = new CommentClient();

  constructor(props: Props) {
    super(props);

    this.state = {
      comments: [],
    };
  }

  componentDidMount() {
    const { result } = this.props;

    if (result.publish_comments_count === 0) {
      return;
    }

    this.commentClient
      .fetchAll(result.id)
      .then(response => {
        this.setState({
          comments: response.data.sort((a, b) => a.id - b.id),
        });
      })
      .catch(error => {
        displayAxiosError(error);
      });
  }

  render() {
    const { result, token, focusForm, userSignedIn } = this.props;
    const { comments } = this.state;

    return (
      <div className="results comments">
        <div className="comment-items">
          {comments.map(comment => (
            <CommentItem
              key={comment.id}
              comment={comment}
              result={result}
              token={token}
              userSignedIn={userSignedIn}
            />
          ))}
          {result.publish_comments_count === 0 && (
            <div className="comment-nothing">コメントはありません</div>
          )}
        </div>
        {userSignedIn && (
          <CommentForm
            visible={true}
            parent={null}
            result={result}
            token={token}
            focus={focusForm}
            onFinishPosting={comment => {
              // $FlowFixMe
              this.setState({ comments: [...comments, comment] });
            }}
          />
        )}
        {!userSignedIn && (
          <a href="/users/sign_in" className="btn btn-outline-primary">
            ログインしてコメントを残す
          </a>
        )}
      </div>
    );
  }
}
