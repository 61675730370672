// @flow
import AxiosClient from '../AxiosClient';
import type { AxiosPromise } from 'axios';
import type { Account } from '../accounts/Account';
import type { Product, ProductForm } from './Product';

export default class ProductClient extends AxiosClient {
  fetchAllByMakerAndSeries(
    type: string,
    makerId: number,
    seriesId: number
  ): AxiosPromise<Array<Product>> {
    return this.buildClient().get(
      `/api/v2/makers/${makerId}/series/${seriesId}/${type}/index_without_others.json`
    );
  }

  create(
    form: ProductForm,
    account: Account,
    token: string
  ): AxiosPromise<Product> {
    return this.buildTokenAuthClient(account, token).post(
      `/makers/${form.maker_id}/series/${form.series_id}/products.json`,
      {
        product: { ...form },
      }
    );
  }
}
