// @flow
import { useEffect } from 'react';

export default function Trigger(): null {
  useEffect(() => {
    const url = new URL(window.location.href);
    const decodedUrl = decodeURIComponent(url.href);
    const params = new URL(decodedUrl).searchParams;
    // 「抽選番号：12345」のようなテキストを取得
    const code = params.get('text');

    if (!code) {
      alert(
        `抽選番号を取得できませんでした。
時間をおいて再度お試しください。
問題が解決しない場合は、お手数ですがお問い合わせください。`
      );
      return;
    }

    const shareText = encodeURIComponent(
      `＼乗船料5000円割引クーポンが3000名に当たる／

#アングラーズ釣船予約 #事前登録キャンペーン #最大3000万円山分け

みんなで参加してクーポンをGETしよう！
https://ships.anglers.jp/lp/prelaunch

${code}`
    );

    if (navigator.userAgent.match(/iPhone|Android.+Mobile/)) {
      window.location.href = `twitter://post?message=${shareText}`;
    }
    setTimeout(() => {
      window.location.href = `https://twitter.com/intent/tweet?text=${shareText}`;
    });
  }, []);

  return null;
}
