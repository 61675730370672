// @flow
import React from 'react';
import type { Area } from '../../models/areas/Area';
import AreaClient from '../../models/areas/AreaClient';
import type { Fish } from '../../models/fishes/Fish';
import { displayAxiosError } from '../commons/messages';

type Props = {
  fish: Fish,
  prefectureId: number,
};

type State = {
  areas: Array<Area>,
};

export default class ExpandableAreas extends React.PureComponent<Props, State> {
  areaClient = new AreaClient();

  constructor(props: Props) {
    super(props);

    this.state = {
      areas: [],
    };
  }

  componentDidMount() {
    const { fish, prefectureId } = this.props;
    this.areaClient
      .fetchAllByFish(6, fish.id, prefectureId)
      .then(response => {
        this.setState({ areas: response.data.splice(0, 5) });
      })
      .catch(error => {
        displayAxiosError(error);
      });
  }

  render() {
    const { fish } = this.props;
    const { areas } = this.state;
    console.log('fish', fish, areas);

    return (
      <div className="accordion" id="accordionAreas">
        <h2 className="mb-0">
          <button
            className="btn btn-lg collapsed h2 font-weight-bold text-left"
            type="button"
            data-toggle="collapse"
            data-target="#collapseAreas"
            aria-expanded="false"
            aria-controls="collapseAreas"
          >
            {`${fish.name}が釣れる近場の釣り場`}
          </button>
        </h2>
        <div
          id="collapseAreas"
          className="collapse"
          data-parent="#accordionAreas"
        >
          {areas.map(a => (
            <div key={a.id} className="row">
              <div className="h5 col">
                <a
                  className="badge badge-light"
                  href={`/areas/${a.id}/fishes/${fish.id}`}
                >
                  {a.name}
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
