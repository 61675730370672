// @flow
import React from 'react';

type Props = {
  fdl: string,
};

function AppFDL(props: Props) {
  return (
    <div className="app-fdl d-flex flex-column align-items-center w-100">
      <p>
        アングラーズアプリなら
        <br />
        釣果を快適に見られます
      </p>
      <a
        href={props.fdl}
        className="fdl-link d-flex justify-content-center align-items-center"
      >
        アプリで釣果を見る
      </a>
    </div>
  );
}

export default React.memo<Props>(AppFDL);
