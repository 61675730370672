// @flow
import React from 'react';
import type { Node } from 'react';
import HeaderMobileMenuModal from './HeaderMobileMenuModal';

type Props = {
  active: string,
  logoIcon: string,
  userId: ?number,
  children?: Node,
  menu?: boolean,
  fdl: string,
};

type State = {
  isOpenMenu: boolean,
};

export default class HeaderMobile extends React.Component<Props, State> {
  static defaultProps = {
    menu: true,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      isOpenMenu: false,
    };
  }

  toggleMenu() {
    this.setState(prevState => ({
      isOpenMenu: !prevState.isOpenMenu,
    }));
  }

  render() {
    const { userId, fdl } = this.props;
    const { isOpenMenu } = this.state;

    return (
      <nav className="navbar navbar-expand-sm navbar-light bg-white header-mobile sticky-top">
        {this.props.menu && (
          <a className="navbar-brand" onClick={() => this.toggleMenu()}>
            <img
              width={30}
              src={this.props.logoIcon}
              alt="釣果検索のアングラーズ"
              className="mr-10"
            />
          </a>
        )}
        {this.props.children}
        <HeaderMobileMenuModal
          userId={userId}
          path={window.location.pathname}
          fdl={fdl}
          isOpen={isOpenMenu}
          onClose={() => this.toggleMenu()}
          onClickLink={() => console.log('do nothing')}
        />
      </nav>
    );
  }
}
