// @flow
import React, { useState, useEffect } from 'react';
import ResultClient from '../../../models/results/ResultClient';
import ResultListWithAds from '../../results/ResultListWithAds';
import type { Result } from '../../../models/results/Result';

type Props = {
  prefectureId: number,
  fishId: number,
  mobile: boolean,
  fdl: string,
};

// page数固定のcomponentです。(1ページ目のコンテンツが取得されます)
function ResultListRoot(props: Props) {
  // ReactOnRailsの不具合で直接Hooksを使ってるコンポーネントをexportできない
  return <ResultList {...props} />;
}

function ResultList({ prefectureId, fishId, mobile, fdl }: Props) {
  const [results, setResults] = useState<Array<Result>>([]);
  const resultClient = new ResultClient();

  useEffect(() => {
    function fetchResults() {
      resultClient
        .fetchByPrefecture(
          1,
          'created_at',
          null,
          null,
          null,
          null,
          prefectureId,
          fishId
        )
        .then(response => {
          setResults(
            [...results, ...response.data].filter(
              (r, i, self) => self.map(r2 => r2.id).indexOf(r.id) === i
            )
          );
        })
        .catch(error => {
          if (process.env.NODE_ENV === 'production') {
            window.location.replace('/500.html');
          }
          console.warn(error);
        });
    }

    fetchResults();
  }, [prefectureId, fishId]);

  return <ResultListWithAds results={results} mobile={mobile} fdl={fdl} />;
}

export default ResultListRoot;
