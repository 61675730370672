// @flow
import React from 'react';
import type { Result } from '../../models/results/Result';

type Props = {
  result: Result,
};

export default function Voters(props: Props) {
  const { result } = props;
  return <div>{`${result.cached_votes_up}件`}</div>;
}
