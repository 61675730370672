// @flow
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, Col, Row } from 'reactstrap';
import {
  List,
  ListItem,
  Spinner as VenueSpinner,
} from '../../commons/native-base';
import { ListContent } from '../../commons/list';
import FieldClient from '../../../models/fields/FieldClient';
import ResultHelper from '../../../helpers/ResultHelper';
import { updateMyFields } from '../../../actions/fields';
import LiteMap from '../../commons/LiteMap';
import LocationModal from './LocationModal';
import type { BaseProps } from '../../../../application';
import type { Position } from '../../../models/positions/Position';
import type { Account } from '../../../models/accounts/Account';
import type { Field } from '../../../models/fields/Field';
import { displayMessage, displayAxiosError } from '../../commons/Toast';
import VenueDetails from './VenueDetails';
import { ActivityIndicator } from '../../commons/react-native';
import { csrfToken } from '../../../helpers/fetch';

type Props = BaseProps & {
  account: Account,
  myFields: Array<Field>,
  position: Position,
  apiKey: string,
  updateMyFields: (Array<Field>) => void,
};

type State = {
  id: ?number,
  field: ?Field,
  loading: boolean,
  deleting: boolean,
  visibleFieldLocationModal: boolean,
};

class ShowView extends React.Component<Props, State> {
  fieldClient = new FieldClient();

  constructor(props: Props) {
    super(props);

    const field = props.location.state && props.location.state.field;

    this.state = {
      id: field != null ? field.id : parseInt(props.match.params.id),
      field: field,
      loading: field == null,
      deleting: false,
      visibleFieldLocationModal: false,
    };
  }

  componentDidMount() {
    const { account } = this.props;
    const { id, field } = this.state;

    if (field == null && id != null) {
      this.fieldClient
        .fetchMyField(id, account)
        .then(response => {
          this.setState({
            loading: false,
            field: response.data,
          });
        })
        .catch(error => {
          this.setState({ loading: false });

          if (error.response.status !== 404) {
            displayAxiosError(error);
          }
        });
    }
  }

  onClickEdit(field: Field) {
    this.props.history.push(`/my/fields/${field.id}/edit`, { field: field });
  }

  onClickDelete(field: Field) {
    const { myFields, updateMyFields } = this.props;

    if (!window.confirm('削除しますか？')) {
      return;
    }

    this.setState({ deleting: true });
    this.fieldClient
      .delete(field.id, csrfToken())
      .then(() => {
        updateMyFields([...myFields.filter(f => f.id !== field.id)]);
        this.props.history.goBack();
        displayMessage('フィールドを削除しました');
      })
      .catch(error => {
        displayAxiosError(error);
      })
      .finally(() => {
        this.setState({ deleting: false });
      });
  }

  render() {
    const { apiKey } = this.props;
    const { field, loading, deleting } = this.state;

    if (loading || deleting) {
      return <ActivityIndicator />;
    } else if (field != null) {
      const position: Position = {
        coords: {
          latitude: field.lat,
          longitude: field.lng,
        },
      };

      return (
        <React.Fragment>
          <h5 className="mb-20">{field.name}</h5>
          <List style={{ backgroundColor: '#fff', marginBottom: 20 }}>
            <ListItem
              onClick={() => this.setState({ visibleFieldLocationModal: true })}
              style={{
                paddingRight: 0,
                paddingTop: 0,
                paddingBottom: 0,
              }}
            >
              <ListContent>
                <Row>
                  <Col xs={3}>正確な位置</Col>
                  <Col xs={9}>
                    {field.lat != null && field.lng != null && (
                      <LiteMap
                        lat={field.lat}
                        lng={field.lng}
                        zoom={12}
                        apiKey={apiKey}
                      />
                    )}
                  </Col>
                </Row>
              </ListContent>
            </ListItem>
            <ListItem>
              <ListContent>
                <Row>
                  <Col xs={3}>天気</Col>
                  <Col xs={9}>
                    {ResultHelper.weather(field)} {ResultHelper.tempc(field)}{' '}
                    {ResultHelper.winddir16pointjp(field)}{' '}
                    {ResultHelper.windspeedmps(field)}
                  </Col>
                </Row>
              </ListContent>
            </ListItem>
            {loading && <VenueSpinner visible={loading} />}
            {!loading && <VenueDetails venue={field.venue} />}
          </List>
          <Row>
            <Col sm={3} xs={6}>
              <Button
                block
                color="primary"
                onClick={() => this.onClickEdit(field)}
              >
                編集する
              </Button>
            </Col>
            <Col sm={3} xs={6}>
              <Button
                block
                color="outline-primary"
                onClick={() => this.onClickDelete(field)}
              >
                削除する
              </Button>
            </Col>
          </Row>
          <LocationModal
            mode={null}
            lat={field.lat}
            lng={field.lng}
            position={position}
            apiKey={apiKey}
            visible={this.state.visibleFieldLocationModal}
            onApply={() => void 0}
            onClose={() => this.setState({ visibleFieldLocationModal: false })}
          />
        </React.Fragment>
      );
    } else {
      return <div>フィールドが見つかりませんでした</div>;
    }
  }
}

export default connect(
  state => ({
    account: state.account,
    myFields: state.myFields,
    position: state.position,
    apiKey: state.railsContext.settings.googleMapsApiKey,
  }),
  dispatch => bindActionCreators({ updateMyFields }, dispatch)
)(ShowView);
