// @flow
import type { Position } from './Position';

export default class PositionRepository {
  load(): ?Position {
    const lat = document.cookie
      .split(';')
      .map(c => c.trim())
      .find(c => c.startsWith('lat='));
    const lng = document.cookie
      .split(';')
      .map(c => c.trim())
      .find(c => c.startsWith('lng='));

    if (lat != null && lng != null) {
      return {
        coords: {
          latitude: parseFloat(lat.replace('lat=', '')),
          longitude: parseFloat(lng.replace('lng=', '')),
        },
      };
    } else {
      return null;
    }
  }

  save(position: Position) {
    document.cookie = `lat=${position.coords.latitude}`;
    document.cookie = `lng=${position.coords.longitude}`;
  }
}
