// @flow
import React from 'react';
import { Row, Col } from 'reactstrap';

import { Icon, Body, Right, Text, ListItem } from '../../commons/native-base';
import ProductHelper from '../../../helpers/ProductHelper';
import { date } from '../../../lib/StringUtils';

import type { RouterHistory } from 'react-router-dom';
import type { MyRod } from '../../../models/my_rods/MyRod';

type Props = {
  history: RouterHistory,
  myRod: MyRod,
};

export default class MyRodListItem extends React.PureComponent<Props> {
  onClickMyRod(myRod: MyRod) {
    this.props.history.push(`/my/rods/${myRod.id}`, { myRod: myRod });
  }

  render() {
    const { myRod } = this.props;

    return (
      <ListItem
        style={{ backgroundColor: '#fff', cursor: 'pointer' }}
        onClick={() => this.onClickMyRod(myRod)}
      >
        <Body style={{ flex: 3 }}>
          <Text>{ProductHelper.rod_details_without_maker(myRod)}</Text>
          <Row>
            <Col>
              <Text className="text-muted">
                {myRod.maker && myRod.maker ? myRod.maker.name : ''}
              </Text>
            </Col>
            <Col>
              <Text className="text-muted">
                {date(myRod.get_date)}
                {myRod.get_date != null || myRod.lost_date != null
                  ? ' 〜 '
                  : ''}
                {date(myRod.lost_date)}
              </Text>
            </Col>
          </Row>
        </Body>
        <Right>
          <Icon name="arrow-forward" />
        </Right>
      </ListItem>
    );
  }
}
