// @flow
import AxiosClient from '../AxiosClient';
import type { Axios, AxiosPromise } from 'axios';
import type { Timeline } from './Timeline';
import moment from 'moment';

export default class TimelineClient extends AxiosClient {
  fetchAll(page: number): AxiosPromise<Array<Timeline>> {
    return this.buildTimelineClient().get('/api/v2/my/timelines.json', {
      params: {
        page: page,
      },
    });
  }

  // private methods

  buildTimelineClient(): Axios {
    const client = this.buildClient();

    client.interceptors.response.use(response => {
      if (response.data instanceof Array) {
        response.data.forEach(timeline => {
          timeline.updated_at = moment(timeline.updated_at);
        });
      }

      return response;
    });

    return client;
  }
}
