// @flow
import React from 'react';
import FourmSpArticleUnderTitle from '../advertisements/FourmSpArticleUnderTitle';
import FourmSpArticleInArticle1st from '../advertisements/FourmSpArticleInArticle1st';
import AppFDL from '../advertisements/AppFDL';
import ResultCard from './ResultCard';
import type { Result } from '../../models/results/Result';

type Props = {
  results: Array<Result>,
  mobile: boolean,
  fdl: string,
};

// page数固定のcomponentです。(1ページ目のコンテンツが取得されます)
function ResultListWithAdsRoot(props: Props) {
  // ReactOnRailsの不具合で直接Hooksを使ってるコンポーネントをexportできない
  return <ResultListWithAds {...props} />;
}

function ResultListWithAds({ results, mobile, fdl }: Props) {
  let adIndex = 0;

  const resultsList = results.reduce((list, result, i) => {
    list.push(<ResultCard key={result.id} result={result} />);

    if (mobile && [3].includes(i)) {
      list.push(
        <div className="col-12" key={adIndex++}>
          <FourmSpArticleUnderTitle />
        </div>
      );
    } else if (mobile && [7].includes(i)) {
      list.push(
        <div className="col-12" key={adIndex++}>
          <FourmSpArticleInArticle1st />
        </div>
      );
    } else if (mobile && [11].includes(i)) {
      list.push(
        <div className="col-12 mt-20 mb-50" key={adIndex++}>
          <AppFDL fdl={fdl} />
        </div>
      );
    }

    return list;
  }, []);

  return <div className="row">{resultsList}</div>;
}

export default ResultListWithAdsRoot;
