// @flow
import React from 'react';
import type { GoogleAdvertisement } from '../../models/advertisements/GoogleAdvertisement';

type Props = {
  advertisement: GoogleAdvertisement,
};

export default class GoogleAdManagerDiv extends React.Component<Props> {
  render() {
    const { advertisement } = this.props;
    const adId = advertisement.adId;
    const adPath = advertisement.adPath;
    const adDimensions = advertisement.adDimensions;

    return (
      <div className="advertisements">
        <div className="text-center mb-30 mt-30">
          <div
            id={adId}
            className="gpt-ad"
            data-gpt-div-id={adId}
            data-gpt-path={adPath}
            data-gpt-dimensions={adDimensions}
          />
        </div>
      </div>
    );
  }
}
