// @flow
import React from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import type { BaseProps } from '../../../../application';
import type { Account } from '../../../models/accounts/Account';
import type { Review } from '../../../models/products/reviews/Review';
import { FlatList, ActivityIndicator } from '../../commons/react-native';
import { ListItem, View } from '../../commons/native-base';
import { displayMessage, displayAxiosError } from '../../commons/Toast';
import { date } from '../../../lib/StringUtils';
import ReviewClient from '../../../models/products/reviews/ReviewClient';
import { csrfToken } from '../../../helpers/fetch';

type Props = BaseProps & {
  account: Account,
};

type State = {
  isLoading: boolean,
  productReviews: Array<Review>,
};

class IndexView extends React.Component<Props, State> {
  reviewClient = new ReviewClient();

  constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: true,
      productReviews: [],
    };
  }

  componentDidMount() {
    this.fetchProductReviews();
  }

  fetchProductReviews() {
    const { account } = this.props;

    this.reviewClient
      .fetchMine(account)
      .then(response => {
        console.log(response.data);
        this.setState({ productReviews: response.data, isLoading: false });
      })
      .catch(error => {
        this.setState({ isLoading: false });
        displayAxiosError(error);
      });
  }

  onClickRemove(id: number) {
    const { account } = this.props;
    const { productReviews } = this.state;

    if (window.confirm('レビューを削除しますか?')) {
      this.reviewClient
        .delete(id, account, csrfToken())
        .then(() => {
          this.setState({
            productReviews: productReviews.filter(review => review.id !== id),
          });
          displayMessage('フォローを解除しました');
        })
        .catch(error => {
          displayAxiosError(error);
        });
    }
  }

  renderFooter = () => {
    const { isLoading, productReviews } = this.state;

    if (isLoading) {
      return (
        <View
          style={{
            paddingVertical: 20,
            borderTopWidth: 1,
            borderColor: '#CED0CE',
          }}
        >
          <ActivityIndicator animating size="large" />
        </View>
      );
    } else {
      return (
        <React.Fragment>
          {productReviews.length === 0 && <div>レビューはありません</div>}
          {false && (
            <Row style={{ paddingTop: 20 }}>
              <Col md={6}>
                <Link to="/my/rods/new" className="btn btn-block btn-primary">
                  レビューを登録する
                </Link>
              </Col>
            </Row>
          )}
        </React.Fragment>
      );
    }
  };

  render() {
    const { productReviews } = this.state;

    return (
      <FlatList
        data={productReviews}
        renderItem={item => (
          <ListItem key={item.id}>
            <div
              className="listview-content products"
              style={{ cursor: 'pointer' }}
              onClick={() =>
                (window.location.href = `/tackles/${item.product.id}/reviews/${item.id}`)
              }
            >
              <div>{item.product.full_name}</div>
              <div className="row mb-5">
                <div className="col-6">
                  <div className="star-rating">
                    <div
                      className="star-rating-front"
                      style={{ width: `${item.total_score}rem` }}
                    >
                      ★★★★★
                    </div>
                    <div className="star-rating-back">★★★★★</div>
                  </div>
                </div>
                <div className="col-6 text-muted">{date(item.updated_at)}</div>
              </div>
              <div className="one-line mb-5">
                <strong>{item.title}</strong>
              </div>
              <div className="three-line">{item.content}</div>
            </div>
            <div className="listview-actions">
              <i
                onClick={() => this.onClickRemove(item.id)}
                className="listview-actions-item ion-ios-close-outline"
              />
            </div>
          </ListItem>
        )}
        ListFooterComponent={this.renderFooter}
      />
    );
  }
}

export default connect(state => ({
  account: state.account,
}))(IndexView);
