// @flow
import React from 'react';
import { Card, CardBody, CardText, CardTitle } from 'reactstrap';
import { withoutLineFeed } from '../../lib/StringUtils';
import type { User } from '../../models/users/User';
import { default as MeisterIcon } from '../../assets/images/views/users/meister.png';

type Props = {
  user: User,
};

export default function UserCard({ user }: Props) {
  return (
    <Card className="users text-center mb-30">
      <div className="d-flex justify-content-center align-items-center">
        <div className="user-thumb">
          <img src={user.thumb_url} className="user-thumb-img" width={64} />
          {user.is_meister && (
            <img src={MeisterIcon} className="user-thumb-meister-img" />
          )}
        </div>
      </div>
      <CardBody>
        <CardTitle className="text-truncate">{user.name}</CardTitle>
        <CardText className="profile-comment">
          {withoutLineFeed(user.profile_comment)}
        </CardText>
      </CardBody>
    </Card>
  );
}
