// @flow
import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import GoogleMap from 'google-map-react';
import { STYLE_OPTIONS } from '../../commons/GoogleMaps';
import LocationSearchInput from '../../areas/LocationSearchInput';
import type { Position } from '../../../models/positions/Position';

type Props = {
  mode: ?number,
  lat: ?number,
  lng: ?number,
  position: Position,
  visible: boolean,
  apiKey: string,
  onApply: any => void,
  onClose: () => void,
};

type State = {
  searching: boolean,
  searchText: ?string,
  address: string,
  marker: any,
};

export default class LocationModal extends React.Component<Props, State> {
  googleMap: GoogleMap;

  constructor(props: Props) {
    super(props);

    this.state = {
      searching: false,
      searchText: null,
      address: '',
      marker: {
        latitude: props.lat,
        longitude: props.lng,
      },
    };
  }

  onChangeMap(e: any) {
    const { lat, lng } = e.center;

    this.setState({
      marker: {
        latitude: lat,
        longitude: lng,
      },
    });
  }

  onApply() {
    const { marker } = this.state;
    this.props.onApply(marker);
  }

  onSearch(lat: number, lng: number) {
    // this.onChangeAddress(lat, lng);
    this.googleMap.map_.panTo({ lat: lat, lng: lng });
  }

  render() {
    const { position, apiKey, onClose } = this.props;

    return (
      <Modal isOpen={this.props.visible} toggle={onClose} className="">
        <ModalHeader toggle={onClose}>
          <LocationSearchInput
            address={this.state.address}
            onSearch={(lat, lng) => this.onSearch(lat, lng)}
            onSearchClose={() => console.warn('search closed')}
          />
        </ModalHeader>
        <ModalBody style={{ height: 400, padding: 0 }}>
          <GoogleMap
            ref={googleMap => (this.googleMap = googleMap)}
            bootstrapURLKeys={{ key: apiKey }}
            defaultCenter={[
              position.coords.latitude,
              position.coords.longitude,
            ]}
            defaultZoom={11}
            options={{
              maxZoom: 13,
              styles: STYLE_OPTIONS,
            }}
            onChange={e => this.onChangeMap(e)}
          />
          <div className="cross" />
        </ModalBody>
        <ModalFooter>
          <Button color="outline-primary" onClick={onClose}>
            キャンセル
          </Button>
          <Button color="primary" onClick={() => this.onApply()}>
            決定
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
