// @flow
import React from 'react';
import { Motion, spring } from 'react-motion';
import styled from 'styled-components';

const Marker = styled.div`
  position: absolute
  cursor: pointer
  width: 40px
  height: 40px
  left: -40px / 2
  top: -40px / 2

  border: 5px solid #3498db
  border-radius: 50%
  background-color: white
  text-align: center
  color: #333

  font-size: 14px
  font-weight: bold
  display: flex
  align-items: center
  justify-content: center
`;

type Props = {
  text: string,
};

type State = {
  hovered: boolean,
};

export default class ClusterMarker extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      hovered: false,
    };
  }

  render() {
    const { text } = this.props;
    const { hovered } = this.state;

    return (
      <Motion
        defaultStyle={{ scale: 0.6 }}
        style={{
          scale: spring(hovered ? 1.15 : 1, {
            stiffness: 320,
            damping: 7,
            precision: 0.001,
          }),
        }}
      >
        {({ scale }) => (
          <Marker
            style={{
              transform: `translate3D(0,0,0) scale(${scale}, ${scale})`,
            }}
          >
            <div>{text}</div>
          </Marker>
        )}
      </Motion>
    );
  }
}
