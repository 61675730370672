// @flow
import React, { useState } from 'react';
import SelectModal from './SelectModal';
import type { Region } from '../../models/regions/Region.js';
import type { Prefecture } from '../../models/prefectures/Prefecture.js';
import styled from 'styled-components';
import { viewSp } from '../../lib/Mixins';
import CheckedImage from '../../assets/images/views/ships/checked.png';
import UncheckedImage from '../../assets/images/views/ships/unchecked.png';

type Props = {
  regions: Array<Region>,
  visible: boolean,
  onApply: (prefectures: Array<Prefecture>) => void,
  onClose: (status: boolean) => void,
  initialPrefectures: Array<Prefecture>,
};

function SelectPrefectureModalRoot(props: Props) {
  // ReactOnRailsの不具合で直接Hooksを使ってるコンポーネントをexportできない
  return <SelectPrefectureModal {...props} />;
}

function SelectPrefectureModal({
  regions,
  visible,
  onApply,
  onClose,
  initialPrefectures,
}: Props) {
  const [selectedPrefectures, setSelectedPrefectures] = useState(
    initialPrefectures ? initialPrefectures : []
  );

  return (
    <SelectModal
      visible={visible}
      onClose={onClose}
      onClear={() => setSelectedPrefectures([])}
      title="都道府県を選択する"
      onApply={() => onApply(selectedPrefectures)}
    >
      {regions.map(region => (
        <Checkboxs key={region.id}>
          <CheckboxTitle>{region.name}</CheckboxTitle>
          <CheckboxItemContainer>
            {/* 変数名が長いとlinterが暴走するので「p」の1文字に短縮してる */}
            {region.prefectures.map(p => (
              <CheckboxItem
                key={p.id}
                onClick={() =>
                  setSelectedPrefectures(
                    selectedPrefectures.find(obj => obj.id === p.id)
                      ? selectedPrefectures.filter(obj => obj.id !== p.id)
                      : selectedPrefectures.concat(p)
                  )
                }
              >
                <CheckboxBool>
                  {selectedPrefectures.find(obj => obj.id === p.id) && (
                    <img src={CheckedImage} />
                  )}
                  {!selectedPrefectures.find(obj => obj.id === p.id) && (
                    <img src={UncheckedImage} />
                  )}
                </CheckboxBool>
                {p.name}
              </CheckboxItem>
            ))}
          </CheckboxItemContainer>
        </Checkboxs>
      ))}
    </SelectModal>
  );
}

const Checkboxs = styled.div`
  border-bottom: 1px solid #e9ecef;
  padding: 20px 0;

  &:last-child {
    border-bottom: none;
  }
`;

const CheckboxTitle = styled.div`
  color: #a0a0a0;
  font-size: 12px;
  margin-bottom: 10px;
`;

const CheckboxItemContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const CheckboxItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
  padding: 10px 0;
  width: 24%;

  ${viewSp} {
    width: 50%;
  }
`;

const CheckboxBool = styled.div`
  margin-right: 15px;
  line-height: 1;
  z-index: 1;

  img {
    height: 20px;
    width: 20px;
  }
`;

export default React.memo<Props>(SelectPrefectureModalRoot);
