// @flow
const breakPointNum = 1020;

export function breakPoint() {
  return breakPointNum;
}

export function viewPc() {
  return `@media screen and (min-width: ${breakPoint()}px)`;
}

export function viewSp() {
  return `@media screen and (max-width: ${breakPoint() - 1}px)`;
}

export function viewSpXs() {
  return `@media screen and (max-width: 320px)`;
}
