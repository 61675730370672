// @flow
export default class WeatherHelper {
  static icon(name: string): ?string {
    switch (name) {
      case '快晴':
        return 'icon-w-sunny';
      case '晴れ':
        return 'icon-w-partly';
      case '曇り':
        return 'icon-w-cloud';
      case '豪雨':
        return 'icon-w-heavy-rain';
      case '雨':
        return 'icon-w-rain';
      case '小雨':
        return 'icon-w-light-rain';
      case '雪':
        return 'icon-w-snow';
      case '雷雨':
        return 'icon-w-thunder';
      case '霧':
        return 'icon-w-fog';
      default:
        return null;
    }
  }

  static color(name: ?string): string {
    switch (name) {
      case '快晴':
        return '#ff6d6d';
      case '晴れ':
        return '#ffe963';
      case '曇り':
        return '#a7a7a7';
      case '豪雨':
        return '#4b595e';
      case '雨':
        return '#8ad0ce';
      case '小雨':
        return '#8ad0ce';
      case '雪':
        return '#8ad0ce';
      case '雷雨':
        return '#4b595e';
      case '霧':
        return '#8ad0ce';
      default:
        return '#fff';
    }
  }
}
