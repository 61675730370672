// @flow
import AxiosClient from '../AxiosClient';
import type { Axios, AxiosPromise } from 'axios';
import type { Area } from './Area';
import moment from 'moment';

export default class AreaClient extends AxiosClient {
  fetchAllByLocation(
    page: number,
    lat: number,
    lng: number
  ): AxiosPromise<Array<Area>> {
    return this.buildAreaClient().get('/api/v2/areas/list_by_location.json', {
      params: {
        page: page,
        lat: lat,
        lng: lng,
      },
    });
  }

  fetchAllByFish(
    perPage: ?number,
    fishId: number,
    prefectureId: ?number
  ): AxiosPromise<Array<Area>> {
    return this.buildAreaClient().get(`/api/v2/fishes/${fishId}/areas.json`, {
      params: {
        per_page: perPage,
        prefecture_id: prefectureId,
      },
    });
  }

  fetchMyAreas(page: number): AxiosPromise<Array<Area>> {
    return this.buildAreaClient().get('/api/v2/my/areas.json', {
      params: {
        page: page,
      },
    });
  }

  stopFollowing(id: number, token: string): AxiosPromise<any> {
    return this.buildClient(token).delete(`/api/v2/my/areas/${id}.json`);
  }

  searchFromResults(page: number, keyword: ?string): AxiosPromise<Array<Area>> {
    return this.buildAreaClient().get(
      '/api/v2/areas/search_from_results.json',
      {
        params: {
          page: page,
          keyword: keyword,
        },
      }
    );
  }

  // private methods

  buildAreaClient(): Axios {
    const client = this.buildClient();

    client.interceptors.response.use(response => {
      if (response.data instanceof Array) {
        response.data.forEach(area => {
          if (area.latest_caught_at != null) {
            area.latest_caught_at = moment(area.latest_caught_at);
          }
        });
      } else if (response.data instanceof Object) {
        if (response.data.latest_caught_at != null) {
          // $FlowFixMe
          response.data.latest_caught_at = moment(
            response.data.latest_caught_at
          );
        }
      }

      return response;
    });

    return client;
  }
}
