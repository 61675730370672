// @flow
import React from 'react';
import type { Prefecture } from '../../models/prefectures/Prefecture';

type Props = {
  prefecture: Prefecture,
};

export default class PrefectureCard extends React.PureComponent<Props> {
  onClick() {
    // $FlowFixMe
    gtag('event', 'click', {
      event_category: 'ToPrefecture',
      event_label: 'prefecture_card',
    });
  }

  render() {
    const { prefecture } = this.props;

    return (
      <div className="col-5 col-md-3 thumb carousel-thumb" key={prefecture.id}>
        <a
          href={`/prefectures/${prefecture.id}`}
          onClick={this.onClick.bind(this)}
          // eslint-disable-next-line react/jsx-no-target-blank
          target="_blank"
        >
          <div className="card shadow-card">
            <div className="thumbnail">
              <img src={prefecture.thumbnail_url} alt={prefecture.name} />
            </div>
            <div className="shadow-card-body">
              <div className="name">
                <h6 className="c-text">
                  {prefecture.name}
                  の釣り場速報
                </h6>
              </div>
            </div>
          </div>
        </a>
      </div>
    );
  }
}
