// @flow
import React from 'react';
import { Modal, ModalHeader } from 'reactstrap';
import type { Account } from '../../models/accounts/Account';
import type { Maker } from '../../models/makers/Maker';
import { SelectBody } from './SelectBody';

const SELECT_BODY = 0;
const ADD_BODY = 1;

type Props = {
  visible: boolean,
  productType: string,
  account: Account,
  onApply: (maker: Maker) => void,
  onClose: () => void,
};

type State = {
  body: number,
};

export class SelectMakerModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      body: SELECT_BODY,
    };
  }

  onAddMaker() {
    this.setState({ body: ADD_BODY });
  }

  onApply(maker: Maker) {
    this.setState({ body: SELECT_BODY }, () => this.props.onApply(maker));
  }

  onClose() {
    this.setState({ body: SELECT_BODY }, () => this.props.onClose());
  }

  render(): React$Node {
    const { visible, productType } = this.props;
    const { body } = this.state;

    return (
      <Modal isOpen={visible} toggle={() => this.onClose()}>
        {body === SELECT_BODY && (
          <React.Fragment>
            <ModalHeader toggle={() => this.onClose()}>
              メーカー選択
            </ModalHeader>
            <SelectBody
              productType={productType}
              onApply={maker => this.onApply(maker)}
              onAddMaker={() => this.onAddMaker()}
            />
          </React.Fragment>
        )}
      </Modal>
    );
  }
}
