// @flow
import React, { useState, useRef } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import NoImage from '../../assets/images/views/ships/noimage.png';
import UpIcon from '../../assets/images/views/ships/up.png';
import type { ShipWithDetails } from '../../models/ships/Ship.js';
import styled from 'styled-components';
import { viewSp } from '../../lib/Mixins';
import ShipClient from '../../models/ships/ShipClient';
import GamesIcon from '../../assets/images/views/ships/games_icon.png';

type Props = {
  ship: ShipWithDetails,
  isSignedIn: boolean,
  token: string,
};

function ShipListItemRoot(props: Props) {
  // ReactOnRailsの不具合で直接Hooksを使ってるコンポーネントをexportできない
  return <ShipListItem {...props} />;
}

function ShipListItem({ ship, isSignedIn, token }: Props) {
  const [isFollowing, setIsFollowing] = useState(ship.following);
  // ローディングアニメーション(ぐるぐる)にしたい場合はstateで管理した方が良い
  const isSending = useRef(false);

  function onFollow() {
    if (isSending.current) return;

    isSending.current = true;
    new ShipClient()
      .follow(ship.id, token)
      .then(() => {
        window.notify(`${ship.name}をフォローしました`, 'success');
        setIsFollowing(true);
      })
      .catch(() => {
        window.notify(`${ship.name}のフォローが失敗しました`, 'danger');
      })
      .finally(() => {
        isSending.current = false;
      });
  }

  function onUnFollow() {
    if (isSending.current) return;

    isSending.current = true;
    new ShipClient()
      .stopFollow(ship.id, token)
      .then(() => {
        window.notify(`${ship.name}のフォローを解除しました`, 'success');
        setIsFollowing(false);
      })
      .catch(() => {
        window.notify(`${ship.name}のフォロー解除に失敗しました`, 'danger');
      })
      .finally(() => {
        isSending.current = false;
      });
  }

  return (
    <Container>
      <a href={`/ships/${ship.id}`} target="blank" rel="noopener">
        {ship.thumbnails && ship.thumbnails.length > 0 && (
          <Image src={ship.thumbnails[0]} />
        )}
        {ship.thumbnails && ship.thumbnails.length === 0 && (
          <Image src={NoImage} />
        )}
      </a>
      <Detail>
        <a href={`/ships/${ship.id}`} target="blank" rel="noopener">
          {(ship.has_entered_game || ship.had_entered_game) && (
            <Games src={GamesIcon} />
          )}
          <Prefecture>{ship.prefecture.name}</Prefecture>
          <ShipName>{ship.name}</ShipName>
          {ship.fishes && ship.fishes.length > 0 && (
            <Fishes>
              {ship.fishes.map(fish => (
                <Fish key={fish.id}>
                  {fish.name}
                  <img src={UpIcon} />
                </Fish>
              ))}
            </Fishes>
          )}
        </a>
        <FollowButtonContainer>
          <FollowingInfo>
            <div>
              <strong>{ship.followers_size}</strong>
              <span>名がフォロー中</span>
            </div>
          </FollowingInfo>
          <FollowButton>
            {isSignedIn && (
              <>
                {isFollowing && (
                  <UnFollow onClick={() => onUnFollow()}>フォロー中</UnFollow>
                )}
                {!isFollowing && (
                  <Follow onClick={() => onFollow()}>
                    <Plus>＋</Plus>フォロー
                  </Follow>
                )}
              </>
            )}
            {!isSignedIn && (
              <a href="/users/sign_in">
                <Follow>
                  <Plus>＋</Plus>フォロー
                </Follow>
              </a>
            )}
          </FollowButton>
        </FollowButtonContainer>
      </Detail>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  color: #2b2b2b;
  display: flex;
  overflow: hidden;
  padding: 20px 0;
  width: 100%;

  &:hover {
    color: #2b2b2b;
    text-decoration: none;
  }
`;

const Image = styled.img`
  border-radius: 8px;
  min-height: 102px;
  height: 102px;
  min-width: 136px;
  width: 136px;

  ${viewSp} {
    min-height: 78px;
    height: 78px;
    min-width: 104px;
    width: 104px;
  }
`;

const Detail = styled.div`
  padding-left: 20px;
  overflow: inherit;
  width: 100%;

  a {
    text-decoration: none;
  }
`;

const Games = styled.img`
  margin-bottom: 8px;
  width: 166px;

  ${viewSp} {
    margin-bottom: 6px;
    width: 140px;
  }
`;

const Prefecture = styled.div`
  color: #a0a0a0;
  font-size: 12px;
  line-height: 1;

  ${viewSp} {
    font-size: 10px;
  }
`;

const ShipName = styled.div`
  color: #2b2b2b;
  font-size: 18px;
  margin-top: 8px;
  font-weight: bold;
  line-height: 1;

  ${viewSp} {
    font-size: 14px;
  }
`;

const Fishes = styled.div`
  color: #2b2b2b;
  display: flex;
  align-items: center;
  margin-top: 10px;
  overflow-x: auto;
  line-height: 1;

  ${viewSp} {
    margin-top: 6px;
  }
`;

const Fish = styled.div`
  border: 1px solid #0000001a;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
  height: 28px;
  margin-left: 8px;
  padding: 0 8px;
  white-space: nowrap;

  ${viewSp} {
    font-size: 10px;
    height: 23px;
    margin-left: 5px;
  }

  &:first-child {
    margin-left: 0;
  }

  img {
    width: 15px;

    ${viewSp} {
      width: 12px;
    }
  }
`;

const FollowButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 6px;

  ${viewSp} {
    bottom: 18px;
    margin-top: 8px;
  }
`;

const FollowingInfo = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  line-height: 1;

  strong {
    font-size: 16px;
    margin-right: 4px;

    ${viewSp} {
      font-size: 13px;
      margin-right: 2px;
    }
  }

  span {
    font-size: 10px;
  }
`;

const FollowButton = styled.div`
  a {
    &:hover {
      text-decoration: none;
    }
  }
`;

const Follow = styled.div`
  cursor: pointer;
  background-color: #0877bc;
  border: none;
  border-radius: 16px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
  height: 26px;
  width: 82px;

  ${viewSp} {
    border-radius: 14px;
    font-size: 10px;
    height: 22px;
    width: 72px;
  }

  img {
    margin-right: 3px;
    width: 16px;

    ${viewSp} {
      width: 14px;
    }
  }
`;

const UnFollow = styled(Follow)`
  box-sizing: border-box;
  background-color: white;
  border: 1.5px solid #0877bc;
  color: #0877bc;
`;

const Plus = styled.span`
  margin-right: 2px;
`;

export default React.memo<Props>(ShipListItemRoot);
