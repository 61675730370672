// @flow
import React from 'react';

type Props = {
  name: string,
};

export default function IconMoon({ name, ...props }: Props) {
  return <i className={name} {...props} />;
}
