// @flow
import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import NoImage from '../../assets/images/views/ships/noimage.png';
import NextIcon from '../../assets/images/views/ships/next.png';
import PrevIcon from '../../assets/images/views/ships/prev.png';
import styled from 'styled-components';
import { breakPoint, viewSp } from '../../lib/Mixins';

type Props = {
  images: Array<string>,
};

function SlickCarouselRoot(props: Props): React$Node {
  // ReactOnRailsの不具合で直接Hooksを使ってるコンポーネントをexportできない
  return <SlickCarousel {...props} />;
}

function SlickCarousel({ images }: Props) {
  const [innerWidth, setInnerWidth] = useState(0);
  const isNoImageEnable = images.length < 2;
  const imageList = isNoImageEnable
    ? [...images, ...new Array(3 - images.length).fill(NoImage)]
    : images;

  // https://github.com/akiran/react-slick/issues/1195#issuecomment-390383615
  /* eslint-disable */
  // $FlowFixMe
  const SlickButtonFix = ({ currentSlide, slideCount, children, ...props }) => (
    <span {...props}>{children}</span>
  );
  /* eslint-enable */

  const settings = {
    dots: isNoImageEnable ? false : true,
    arrows: isNoImageEnable ? false : true,
    swipe: isNoImageEnable ? false : true,
    infinite: true,
    centerMode: true,
    centerPadding: innerWidth > breakPoint() ? '30%' : '0%',
    nextArrow: (
      <SlickButtonFix>
        <img src={NextIcon} />
      </SlickButtonFix>
    ),
    prevArrow: (
      <SlickButtonFix>
        <img src={PrevIcon} />
      </SlickButtonFix>
    ),
  };

  useEffect(() => {
    setInnerWidth(window.innerWidth);
  });

  return (
    <Container>
      <Slider {...settings}>
        {imageList.map((image, index) => (
          <img key={index} src={image} />
        ))}
      </Slider>
    </Container>
  );
}

const arrowSize = '36px';
const Container = styled.div`
  position: relative;

  img {
    object-fit: cover;
    height: 30vw;
    width: calc(100% - 4px);

    ${viewSp} {
      height: auto;
      width: 100% !important;
    }
  }

  .slick-arrow {
    position: absolute;
    top: calc(50% - ${arrowSize} / 2);
    transform: translateX(-50%);
    z-index: 999;

    img {
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 50%;

      ${viewSp} {
        display: none;
      }
    }

    &::before {
      display: none;
    }
  }

  .slick-prev,
  .slick-next {
    height: ${arrowSize};
    width: ${arrowSize};

    img {
      height: 100%;
      width: 100%;
    }
  }

  .slick-prev {
    left: 17.5%;
  }

  .slick-next {
    right: calc(17.5% - ${arrowSize});
  }

  // ↓ここはslick内部のスタイル(collapsed)を使っているのでbemを使わない
  .slick-dots {
    margin: 0;
    padding: 0;
    display: flex !important;
    justify-content: center;
    position: absolute;
    bottom: 16px;
    left: 50%;
    transform: translateX(-50%);

    button {
      opacity: 0;
      width: 6px;
      height: 6px;
    }

    li {
      background-color: white;
      border-radius: 50%;
      margin: 0 3px;
      position: relative;
      list-style: none;
      opacity: 0.5;
      height: 6px;
      width: 6px;

      &.slick-active {
        opacity: 1;
      }
    }
  }
`;

export default SlickCarouselRoot;
