// @flow
import { hankana2zenkana, hiraganaToKatagana } from '../lib/StringUtils';

const FISH_SPECIAL_MAP = {
  'サケ': 'ザケ', // eslint-disable-line
};

export default class FishHelper {
  static convertSearchableName(text: string) {
    if (text != null && text != '') {
      text = text.replace(/([.*+?^=!:${}()|[\]\\/\\])/g, '\\$1');
    }

    const ret = hankana2zenkana(hiraganaToKatagana(text));
    const reg = new RegExp(
      '(' + Object.keys(FISH_SPECIAL_MAP).join('|') + ')',
      'g'
    );

    return ret.replace(reg, c => {
      return FISH_SPECIAL_MAP[c];
    });
  }
}
