// @flow
import { UPDATE_MY_REELS } from '../actions/types';
import type { MyReel } from '../models/my_reels/MyReel';

const initialState = [];

export default (
  state: Array<MyReel> = initialState,
  action: any
): Array<MyReel> => {
  switch (action.type) {
    case UPDATE_MY_REELS:
      return action.payload;
    default:
      return state;
  }
};
