// @flow
import React, { useEffect, useState } from 'react';
import SelectModal from './SelectModal';
import CheckedImage from '../../assets/images/views/ships/checked.png';
import UncheckedImage from '../../assets/images/views/ships/unchecked.png';
import FishHelper from '../../helpers/FishHelper';
import type { FishWithDetails } from '../../models/fishes/Fish';
import styled from 'styled-components';
import { viewSp } from '../../lib/Mixins';
import { useDebounce } from '../../utils/UseDebounce';

type Props = {
  fishes: Array<FishWithDetails>,
  visible: boolean,
  onApply: (fishes: Array<FishWithDetails>) => void,
  onClose: (status: boolean) => void,
  initialFishes: Array<FishWithDetails>,
};

function SelectFishModalRoot(props: Props) {
  // ReactOnRailsの不具合で直接Hooksを使ってるコンポーネントをexportできない
  return <SelectFishModal {...props} />;
}

function SelectFishModal({
  fishes,
  visible,
  onApply,
  onClose,
  initialFishes,
}: Props) {
  // console.log('SelectFishModal.render');
  const [keyword, setKeyword] = useState('');
  const debounceKeyword = useDebounce(keyword, 1000);
  const [filterdFishes, setFilterdFishes] = useState(fishes);
  const [selectedFishes, setSelectedFishes] = useState(
    initialFishes ? initialFishes : []
  );
  const handleChange = e => setKeyword(e.target.value);

  useEffect(() => {
    const value = FishHelper.convertSearchableName(debounceKeyword);
    const regValue = new RegExp(value, 'i');

    const filteredFishes = fishes.filter(
      fish =>
        regValue.test(FishHelper.convertSearchableName(fish.name)) ||
        (fish.children &&
          fish.children.find(ff =>
            regValue.test(FishHelper.convertSearchableName(ff.name))
          ))
    );
    setFilterdFishes(filteredFishes);
  }, [debounceKeyword]);

  function buildChildren(children) {
    return children ? children.map(child => child.name).join('、') : '';
  }

  return (
    <SelectModal
      visible={visible}
      onClose={onClose}
      onClear={() => setSelectedFishes([])}
      title="魚種を選択する"
      onApply={() => onApply(selectedFishes)}
    >
      <TextField
        type="text"
        onChange={handleChange}
        placeholder="魚種名を入力"
      />
      <div>
        {filterdFishes.length > 0 && debounceKeyword !== '' && (
          <FishesCount>{filterdFishes.length}件</FishesCount>
        )}
      </div>
      {filterdFishes.length === 0 && debounceKeyword !== '' && (
        <NotFound>
          検索にヒットする魚種は
          <br />
          見つかりませんでした。
        </NotFound>
      )}
      <Fishes>
        {filterdFishes.map(fish => (
          <Checkbox
            key={fish.id}
            onClick={() =>
              setSelectedFishes(
                selectedFishes.find(sFish => sFish.id === fish.id)
                  ? selectedFishes.filter(sFish => sFish.id !== fish.id)
                  : selectedFishes.concat(fish)
              )
            }
          >
            <CheckboxBool>
              {selectedFishes.find(sFish => sFish.id === fish.id) && (
                <img src={CheckedImage} />
              )}
              {!selectedFishes.find(sFish => sFish.id === fish.id) && (
                <img src={UncheckedImage} />
              )}
            </CheckboxBool>
            <FishImage>
              <img src={fish.image} alt={fish.name} loading="lazy" />
            </FishImage>
            <FishParent>{fish.name}</FishParent>
            <FishChildren>{buildChildren(fish.children)}</FishChildren>
          </Checkbox>
        ))}
        {[...Array(3 - (fishes.length % 3))].map((data, index) => (
          <Checkbox key={index}>
            <Dummy />
          </Checkbox>
        ))}
      </Fishes>
    </SelectModal>
  );
}

const TextField = styled.input`
  background-color: white;
  border-radius: 10px;
  border: none;
  margin: 24px auto 14px;
  padding: 16px;
  height: 47px;
  width: 100%;

  ${viewSp} {
    margin: 15px auto 10px;
    height: 44px;
  }

  &::placeholder {
    color: #a0a0a0;
    font-size: 15px;

    ${viewSp} {
      font-size: 13px;
    }
  }

  &:-ms-input-placeholder {
    color: #a0a0a0;
    font-size: 15px;

    ${viewSp} {
      font-size: 13px;
    }
  }
`;

const FishesCount = styled.div`
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 6px;
`;

const NotFound = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  margin: 18vh auto 0;
  height: 100%;
  width: 100%;

  ${viewSp} {
    margin: 27vh auto 0;
  }
`;

const Fishes = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Checkbox = styled.div`
  margin: 10px 0;
  position: relative;
  width: calc((100% / 3) - 13px);

  ${viewSp} {
    width: calc((100% / 2) - 6px);
  }
`;

const CheckboxBool = styled.div`
  top: 6px;
  right: 6px;
  position: absolute;
  line-height: 1;
  z-index: 1;

  img {
    height: 20px;
    width: 20px;
  }
`;

const FishImage = styled.div`
  border-radius: 10px;
  object-fit: cover;
  padding-top: 56.25%;
  position: relative;
  overflow: hidden;
  width: 100%;

  img {
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    height: 100%;
    object-fit: cover;
    position: absolute;
    width: 100%;
  }
`;

const Dummy = styled(FishImage)`
  ${viewSp} {
    display: none;
  }
`;

const FishParent = styled.div`
  font-size: 13px;
  font-weight: bold;
  margin-top: 12px;
  line-height: 1;
`;

const FishChildren = styled.div`
  color: #a0a0a0;
  font-size: 10px;
  margin-top: 8px;
  white-space: break-word;
  // CSSで複数行のellipsis）https://codepen.io/martinwolf/pen/nmeQYK
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  ${viewSp} {
    margin-top: 6px;
  }
`;

export default React.memo<Props>(SelectFishModalRoot);
