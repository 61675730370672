// @flow
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Col, Row } from 'reactstrap';
import type { BaseProps } from '../../../../application';
import type { Account } from '../../../models/accounts/Account';
import type { MyReel } from '../../../models/my_reels/MyReel';
import type { MyReelForm } from '../../../models/my_reels/MyReelForm';
import moment from 'moment';
import { ActivityIndicator } from '../../commons/react-native';
import { View } from '../../commons/native-base';
import {
  displayMessage,
  displayAxiosError,
  displayEntityError,
} from '../../commons/Toast';
import MyReelClient from '../../../models/my_reels/MyReelClient';
import { updateMyReel, updateMyReels } from '../../../actions/myReels';
import { FormComp } from './FormComp';
import { validateNumber } from '../../../lib/NumberUtils';
import { csrfToken } from '../../../helpers/fetch';

type Props = BaseProps & {
  account: Account,
  myReels: Array<MyReel>,
  updateMyReel: (myReel: MyReel) => void,
  updateMyReels: (myRods: Array<MyReel>) => void,
};

type State = {
  id: number,
  form: ?MyReelForm,
  loading: boolean,
};

class EditView extends React.Component<Props, State> {
  myReelClient = new MyReelClient();

  constructor(props: Props) {
    super(props);
    const myReel = props.location.state && props.location.state.myReel;
    this.state = {
      id: myReel != null ? myReel.id : parseInt(props.match.params.id),
      form: myReel != null ? this.buildForm(myReel) : null,
      loading: myReel == null,
    };
  }

  componentDidMount() {
    const { account } = this.props;
    const { id, form } = this.state;

    if (form == null) {
      this.myReelClient
        .fetchMyReel(id, account)
        .then(response => {
          this.setState({
            loading: false,
            form: this.buildForm(response.data),
          });
        })
        .catch(error => {
          this.setState({ loading: false });

          if (error.response.status !== 404) {
            displayAxiosError(error);
          }
        });
    }
  }

  buildForm(myReel: MyReel): MyReelForm {
    return {
      name: myReel.name,
      get_date: myReel.get_date != null ? moment(myReel.get_date) : null,
      lost_date: myReel.lost_date != null ? moment(myReel.lost_date) : null,
      maker: myReel.maker,
      series: myReel.series,
      reel: myReel.reel,
      line_maker: myReel.line_maker,
      line_series: myReel.line_series,
      line: myReel.line,
      line_name: myReel.line_name,
      reel_thread_date:
        myReel.reel_thread_date != null
          ? moment(myReel.reel_thread_date)
          : null,
      reel_length: myReel.reel_length,
      memo: myReel.memo != null ? myReel.memo : '',
      images: myReel.images.map(image => {
        return {
          id: image.id,
          image: null,
          url: image.image.thumb.url,
          _destroy: false,
        };
      }),
    };
  }

  validate(form: MyReelForm): boolean {
    const errors = {};

    const errorsReelLength = validateNumber(form.reel_length, 0, 9999);
    if (errorsReelLength.length > 0) {
      errors.reel_length = errorsReelLength;
    }

    if (Object.keys(errors).length > 0) {
      displayEntityError(errors, {
        reel_length: '巻いた長さ',
      });
      return false;
    }

    return true;
  }

  onSubmit(form: MyReelForm) {
    const {
      history,
      account,
      myReels,
      updateMyReel,
      updateMyReels,
    } = this.props;
    const { id } = this.state;

    if (!this.validate(form)) {
      return;
    }

    this.setState({ loading: true });
    this.myReelClient
      .update(id, form, account, csrfToken())
      .then(response => {
        displayMessage('更新が完了しました');
        updateMyReel(response.data);
        updateMyReels([
          ...myReels.filter(r => r.id !== response.data.id),
          response.data,
        ]);
        history.goBack();
      })
      .catch(error => {
        if (error.response.status === 422) {
          displayEntityError(error.response.data, {
            maker: 'メーカー',
            series: 'シリーズ',
            reel: 'モデル',
            reelnm: 'リール名',
            line: 'ライン名',
          });
        } else {
          displayAxiosError(error);
        }
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  render() {
    const { account } = this.props;
    const { loading, form } = this.state;

    if (loading) {
      return (
        <View
          style={{
            paddingVertical: 20,
            borderTopWidth: 1,
            borderColor: '#CED0CE',
          }}
        >
          <ActivityIndicator animating size="large" />
        </View>
      );
    } else if (form != null) {
      return (
        <React.Fragment>
          <div style={{ marginBottom: 30 }}>
            <FormComp
              form={form}
              account={account}
              onChangeForm={form => this.setState({ form: form })}
              displayAxiosError={error => displayAxiosError(error)}
            />
          </div>
          <Row>
            <Col sm={3} xs={6}>
              <Button
                block
                color="outline-primary"
                onClick={() => this.props.history.goBack()}
              >
                キャンセル
              </Button>
            </Col>
            <Col sm={3} xs={6}>
              <Button block color="primary" onClick={() => this.onSubmit(form)}>
                完了
              </Button>
            </Col>
          </Row>
        </React.Fragment>
      );
    } else {
      return <div>リールが見つかりませんでした</div>;
    }
  }
}

export default connect(
  state => ({
    account: state.account,
    myReels: state.myReels,
  }),
  dispatch => bindActionCreators({ updateMyReel, updateMyReels }, dispatch)
)(EditView);
