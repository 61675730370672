// @flow
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, Col, Row } from 'reactstrap';
import FieldClient from '../../../models/fields/FieldClient';
import { updateMyField, updateMyFields } from '../../../actions/fields';
import FormComp from './FormComp';
import type { Account } from '../../../models/accounts/Account';
import type { FieldForm } from '../../../models/fields/FieldForm';
import type { Field } from '../../../models/fields/Field';
import {
  displayMessage,
  displayAxiosError,
  displayEntityError,
} from '../../commons/Toast';
import type { BaseProps } from '../../../../application';
import { Spinner } from '../../commons/native-base';
import { csrfToken } from '../../../helpers/fetch';

type Props = BaseProps & {
  account: Account,
  myField: Field,
  myFields: Array<Field>,
  updateMyField: Field => void,
  updateMyFields: (Array<Field>) => void,
};

type State = {
  id: number,
  form: ?FieldForm,
  loading: boolean,
  submitting: boolean,
};

class EditView extends React.Component<Props, State> {
  fieldClient = new FieldClient();

  constructor(props: Props) {
    super(props);

    const field = props.location.state && props.location.state.field;
    let form = null;
    if (field != null) {
      form = {
        name: field.name,
        lat: field.lat,
        lng: field.lng,
        venue: field.venue,
      };
    }

    this.state = {
      id: field != null ? field.id : parseInt(props.match.params.id),
      form: form,
      loading: field == null,
      submitting: false,
    };
  }

  componentDidMount() {
    const { account } = this.props;
    const { id, form } = this.state;

    if (form == null) {
      this.fieldClient
        .fetchMyField(id, account)
        .then(response => {
          const field = response.data;
          this.setState({
            loading: false,
            form: {
              name: field.name,
              lat: field.lat,
              lng: field.lng,
              venue: field.venue,
            },
          });
        })
        .catch(error => {
          this.setState({ loading: false });

          if (error.response.status !== 404) {
            displayAxiosError(error);
          }
        });
    }
  }

  onSubmit(form: FieldForm) {
    const { myFields, updateMyField, updateMyFields } = this.props;
    const { id } = this.state;

    this.setState({ submitting: true });
    this.fieldClient
      .update(id, form, csrfToken())
      .then(response => {
        displayMessage('更新が完了しました');
        updateMyField(response.data);
        updateMyFields([
          response.data,
          ...myFields.filter(f => f.id !== response.data.id),
        ]);
        this.props.history.goBack();
      })
      .catch(error => {
        if (error.response.status === 422) {
          displayEntityError(error.response.data, {
            fieldnm: 'フィールド名',
            name: 'フィールド名',
            latlng: '正確な位置',
          });
        } else {
          displayAxiosError(error);
        }
      })
      .finally(() => {
        this.setState({
          submitting: false,
        });
      });
  }

  render() {
    const { form, loading, submitting } = this.state;

    if (loading || submitting) {
      return <Spinner />;
    } else if (form != null) {
      return (
        <React.Fragment>
          <h5>フィールド編集</h5>
          <FormComp
            form={form}
            onChangeForm={form => {
              this.setState({ form: form });
            }}
          />
          <Row>
            <Col sm={3} xs={6}>
              <Button
                block
                color="outline-primary mr-2"
                onClick={() => this.props.history.goBack()}
              >
                キャンセル
              </Button>
            </Col>
            <Col sm={3} xs={6}>
              <Button block color="primary" onClick={() => this.onSubmit(form)}>
                完了
              </Button>
            </Col>
          </Row>
        </React.Fragment>
      );
    } else {
      return <div>フィールドが見つかりませんでした</div>;
    }
  }
}

export default connect(
  state => ({
    account: state.account,
    myField: state.myField,
    myFields: state.myFields,
  }),
  dispatch => bindActionCreators({ updateMyField, updateMyFields }, dispatch)
)(EditView);
