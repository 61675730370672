// @flow
import React from 'react';
import type { Area } from '../../models/areas/Area';
import { Card, CardBody, CardTitle } from 'reactstrap';

type Props = {
  area: Area,
};

export default function AreaCard({ area }: Props) {
  return (
    <Card className="areas thumb mb-30">
      <div className="image">
        <img src={area.thumb_url} alt={area.name} />
      </div>
      <CardBody className="pt-15">
        <CardTitle>{area.name}</CardTitle>
        {area.regions != null && (
          <div className="text-muted">
            {area.regions.map(region => (
              <small key={region.id} className="mr-5">
                {region.name}
              </small>
            ))}
          </div>
        )}
        {area.children_names != null && area.children_names.length > 0 && (
          <div className="tags">
            {area.children_names.map(child_area_name => (
              <span key={child_area_name} className="badge badge-light mr-1">
                {child_area_name}
              </span>
            ))}
          </div>
        )}
      </CardBody>
    </Card>
  );
}
