// @flow
export const UPDATE_POSITION = 'UPDATE_POSITION';
export const UPDATE_MY_FIELDS = 'UPDATE_MY_FIELDS';
export const UPDATE_MY_FIELD = 'UPDATE_MY_FIELD';
export const UPDATE_MY_RODS = 'UPDATE_MY_RODS';
export const UPDATE_MY_ROD = 'UPDATE_MY_ROD';
export const UPDATE_MY_REELS = 'UPDATE_MY_REELS';
export const UPDATE_MY_REEL = 'UPDATE_MY_REEL';
export const UPDATE_MY_LURES = 'UPDATE_MY_LURES';
export const UPDATE_MY_LURE = 'UPDATE_MY_LURE';
export const UPDATE_MY_TACKLE = 'UPDATE_MY_TACKLE';
