// @flow
import AxiosClient from '../AxiosClient';
import type { AxiosPromise } from 'axios';
import type { Account } from '../accounts/Account';
import type { Field } from './Field';
import type { FieldForm } from './FieldForm';
import type { FieldOrVenue } from './FieldOrVenue';

export default class FieldClient extends AxiosClient {
  fetchMyFields(account: Account): AxiosPromise<Array<Field>> {
    return this.buildTokenAuthClient(account).get('/my/fields.json', {
      params: {
        sort: '-upddate',
      },
    });
  }

  fetchMyField(id: number, account: Account): AxiosPromise<Field> {
    return this.buildTokenAuthClient(account).get(`/my/fields/${id}.json`);
  }

  fetchNearestsWithVenue(
    lat: number,
    lng: number,
    account: Account
  ): AxiosPromise<Array<FieldOrVenue>> {
    return this.buildTokenAuthClient(account).get(
      `/my/fields/nearests_with_venue.json`,
      {
        params: {
          latitude: lat,
          longitude: lng,
        },
      }
    );
  }

  create(form: FieldForm, token: string): AxiosPromise<Field> {
    return this.buildClient(token).post(`/api/v2/my/fields.json`, {
      myfield: {
        name: form.name,
        venue_id: form.venue ? form.venue.id : null,
        lat: form.lat,
        lng: form.lng,
      },
    });
  }

  update(id: number, form: FieldForm, token: string): AxiosPromise<Field> {
    return this.buildClient(token).patch(`/api/v2/my/fields/${id}.json`, {
      myfield: {
        name: form.name,
        venue_id: form.venue ? form.venue.id : null,
        lat: form.lat,
        lng: form.lng,
      },
    });
  }

  delete(id: number, token: string): Promise<any> {
    return this.buildClient(token).delete(`/api/v2/my/fields/${id}.json`);
  }
}
