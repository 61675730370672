// @flow
import AxiosClient from '../../../AxiosClient';
import type { AxiosPromise } from 'axios';
import type { Comment } from './Comment';
import type { Review } from '../Review';

export default class CommentClient extends AxiosClient {
  fetchAll(
    review: Review,
    limit: ?number
  ): AxiosPromise<Array<Comment>> {
    return this.buildClient().get(
      `/api/v2/products/${review.product.id}/reviews/${review.id}/comments.json`,
      {
        params: {
          limit: limit,
        },
      }
    );
  }

  post(
    review: Review,
    commentId: ?number,
    body: string,
    token: string
  ): AxiosPromise<Comment> {
    return this.buildClient(token).post(
      `/api/v2/products/${review.product.id}/reviews/${review.id}/comments.json`,
      {
        comment: {
          comment_id: commentId,
          body: body,
        },
      }
    );
  }

  destroy(
    review: Review,
    commentId: number,
    token: string
  ): AxiosPromise<Comment> {
    return this.buildClient(token).delete(
      `/api/v2/products/${review.product.id}/reviews/${review.id}/comments/${commentId}.json`
    );
  }
}
