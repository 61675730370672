// @flow
import React from 'react';
import UserClient from '../../models/users/UserClient';
import type { User } from '../../models/users/User';
import { Button } from 'reactstrap';

type Props = {
  user: User,
  isSignedIn: boolean,
  token: string,
};

type State = {
  user: User,
};

export default class FollowableUserComp extends React.PureComponent<
  Props,
  State
> {
  userClient = new UserClient();

  constructor(props: Props) {
    super(props);

    this.state = {
      user: props.user,
    };
  }

  onPressFollow() {
    const { user } = this.state;

    if (this.props.isSignedIn) {
      this.userClient
        .follow(user.id, this.props.token)
        .then(() => {
          this.setState({
            user: {
              ...user,
              following: true,
              followers_size: user.followers_size + 1,
            },
          });

          // $FlowFixMe
          notify('フォローしました', 'success');
        })
        .catch(error => {
          console.warn(error);
          // $FlowFixMe
          notify(error, 'danger');
        });
    } else {
      window.location.href = '/users/sign_in';
      // $FlowFixMe
      notify('フォローするにはログインが必要です', 'danger');
    }
  }

  onPressStopFollowing() {
    const { user } = this.state;

    this.userClient
      .stopFollowing(user.id, this.props.token)
      .then(() => {
        this.setState({
          user: {
            ...user,
            following: false,
            followers_size: user.followers_size - 1,
          },
        });

        // $FlowFixMe
        notify('フォローを解除しました', 'success');
      })
      .catch(error => {
        // $FlowFixMe
        notify(error, 'danger');
      });
  }

  render() {
    const { user } = this.state;

    return (
      <div className="clearfix">
        <div className="pull-left">
          <span className="mr-10">
            <img src={user.thumb_url} className="rounded-circle" width="32" />
          </span>
          {user.is_official ? (
            <a href={`/users/${user.id}`} className="f-18 c-text">
              {user.name}
              <i className="fa fa-check-square text-approved ml-5" />
            </a>
          ) : (
            <a href={`/users/${user.id}`} className="f-18 c-text">
              {user.name}
            </a>
          )}
        </div>
        <div className="pull-right">
          <span className="f-18">
            {user.following ? (
              <Button color="info" onClick={() => this.onPressStopFollowing()}>
                フォロー中
              </Button>
            ) : (
              <Button outline color="info" onClick={() => this.onPressFollow()}>
                フォローする
              </Button>
            )}
          </span>
        </div>
      </div>
    );
  }
}
