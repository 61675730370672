// @flow
import React, { useState } from 'react';
import styled from 'styled-components';
import InfiniteScroll from 'react-infinite-scroller';
import Loader from '../../commons/Loader.js';
import { breakPoint, viewSp } from '../../../lib/Mixins.js';
import ResultClient from '../../../models/results/ResultClient.js';
import type { Result } from '../../../models/results/Result.js';
import type { Ship } from '../../../models/ships/Ship.js';
import ResultList from '../../results/ResultList.js';
import { hasNextPageByHeaders } from '../../../utils/commonUtils';

type Props = {
  ship: Ship,
};

function IndexViewRoot(props: Props) {
  // ReactOnRailsの不具合で直接Hooksを使ってるコンポーネントをexportできない
  return <IndexView {...props} />;
}

function IndexView({ ship }: Props) {
  const [isCrew, setIsCrew] = useState(false);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState<{
    results: Array<Result>,
    totalResultsCount: number,
    hasNextPage: boolean,
  }>({
    results: [],
    totalResultsCount: 0,
    hasNextPage: true,
  });

  function resetResult() {
    setState({ results: [], totalResultsCount: 0, hasNextPage: true });
    setIsCrew(!isCrew);
    setPage(1);
    setIsLoading(false);
  }

  function onLoadMore() {
    if (!isLoading && state.hasNextPage) {
      setIsLoading(true);

      new ResultClient()
        .fetchByShip(page, ship.id, isCrew)
        .then(response => {
          setState({
            results: [...state.results, ...response.data].filter(
              (r, i, self) => self.map(r2 => r2.id).indexOf(r.id) === i
            ),
            totalResultsCount: response.headers
              ? Number(response.headers.total)
              : 0,
            hasNextPage: hasNextPageByHeaders(response.headers),
          });
        })
        .catch(error => {
          if (process.env.NODE_ENV === 'production') {
            window.location.replace('/500.html');
          }
          console.warn(error);
        })
        .finally(() => {
          setIsLoading(false);
          setPage(page + 1);
        });
    }
  }

  return (
    <>
      <Title>
        <span>{ship.prefecture.name}</span>
        <h1>{ship.name}</h1>
      </Title>

      <ListHeader>
        <ResultCount>
          <strong>{state.totalResultsCount.toLocaleString()}</strong>件
        </ResultCount>
        <Checkbox
          onClick={() => resetResult()}
          display={isCrew ? 'initial' : 'none'}
        >
          <span />
          <div>船長・中乗りの釣果のみ</div>
        </Checkbox>
      </ListHeader>

      <List>
        <InfiniteScroll
          pageStart={0}
          loadMore={() => onLoadMore()}
          hasMore={state.hasNextPage && !isLoading} // https://github.com/danbovey/react-infinite-scroller/issues/143#issuecomment-421973425
          loader={<Loader key={0} />}
        >
          <ResultList results={state.results} />
        </InfiniteScroll>

        {state.hasNextPage === false && state.results.length === 0 && (
          <NotFound>釣果が見つかりませんでした。</NotFound>
        )}
      </List>
    </>
  );
}

const Title = styled.div`
  margin-top: 40px;
  padding-bottom: 37px;
  text-align: center;
  width: 100%;

  ${viewSp} {
    margin-top: 10px;
    padding-bottom: 18px;
  }

  span {
    font-size: 16px;
    color: #a0a0a0;
    line-height: 1;

    ${viewSp} {
      font-size: 12px;
    }
  }

  h1 {
    font-size: 32px;
    margin: 10px 0 0;
    line-height: 1;

    ${viewSp} {
      font-size: 24px;
      margin: 8px 0 0;
    }
  }
`;

const ListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #0000001a;
  border-bottom: 1px solid #0000001a;
  max-width: ${breakPoint}px;
  margin: 0 auto;
  padding: 20px 0;

  ${viewSp} {
    border-bottom: none;
    padding: 13.5px 20px;
  }
`;

const ResultCount = styled.div`
  font-size: 12px;

  strong {
    font-size: 18px;
    font-weight: bold;
    margin-right: 2px;
  }
`;

const Checkbox = styled.div`
  align-items: center;
  display: flex;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  line-height: 1;

  span {
    border: 2px solid #e5e5e5;
    border-radius: 4px;
    height: 16px;
    width: 16px;
    margin-right: 10px;
    position: relative;

    ${viewSp} {
      margin-right: 8px;
    }

    &::before {
      content: '';
      border-bottom: 1.5px solid #0877bc;
      border-right: 1.5px solid #0877bc;
      position: absolute;
      top: 48%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%) rotate(45deg);
      height: 60%;
      width: 35%;
      display: ${({ display }) => display};
    }
  }
`;

const List = styled.div`
  max-width: ${breakPoint}px;
  margin: 0 auto;
  padding-bottom: 20px;
  width: 100%;

  ${viewSp} {
    background-color: #f8f8f8;
    padding: 0 20px 20px;
  }
`;

const NotFound = styled.div`
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
  padding: 20px 0;

  ${viewSp} {
    font-size: 20px;
  }
`;

export default IndexViewRoot;
