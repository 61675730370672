// @flow
import ResultHelper from '../helpers/ResultHelper';
import moment from 'moment';

export default class VenueHelper {
  static isSea(venue: any) {
    return venue != null && venue.type === 'SeaVenue';
  }

  static isLand(venue: any) {
    return venue != null && venue.type === 'LandVenue';
  }

  static suii(venue: any) {
    return venue != null ? ResultHelper.suii(venue) : null;
  }

  static zenuryo(venue: any) {
    return venue != null ? ResultHelper.zenuryo(venue) : null;
  }

  static hosuiryo(venue: any) {
    return venue != null ? ResultHelper.hosuiryo(venue) : null;
  }

  static tide(venue: any) {
    return venue != null ? ResultHelper.tide(venue) : null;
  }

  static shionm(venue: any) {
    return venue != null ? ResultHelper.shionm(venue) : null;
  }

  static sunset(venue: any) {
    return venue != null && venue.sunset != null
      ? moment(venue.sunset, 'HHmm').format('HH:mm')
      : null;
  }

  static sunrise(venue: any) {
    return venue != null && venue.sunrise != null
      ? moment(venue.sunrise, 'HHmm').format('HH:mm')
      : null;
  }

  static moonage(venue: any) {
    return venue != null ? venue.moonage : null;
  }

  static icon(venue: any) {
    if (VenueHelper.isLand(venue)) {
      return 'icon-field_freshwater';
    } else if (VenueHelper.isSea(venue)) {
      return 'icon-field_saltwater';
    } else {
      return '';
    }
  }

  static iconColor(venue: any) {
    if (VenueHelper.isLand(venue)) {
      return '#2196f3';
    } else if (VenueHelper.isSea(venue)) {
      return '#0097a7';
    } else {
      return '';
    }
  }

  static datetime(venue: any) {
    let r = '';

    if (venue.observeymd != null) {
      r += venue.observeymd;
    }

    if (venue.observehour != null) {
      r += venue.observehour;
    }

    if (venue.observetime != null) {
      r += venue.observetime;
    }

    return r;
  }
}
