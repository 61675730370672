// @flow
import React from 'react';
import {
  View,
  Text,
  ActivityIndicator,
} from '../commons/react-native';
import {
  VictoryAxis,
  VictoryChart,
  VictoryLine,
  VictoryScatter,
} from 'victory';
import _ from 'lodash';
import moment from 'moment';
// import 'moment-round';
import VenueClient from '../../models/venues/VenueClient';
import tideGraphTheme from '../../assets/themes/styles/tideGraph';
import type { TidePerDay } from '../../models/venues/TidePerDay';
import type { Venue } from '../../models/venues/Venue';

type Props = {
  venue?: ?Venue,
  tidePerDay?: ?TidePerDay,
  datetime?: ?moment,
  // width: ?number,
};

type State = {
  tidePerDay: ?TidePerDay,
};

export default class TideGraphReact extends React.PureComponent<Props, State> {
  venueClient = new VenueClient();

  // static defaultProps = {
  //   width: Dimensions.get('window').width,
  // };

  constructor(props: Props) {
    super(props);

    this.state = {
      tidePerDay: props.tidePerDay,
    };
  }

  // eslint-disable-next-line no-unused-vars
  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    if (nextProps.hasOwnProperty('tidePerDay')) {
      return {
        tidePerDay: nextProps.tidePerDay,
      };
    }
  }

  componentDidMount() {
    if (this.props.venue != null && this.props.tidePerDay == null) {
      const { venue, datetime } = this.props;
      this.venueClient
        .fetchVenueWithConditions(venue.id, datetime)
        .then(response => {
          // $FlowFixMe
          this.setState({ tidePerDay: response.data.perdays[0] });
        })
        .catch(error => {
          console.error(error);
        });
    }
  }

  /**
   * 以下の仕様で潮位を決定する
   * ・同時（HH）内に満干潮があり、釣果分（mm）＜満干潮分（mm）であれば、その時間（HH）にDot。
   * ・同時（HH）内に満干潮があり、釣果分（mm）＞満干潮分（mm）であれば、＋１時間（HH）にDot。
   * ・その他、30分〜翌時29分は最も近い時（HH）にDot。
   * @see https://github.com/anglers/Anglers/issues/89
   */
  buildTideHour(datetime: moment, tidePerDay: any) {
    let tideTimes = [
      tidePerDay.hightide1time,
      tidePerDay.hightide2time,
      tidePerDay.hightide3time,
      tidePerDay.hightide4time,
      tidePerDay.lowtide1time,
      tidePerDay.lowtide2time,
      tidePerDay.lowtide3time,
      tidePerDay.lowtide4time,
    ];

    // 日付(HHmm)として変換出来るものだけ抽出
    tideTimes = tideTimes.filter(t => !isNaN(parseInt(t)));

    // 同時内が存在する配列のindex取得する
    let posSameHour = -1;
    const momFisTime = moment(datetime, 'HHmm');
    for (let i = 0; i < tideTimes.length; i++) {
      if (moment(tideTimes[i], 'HHmm').isSame(momFisTime, 'hour')) {
        posSameHour = i;
        break;
      }
    }

    if (posSameHour < 0) {
      // 最も近い時に合わす（HH）
      return momFisTime.round(60, 'minutes').format('HH');
    }

    if (momFisTime.isAfter(moment(tideTimes[posSameHour], 'HHmm'))) {
      // 釣果時刻 > 満干潮時刻であれば、分を切り上げ
      return momFisTime.ceil(60, 'minutes').format('HH');
    } else {
      // 釣果時刻 <= 満干潮時刻であれば、分を切り捨て
      return momFisTime.floor(60, 'minutes').format('HH');
    }
  }

  render() {
    const { tidePerDay } = this.state;
    const height = 250;

    if (tidePerDay != null && tidePerDay.perhours != null) {
      const data = tidePerDay.perhours.map(d => {
        return {
          x: parseInt(d.observehour),
          y: Math.round(d.tide),
        };
      });
      let tideHour = 0;
      let tide = 0;

      if (this.props.datetime != null) {
        tideHour = parseInt(
          this.buildTideHour(this.props.datetime, tidePerDay)
        );
        tide = data[_.findIndex(data, d => d.x === tideHour)].y;
      }

      return (
        <View
          pointerEvents="none"
          style={{
            backgroundColor: '#2c3e50',
          }}
        >
          <View
            style={{
              paddingTop: 8,
            }}
          >
            <Text
              style={{
                color: '#fff',
                textAlign: 'center',
              }}
            >
              {moment(tidePerDay.observeymd).format('YYYY年MM月DD日(ddd)')}
            </Text>
          </View>
          <VictoryChart
            // width={this.props.width}
            height={height}
            theme={tideGraphTheme}
            domainPadding={{ y: 50 }}
          >
            <VictoryAxis
              crossAxis
              tickCount={24}
              standalone={false}
              style={{
                axis: {
                  stroke: 'white',
                  strokeWidth: 1,
                },
              }}
            />
            <VictoryAxis
              crossAxis
              dependentAxis
              standalone={false}
              style={{
                axis: {
                  stroke: 'white',
                  strokeWidth: 1,
                },
              }}
            />
            <VictoryLine
              interpolation="cardinal"
              data={data}
              style={{
                data: {
                  stroke: '#33B5E5',
                  strokeWidth: 1,
                },
              }}
            />
            {this.props.datetime != null && (
              <VictoryScatter
                style={{
                  data: {
                    fill: '#f39c12',
                    stroke: '#e67e22',
                    fillOpacity: 1,
                    strokeWidth: 3,
                  },
                }}
                data={[
                  {
                    x: tideHour,
                    y: tide,
                    symbol: 'circle',
                    size: 6,
                  },
                ]}
              />
            )}
          </VictoryChart>
        </View>
      );
    } else {
      return (
        <View
          style={{
            flex: 1,
            justifyContent: 'center',
            height: height,
            backgroundColor: '#2c3e50',
          }}
        >
          <ActivityIndicator size="large" />
        </View>
      );
    }
  }
}
