// @flow
import React from 'react';
import type { Area } from '../../models/areas/Area';
import HorizontalResultList from '../results/HorizontalResultList';

type Props = {
  heading: string,
  areas: Array<Area>,
  fishId: number,
};

function ExpandableAreas({ heading, areas, fishId }: Props) {
  return (
    <div className="accordion" id="accordionAreas">
      <h2 className="mb-0">
        <button
          className="btn btn-lg collapsed h2 font-weight-bold text-left"
          type="button"
          data-toggle="collapse"
          data-target="#collapseAreas"
          aria-expanded="false"
          aria-controls="collapseAreas"
        >
          {heading}
        </button>
      </h2>
      <div
        id="collapseAreas"
        className="collapse"
        data-parent="#accordionAreas"
      >
        <div className="badges">
          {areas.map(a => (
            <div key={a.id} className="row">
              <div className="h5 col">
                <a
                  className="badge badge-light"
                  href={location.pathname.replace(
                    /\/areas\/\d*/,
                    `/areas/${a.id}`
                  )}
                >
                  {a.name}
                </a>
              </div>
            </div>
          ))}
        </div>

        <HorizontalResultList fishId={fishId} />
      </div>
    </div>
  );
}

export default React.memo<Props>(ExpandableAreas);
