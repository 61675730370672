// @flow
import React from 'react';
import { Modal, ModalHeader } from 'reactstrap';
import type { Account } from '../../models/accounts/Account';
import type { Maker } from '../../models/makers/Maker';
import type { Series } from '../../models/series/Series';
import type { Product } from '../../models/products/Product';
import { SelectBody } from './SelectBody';

const SELECT_BODY = 0;
const ADD_BODY = 1;

type Props = {
  visible: boolean,
  maker: Maker,
  series: Series,
  type: string,
  account: Account,
  onApply: (product: Product) => void,
  onClose: () => void,
};

type State = {
  body: number,
};

export class SelectProductModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      body: SELECT_BODY,
    };
  }

  onAddProduct() {
    this.setState({ body: ADD_BODY });
  }

  onApply(product: Product) {
    this.setState({ body: SELECT_BODY }, () => this.props.onApply(product));
  }

  onClose() {
    this.setState({ body: SELECT_BODY }, () => this.props.onClose());
  }

  render(): React$Node {
    const { visible, type, maker, series } = this.props;
    const { body } = this.state;

    return (
      <Modal isOpen={visible} toggle={() => this.onClose()}>
        {body === SELECT_BODY && (
          <React.Fragment>
            <ModalHeader toggle={() => this.onClose()}>モデル選択</ModalHeader>
            <SelectBody
              type={type}
              maker={maker}
              series={series}
              onApply={product => this.onApply(product)}
              onAddProduct={() => this.onAddProduct()}
            />
          </React.Fragment>
        )}
      </Modal>
    );
  }
}
