// @flow
import moment from 'moment';
import TideGraph from '../venues/TideGraph';
import JQueryView from '../../../common/views/JQueryView';
import { STYLE_OPTIONS } from '../commons/GoogleMaps';
import type { Area } from '../../models/areas/Area';
import type { Venue } from '../../models/venues/Venue';

const POLE_RADIUS = 6356752.314;
const CIRCLE_RADIUS = 4000;

declare var $: any;
declare var google: any;
declare var MasterSlider: any;

export default class ShowView extends JQueryView {
  caughtAt: string;
  area: ?Area;
  mapOptions: any;
  venue: ?Venue;
  postalCode: any;

  constructor(
    caughtAt: string,
    area: ?Area,
    mapOptions: any,
    venue: ?Venue,
    postalCode: any,
    isMobile: boolean
  ) {
    super('.content');
    this.caughtAt = caughtAt;
    this.area = area;
    this.venue = venue;
    this.postalCode = postalCode;
    this.mapOptions = mapOptions;

    const slider = new MasterSlider();
    slider.control('arrows');
    slider.control('thumblist', {
      autohide: false,
      dir: 'h',
      arrows: false,
      align: 'bottom',
      width: 127,
      height: 137,
      margin: 5,
      space: 5,
    });
    slider.setup('masterslider', {
      layout: 'fillwidth',
      autoHeight: 'true',
      space: 5,
      view: 'scale',
      loop: true,
    });

    if (isMobile) {
      $('.carousel').carousel({
        interval: false,
      });

      $('.carousel-inner').swipe({
        swipeLeft: () => {
          $('.carousel-inner')
            .parent()
            .carousel('next');
        },
        swipeRight: () => {
          $('.carousel-inner')
            .parent()
            .carousel('prev');
        },
      });
    }

    this.render();
  }

  buildGoogleMap(latLng: any, zoom: number, radius: number): any {
    const map = new google.maps.Map(document.getElementById('area_map'), {
      zoom: zoom,
      center: latLng,
      mapTypeControl: false,
      streetViewControl: false,
      mapTypeControlOptions: {
        style: google.maps.MapTypeControlStyle.DEFAULT,
      },
      mapTypeId: google.maps.MapTypeId.ROADMAP,
    });

    map.mapTypes.set(
      'angelers',
      new google.maps.StyledMapType(STYLE_OPTIONS, { name: 'angelers' })
    );
    map.setMapTypeId('angelers');

    new google.maps.Circle({
      map: map,
      center: latLng,
      radius: radius,
      strokeColor: '#ed6c63',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#ed6c63',
      fillOpacity: 0.35,
    });

    return map;
  }

  renderAreaMap(area: Area) {
    if (area.lat != null && area.lng != null) {
      const latLng = new google.maps.LatLng(area.lat, area.lng);
      const map = this.buildGoogleMap(
        latLng,
        this.mapOptions['zoomLevel'],
        this.mapOptions['radius']
      );
      const info = new google.maps.InfoWindow({
        content: `<a href="/areas/${area.id}" class="c-blue">${area.name}</a>`,
        position: new google.maps.LatLng(area.lat, area.lng),
      });
      info.open(map);
    }
  }

  renderCityMap() {
    const latLng = new google.maps.LatLng(
      this.postalCode.city_latitude,
      this.postalCode.city_longitude
    );
    const map = this.buildGoogleMap(latLng, 12, CIRCLE_RADIUS);
    const info = new google.maps.InfoWindow({
      content: this.postalCode.name,
      position: new google.maps.LatLng(
        this.postalCode.city_latitude +
          (360 * CIRCLE_RADIUS) / (2 * Math.PI * POLE_RADIUS),
        this.postalCode.city_longitude
      ),
    });
    info.open(map);
  }

  render() {
    // if (this.area != null) {
    //   this.renderAreaMap(this.area);
    // } else if (this.postalCode != null) {
    //   this.renderCityMap();
    // }

    if (this.venue != null && this.caughtAt != null) {
      const graph = new TideGraph(this.venue.id, '#tide_graph');
      graph.show(moment(this.caughtAt));
    }
  }
}
