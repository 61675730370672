// @flow
import React from 'react';
import moment from 'moment';
import type { Magazine } from '../../models/magazines/Magazine';
import MagazineClient from '../../models/magazines/MagazineClient';

type Props = {};

type State = {
  recent_hot_magazines: Array<Magazine>,
  hot_magazines: Array<Magazine>,
};

export default class MagazineListComp extends React.Component<Props, State> {
  magazineClient = new MagazineClient();

  constructor(props: Props) {
    super(props);

    this.state = {
      recent_hot_magazines: [],
      hot_magazines: [],
    };
  }

  componentDidMount() {
    this.magazineClient
      .fetchRecentHotMagazines()
      .then(response => this.setState({ recent_hot_magazines: response.data }))
      .catch(error => {
        console.log(error);
      });

    this.magazineClient
      .fetchHotMagazines()
      .then(response => this.setState({ hot_magazines: response.data }))
      .catch(error => {
        console.log(error);
      });
  }

  render() {
    const { recent_hot_magazines, hot_magazines } = this.state;
    return (
      <div className="magazines access_ranking">
        <h4 className="title_border">最近の人気記事</h4>
        {recent_hot_magazines.map((magazine, i) => {
          return (
            <div key={i} className="magazines mb-30 mt-10 list-hover">
              <a href={`/magazines/${magazine.id}`} className="body-color">
                <div className="media align-items-center">
                  <div className="ml-10 mr-10 rank">{i + 1}</div>
                  <div className="text-center magazine_image_48 mr-10">
                    <img
                      src={
                        magazine.thumbnail != null
                          ? magazine.thumbnail
                          : 'images/noimage.png'
                      }
                      alt={magazine.title}
                    />
                  </div>
                  <div className="media-body tags">
                    <span className="h6">{magazine.title}</span>
                    <br />
                    <small className="date">
                      {moment(magazine.updated_at).format('YYYY年MM月DD日')}
                      更新
                    </small>
                    <span className="badge badge-light p-5">
                      {magazine.magazine_category.name}
                    </span>
                  </div>
                </div>
              </a>
            </div>
          );
        })}

        <h4 className="title_border">過去の人気記事</h4>
        {hot_magazines.map((magazine, i) => {
          return (
            <div key={i} className="magazines mb-30 mt-10 list-hover">
              <a href={`/magazines/${magazine.id}`} className="body-color">
                <div className="media align-items-center">
                  <div className="ml-10 mr-10 rank">{i + 1}</div>
                  <div className="text-center magazine_image_48 mr-10">
                    <img
                      src={
                        magazine.thumbnail != null
                          ? magazine.thumbnail
                          : 'images/noimage.png'
                      }
                      alt={magazine.title}
                    />
                  </div>
                  <div className="media-body tags">
                    <span className="h6">{magazine.title}</span>
                    <br />
                    <small className="date">
                      {moment(magazine.updated_at).format('YYYY年MM月DD日')}{' '}
                      更新
                    </small>
                    <span className="badge badge-light p-5">
                      {magazine.magazine_category.name}
                    </span>
                  </div>
                </div>
              </a>
            </div>
          );
        })}
      </div>
    );
  }
}
