// @flow
import React, { useState, useEffect } from 'react';
import ResultClient from '../../../models/results/ResultClient';
import ResultCard from '../../results/ResultCard';
import type { Result } from '../../../models/results/Result';

type Props = {
  areaId: number,
  fishId: number,
};

// page数固定のcomponentです。(1ページ目のコンテンツが取得されます)
function PopularResultsRoot(props: Props) {
  // ReactOnRailsの不具合で直接Hooksを使ってるコンポーネントをexportできない
  return <PopularResults {...props} />;
}

function PopularResults({ areaId, fishId }: Props) {
  const [popularResults, setPopularResults] = useState<Array<Result>>([]);

  useEffect(() => {
    const resultClient = new ResultClient();

    resultClient
      .fetchAll(1, 'votes_up', null, null, null, null, fishId, areaId, null)
      .then(response => {
        setPopularResults(response.data);
      })
      .catch(error => {
        if (process.env.NODE_ENV === 'production') {
          window.location.replace('/500.html');
        }
        console.warn(error);
      });
  }, [areaId, fishId]);

  return (
    <>
      {popularResults != null && (
        <>
          <h2>人気の投稿</h2>
          <div className="col-12 mb-50">
            <div className="carousel">
              <div className="carousel-wrap">
                {popularResults.slice(0, 8).map(result => {
                  return (
                    <ResultCard
                      key={result.id}
                      result={result}
                      className="col-5 col-md-3 carousel-thumb"
                      withComment={false}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default PopularResultsRoot;
