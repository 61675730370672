// @flow
import React from 'react';
import type { Node } from 'react';
import HeaderMenu from './HeaderMenu';
import HeaderMobileMenu from './HeaderMobileMenu';

type Props = {
  mobile: boolean,
  logo: string,
  logoIcon: string,
  active: string,
  fdl: string,
  userId: ?number,
  userName: ?string,
  children?: Node,
};

type State = {};

export default class Header extends React.Component<Props, State> {
  render() {
    const { mobile, children, userId, userName, fdl } = this.props;

    return (
      <nav className="navbar navbar-expand-sm navbar-light bg-white sticky-top">
        <div className="navbar-brand d-flex align-items-center">
          {mobile && children ? (
            <a href="/">
              <img
                width={30}
                alt="釣果検索のアングラーズ"
                src={this.props.logoIcon}
                className="mr-15"
              />
            </a>
          ) : (
            <a href="/">
              <img
                width="140"
                alt="釣果検索のアングラーズ"
                src={this.props.logo}
                className="mr-15"
              />
            </a>
          )}
          {children ? this.props.children : null}
        </div>
        {!mobile && (
          <React.Fragment>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbar"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div id="navbar" className="collapse navbar-collapse">
              <HeaderMenu active={this.props.active} userName={userName} />
            </div>
          </React.Fragment>
        )}
        {mobile && (
          <HeaderMobileMenu
            userId={userId}
            path={window.location.pathname}
            fdl={fdl}
          />
        )}
      </nav>
    );
  }
}
