// @flow
import { useState, useEffect } from 'react';
import AuthClient from '../../../../models/auth/AuthClient';

const useResend = ({
  isParentSubmitting,
}: {
  isParentSubmitting: boolean,
}): {
  email: string,
  setEmail: (email: string) => void,
  emailErrors: string[],
  isSubmitting: boolean,
  handleSubmit: () => Promise<void>,
} => {
  const [email, setEmail] = useState('');
  const [emailErrors, setEmailErrors] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(isParentSubmitting);

  useEffect(() => {
    setIsSubmitting(isParentSubmitting);
    setEmailErrors([]);
  }, [isParentSubmitting]);

  const handleSubmit = async () => {
    setIsSubmitting(true);

    return await new AuthClient()
      .resend(email)
      .then(() => {
        window.notify('認証コードを再送信しました', 'success');
        setEmail('');
        setEmailErrors([]);
      })
      .catch(e => {
        if (e.response.status === 422) {
          if (e.response.data.email) {
            setEmailErrors(e.response.data.email);
          }
        } else {
          window.location.href = '/users/sign_up';
        }
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return {
    email,
    setEmail,
    emailErrors,
    isSubmitting,
    handleSubmit,
  };
};

export default useResend;
