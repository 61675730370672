// @flow
export function computeDistanceBetween(
  lat1: number,
  lng1: number,
  lat2: number,
  lng2: number
): number {
  return (
    6378.14 *
    Math.acos(
      Math.cos(radians(lat1)) *
        Math.cos(radians(lat2)) *
        Math.cos(radians(lng2) - radians(lng1)) +
        Math.sin(radians(lat1)) * Math.sin(radians(lat2))
    )
  );
}

function radians(deg: number): number {
  return (deg * Math.PI) / 180;
}
