// @flow
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Col, Row } from 'reactstrap';
import moment from 'moment';
import type { BaseProps } from '../../../../application';
import type { Account } from '../../../models/accounts/Account';
import type { MyLure } from '../../../models/my_lures/MyLure';
import type { Maker } from '../../../models/makers/Maker';
import { ActivityIndicator } from '../../commons/react-native';
import { View } from '../../commons/native-base';
import { List, ListItem } from '../../commons/native-base';
import { ListContent } from '../../commons/list';
import { updateMyLures } from '../../../actions/myLures';
import { displayAxiosError, displayMessage } from '../../commons/Toast';
import MyLureClient from '../../../models/my_lures/MyLureClient';

type Props = BaseProps & {
  account: Account,
  myLures: Array<MyLure>,
  updateMyLures: (myLures: Array<MyLure>) => void,
};

type State = {
  id: ?number,
  myLure: ?MyLure,
  loading: boolean,
};

class ShowView extends React.Component<Props, State> {
  myLureClient = new MyLureClient();

  constructor(props: Props) {
    super(props);

    const myLure = props.location.state && props.location.state.myLure;

    this.state = {
      id: myLure != null ? myLure.id : parseInt(props.match.params.id),
      myLure: myLure,
      loading: myLure == null,
    };
  }

  // componentWillReceiveProps(nextProps: Props) {
  //   if (nextProps.screenProps.myLure) {
  //     this.setState({
  //       myLure: nextProps.screenProps.myLure,
  //     });
  //   }
  // }

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    const { myLures } = nextProps;
    const myLureId = prevState.myLure ? prevState.myLure.id : null;

    if (!prevState.loading) {
      if (myLures != null && myLures.length > 0 && myLureId != null) {
        let myLure = null;

        myLures.find(lure => {
          myLure =
            lure.colors != null &&
            lure.colors.find(color => color.id === myLureId);
          return myLure != null;
        });

        if (myLure != null) {
          return {
            myLure: myLure,
          };
        }
      }
    }
    return null;
  }

  componentDidMount() {
    const { account } = this.props;
    const { id, myLure } = this.state;

    if (myLure == null && id != null) {
      this.myLureClient
        .fetchMyLure(id, account)
        .then(response => {
          this.setState({
            loading: false,
            myLure: response.data,
          });
        })
        .catch(error => {
          this.setState({ loading: false });

          if (error.response.status !== 404) {
            displayAxiosError(error);
          }
        });
    }
  }

  onClickMaker(maker: ?Maker) {
    if (maker != null) {
      window.location.href = `/makers/${maker.id}`;
    }
  }

  onClickEdit(myLure: MyLure) {
    // this.props.history.push(`/my/lures/${myLure.id}/edit`, { myLure: myLure });
    this.props.history.push(`/my/lures/${myLure.id}/edit`);
  }

  onClickDelete(myLure: MyLure) {
    const { history, account, updateMyLures } = this.props;

    this.setState({ loading: true });
    this.myLureClient
      .delete(myLure.id, account)
      .then(response => {
        displayMessage('ルアーを削除しました');
        updateMyLures(response.data);
        history.goBack();
      })
      .catch(error => {
        displayAxiosError(error);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  render() {
    const { myLure, loading } = this.state;

    if (loading) {
      return (
        <View
          style={{
            paddingVertical: 20,
            borderTopWidth: 1,
            borderColor: '#CED0CE',
          }}
        >
          <ActivityIndicator animating size="large" />
        </View>
      );
    } else if (myLure != null) {
      return (
        <React.Fragment>
          {myLure.images.length > 0 && (
            <Row className="mb-30">
              {myLure.images.map(image => (
                <Col key={image.id} lg={4}>
                  <a href={image.image.url}>
                    <img src={image.image.thumb.url} className="img-fluid" />
                  </a>
                </Col>
              ))}
            </Row>
          )}
          <List style={{ backgroundColor: '#fff', marginBottom: 20 }}>
            <ListItem
              style={{ cursor: 'pointer' }}
              onClick={() => this.onClickMaker(myLure.maker)}
            >
              <ListContent>
                <Row>
                  <Col xs={4}>メーカー</Col>
                  <Col xs={8}>{myLure.maker ? myLure.maker.name : ''}</Col>
                </Row>
              </ListContent>
            </ListItem>
            <ListItem>
              <ListContent>
                <Row>
                  <Col xs={4}>ルアー名</Col>
                  <Col xs={8}>{myLure.name ? myLure.name : ''}</Col>
                </Row>
              </ListContent>
            </ListItem>
            <ListItem>
              <ListContent>
                <Row>
                  <Col xs={4}>ルアーカラー</Col>
                  <Col xs={8}>{myLure.color ? myLure.color : ''}</Col>
                </Row>
              </ListContent>
            </ListItem>
            <ListItem>
              <ListContent>
                <Row>
                  <Col xs={4}>使用開始日</Col>
                  <Col xs={8}>
                    {myLure.get_date != null
                      ? moment(myLure.get_date).format('YYYY年MM月DD日')
                      : ''}
                  </Col>
                </Row>
              </ListContent>
            </ListItem>
            <ListItem>
              <ListContent>
                <Row>
                  <Col xs={4}>使用終了日</Col>
                  <Col xs={8}>
                    {myLure.lost_date != null
                      ? moment(myLure.lost_date).format('YYYY年MM月DD日')
                      : ''}
                  </Col>
                </Row>
              </ListContent>
            </ListItem>
            <ListItem>
              <ListContent>
                <Row>
                  <Col xs={4}>メモ</Col>
                  <Col xs={8} style={{ whiteSpace: 'pre-line' }}>
                    {myLure.memo}
                  </Col>
                </Row>
              </ListContent>
            </ListItem>
          </List>
          <Row>
            <Col sm={3} xs={6}>
              <Button
                block
                color="primary"
                onClick={() => this.onClickEdit(myLure)}
              >
                編集する
              </Button>
            </Col>
            <Col sm={3} xs={6}>
              <Button
                block
                color="outline-primary"
                onClick={() => this.onClickDelete(myLure)}
              >
                削除する
              </Button>
            </Col>
          </Row>
        </React.Fragment>
      );
    } else {
      return <div>ルアーが見つかりませんでした</div>;
    }
  }
}

export default connect(
  state => ({
    account: state.account,
    myLures: state.myLures,
  }),
  dispatch => bindActionCreators({ updateMyLures }, dispatch)
)(ShowView);
