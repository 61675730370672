// @flow
import React, { useState, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import Loader from '../../commons/Loader.js';
import ShipClient from '../../../models/ships/ShipClient.js';
import ShipListItem from '../../ships/ShipListItem.js';
import type { ShipWithDetails } from '../../../models/ships/Ship.js';
import type { Account } from '../../../models/accounts/Account.js';
import { hasNextPageByHeaders } from '../../../utils/commonUtils';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { viewSp } from '../../../lib/Mixins';
import { csrfToken } from '../../../helpers/fetch.js';

type Props = {
  account: Account,
};

function ListItem({ ship }: { ship: ShipWithDetails }) {
  return (
    <ListItemContainer>
      <ShipListItem
        key={ship.id}
        ship={ship}
        isSignedIn={true}
        token={csrfToken()}
      />
    </ListItemContainer>
  );
}

const MemoedListItem = React.memo(ListItem);

function IndexViewRoot(props: Props) {
  // ReactOnRailsの不具合で直接Hooksを使ってるコンポーネントをexportできない
  return <IndexView {...props} />;
}

function IndexView({ account }: Props) {
  const page = useRef(1);
  const [isLoading, setIsLoading] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [ships, setShips] = useState<Array<ShipWithDetails>>([]);

  function onLoadMore() {
    if (!isLoading && hasNextPage) {
      setIsLoading(true);

      new ShipClient()
        .fetchFollowingsByUser(page.current, account.id)
        .then(response => {
          setShips(
            [...ships, ...response.data].filter(
              (s, i, self) => self.map(s2 => s2.id).indexOf(s.id) === i
            )
          );
          setHasNextPage(hasNextPageByHeaders(response.headers));
        })
        .finally(() => {
          setIsLoading(false);
          page.current = page.current + 1;
        });
    }
  }

  return (
    <Container>
      <Title>フォロー中の釣船・船宿</Title>
      <InfiniteScroll
        pageStart={0}
        loadMore={() => onLoadMore()}
        hasMore={hasNextPage && !isLoading} // https://github.com/danbovey/react-infinite-scroller/issues/143#issuecomment-421973425
        loader={<Loader key={0} />}
      >
        {ships.map(ship => (
          <MemoedListItem key={ship.id} ship={ship} />
        ))}
      </InfiniteScroll>
    </Container>
  );
}

const Container = styled.div`
  ${viewSp} {
    padding: 0 10px;
  }
`;

const Title = styled.h2`
  border-bottom: 1px solid #e9ecef;
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
  padding-bottom: 20px;
`;

const ListItemContainer = styled.div`
  border-bottom: 1px solid #e9ecef;
`;

const mapStateToProps = state => state;
export default connect(mapStateToProps)(IndexViewRoot);
