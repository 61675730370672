// @flow
import React from 'react';
import 'react-dates/initialize';
import moment from 'moment';
import 'moment/locale/ja';
import { DateRangePicker } from 'react-dates';
import { DateRangePickerPhrases } from 'react-dates/lib/defaultPhrases';
import { START_DATE, END_DATE } from 'react-dates/lib/constants';
import isInclusivelyAfterDay from 'react-dates/lib/utils/isInclusivelyAfterDay';

moment.locale('ja');

type Props = {
  orientation: string,
  withFullScreenPortal: boolean,
  onDatesChange: (startDate: ?string, endDate: ?string) => void,
};

type State = {
  focusedInput: any,
  startDate: ?moment,
  endDate: ?moment,
};

export default class DateRangeView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      focusedInput: null,
      startDate: null,
      endDate: null,
    };
  }

  onDatesChange(dates: any) {
    const { startDate, endDate } = dates;
    // $FlowFixMe
    const startOn = startDate
      ?.clone()
      .subtract(12, 'hours')
      .format('YYYY-MM-DD[T]HH:mm:ss.SSSZZ');
    // $FlowFixMe
    const endOn = endDate
      ?.clone()
      .add(12, 'hours')
      .format('YYYY-MM-DD[T]HH:mm:ss.SSSZZ');

    this.setState(
      {
        startDate: startDate,
        endDate: endDate,
      },
      () => this.props.onDatesChange(startOn, endOn)
    );
  }

  render() {
    const { focusedInput, startDate, endDate } = this.state;

    if (focusedInput != null || startDate != null || endDate != null) {
      return (
        <DateRangePicker
          onDatesChange={dates => this.onDatesChange(dates)}
          onFocusChange={focusedInput =>
            this.setState({ focusedInput: focusedInput })
          }
          focusedInput={focusedInput}
          startDate={startDate}
          startDateId={START_DATE}
          startDatePlaceholderText="開始日"
          endDate={endDate}
          endDateId={END_DATE}
          endDatePlaceholderText="終了日"
          monthFormat="YYYY[年]MMMM"
          displayFormat="YYYY年MM月DD日"
          isOutsideRange={day =>
            isInclusivelyAfterDay(day, moment().add(1, 'days'))
          }
          showClearDates={true}
          phrases={DateRangePickerPhrases}
          orientation={this.props.orientation}
          withFullScreenPortal={this.props.withFullScreenPortal}
        />
      );
    } else {
      return (
        <button
          className="btn btn-light mr-10"
          onClick={() => this.setState({ focusedInput: 'startDate' })}
        >
          日付
        </button>
      );
    }
  }
}
