// @flow
import { UPDATE_MY_LURE } from '../actions/types';
import type { MyLure } from '../models/my_lures/MyLure';

const initialState = null;

export default (state: ?MyLure = initialState, action: any): ?MyLure => {
  switch (action.type) {
    case UPDATE_MY_LURE:
      return action.payload;
    default:
      return state;
  }
};
