// @flow
import React from 'react';
import { Modal, ModalHeader } from 'reactstrap';
import type { Account } from '../../models/accounts/Account';
import type { Maker } from '../../models/makers/Maker';
import type { Series } from '../../models/series/Series';
import { SelectBody } from './SelectBody';

const SELECT_BODY = 0;

type Props = {
  visible: boolean,
  productType: string,
  maker: Maker,
  account: Account,
  onApply: (series: Series) => void,
  onClose: () => void,
};

type State = {
  body: number,
};

export class SelectSeriesModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      body: SELECT_BODY,
    };
  }

  onApply(series: Series) {
    this.setState({ body: SELECT_BODY }, () => this.props.onApply(series));
  }

  onClose() {
    this.setState({ body: SELECT_BODY }, () => this.props.onClose());
  }

  render(): React$Node {
    const { visible, productType, maker } = this.props;
    const { body } = this.state;
    const title = body === SELECT_BODY ? 'シリーズ選択' : 'シリーズを登録';

    return (
      <Modal isOpen={visible} toggle={() => this.onClose()}>
        <ModalHeader toggle={() => this.onClose()}>{title}</ModalHeader>
        {body === SELECT_BODY && (
          <SelectBody
            productType={productType}
            maker={maker}
            onApply={series => this.onApply(series)}
          />
        )}
      </Modal>
    );
  }
}
