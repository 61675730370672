// @flow
import JQueryView, { on } from '../../../../common/views/JQueryView';

declare var $: any;

export default class FormView extends JQueryView {
  image1: any;
  image2: any;
  image3: any;
  image4: any;
  image5: any;

  constructor(
    image1: string,
    image2: string,
    image3: string,
    image4: string,
    image5: string
  ) {
    super('.simple_form');
    this.image1 = image1;
    this.image2 = image2;
    this.image3 = image3;
    this.image4 = image4;
    this.image5 = image5;
    this.render();
  }

  @on('change #reel_image1')
  onChangeImage1(event: any) {
    if (!event.target.files.length) {
      return;
    }

    let file = $(event.target).prop('files')[0];
    let fr = new FileReader();
    fr.onload = () => {
      this.image1 = fr.result;
      this.render();
    };
    fr.readAsDataURL(file);
  }

  @on('change #reel_image2')
  onChangeImage2(event: any) {
    if (!event.target.files.length) {
      return;
    }

    let file = $(event.target).prop('files')[0];
    let fr = new FileReader();
    fr.onload = () => {
      this.image2 = fr.result;
      this.render();
    };
    fr.readAsDataURL(file);
  }

  @on('change #reel_image3')
  onChangeImage3(event: any) {
    if (!event.target.files.length) {
      return;
    }

    let file = $(event.target).prop('files')[0];
    let fr = new FileReader();
    fr.onload = () => {
      this.image3 = fr.result;
      this.render();
    };
    fr.readAsDataURL(file);
  }

  @on('change #reel_image4')
  onChangeImage4(event: any) {
    if (!event.target.files.length) {
      return;
    }

    let file = $(event.target).prop('files')[0];
    let fr = new FileReader();
    fr.onload = () => {
      this.image4 = fr.result;
      this.render();
    };
    fr.readAsDataURL(file);
  }

  @on('change #reel_image5')
  onChangeImage5(event: any) {
    if (!event.target.files.length) {
      return;
    }

    let file = $(event.target).prop('files')[0];
    let fr = new FileReader();
    fr.onload = () => {
      this.image5 = fr.result;
      this.render();
    };
    fr.readAsDataURL(file);
  }

  render() {
    if (this.image1 != '') {
      $('#preview_image1')
        .css('background-image', `url(${this.image1})`)
        .show();
    }

    if (this.image2 != '') {
      $('#preview_image2')
        .css('background-image', `url(${this.image2})`)
        .show();
    }

    if (this.image3 != '') {
      $('#preview_image3')
        .css('background-image', `url(${this.image3})`)
        .show();
    }

    if (this.image4 != '') {
      $('#preview_image4')
        .css('background-image', `url(${this.image4})`)
        .show();
    }

    if (this.image5 != '') {
      $('#preview_image5')
        .css('background-image', `url(${this.image5})`)
        .show();
    }
  }
}
