// @flow
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, Col, Row } from 'reactstrap';
import FieldClient from '../../../models/fields/FieldClient';
import { updateMyFields } from '../../../actions/fields';
import FormComp from './FormComp';
import type { Account } from '../../../models/accounts/Account';
import type { FieldForm } from '../../../models/fields/FieldForm';
import type { Field } from '../../../models/fields/Field';
import { initialValues } from '../../../models/fields/FieldForm';
import type { BaseProps } from '../../../../application';
import {
  displayMessage,
  displayAxiosError,
  displayEntityError,
} from '../../commons/Toast';
import { Spinner } from '../../commons/native-base';
import { csrfToken } from '../../../helpers/fetch';

type Props = BaseProps & {
  account: Account,
  myField: Field,
  myFields: Array<Field>,
  updateMyFields: (myFields: Array<Field>) => void,
};

type State = {
  form: FieldForm,
  submitting: boolean,
};

class NewView extends React.Component<Props, State> {
  fieldClient = new FieldClient();

  constructor(props: Props) {
    super(props);

    this.state = {
      form: initialValues,
      submitting: false,
    };
  }

  onSubmit = () => {
    const { updateMyFields } = this.props;
    const { form } = this.state;

    this.setState({
      submitting: true,
    });
    this.fieldClient
      .create(form, csrfToken())
      .then(response => {
        updateMyFields([response.data, ...this.props.myFields]);
        displayMessage('登録が完了しました');
        this.props.history.goBack();
      })
      .catch(error => {
        if (error.response.status === 422) {
          displayEntityError(error.response.data, {
            fieldnm: 'フィールド名',
            name: 'フィールド名',
            latlng: '正確な位置',
          });
        } else {
          displayAxiosError(error);
        }
        this.setState({
          submitting: false,
        });
      });
  };

  render() {
    const { form, submitting } = this.state;

    if (submitting) {
      return <Spinner />;
    } else {
      return (
        <div>
          <h5>フィールド登録</h5>
          <FormComp
            form={form}
            onChangeForm={form => {
              this.setState({ form: form });
            }}
          />
          <Row>
            <Col sm={3} xs={6}>
              <Button
                block
                color="outline-primary"
                onClick={() => this.props.history.goBack()}
              >
                キャンセル
              </Button>
            </Col>
            <Col sm={3} xs={6}>
              <Button block color="primary" onClick={this.onSubmit}>
                完了
              </Button>
            </Col>
          </Row>
        </div>
      );
    }
  }
}

export default connect(
  state => ({
    account: state.account,
    myField: state.myField,
    myFields: state.myFields,
  }),
  dispatch => bindActionCreators({ updateMyFields }, dispatch)
)(NewView);
