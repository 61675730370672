// @flow
import React, { useState, useEffect } from 'react';
import ResultClient from '../../models/results/ResultClient';
import ResultCard from '../results/ResultCard';
import type { Result } from '../../models/results/Result';

type Props = {
  fishId: number,
  regionId?: number,
};

// page数固定のcomponentです。(1ページ目のコンテンツが取得されます)
function HorizontalResultListRoot(props: Props) {
  // ReactOnRailsの不具合で直接Hooksを使ってるコンポーネントをexportできない
  return <HorizontalResultList {...props} />;
}

function HorizontalResultList({ fishId, regionId }: Props) {
  const [results, setResults] = useState<Array<Result>>([]);
  const resultClient = new ResultClient();

  useEffect(() => {
    function fetchResults() {
      resultClient
        .fetchAll(
          1,
          'created_at',
          null,
          null,
          null,
          null,
          fishId,
          null,
          null,
          regionId
        )
        .then(response => {
          setResults(prevResults =>
            [...prevResults, ...response.data].filter(
              (r, i, self) => self.map(r2 => r2.id).indexOf(r.id) === i
            )
          );
        })
        .catch(error => {
          if (process.env.NODE_ENV === 'production') {
            window.location.replace('/500.html');
          }
          console.warn(error);
        });
    }

    fetchResults();
  }, [fishId]);

  return (
    <>
      {results.length > 0 && (
        <div className="mt-50">
          <div className="carousel">
            <div className="carousel-wrap">
              {results.slice(0, 20).map(result => (
                <ResultCard
                  key={result.id}
                  result={result}
                  className="col-5 col-md-3 carousel-thumb"
                  withComment={false}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default HorizontalResultListRoot;
