// @flow
import React from 'react';
import type { Node } from 'react';
import { FormGroup, Input, Label, Row, Col } from 'reactstrap';
import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';
import type { Account } from '../../../models/accounts/Account';
import type { MyRodForm } from '../../../models/my_rods/MyRodForm';
import type { ImageForm } from '../../../models/images/ImageForm';
import { Content, Text, List, ListItem } from '../../commons/native-base';
import { ListContent, ListActions } from '../../commons/list';
import { SelectMakerModal } from '../../makers/SelectMakerModal';
import { SelectSeriesModal } from '../../series/SelectSeriesModal';
import { SelectProductModal } from '../../products/SelectProductModal';
import { ImageList } from '../../commons/ImageList';

const MOMENT_FORMAT = 'YYYY-MM-DD';

type Props = {
  form: MyRodForm,
  account: Account,
  onChangeForm: (form: MyRodForm) => void,
  displayAxiosError: (error: any) => void,
};

type State = {
  form: MyRodForm,
  visibleMakerModal: boolean,
  visibleSeriesModal: boolean,
  visibleModelModal: boolean,
  startDateFocused: boolean,
  endDateFocused: boolean,
};

export class FormComp extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      form: props.form,
      visibleMakerModal: false,
      visibleSeriesModal: false,
      visibleModelModal: false,
      startDateFocused: false,
      endDateFocused: false,
    };
  }

  // https://flow.org/en/docs/types/utilities/#toc-shape
  // version次第でPartialに移行すること
  static getDerivedStateFromProps(nextProps: Props): $Shape<State> {
    return {
      form: nextProps.form,
    };
  }

  showAlert(text: string) {
    window.confirm(text);
  }

  onClickSeries() {
    if (this.state.form.maker == null) {
      this.showAlert('メーカーを選択してください');
    } else {
      this.setState({ visibleSeriesModal: true });
    }
  }

  onClickModel() {
    if (this.state.form.maker == null || this.state.form.series == null) {
      this.showAlert('メーカーとシリーズを選択して下さい');
    } else {
      this.setState({ visibleModelModal: true });
    }
  }

  onRemoveImage(image: ImageForm, index: number) {
    const { onChangeForm } = this.props;
    const { form } = this.state;

    if (image.id != null) {
      form.images[index]._destroy = true;
    } else {
      form.images.splice(index, 1);
    }

    onChangeForm(form);
  }

  onAppendImage(acceptedFiles: Array<any>) {
    const { onChangeForm } = this.props;
    const { form } = this.state;

    form.images.push({
      id: null,
      image: acceptedFiles[0],
      url: URL.createObjectURL(acceptedFiles[0]),
      _destroy: false,
    });

    onChangeForm(form);
  }

  render(): Node {
    const { account, onChangeForm } = this.props;
    const { form, startDateFocused, endDateFocused } = this.state;

    return (
      <Content>
        <List style={{ backgroundColor: '#fff' }}>
          <ListItem>
            <ListContent
              onClick={() => this.setState({ visibleMakerModal: true })}
              style={{ cursor: 'pointer' }}
            >
              <Row>
                <Col xs={4}>メーカー</Col>
                <Col xs={8}>
                  {form.maker ? (
                    <Text>{form.maker.name}</Text>
                  ) : (
                    <Text className="text-muted">メーカーを選択</Text>
                  )}
                </Col>
              </Row>
            </ListContent>
            <ListActions>
              <i
                onClick={() => {
                  onChangeForm({
                    ...form,
                    maker: null,
                    series: null,
                    product: null,
                  });
                }}
                className="listview-actions-item ion-ios-close-outline"
              />
            </ListActions>
          </ListItem>
          <ListItem>
            <ListContent
              onClick={() => this.onClickSeries()}
              style={{ cursor: 'pointer' }}
            >
              <Row>
                <Col xs={4}>シリーズ</Col>
                <Col xs={8}>
                  {form.series ? (
                    <Text>{form.series.name}</Text>
                  ) : (
                    <Text className="text-muted">シリーズを選択</Text>
                  )}
                </Col>
              </Row>
            </ListContent>
            <ListActions>
              <i
                onClick={() => {
                  onChangeForm({
                    ...form,
                    series: null,
                    product: null,
                  });
                }}
                className="listview-actions-item ion-ios-close-outline"
              />
            </ListActions>
          </ListItem>
          <ListItem>
            <ListContent
              onClick={() => this.onClickModel()}
              style={{ cursor: 'pointer' }}
            >
              <Row>
                <Col xs={4}>モデル</Col>
                <Col xs={8}>
                  {form.product != null && form.product.name != null ? (
                    <Text>{form.product.name}</Text>
                  ) : (
                    <Text className="text-muted">モデルを選択</Text>
                  )}
                </Col>
              </Row>
            </ListContent>
            <ListActions>
              <i
                onClick={() => {
                  onChangeForm({
                    ...form,
                    product: null,
                  });
                }}
                className="listview-actions-item ion-ios-close-outline"
              />
            </ListActions>
          </ListItem>
          <ListItem>
            <ListContent>
              <Row>
                <Col xs={4}>使用開始日</Col>
                <Col xs={8}>
                  <SingleDatePicker
                    id="get_date"
                    date={form.get_date}
                    onDateChange={date => {
                      onChangeForm({
                        ...form,
                        get_date: date,
                      });
                    }}
                    focused={startDateFocused}
                    onFocusChange={({ focused }) =>
                      this.setState({ startDateFocused: focused })
                    }
                    numberOfMonths={1}
                    displayFormat={MOMENT_FORMAT}
                    transitionDuration={0}
                    isOutsideRange={() => false}
                    placeholder="使用開始日を指定"
                  />
                </Col>
              </Row>
            </ListContent>
            <ListActions>
              <i
                onClick={() => {
                  onChangeForm({
                    ...form,
                    get_date: null,
                  });
                }}
                className="listview-actions-item ion-ios-close-outline"
              />
            </ListActions>
          </ListItem>
          <ListItem>
            <ListContent>
              <Row>
                <Col xs={4}>使用終了日</Col>
                <Col xs={8}>
                  <SingleDatePicker
                    id="lost_date"
                    date={form.lost_date}
                    onDateChange={date =>
                      onChangeForm({
                        ...form,
                        lost_date: date,
                      })
                    }
                    focused={endDateFocused}
                    onFocusChange={({ focused }) =>
                      this.setState({ endDateFocused: focused })
                    }
                    numberOfMonths={1}
                    displayFormat={MOMENT_FORMAT}
                    transitionDuration={0}
                    isOutsideRange={() => false}
                    placeholder="使用終了日を指定"
                  />
                </Col>
              </Row>
            </ListContent>
            <ListActions>
              <i
                onClick={() => {
                  onChangeForm({
                    ...form,
                    lost_date: null,
                  });
                }}
                className="listview-actions-item ion-ios-close-outline"
              />
            </ListActions>
          </ListItem>
        </List>

        <FormGroup
          style={{
            borderTop: '1px solid #f0f0f0',
            paddingTop: 16,
            paddingBottom: 12,
          }}
        >
          <Label>メモ</Label>
          <Input
            type="textarea"
            placeholder="このロッドに関するメモを入力"
            value={form.memo}
            onChange={e => {
              this.props.onChangeForm({
                ...form,
                memo: e.target.value,
              });
            }}
          />
        </FormGroup>

        <FormGroup className="mb-20">
          <Label>画像</Label>
          <ImageList
            images={this.state.form.images}
            onRemoveImage={(image, index) => this.onRemoveImage(image, index)}
            onAppendImage={acceptedFiles => this.onAppendImage(acceptedFiles)}
          />
        </FormGroup>

        <SelectMakerModal
          visible={this.state.visibleMakerModal}
          productType="Rod"
          account={account}
          onApply={maker => {
            onChangeForm({
              ...form,
              maker: maker,
              series:
                form.maker && form.maker.id !== maker.id ? null : form.series,
              product:
                form.maker && form.maker.id !== maker.id ? null : form.product,
            });
            this.setState({ visibleMakerModal: false });
          }}
          onClose={() => this.setState({ visibleMakerModal: false })}
        />
        {form.maker != null && (
          <SelectSeriesModal
            visible={this.state.visibleSeriesModal}
            maker={form.maker}
            productType="Rod"
            account={account}
            onApply={series => {
              onChangeForm({
                ...form,
                series: series,
                product:
                  form.series && form.series.id !== series.id
                    ? null
                    : form.product,
              });
              this.setState({ visibleSeriesModal: false });
            }}
            onClose={() => this.setState({ visibleSeriesModal: false })}
          />
        )}
        {form.maker != null && form.series != null && (
          <SelectProductModal
            visible={this.state.visibleModelModal}
            type="Rod"
            maker={form.maker}
            series={form.series}
            account={account}
            onApply={product => {
              onChangeForm({
                ...form,
                product: product,
              });
              this.setState({ visibleModelModal: false });
            }}
            onClose={() => this.setState({ visibleModelModal: false })}
          />
        )}
      </Content>
    );
  }
}
