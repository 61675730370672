// @flow

// google-ad-manager
export default class Gpt {
  slots: any;

  constructor() {
    this.slots = {};
    window.googletag = window.googletag || {};
    window.googletag.cmd = window.googletag.cmd || [];

    window.addEventListener('DOMContentLoaded', this.clearAds.bind(this));
    window.addEventListener('load', this.evaluate.bind(this));
    // this.evaluate();
  }

  evaluate() {
    let meta_targeting = (document.querySelector(
      'meta[name=gpt-targeting]'
    ): any);
    let targeting = {};

    if (meta_targeting) {
      targeting = JSON.parse(meta_targeting.content);
    }

    window.googletag.cmd.push(() => {
      for (let target in targeting) {
        window.googletag.pubads().setTargeting(target, targeting[target]);
      }
    });

    const ads = document.querySelectorAll('.gpt-ad');
    for (let slot of ads) {
      const cachedSlot = this.slots[slot.id];
      if (cachedSlot) {
        this.refreshSlot(cachedSlot);
      } else {
        this.defineSlot(slot);
      }
    }
  }

  defineSlot(slotEl: any) {
    const divId = slotEl.id;
    const path = slotEl.dataset.gptPath;
    const dimensions = JSON.parse(slotEl.dataset.gptDimensions);

    window.googletag.cmd.push(() => {
      const slot = window.googletag
        .defineSlot(path, dimensions, divId)
        .addService(window.googletag.pubads());

      window.googletag.enableServices();
      window.googletag.display(divId);
      this.slots[divId] = slot;
    });
  }

  refreshSlot(slot: any) {
    window.googletag.cmd.push(() => {
      window.googletag.pubads().refresh([slot]);
    });
  }

  clearAds() {
    window.googletag.cmd.push(() => {
      window.googletag.pubads().clearTargeting();
      window.googletag.pubads().clear();
    });
  }
}
