// @flow
import type { Photo } from '../photos/Photo';
import type { User } from '../users/User';
import type { BaseResult } from './BaseResult';

export const WEATHERS = [
  { key: 1, name: '快晴', checked: false },
  { key: 2, name: '晴れ', checked: false },
  { key: 3, name: '曇り', checked: false },
  { key: 4, name: '豪雨', checked: false },
  { key: 5, name: '雨', checked: false },
  { key: 6, name: '小雨', checked: false },
  { key: 7, name: '雪', checked: false },
  { key: 8, name: '雷雨', checked: false },
  { key: 9, name: '霧', checked: false },
];

export const ORDERS = [
  { key: 'created_at', name: '新着' },
  { key: 'caught_at', name: '釣れた日' },
  { key: 'votes_up', name: 'いいね！数' },
];

export type TimeRange = {
  start_at: string,
  end_at: string,
};

export const DEFAULT_TIMES_RANGE: Array<TimeRange & { id: number, name: string }> = [ // eslint-disable-line
  { id: 1, name: '朝マヅメ', start_at: '0400', end_at: '0700' },
  { id: 2, name: '夕マヅメ', start_at: '1700', end_at: '1900' },
  { id: 3, name: '夜間', start_at: '2200', end_at: '0200' },
];

export type Result = BaseResult & {
  id: number,
  user: User,
  thumb_url: string,
  image_url: string,
  square_url: string,
  cached_votes_up: number,
  page_views: ?number,
  voted: ?boolean, // null is loading
  photos: ?Array<Photo>,
  groups: ?Array<any>,
  lure_details: ?string,
  rod_details: ?string,
  reel_details: ?string,
  line_details: ?string,
  fieldnm: ?string,
  fisdatetime: ?string, // @deprecated
  following: ?boolean,
  allow_accept_comments: boolean,
  publish_comments_count: number,
  fishinglog_published_results_count: number,
};
