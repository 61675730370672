// @flow
import React from 'react';

type Props = {};

export default class Loader extends React.PureComponent<Props> {
  render() {
    return (
      <div className="loader">
        <div id="circle_1" className="circle" />
        <div id="circle_2" className="circle" />
        <div id="circle_3" className="circle" />
        <div className="clearfix" />
      </div>
    );
  }
}
