// @flow
import React from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';

type Props = {
  address: string,
  onSearch: (lat: number, lng: number) => void,
  onSearchClose: () => void,
  width?: number,
};

type State = {
  address: string,
};

export default class LocationSearchInput extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      address: props.address,
    };
  }

  onSelect(address: any) {
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => this.props.onSearch(latLng.lat, latLng.lng))
      .catch(error => console.warn(error));
  }

  render() {
    const { width } = this.props;
    const { address } = this.state;

    return (
      <form className="form-inline">
        <PlacesAutocomplete
          value={address}
          onChange={address => this.setState({ address })}
          onSelect={address => this.onSelect(address)}
          debounce={1000}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div className="input-group">
              <input
                {...getInputProps({
                  className:
                    'form-control border-right-0 location-search-input',
                  placeholder: '住所・地名など',
                })}
                style={{ width: width }}
              />
              <span className="input-group-append">
                {address && address != '' ? (
                  <div
                    className="input-group-text bg-transparent"
                    onClick={() => this.props.onSearchClose()}
                  >
                    <i className="fa fa-close" />
                  </div>
                ) : (
                  <div className="input-group-text bg-transparent">
                    <i className="fa fa-location-arrow" />
                  </div>
                )}
              </span>
              <div className="autocomplete-dropdown-container">
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion, index) => {
                  const className = suggestion.active
                    ? 'suggestion-item active'
                    : 'suggestion-item';
                  const style = suggestion.active
                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                      key={index}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      </form>
    );
  }
}
