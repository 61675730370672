// @flow
import React from 'react';
import { Row, Col } from 'reactstrap';
import Voters from './Voters';
import ResultClient from '../../models/results/ResultClient';
import { displayAxiosError, displayMessage } from '../commons/messages';
import type { Result } from '../../models/results/Result';
import ShareModal from './ShareModal';

type Props = {
  result: Result,
  voteAccessible: boolean,
  isSignedIn: boolean,
  token: string,
  onToggleVoted: (voted: boolean, cached_votes_up: number) => void,
  onClickComments: (focusedInput: boolean) => void,
};

type State = {
  voting: boolean,
  commentsVisible: boolean,
  shareModalVisible: boolean,
};

export default class ResultSocialComp extends React.Component<Props, State> {
  resultClient = new ResultClient();

  constructor(props: Props) {
    super(props);

    this.state = {
      voting: false,
      commentsVisible: false,
      shareModalVisible: false,
    };
  }

  upVote() {
    const { result } = this.props;

    this.setState({ voting: true });

    this.resultClient
      .upVote(result.id, this.props.token)
      .then(response => {
        this.props.onToggleVoted(
          response.data.voted,
          result.cached_votes_up + 1
        );
        displayMessage('いいね！しました');
      })
      .catch(error => {
        if (error.response.status === 400) {
          displayMessage(error.response.data.message);
        } else {
          displayAxiosError(error);
        }
      })
      .finally(() => this.setState({ voting: false }));
  }

  downVote() {
    const { result } = this.props;

    this.setState({ voting: true });

    this.resultClient
      .downVote(result.id, this.props.token)
      .then(response => {
        this.props.onToggleVoted(
          response.data.voted,
          response.data.cached_votes_up
        );
        displayMessage('いいね!を取り消しました');
      })
      .catch(error => {
        if (error.response.status === 400) {
          displayMessage(error.response.data.message);
        } else {
          displayAxiosError(error);
        }
      })
      .finally(() => this.setState({ voting: false }));
  }

  render() {
    const { result } = this.props;
    const { voting, shareModalVisible } = this.state;
    const existsVotes =
      result.cached_votes_up != null && result.cached_votes_up > 0;
    const existsComments =
      result.publish_comments_count != null &&
      result.publish_comments_count > 0;

    return (
      <div>
        {(existsVotes || existsComments) && (
          <div className="d-flex justify-content-between border-bottom pt-10 pb-10">
            <div>
              {existsVotes && (
                <div className="d-flex flex-row align-items-center">
                  <span className="fa-stack mr-2">
                    <i className="fa fa-circle fa-stack-2x text-primary" />
                    <i className="fa fa-thumbs-o-up fa-stack-1x text-light" />
                  </span>
                  <Voters result={result} />
                </div>
              )}
            </div>
            {existsComments && (
              <div
                className="comments-link float-right"
                onClick={() => this.props.onClickComments(false)}
              >
                {`コメント${result.publish_comments_count}件`}
              </div>
            )}
          </div>
        )}
        {(existsVotes || existsComments) && <div />}
        <Row className="pt-10 pb-10">
          <Col className={result.voted ? 'text-primary' : ''}>
            <div className="d-flex flex-row align-items-center justify-content-center like">
              {!voting && !result.voted && (
                <div id={`like_${result.id}`} onClick={() => this.upVote()}>
                  <i className="fa fa-thumbs-o-up mr-1" />
                  いいね！
                </div>
              )}
              {!voting && result.voted && (
                <div onClick={() => this.downVote()}>
                  <i className="fa fa-thumbs-up mr-1" />
                  いいね！
                </div>
              )}
              {voting && (
                <div className="text-primary">
                  <i className="fa fa-spinner fa-spin" />
                </div>
              )}
            </div>
          </Col>
          {result.allow_accept_comments && (
            <Col>
              <div
                id={`comment_${result.id}`}
                className="d-flex flex-row align-items-center justify-content-center comments-link"
                onClick={() => this.props.onClickComments(true)}
              >
                <i className="fa fa-comment-o mr-1" />
                コメントする
              </div>
            </Col>
          )}
          <Col>
            <div
              className="d-flex flex-row align-items-center justify-content-center share-link"
              onClick={() => this.setState({ shareModalVisible: true })}
            >
              <i className="fa fa-share-alt mr-1" />
              シェア
            </div>
          </Col>
        </Row>
        <ShareModal
          result={result}
          visible={shareModalVisible}
          onClose={() => this.setState({ shareModalVisible: false })}
        />
      </div>
    );
  }
}
