// @flow
import * as React from 'react';
import InfiniteScroll from 'react-infinite-scroller';

type Props = {
  data: Array<any>,
  hasMore: boolean,
  ListFooterComponent?: () => React.Node,
  onEndReached?: () => void,
  renderItem: (item: any) => React.Node,
};

export class FlatList extends React.Component<Props> {
  static defaultProps = {
    hasMore: false,
  };

  render() {
    const {
      data,
      hasMore,
      ListFooterComponent,
      onEndReached,
      renderItem,
    } = this.props;

    if (onEndReached != null) {
      return (
        <div className="listview bordered">
          <InfiniteScroll
            pageStart={0}
            hasMore={hasMore}
            loadMore={onEndReached}
            loader={ListFooterComponent}
          >
            {data.map(item => renderItem(item))}
          </InfiniteScroll>
        </div>
      );
    } else {
      return (
        <div>
          <div className="listview bordered">
            {data.map(item => renderItem(item))}
          </div>
          {ListFooterComponent != null && <ListFooterComponent />}
        </div>
      );
    }
  }
}
