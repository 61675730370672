// @flow
import React, { useState, useEffect } from 'react';
import ResultClient from '../../models/results/ResultClient';
import ResultCard from '../results/ResultCard';
import type { Result } from '../../models/results/Result';

type Props = {
  areaId?: number,
  fishId?: number,
  prefectureId?: number,
};

// page数固定のcomponentです。(1ページ目のコンテンツが取得されます)
function HorizontalPickupResultListRoot(props: Props) {
  // ReactOnRailsの不具合で直接Hooksを使ってるコンポーネントをexportできない
  return <HorizontalPickupResultList {...props} />;
}

function HorizontalPickupResultList({ areaId, fishId, prefectureId }: Props) {
  const [results, setResults] = useState<Array<Result>>([]);

  useEffect(() => {
    function fetchResults() {
      const resultClient = new ResultClient();

      resultClient
        .fetchAll(
          1,
          'caught_at',
          null,
          null,
          null,
          null,
          fishId,
          areaId,
          prefectureId
        )
        .then(response => {
          setResults(response.data.sort(() => Math.random() - 0.5));
        })
        .catch(error => {
          if (process.env.NODE_ENV === 'production') {
            window.location.replace('/500.html');
          }
          console.warn(error);
        });
    }
    fetchResults();
  }, [areaId, fishId, prefectureId]);

  return (
    <>
      {results.slice(0, 20).length > 0 && (
        <div className="mt-50">
          <div className="carousel">
            <div className="carousel-wrap">
              {results.map(result => (
                <ResultCard
                  key={result.id}
                  result={result}
                  className="col-5 col-md-3 carousel-thumb"
                  withComment={false}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default HorizontalPickupResultListRoot;
