// @flow
import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import type { Review } from '../../../models/products/reviews/Review';
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
} from 'react-share';

type Props = {
  review: Review,
  visible: boolean,
  onClose: () => void,
};

export default function ShareModal(props: Props) {
  const { review, visible } = props;
  const url = `https://anglers.jp/tackles/${review.product.id}/reviews/${review.id}`;
  const title = `${review.product.full_name}のレビュー`;
  const tags: Array<string> = [];

  tags.push('#アングラーズ');
  tags.push('#ANGLERS');
  // TODO

  return (
    <Modal isOpen={visible} toggle={() => props.onClose()}>
      <ModalHeader toggle={() => props.onClose()}>シェア</ModalHeader>
      <ModalBody>
        <div className="d-flex">
          <FacebookShareButton url={url} quote={title} className="mr-2">
            <FacebookIcon size={32} round={true} />
          </FacebookShareButton>
          <TwitterShareButton url={url} title={title} hashtags={tags}>
            <TwitterIcon size={32} round={true} />
          </TwitterShareButton>
        </div>
      </ModalBody>
    </Modal>
  );
}
