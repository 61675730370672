// @flow
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { ListItem } from '../../commons/native-base';
import { updateMyField } from '../../../actions/fields';
import VenueHelper from '../../../helpers/VenueHelper';
import ResultHelper from '../../../helpers/ResultHelper';
import WeatherHelper from '../../../helpers/WeatherHelper';

import type { RouterHistory } from 'react-router-dom';
import type { Field } from '../../../models/fields/Field';

type Props = {
  history: RouterHistory,
  field: Field,
  updateMyField: Field => void,
};

class FieldListItem extends React.PureComponent<Props> {
  renderField(field: Field) {
    if (VenueHelper.isLand(field.venue)) {
      const hosuiryo = VenueHelper.hosuiryo(field.venue);

      return (
        <div className="d-flex">
          <div className="mr-20">
            <div>観測所</div>
            <div>水位</div>
            {!!hosuiryo && <div>放水量</div>}
          </div>
          <div>
            <div>{field.venue.name}</div>
            <div>{VenueHelper.suii(field.venue)}</div>
            {!!hosuiryo && <div>{hosuiryo}</div>}
          </div>
        </div>
      );
    } else if (VenueHelper.isSea(field.venue)) {
      return (
        <div className="d-flex">
          <div className="mr-20">
            <div>観測所</div>
            <div>潮位</div>
            <div>潮名</div>
          </div>
          <div>
            <div>{field.venue.name}</div>
            <div>{field.venue.tide}</div>
            <div>{field.venue.shionm}</div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="d-flex">
          <div className="mr-20">
            <div>観測所</div>
          </div>
          <div>
            <div>なし</div>
          </div>
        </div>
      );
    }
  }

  onClickField(field: Field) {
    this.props.history.push(`/my/fields/${field.id}`, { field: field });
  }

  render() {
    const { field } = this.props;

    return (
      <ListItem
        style={{ cursor: 'pointer' }}
        onClick={() => this.onClickField(field)}
      >
        <div className="listview-content">
          <div className="media">
            <div className="mr-3">
              <i
                className={VenueHelper.icon(field.venue)}
                style={{
                  color: VenueHelper.iconColor(field.venue),
                  fontSize: 24,
                }}
              />
            </div>
            <div className="media-body">
              <div className="d-flex justify-content-between">
                <div>
                  <h5 className="mt-0">{field.name}</h5>
                  {this.renderField(field)}
                </div>
                <div className="d-flex">
                  <div>
                    <i
                      className={WeatherHelper.icon(field.weather)}
                      style={{
                        color: WeatherHelper.color(field.weather),
                        fontSize: 24,
                      }}
                    />
                  </div>
                  <div>
                    <div>{ResultHelper.tempc(field)}</div>
                    <div>{ResultHelper.winddir16pointjp(field)}</div>
                    <div>{ResultHelper.windspeedmps(field)}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ListItem>
    );
  }
}

export default connect(
  state => ({
    account: state.account,
  }),
  dispatch => bindActionCreators({ updateMyField }, dispatch)
)(FieldListItem);
