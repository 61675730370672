// @flow
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { viewPc, viewSp } from '../../lib/Mixins';
import HeaderLogo from '../../assets/images/views/meisters/header_logo.png';
import FacebookIcon from '../../assets/images/views/meisters/facebook_icon.png';
import TwitterIcon from '../../assets/images/views/meisters/twitter_icon.png';
import HeaderMenuIcon from '../../assets/images/views/meisters/header_menu.png';
import HeaderNavigationSp from './HeaderNavigationSp';

const meistersPath = '/meisters';
const meisterMembersPath = '/meisters/members';

function HeaderRoot() {
  // ReactOnRailsの不具合で直接Hooksを使ってるコンポーネントをexportできない
  return <Header />;
}

function Header() {
  const [open, setOpen] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      // react_on_railsが無駄なdivで囲う仕様なので、parentNodeに対してスタイルを適用する必要がある
      const parentNode = containerRef.current.parentNode;
      parentNode.style.position = 'sticky';
      parentNode.style.top = '50px';
      parentNode.style.zIndex = '500';
    }
  }, []);

  return (
    <Container open={open} ref={containerRef}>
      <MeisterContainer>
        <MeisterLogoContainer href={meistersPath}>
          <img src={HeaderLogo} alt="ヘッダーアイコン" />
        </MeisterLogoContainer>

        <NavIconSP
          open={open}
          src={HeaderMenuIcon}
          onClick={() => setOpen(!open)}
        />

        <NavContainerPC>
          <NavLinkContainer>
            <NavLink
              href={meistersPath}
              active={meistersPath === window.location.pathname}
            >
              トップ
            </NavLink>
            <NavLink
              href={meisterMembersPath}
              active={meisterMembersPath === window.location.pathname}
            >
              アングラーズマイスター 一覧
            </NavLink>
          </NavLinkContainer>
          <ShareIconContainer>
            <ShareIcon
              href={encodeURI(
                `https://twitter.com/intent/tweet?text=${document.title}&url=${window.location.href}&hashtags=ANGLERS_jp`
              )}
              target="_blank"
              rel="nofollow noreferrer"
            >
              <img src={TwitterIcon} alt="ツイッターシェアアイコン" />
            </ShareIcon>
            <ShareIcon
              href={encodeURI(
                `https://www.facebook.com/sharer/sharer.php.php?&url=${window.location.href}`
              )}
              target="_blank"
              rel="nofollow noreferrer"
            >
              <img src={FacebookIcon} alt="フェイスブックシェアアイコン" />
            </ShareIcon>
          </ShareIconContainer>
        </NavContainerPC>
      </MeisterContainer>

      <HeaderNavigationSp open={open} />
    </Container>
  );
}

const meisterHeaderHeightPC = 70;
const meisterHeaderHeightSP = 60;
const Container = styled.div`
  background: #202021;
  color: white;
  height: ${`${meisterHeaderHeightPC}px`};
  width: 100%;

  a {
    color: white;
    text-decoration: none;
  }

  ${viewSp} {
    height: ${`${meisterHeaderHeightSP}px`};
  }

  ${viewPc} {
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  }
`;

const MeisterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  height: 100%;
  max-width: 943px;
  width: 100%;

  ${viewSp} {
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    padding: 0 16px;
  }
`;

const MeisterLogoContainer = styled.a`
  display: flex;
  align-items: center;

  img {
    margin-right: 10px;
    height: 48px;

    ${viewSp} {
      height: 36px;
    }
  }
`;

const NavIconSP = styled.img`
  width: 16px;
  transform: ${props => (props.open ? 'rotate(180deg)' : 'rotate(0deg)')};

  ${viewPc} {
    display: none;
  }
`;

const NavContainerPC = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${viewSp} {
    display: none;
  }
`;

const ShareIconContainer = styled.div`
  margin-left: 102px;
  display: flex;
  align-items: center;
`;

const ShareIcon = styled.a`
  margin-left: 18px;
  height: 27px;
  width: 27px;

  &:first-child {
    margin-left: 0;
  }

  img {
    height: 100%;
    width: 100%;
  }
`;

const NavLinkContainer = styled.div`
  display: flex;
  align-items: center;
`;

const NavLink = styled.a`
  margin-left: 48px;
  font-size: 14px;
  font-weight: bold;
  position: relative;
  line-height: 1;

  &:first-child {
    margin-left: 0;
  }

  &::before {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    border: 1px solid white;
    width: 100%;
    display: ${props => (props.active ? 'initial' : 'none')};
  }
`;

export default HeaderRoot;
