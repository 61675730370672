// @flow
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Col, Row } from 'reactstrap';
import moment from 'moment';
import type { BaseProps } from '../../../../application';
import type { Account } from '../../../models/accounts/Account';
import type { MyRod } from '../../../models/my_rods/MyRod';
import type { MyRodForm } from '../../../models/my_rods/MyRodForm';
import { ActivityIndicator } from '../../commons/react-native';
import { View } from '../../commons/native-base';
import {
  displayMessage,
  displayAxiosError,
  displayEntityError,
} from '../../commons/Toast';
import { updateMyRods } from '../../../actions/myRods';
import MyRodClient from '../../../models/my_rods/MyRodClient';
import { FormComp } from './FormComp';
import { csrfToken } from '../../../helpers/fetch';

type Props = BaseProps & {
  account: Account,
  myRods: Array<MyRod>,
  updateMyRods: (myRods: Array<MyRod>) => void,
};

type State = {
  form: MyRodForm,
  loading: boolean,
};

class NewView extends React.Component<Props, State> {
  rodClient = new MyRodClient();

  constructor(props: Props) {
    super(props);

    this.state = {
      form: {
        name: null,
        get_date: moment(),
        lost_date: null,
        maker: null,
        series: null,
        product: null,
        memo: '',
        images: [],
      },
      loading: false,
    };
  }

  onSubmit() {
    const { history, account, myRods, updateMyRods } = this.props;
    const { form } = this.state;

    this.setState({ loading: true });
    this.rodClient
      .create(form, account, csrfToken())
      .then(response => {
        displayMessage('登録が完了しました');
        updateMyRods([...myRods, response.data]);
        history.goBack();
      })
      .catch(error => {
        if (error.response.status === 422) {
          displayEntityError(error.response.data, {
            maker: 'メーカー',
            series: 'シリーズ',
            product_rod: 'モデル',
            rodnm: 'ロッド名',
          });
        } else {
          displayAxiosError(error);
        }
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  render() {
    const { account } = this.props;
    const { form, loading } = this.state;

    if (loading) {
      return (
        <View
          style={{
            paddingVertical: 20,
            borderTopWidth: 1,
            borderColor: '#CED0CE',
          }}
        >
          <ActivityIndicator animating size="large" />
        </View>
      );
    } else {
      return (
        <React.Fragment>
          <div style={{ marginBottom: 30 }}>
            <FormComp
              form={form}
              account={account}
              onChangeForm={form => this.setState({ form: form })}
              displayAxiosError={error => displayAxiosError(error)}
            />
          </div>
          <Row>
            <Col sm={3} xs={6}>
              <Button
                block
                color="outline-primary"
                onClick={() => this.props.history.goBack()}
              >
                キャンセル
              </Button>
            </Col>
            <Col sm={3} xs={6}>
              <Button block color="primary" onClick={() => this.onSubmit()}>
                完了
              </Button>
            </Col>
          </Row>
        </React.Fragment>
      );
    }
  }
}

export default connect(
  state => ({
    account: state.account,
    myRods: state.myRods,
  }),
  dispatch => bindActionCreators({ updateMyRods }, dispatch)
)(NewView);
