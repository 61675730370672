// @flow
import React from 'react';
import { ModalBody } from 'reactstrap';
import type { Maker } from '../../models/makers/Maker';
import type { Series } from '../../models/series/Series';
import { hankana2zenkana, hiraganaToKatagana } from '../../lib/StringUtils';
import { FlatList } from '../commons/react-native';
import { Text, Body, ListItem } from '../commons/native-base';
import SearchBar from '../commons/SearchBar';
import SeriesClient from '../../models/series/SeriesClient';
import { displayAxiosError } from '../commons/messages';
import { SelectFooter } from './SelectFooter';

// const Immutable = require('immutable');

type Props = {
  maker: ?Maker,
  productType: string,
  onApply: (series: Series) => void,
};

type State = {
  series: Array<Series>,
  filteredSeries: Array<Series>,
  isLoading: boolean,
  typingTimeout: any,
};

export class SelectBody extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      series: [],
      filteredSeries: [],
      isLoading: true,
      typingTimeout: 0,
    };
  }

  // shouldComponentUpdate(nextProps: Props, nextState: State) {
  //   const diffProps = this.props.productType === nextProps.productType;

  //   const diffState =
  //     this.state.isLoading === nextState.isLoading &&
  //     this.state.typingTimeout === nextState.typingTimeout &&
  //     Immutable.is(
  //       Immutable.fromJS(this.state.makers),
  //       Immutable.fromJS(nextState.makers)
  //     ) &&
  //     Immutable.is(
  //       Immutable.fromJS(this.state.filteredMakers),
  //       Immutable.fromJS(nextState.filteredMakers)
  //     );

  //   return !(diffProps && diffState);
  // }

  componentDidMount() {
    const { maker, productType } = this.props;

    if (maker != null) {
      new SeriesClient()
        .fetchAllByMaker(maker.id, productType)
        .then(response => {
          this.setState({
            series: response.data,
            filteredSeries: response.data,
            isLoading: false,
          });
        })
        .catch(error => {
          this.setState({ isLoading: false });
          displayAxiosError(error);
        });
    }
  }

  onChangeFilter(text: string) {
    if (this.state.typingTimeout > 0) {
      clearTimeout(this.state.typingTimeout);
    }

    const value = hiraganaToKatagana(text);

    const filteredSeries = this.state.series.filter(m => {
      const name_jp = hankana2zenkana(hiraganaToKatagana(m.name_jp || ''));
      return (
        m.name_en.toLowerCase().indexOf(value.toLowerCase()) >= 0 ||
        m.name_jp.toLowerCase().indexOf(value.toLowerCase()) >= 0 ||
        name_jp.toLowerCase().indexOf(hankana2zenkana(value)) >= 0
      );
    });

    this.setState({
      typingTimeout: setTimeout(() => {
        this.setState({
          filteredSeries: filteredSeries,
        });
      }, 1000),
    });
  }

  render(): React$Node {
    const { onApply } = this.props;
    const { filteredSeries, isLoading } = this.state;

    return (
      <ModalBody className="mb-60">
        <SearchBar
          onChangeText={text => this.onChangeFilter(text)}
          placeholder="キーワードで絞り込み"
          style={{ marginBottom: 20 }}
        />
        <FlatList
          keyboardShouldPersistTaps={'handled'}
          data={filteredSeries}
          renderItem={item => (
            <ListItem
              key={item.id}
              style={{ backgroundColor: '#fff', cursor: 'pointer' }}
              onClick={() => onApply(item)}
            >
              <Body>
                <Text>{item.name}</Text>
              </Body>
            </ListItem>
          )}
          keyExtractor={item => item.id.toString()}
          ListFooterComponent={() => (
            <SelectFooter
              filteredSeries={filteredSeries}
              isLoading={isLoading}
              makerName={this.props.maker ? this.props.maker.name : ''}
            />
          )}
        />
      </ModalBody>
    );
  }
}
