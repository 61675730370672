// @flow
import React from 'react';
import { ModalBody } from 'reactstrap';
import type { Maker } from '../../models/makers/Maker';
import { hankana2zenkana, hiraganaToKatagana } from '../../lib/StringUtils';
import { ActivityIndicator, FlatList, View } from '../commons/react-native';
import { Text, Left, Body, ListItem } from '../commons/native-base';
import SearchBar from '../commons/SearchBar';
import MakerClient from '../../models/makers/MakerClient';
import { displayAxiosError } from '../commons/messages';

// const Immutable = require('immutable');

type Props = {
  productType: string,
  onApply: (maker: Maker) => void,
  onAddMaker: () => void,
};

type State = {
  makers: Array<Maker>,
  filteredMakers: Array<Maker>,
  isLoading: boolean,
  typingTimeout: any,
  searchFields: {
    [makerId: number]: {
      nameEn: string,
      nameJp: string,
      metaKeywords: string,
      katakanaNameJp: string,
      katakanaMetaKeywords: string,
    },
  },
};

export class SelectBody extends React.Component<Props, State> {
  makerClient = new MakerClient();

  constructor(props: Props) {
    super(props);

    this.state = {
      makers: [],
      filteredMakers: [],
      isLoading: true,
      typingTimeout: 0,
      searchFields: {},
    };
  }

  // shouldComponentUpdate(nextProps: Props, nextState: State) {
  //   const diffProps = this.props.productType === nextProps.productType;

  //   const diffState =
  //     this.state.isLoading === nextState.isLoading &&
  //     this.state.typingTimeout === nextState.typingTimeout &&
  //     Immutable.is(
  //       Immutable.fromJS(this.state.makers),
  //       Immutable.fromJS(nextState.makers)
  //     ) &&
  //     Immutable.is(
  //       Immutable.fromJS(this.state.filteredMakers),
  //       Immutable.fromJS(nextState.filteredMakers)
  //     );

  //   return !(diffProps && diffState);
  // }

  componentDidMount() {
    const { productType } = this.props;

    this.makerClient
      .fetchAll(productType)
      .then(response => {
        this.setState({
          makers: response.data,
          filteredMakers: response.data,
          isLoading: false,
          searchFields: this.buildSearchFields(response.data),
        });
      })
      .catch(error => {
        this.setState({ isLoading: false });
        displayAxiosError(error);
      });
  }

  buildSearchFields(makers: Array<Maker>) {
    return makers.reduce((acc, m) => {
      acc[m.id] = {
        nameEn: m.name_en.toLowerCase(),
        nameJp: m.name_jp.toLowerCase(),
        metaKeywords: m.meta_keywords.toLowerCase(),
        katakanaNameJp: hankana2zenkana(
          hiraganaToKatagana(m.name_jp || '')
        ).toLowerCase(),
        katakanaMetaKeywords: hankana2zenkana(
          hiraganaToKatagana(m.meta_keywords)
        ).toLowerCase(),
      };
      return acc;
    }, {});
  }

  onChangeFilter(text: string) {
    if (this.state.typingTimeout > 0) {
      clearTimeout(this.state.typingTimeout);
    }

    const value = hiraganaToKatagana(text);

    const filteredMakers = this.state.makers.filter(m => {
      const searchField = this.state.searchFields[m.id];
      return (
        searchField.nameEn.indexOf(value.toLowerCase()) >= 0 ||
        searchField.nameJp.indexOf(value.toLowerCase()) >= 0 ||
        searchField.metaKeywords.indexOf(value.toLowerCase()) >= 0 ||
        searchField.katakanaNameJp.indexOf(hankana2zenkana(value)) >= 0 ||
        searchField.katakanaMetaKeywords.indexOf(hankana2zenkana(value)) >= 0
      );
    });

    this.setState({
      typingTimeout: setTimeout(() => {
        this.setState({
          filteredMakers: filteredMakers,
        });
      }, 1000),
    });
  }

  listFooter = () => {
    const { filteredMakers, isLoading } = this.state;

    if (isLoading) {
      return (
        <div
          style={{
            paddingVertical: 20,
            borderTopWidth: 1,
            borderColor: '#CED0CE',
          }}
        >
          <ActivityIndicator animating size="large" />
        </div>
      );
    } else {
      if (filteredMakers.length === 0) {
        return (
          <View
            className="mb-60"
            style={{
              flex: 1,
              alignItems: 'center',
              paddingVertical: 20,
            }}
          >
            <div className="mb-4">メーカーは見つかりませんでした</div>
            <a href="/contact/maker">メーカー追加のお問い合わせはこちら</a>
            {/*
              <Button outline block color="primary" onClick={onAddMaker}>
                メーカーを登録する
              </Button>
            */}
          </View>
        );
      } else {
        return (
          <div className="mt-4 mb-60">
            <a href="/contact/maker">メーカー追加のお問い合わせはこちら</a>
            {/*
              <Button outline block color="primary" onClick={onAddMaker}>
                メーカーを登録する
              </Button>
            */}
          </div>
        );
      }
    }
  };

  render() {
    const { filteredMakers } = this.state;

    return (
      <ModalBody>
        <SearchBar
          onChangeText={text => this.onChangeFilter(text)}
          placeholder="キーワードで絞り込み"
          style={{ marginBottom: 20 }}
        />
        <FlatList
          keyboardShouldPersistTaps={'handled'}
          data={filteredMakers}
          renderItem={item => (
            <ListItem
              thumbnail
              key={item.id}
              style={{ backgroundColor: '#fff', cursor: 'pointer' }}
              onClick={() => this.props.onApply(item)}
            >
              <Left>
                <img
                  src={item.thumb_url}
                  width="48"
                  alt={item.name}
                  onError={e => (e.target.src = 'images/noimage.png')}
                />
              </Left>
              <Body
                style={{
                  display: 'flex',
                  'align-items': 'center',
                  'padding-left': '1rem',
                }}
              >
                <Text>{item.name}</Text>
              </Body>
            </ListItem>
          )}
          keyExtractor={item => item.id.toString()}
          ListFooterComponent={this.listFooter}
        />
      </ModalBody>
    );
  }
}
