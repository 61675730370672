// @flow
import AxiosClient from '../AxiosClient';
import type { Prefecture } from './Prefecture';
import type { Axios, AxiosPromise } from 'axios';

export default class PrefectureClient extends AxiosClient {
  fetchAll(keyword: ?string): AxiosPromise<Array<Prefecture>> {
    return this.buildPrefectureClient().get('/api/v2/prefectures.json', {
      params: {
        keyword: keyword,
      },
    });
  }

  // private methods

  buildPrefectureClient(): Axios {
    const client = this.buildClient();

    client.interceptors.response.use(response => {
      return response;
    });

    return client;
  }
}
