// @flow
import * as React from 'react';

type Props = {
  children: React.Node,
};

export function List({ children, ...props }: Props) {
  return (
    <div className="listview bordered" {...props}>
      {children}
    </div>
  );
}
