// @flow
import account from './account';
import position from './position';
import myFields from './myFields';
import myField from './myField';
import myRods from './myRods';
import myRod from './myRod';
import myReels from './myReels';
import myReel from './myReel';
import myLures from './myLures';
import myLure from './myLure';
import railsContext from './railsContext';

export default {
  account,
  position,
  myFields,
  myField,
  myRods,
  myRod,
  myReels,
  myReel,
  myLures,
  myLure,
  railsContext,
};
