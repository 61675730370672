// @flow
import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import * as L from 'leaflet';
import { GestureHandling } from 'leaflet-gesture-handling';
import { viewSp } from '../../lib/Mixins';

import 'leaflet/dist/leaflet.css';
import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css';

type Props = {
  latitude: number,
  longitude: number,
  zoom_level: number,
  radius: number,
  height: string,
};

function LeafletRoot(props: Props) {
  // ReactOnRailsの不具合で直接Hooksを使ってるコンポーネントをexportできない
  return <Leaflet {...props} />;
}

function Leaflet({ latitude, longitude, zoom_level, radius, height }: Props) {
  const map = useRef(null);

  useEffect(() => {
    L.Map.addInitHook('addHandler', 'gestureHandling', GestureHandling);

    const view_map = L.map(map.current, {
      center: [latitude, longitude],
      zoom: zoom_level,
      scrollWheelZoom: false,
      gestureHandling: true,
      gestureHandlingOptions: {
        duration: 3000,
        text: {
          touch: '地図を移動させるには指２本で操作します',
          scroll:
            '地図をズームするには、Ctrlキーを押しながらスクロールしてください',
          scrollMac:
            '地図をズームするには、\u2318キーを押しながらスクロールしてください',
        },
      },
    });

    const tileLayer = L.tileLayer(
      'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      {
        attribution:
          '© <a href="http://osm.org/copyright">OpenStreetMap</a> contributors, <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>',
      }
    );
    tileLayer.addTo(view_map);

    L.circle([latitude, longitude], {
      color: 'rgba(237, 101, 100, 1)',
      fillColor: 'rgba(237, 101, 100, 1)',
      fillOpacity: 0.5,
      radius: radius,
    }).addTo(view_map);
  }, []);

  return <Map ref={map} height={height} />;
}

// leafletの表示にはpx指定したheightが必要なので、高さを変更したい場合はpropsで上書きしてる
// 親要素に高さを指定して子要素のheightを100%にするのでは期待した結果にならなかった
const Map = styled.div`
  height: ${props => (props.height ? props.height : '360px')};
  width: 100%;

  ${viewSp} {
    height: ${props => (props.height ? props.height : '220px')};
  }
`;

export default LeafletRoot;
