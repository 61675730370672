// @flow
import AxiosClient from '../AxiosClient';
import type { AxiosPromise } from 'axios';
import type { Account } from '../accounts/Account';
import type { Series, SeriesForm } from './Series';

export default class SeriesClient extends AxiosClient {
  fetchAllByMaker(
    makerId: number,
    productType: ?string
  ): AxiosPromise<Array<Series>> {
    return this.buildClient().get(
      `/api/v2/makers/${makerId}/series/index_without_others.json`,
      {
        params: {
          product_type: productType,
        },
      }
    );
  }

  create(
    form: SeriesForm,
    account: Account,
    token: string,
    productType: ?string
  ): AxiosPromise<Series> {
    return this.buildTokenAuthClient(account, token).post(
      `/makers/${form.makerid}/series.json`,
      {
        series: { ...form },
        product_type: productType,
      }
    );
  }
}
