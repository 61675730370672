// @flow
import React from 'react';
import styled from 'styled-components';
import { ToastContainer, toast } from 'react-toastify';

const ToastContainerStyle = styled.div`
  position: absolute;
`;

export function Toast() {
  return (
    <ToastContainerStyle>
      <ToastContainer
        position="top-right"
        newestOnTop
        autoClose={2500}
        hideProgressBar
      />
    </ToastContainerStyle>
  );
}

export function displayMessage(message: string) {
  toast.success(message, {
    bodyClassName: 'toast-body',
  });
}

export function displayError(message: any) {
  if (typeof message === 'string') {
    toast.error(message, {
      bodyClassName: 'toast-body',
    });
  } else {
    console.warn(message);
  }
}

export function displayAxiosError(error: any) {
  if (error.response) {
    // if (error.response.status === 426) {
    //   this.props.screenProps.dispatch(
    //     updateInitState({
    //       ...this.props.screenProps.initState,
    //       forceUpdate: true,
    //     })
    //   );
    //   return;
    // }

    const maybeNull = error.hasOwnProperty('message')
      ? error.message
      : '不明なエラーが発生しました';

    displayError(
      error.response.data && error.response.data.hasOwnProperty('message')
        ? error.response.data.message
        : maybeNull
    );

    return;
  } else if (error.request) {
    console.log(error.request);
  } else {
    console.log('Error', error.message);
  }

  displayError(
    error.hasOwnProperty('message')
      ? error.message
      : '不明なエラーが発生しました'
  );
}

// 422のエラーのみ使用可能
// ただし、プロフィール画面など新形式のフォームでの使用はせず、あくまでの旧形式のフォームでの使用とする
// 例えば旧形式のフォームは、ロッド・リール・ルアー・フィールドなど。
//
// Usage.
// error: error.response.data
// keyValue: {xxxx: '〇〇名', yyyy: 'ＸＸ名'}
export function displayEntityError(errors: any, keyValue: any) {
  if (!errors) {
    return;
  }

  for (let column in keyValue) {
    if (errors.hasOwnProperty(column)) {
      displayError(keyValue[column] + errors[column][0]);
      return;
    }
  }

  displayError(errors[Object.keys(errors)[0]][0]);
}
