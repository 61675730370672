// @flow
import React from 'react';
import Dropzone from 'react-dropzone';
import type { ImageForm } from '../../models/images/ImageForm';

type Props = {
  images: Array<ImageForm>,
  onRemoveImage: (image: ImageForm, index: number) => void,
  onAppendImage: (acceptedFiles: Array<any>) => void,
};

export function ImageList({ images, onRemoveImage, onAppendImage }: Props) {
  return (
    <div
      className="d-flex justify-content-start"
      style={{
        backgroundColor: '#fff',
        paddingBottom: 16,
      }}
    >
      {images.map((image, i) => {
        if (!image._destroy) {
          return (
            <div key={i} style={{ position: 'relative' }}>
              <i
                className="ion-ios-close"
                style={{
                  position: 'absolute',
                  top: -12,
                  right: 12,
                  fontSize: 24,
                  color: '#fff',
                }}
              />
              <i
                className="ion-ios-close-outline"
                style={{
                  position: 'absolute',
                  top: -12,
                  right: 12,
                  fontSize: 24,
                  cursor: 'pointer',
                }}
                onClick={() => onRemoveImage(image, i)}
              />
              <img
                style={{ width: 120, height: 120, marginRight: 16 }}
                src={image.url}
              />
            </div>
          );
        }
      })}
      {images.filter(image => !image._destroy).length < 3 && (
        <Dropzone accept="image/jpeg,image/png" onDrop={onAppendImage}>
          {({ getRootProps, getInputProps }) => (
            <section
              style={{
                width: 120,
                height: 120,
                border: '1px solid #ccc',
                color: '#ccc',
              }}
              key={images.length}
            >
              <div
                {...getRootProps()}
                className="d-flex flex-column justify-content-center text-center"
                style={{ width: '100%', height: '100%' }}
              >
                <input {...getInputProps()} />
                <i className="fa fa-plus" />
              </div>
            </section>
          )}
        </Dropzone>
      )}
    </div>
  );
}
