// @flow
import React from 'react';
import CommentForm from './CommentForm';
import type { Review } from '../../../../models/products/reviews/Review';
import type { Comment } from '../../../../models/products/reviews/comments/Comment';
import { REMOVED_USER_ID } from '../../../../models/users/User';
import moment from 'moment';

type Props = {
  comment: Comment,
  review: Review,
  token: string,
  userSignedIn: boolean,
};

type State = {
  comments: Array<Comment>,
  reply: boolean,
};

export default class CommentItem extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      comments: props.comment.comments,
      reply: false,
    };
  }

  onFinishPosting(comment: Comment) {
    const { comments } = this.state;

    this.setState({
      comments: [...comments, comment],
      reply: false,
    });
  }

  render() {
    const { comment, review, token, userSignedIn } = this.props;
    const { comments, reply } = this.state;

    return (
      <div className="comment">
        <div className="comment-media">
          <a href={`/users/${comment.user.id}`}>
            <img
              src={comment.user.thumb_url}
              width={32}
              height={32}
              className="rounded-circle mr-3 flex-shrink-1"
              alt={comment.user.name}
            />
          </a>
          <div className="comment-body w-100">
            <div className="text-muted mt-0">{comment.user.name}</div>
            <div
              className="comment-details"
              style={{ display: reply ? null : 'inline-block' }}
            >
              <p>{comment.body}</p>
              <div className="d-flex justify-content-between align-items-center">
                <small className="text-muted mr-3">
                  {moment(comment.created_at).fromNow()}
                </small>
                {!reply && userSignedIn && comment.user.id !== REMOVED_USER_ID && (
                  <small
                    className="reply"
                    onClick={() => this.setState({ reply: true })}
                  >
                    返信する
                  </small>
                )}
              </div>
              {reply && userSignedIn && (
                <CommentForm
                  visible={reply}
                  parent={comment}
                  review={review}
                  token={token}
                  focus={true}
                  style={{ marginTop: '1rem' }}
                  onFinishPosting={comment => this.onFinishPosting(comment)}
                />
              )}
            </div>
          </div>
        </div>
        {comments.map(child => (
          <CommentItem
            key={child.id}
            comment={child}
            review={review}
            token={token}
            userSignedIn={userSignedIn}
          />
        ))}
      </div>
    );
  }
}
