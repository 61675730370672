// @flow
import React from 'react';
import { Container, TabContent } from 'reactstrap';
import styled from 'styled-components';
import classNames from 'classnames';
import $ from 'jquery';
import TabNav from '../commons/tabs/TabNav';
import NavLink from '../commons/tabs/NavLink';
import NavItem from '../commons/tabs/NavItem';
import { ORDERS, WEATHERS } from '../../models/results/Result';
import WeatherButton from './WeatherButton';
import DateRangeView from './DateRangeView';
import ResultsTabPane, { RESULTS_TAB } from './ResultsTabPane';
import AreasTabPane, { AREAS_TAB } from './AreasTabPane';
import UsersTabPane, { USERS_TAB } from './UsersTabPane';
import FishesTabPane, { FISHES_TAB } from './FishesTabPane';
import { camelize } from '../../lib/StringUtils';

const Border = styled.div`
  position: relative;
  top: -2px;
  z-index: -1;
  border-bottom: 2px solid #eee;
`;

type Props = {
  mobile: boolean,
  keyword: ?string,
  fdl: string,
};

type State = {
  activeTab: string,
  order: any,
  keyword: string,
  weathers: Array<any>,
  weathersPrev: Array<any>,
  startOn: ?string,
  endOn: ?string,
};

export default class ResultsBody extends React.Component<Props, State> {
  canCloseWeathersDropdown = false;

  constructor(props: Props) {
    super(props);

    this.state = {
      activeTab: RESULTS_TAB,
      order: ORDERS[0],
      keyword: props.keyword != null ? props.keyword : '',
      weathers: WEATHERS,
      weathersPrev: WEATHERS,
      startOn: null,
      endOn: null,
    };
  }

  componentDidMount() {
    $('#weathers_dropdown').on({
      'shown.bs.dropdown': () => {
        this.canCloseWeathersDropdown = true;
      },
      click: e => {
        const target = $(e.target);
        this.canCloseWeathersDropdown = target.hasClass('closable');
      },
      'hide.bs.dropdown': () => {
        return this.canCloseWeathersDropdown;
      },
    });
  }

  onClickNavLink(tabName: string) {
    if (this.state.activeTab !== tabName) {
      this.setState(
        {
          activeTab: tabName,
        },
        () => {
          // $FlowFixMe
          gtag('event', 'click', {
            event_category: `Click${camelize(tabName)}Pane`,
            event_label: 'ResultsBody',
          });
        }
      );
    }
  }

  onDatesChange(startOn: ?string, endOn: ?string) {
    this.setState({
      startOn: startOn,
      endOn: endOn,
    });
  }

  onClickOrder(event: any, order: any) {
    event.preventDefault();

    if (this.state.order.key !== order.key) {
      this.setState({
        order: order,
      });
    }
  }

  onChangeWeather(weather: any) {
    const nextState = this.state.weathersPrev.map(w => {
      return {
        key: w.key,
        name: w.name,
        checked: w.key === weather.key ? !weather.checked : w.checked,
      };
    });

    this.setState({ weathersPrev: nextState });
  }

  onCancelWeather(event: any) {
    event.preventDefault();
    this.setState({ weathersPrev: this.state.weathers });
  }

  onApplyWeather = (event: any) => {
    event.preventDefault();
    this.setState({
      weathers: this.state.weathersPrev,
    });
  };

  renderFilters() {
    const { weathers, weathersPrev } = this.state;

    return (
      <div className="float-right" style={{ paddingTop: 8 }}>
        <span className="dropdown" id="weathers_dropdown">
          <WeatherButton weathers={weathers} />
          <div className="dropdown-menu dropdown-menu-right p-20">
            <div className="mb-20">
              {weathersPrev.map(weather => {
                return (
                  <div className="form-check" key={weather.key}>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={weather.checked}
                      onChange={() => this.onChangeWeather(weather)}
                    />
                    <label className="form-check-label">{weather.name}</label>
                  </div>
                );
              })}
            </div>
            <div className="clearfix">
              <div className="pull-left">
                <a
                  href=""
                  className="btn btn-light btn-sm closable"
                  onClick={e => this.onCancelWeather(e)}
                >
                  キャンセル
                </a>
              </div>
              <div className="pull-right">
                <a
                  href=""
                  className="btn btn-primary btn-sm closable"
                  onClick={this.onApplyWeather}
                >
                  適用
                </a>
              </div>
            </div>
          </div>
        </span>
        <span style={{ display: 'inline-block' }}>
          <DateRangeView
            orientation={this.props.mobile ? 'vertical' : 'horizontal'}
            withFullScreenPortal={this.props.mobile}
            onDatesChange={(startDate, endDate) =>
              this.onDatesChange(startDate, endDate)
            }
          />
        </span>
        <span className="dropdown">
          <button className="btn btn-light" data-toggle="dropdown">
            {this.state.order.name}
          </button>
          <ul className="dropdown-menu dropdown-menu-right">
            {ORDERS.map(order => {
              return (
                <li key={order.key}>
                  <a
                    href=""
                    className="dropdown-item"
                    onClick={e => this.onClickOrder(e, order)}
                  >
                    {order.name}
                  </a>
                </li>
              );
            })}
          </ul>
        </span>
      </div>
    );
  }

  render() {
    const { mobile, keyword } = this.props;
    const { activeTab, weathers, order, startOn, endOn } = this.state;

    return (
      <React.Fragment>
        <Container className="clearfix">
          <div className="float-left">
            <TabNav>
              <NavItem
                className={classNames({
                  active: activeTab === RESULTS_TAB,
                })}
              >
                <NavLink onClick={() => this.onClickNavLink(RESULTS_TAB)}>
                  みんなの釣果
                </NavLink>
              </NavItem>
              <NavItem
                className={classNames({
                  active: activeTab === AREAS_TAB,
                })}
              >
                <NavLink onClick={() => this.onClickNavLink(AREAS_TAB)}>
                  エリア
                </NavLink>
              </NavItem>
              <NavItem
                className={classNames({
                  active: activeTab === USERS_TAB,
                })}
              >
                <NavLink onClick={() => this.onClickNavLink(USERS_TAB)}>
                  ユーザー
                </NavLink>
              </NavItem>
              <NavItem
                className={classNames({
                  active: activeTab === FISHES_TAB,
                })}
              >
                <NavLink onClick={() => this.onClickNavLink(FISHES_TAB)}>
                  魚種
                </NavLink>
              </NavItem>
            </TabNav>
          </div>
          {!mobile && activeTab === RESULTS_TAB && this.renderFilters()}
        </Container>
        <Border />
        {mobile && activeTab === RESULTS_TAB && (
          <div className="clearfix mb-10 mr-10">{this.renderFilters()}</div>
        )}
        <TabContent activeTab={activeTab}>
          <ResultsTabPane
            keyword={keyword}
            mobile={mobile}
            order={order}
            weathers={weathers}
            startOn={startOn}
            endOn={endOn}
            fdl={this.props.fdl}
          />
          <AreasTabPane keyword={keyword} active={activeTab === AREAS_TAB} />
          <FishesTabPane keyword={keyword} active={activeTab === FISHES_TAB} />
          <UsersTabPane keyword={keyword} active={activeTab === USERS_TAB} />
        </TabContent>
      </React.Fragment>
    );
  }
}
