// @flow
import React, { useState, useRef, useEffect } from 'react';
import type { Node } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import Loader from '../../commons/Loader.js';
import SearchIcon from '../../../assets/images/views/ships/search_icon.png';
import PrefectureIcon from '../../../assets/images/views/ships/prefecture.png';
import FishIcon from '../../../assets/images/views/ships/fish.png';
import ModalArrowIcon from '../../../assets/images/views/ships/searches/modal_arrow.png';
// import SortArrowIcon from '../../../assets/images/views/ships/searches/sort_arrow.png';
import MapIcon from '../../../assets/images/views/ships/searches/map.png';
import AddRequestPcIcon from '../../../assets/images/views/ships/searches/add_request_pc.png';
import AddRequestSpIcon from '../../../assets/images/views/ships/searches/add_request_sp.png';
import SelectPrefectureModal from '../SelectPrefectureModal.js';
import SelectFishModal from '../SelectFishModal.js';
import ShipCard from '../ShipCard.js';
import type { Region } from '../../../models/regions/Region.js';
import type { FishWithDetails } from '../../../models/fishes/Fish.js';
import { sendUaReplaceState } from '../../../lib/sendUaReplaceState';
import Breadcrumbs from '../../../lib/Breadcrumbs.js';
import styled from 'styled-components';
import { breakPoint, viewPc, viewSp } from '../../../lib/Mixins';
import { useFetchApi } from './UseFetchApi';
import type { Params } from './UseFetchApi';

type Props = {
  regions: Array<Region>,
  fishes: Array<FishWithDetails>,
  isSignedIn: boolean,
  token: string,
};

function IndexViewRoot(props: Props): Node {
  // ReactOnRailsの不具合で直接Hooksを使ってるコンポーネントをexportできない
  return <IndexView {...props} />;
}

function formatParams(query, params) {
  return (query.get(params) || '')
    .split(',')
    .map(v => (v ? Number(v) : null))
    .filter(v => Boolean(v)); // null除外;
}

function initialParams(
  regions: Array<Region>,
  fishes: Array<FishWithDetails>
): Params {
  const url = new URL(location.href);
  const query = new URLSearchParams(url.search);
  const keyword = query.get('keyword') || '';
  const prefectureIds = formatParams(query, 'prefecture_id_in');
  const fishIds = formatParams(query, 'fishes_id_in');
  const selectedPrefectures = [].concat(
    ...regions.map(region =>
      region.prefectures.filter(p => prefectureIds.includes(p.id))
    )
  );
  const selectedFishes = fishes.filter(f => fishIds.includes(f.id));
  const dreamCupOnly = Boolean(
    JSON.parse((query.get('dream_cup_only') || 'false').toLowerCase())
  );
  return {
    page: 1,
    perPage: 20,
    keyword: keyword,
    prefectures: selectedPrefectures,
    fishes: selectedFishes,
    dreamCupOnly: dreamCupOnly,
    refreshing: true,
  };
}

function IndexView({ regions, fishes, isSignedIn, token }: Props) {
  const [
    { ships, totalCount, isLoading, hasNextPage, params },
    setParams,
  ] = useFetchApi(initialParams(regions, fishes));

  const [breadcrumbItems, setBreadcrumbItems] = useState([]);
  const [visiblePrefectureModal, setVisiblePrefectureModal] = useState(false);
  const [visibleFishModal, setVisibleFishModal] = useState(false);
  const baseMapUrl = `${location.origin}/ships/search/map${location.search}`;
  const [mapPath, setMapPath] = useState(baseMapUrl);
  const timerIdRef = useRef(null);

  function onLoadMore() {
    if (!isLoading && hasNextPage) {
      setParams({
        ...params,
        page: params.page + 1,
        refreshing: false,
      });
    }
  }

  function handleChangeKeyword(keyword: string) {
    clearTimeout(timerIdRef.current);
    timerIdRef.current = setTimeout(
      () => handleChange({ ...params, keyword }),
      1500
    );
  }

  function handleChange(currentParams: Params) {
    setParams({
      ...currentParams,
      page: 1,
      refreshing: true,
    });
    setVisiblePrefectureModal(false);
    setVisibleFishModal(false);
  }

  useEffect(() => {
    function buildPath(value: string) {
      const url = new URL(value);
      if (params.page > 1) {
        url.searchParams.set('page', String(params.page));
      }
      url.searchParams.set('keyword', params.keyword);
      url.searchParams.set(
        'prefecture_id_in',
        String(params.prefectures.map(p => p.id))
      );
      url.searchParams.set(
        'fishes_id_in',
        String(params.fishes.map(f => f.id))
      );
      url.searchParams.set('dream_cup_only', String(params.dreamCupOnly));

      return url.pathname + url.search;
    }

    sendUaReplaceState(buildPath(`${location.origin}${location.pathname}`));
    setMapPath(buildPath(baseMapUrl));
  }, [
    params.page,
    params.keyword,
    params.refreshing,
    params.prefectures,
    params.fishes,
    params.dreamCupOnly,
  ]);

  useEffect(() => {
    function buildBreadcrumbItems() {
      const newBreadcrumbItems = [
        { title: 'トップ', href: '/' },
        { title: '釣船・船宿トップ', href: '/ships' },
        { title: '釣船・船宿一覧', href: '/ships/search' },
      ];
      if (params.prefectures.length > 0) {
        newBreadcrumbItems.push({
          title: `${params.prefectures
            .map(prefecture => prefecture.name)
            .join('、')}の釣船・船宿`,
          href: `/ships/search?prefecture_id_in=${String(
            params.prefectures.map(prefecture => prefecture.id).join(',')
          )}`,
        });
      }
      if (params.fishes.length > 0) {
        let prefectures =
          params.prefectures.length > 0
            ? `${params.prefectures.map(p => p.name).join('、')}で`
            : '';
        newBreadcrumbItems.push({
          title: `${prefectures}${params.fishes
            .map(fish => fish.name)
            .join('、')}が釣れる釣船・船宿`,
        });
      }
      return newBreadcrumbItems;
    }

    setBreadcrumbItems(buildBreadcrumbItems());
  }, [params.prefectures, params.fishes]);

  return (
    <>
      <Breadcrumbs items={breadcrumbItems} />

      <SearchContainer>
        <Container>
          <SearchBar>
            <Inputs>
              <img src={SearchIcon} />
              <input
                placeholder="釣船・船宿名などを入力"
                type="text"
                defaultValue={params.keyword}
                onChange={e => handleChangeKeyword(e.target.value)}
              />
            </Inputs>

            <ModalButtons>
              <Button onClick={() => setVisiblePrefectureModal(true)}>
                <ButtonInnerContainer>
                  <ButtonIcon src={PrefectureIcon} />
                  <ButtonText>
                    {params.prefectures
                      .map(prefecture => prefecture.name)
                      .join('/')}
                    {params.prefectures.length === 0 && <>指定なし</>}
                  </ButtonText>
                </ButtonInnerContainer>
                <ButtonArrowIcon src={ModalArrowIcon} />
              </Button>

              <Button onClick={() => setVisibleFishModal(true)}>
                <ButtonInnerContainer>
                  <ButtonIcon src={FishIcon} />
                  <ButtonText>
                    {params.fishes.map(fish => fish.name).join('/')}
                    {params.fishes.length === 0 && <>指定なし</>}
                  </ButtonText>
                </ButtonInnerContainer>
                <ButtonArrowIcon src={ModalArrowIcon} />
              </Button>
            </ModalButtons>
          </SearchBar>

          <SearchBottomContainer>
            <ResultsCounter>
              検索結果<strong>{totalCount}</strong>件
            </ResultsCounter>
            {/* <ResultsSorter>
            人気順
            <img src={SortArrowIcon} />
          </ResultsSorter> */}
          </SearchBottomContainer>
        </Container>
      </SearchContainer>

      <ListContainer>
        {/* 次回開催まではコメントアウトして非表示 */}
        {/* <DreamCupCheckboxContainer>
          <DreamCupCheckbox
            onClick={() =>
              handleChange({
                ...params,
                ...{ dreamCupOnly: !params.dreamCupOnly },
              })
            }
            active={params.dreamCupOnly}
          >
            <span />
            <strong>ANGLERS DREAM CUP</strong>対象のみ表示
          </DreamCupCheckbox>
        </DreamCupCheckboxContainer> */}
        <div>
          <InfiniteScroll
            pageStart={0}
            loadMore={() => onLoadMore()}
            // https://github.com/danbovey/react-infinite-scroller/issues/143#issuecomment-421973425
            // loadmoreが２重で走るがonLoadMoreで制御しているのでflag１つにする（ローディング画像を表示するのに楽）
            hasMore={hasNextPage}
            initialLoad={false}
            loader={<Loader key={0} />}
          >
            {ships.map((ship, index) => (
              <ShipsListItem key={index}>
                <ShipCard ship={ship} isSignedIn={isSignedIn} token={token} />
              </ShipsListItem>
            ))}
          </InfiniteScroll>
        </div>
        {!isLoading && !hasNextPage && ships.length === 0 && (
          <NotFound>
            <h2>
              検索にヒットする釣船・船宿は
              <br />
              見つかりませんでした。
            </h2>
            <div>
              <small>検索条件を変更して、もう一度検索してください。</small>
            </div>
          </NotFound>
        )}
        {!isLoading && !hasNextPage && (
          <AddRequest>
            <AddRequestMessage>
              <img src={AddRequestPcIcon} className="view-pc" />
              <img src={AddRequestSpIcon} className="view-sp" />
              <div>
                <h2>釣船・船宿の追加申請はこちら</h2>
                <p>
                  お探しの釣船・船宿がない場合は
                  <br className="view-sp" />
                  こちらのフォームから追加申請ができます。
                </p>
              </div>
            </AddRequestMessage>
            <AddRequestLink
              href="https://docs.google.com/forms/d/e/1FAIpQLSc-WkbsmDVzc3JvzbZ25n4WJuaYbF9RFdGyqrhNKsv9I1K7uQ/viewform?usp=sf_link"
              target="_blank"
              rel="noopener"
            >
              追加申請フォームへ
            </AddRequestLink>
          </AddRequest>
        )}
      </ListContainer>

      <SelectPrefectureModal
        regions={regions}
        visible={visiblePrefectureModal}
        onApply={prefectures => handleChange({ ...params, prefectures })}
        onClose={setVisiblePrefectureModal}
        initialPrefectures={params.prefectures}
      />

      <SelectFishModal
        fishes={fishes}
        visible={visibleFishModal}
        onApply={fishes => handleChange({ ...params, fishes })}
        onClose={setVisibleFishModal}
        initialFishes={params.fishes}
      />

      <MapViewLink href={mapPath}>
        <img src={MapIcon} />
        マップで表示する
      </MapViewLink>
    </>
  );
}

const SearchContainer = styled.div`
  margin: 0 auto;
  max-width: ${breakPoint}px;

  ${viewSp} {
    padding: 0 20px;
  }
`;

const ListContainer = styled(SearchContainer)`
  padding-bottom: 112px;

  ${viewSp} {
    background-color: white;
    padding-top: 1px;
    padding-bottom: 49px;
  }
`;

const Container = styled.div`
  background-color: white;
  border-radius: 12px;
  margin: 36px auto 16px;
  padding: 24px;

  ${viewSp} {
    background-color: transparent;
    margin: 20px auto;
    padding: 0;
  }
`;

const SearchBar = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 28px;
  width: 100%;

  ${viewSp} {
    flex-direction: column;
    margin-bottom: 18px;
  }
`;

const Inputs = styled.div`
  position: relative;
  width: 100%;

  img {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
    width: 24px;
    z-index: 1;

    ${viewSp} {
      left: 10px;
    }
  }

  input {
    background-color: white;
    border: 3px solid #0000001a;
    border-radius: 8px;
    padding-left: 50px;
    position: relative;
    height: 52px;
    width: 100%;

    ${viewSp} {
      border: 2px solid #0000001a;
      padding-left: 40px;
      height: 46px;
    }

    &::placeholder {
      color: #6badd7;
      font-size: 16px;

      ${viewSp} {
        font-size: 15px;
      }
    }

    &:-ms-input-placeholder {
      color: #6badd7;
      font-size: 16px;

      ${viewSp} {
        font-size: 15px;
      }
    }
  }
`;

const ModalButtons = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Button = styled.div`
  background-color: white;
  border: 3px solid #0000001a;
  border-radius: 8px;
  color: #0877bc;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  margin-left: 10px;
  height: 52px;
  width: 175px;

  ${viewSp} {
    border: 2px solid #0000001a;
    font-size: 13px;
    margin-left: 0;
    margin-top: 10px;
    height: 46px;
    width: calc(50% - 5px);
  }

  small {
    font-size: 10px;
    font-weight: bold;
    opacity: 0.6;

    ${viewSp} {
      font-size: 9px;
    }
  }
`;

const ButtonInnerContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const ButtonIcon = styled.img`
  margin-left: 10px;
  margin-right: 7px;
  height: 28px;
  width: 28px;

  ${viewSp} {
    height: 24px;
    width: 24px;
  }
`;

const ButtonText = styled.div`
  max-width: 95px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${viewSp} {
    font-size: 13px;
    max-width: 20vw;
  }
`;

const ButtonArrowIcon = styled.img`
  margin-left: 7px;
  margin-right: 11px;
  height: 12px;
  width: 12px;
`;

const SearchBottomContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const ResultsCounter = styled.div`
  color: #a0a0a0;
  font-size: 18px;
  line-height: 1;

  ${viewSp} {
    font-size: 12px;
  }

  strong {
    color: #2b2b2b;
    font-size: 26px;
    margin: 0 10px;

    ${viewSp} {
      font-size: 18px;
      margin: 0 8px;
    }
  }
`;

// const ResultsSorter = styled.div`
//   align-items: center;
//   display: flex;
//   font-size: 15px;
//   font-weight: bold;
//   line-height: 1;

//   ${viewSp} {
//     font-size: 12px;
//   }

//   img {
//     margin-left: 4px;
//     height: 15px;
//     width: 15px;

//     ${viewSp} {
//       height: 12px;
//       width: 12px;
//     }
//   }
// `;

// 次回開催まではコメントアウトして非表示
// const DreamCupCheckboxContainer = styled.div`
//   display: flex;
//   align-items: center;
// `;

// const DreamCupCheckbox = styled.div`
//   font-size: 14px;
//   font-weight: bold;
//   display: flex;
//   align-items: center;
//   cursor: pointer;

//   ${viewPc} {
//     strong {
//       font-size: 15px;
//     }
//   }

//   ${viewSp} {
//     font-size: 12px;
//     padding-top: 20px;
//   }

//   span {
//     border: 2px solid #e5e5e5;
//     border-radius: 4px;
//     display: inline-block;
//     margin-right: 10px;
//     position: relative;
//     height: 22px;
//     width: 22px;

//     ${viewSp} {
//       height: 16px;
//       width: 16px;
//     }

//     &::before {
//       display: ${props => (props.active ? 'initial' : 'none')};
//       content: '';
//       position: absolute;
//       border-bottom: 2px solid #0877bc;
//       border-left: 2px solid #0877bc;
//       top: 3px;
//       left: 3px;
//       width: 12px;
//       height: 8px;
//       transform: rotate(-45deg);

//       ${viewSp} {
//         top: 2px;
//         left: 2px;
//         width: 9px;
//         height: 6px;
//       }
//     }
//   }
// `;

const ShipsListItem = styled.div`
  margin-top: 20px;
`;

const MapViewLink = styled.a`
  background-color: #2b2b2b;
  border-radius: 20px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: bold;
  margin-bottom: env(safe-area-inset-bottom);
  position: fixed;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  height: 40px;
  width: 192px;
  z-index: 1;

  ${viewSp} {
    font-size: 12px;
    height: 36px;
    width: 151px;
  }

  &:hover {
    color: white;
    text-decoration: none;
  }

  img {
    margin-right: 8px;
    height: 24px;
    width: 24px;

    ${viewSp} {
      margin-right: 4px;
      height: 20px;
      width: 20px;
    }
  }
`;

const NotFound = styled.div`
  text-align: center;
  margin-top: 154px;
  margin-bottom: 140px;
  line-height: 1;

  ${viewSp} {
    margin-top: 49px;
    margin-bottom: 35px;
  }

  h2 {
    font-size: 30px;
    font-weight: bold;

    ${viewSp} {
      font-size: 20px;
      line-height: 1.8;
    }

    br {
      ${viewPc} {
        display: none;
      }
    }
  }

  small {
    display: block;
    font-size: 18px;
    margin-top: 30px;

    ${viewSp} {
      font-size: 12px;
    }
  }
`;

const AddRequest = styled.div`
  background-color: white;
  border-radius: 12px;
  min-height: 162px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 66px;
  padding: 0 36px;
  line-height: 1;

  ${viewSp} {
    background-color: #f8f8f8;
    flex-direction: column;
    margin-top: 35px;
    padding: 24px 28px;
    text-align: center;
  }

  img {
    margin-right: 30px;
    height: 90px;
    width: 90px;

    ${viewSp} {
      margin-right: 0;
      margin-bottom: 14px;
    }
  }

  h2 {
    font-size: 26px;
    font-weight: bold;

    ${viewSp} {
      font-size: 19px;
    }
  }

  p {
    font-size: 16px;
    font-family: 'ヒラギノ角ゴシック', sans-serif;
    margin: 0;
    margin-top: 16px;

    ${viewSp} {
      font-size: 13px;
      line-height: 1.8;
      margin-top: 15px;
    }
  }
`;

const AddRequestMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${viewSp} {
    flex-direction: column;
  }
`;

const AddRequestLink = styled.a`
  background-color: #0877bc;
  border-radius: 27px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: bold;
  min-height: 54px;
  width: 194px;

  ${viewSp} {
    margin-top: 20px;
    min-height: 48px;
    width: 279px;
  }

  &:hover {
    color: white;
    text-decoration: none;
  }
`;

export default IndexViewRoot;
