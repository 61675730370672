// @flow
import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import type { Result } from '../../models/results/Result.js';
import { default as UserNoImage } from '../../assets/images/views/users/no-image.png';
import LikeIcon from '../../assets/images/views/ships/like.png';
import CommetIcon from '../../assets/images/views/ships/comment.png';
import { viewSp } from '../../lib/Mixins.js';

type Props = {
  result: Result,
};

function ResultListItem({ result }: Props) {
  return (
    <Container>
      <a href={`/catches/${result.id}`}>
        <ImageContainer>
          <ResultImageContainer>
            <ResultImage src={result.square_url} />
            <ResultUser>
              {result.user.mini_url !== null && (
                <img src={result.user.mini_url} />
              )}
              {result.user.mini_url === null && <img src={UserNoImage} />}
              <UserName>{result.user.name}</UserName>
            </ResultUser>
          </ResultImageContainer>
        </ImageContainer>
      </a>
      <ResultFish>
        {result.fish && (
          <FishDetail>
            <a href={`/fishes/${String(result.fish && result.fish.id)}`}>
              {result.fish && result.fish.name}
            </a>
            {result.size && (
              <span>
                {result.size}
                cm
              </span>
            )}
          </FishDetail>
        )}
        {!result.fish && <small>未選択</small>}
      </ResultFish>
      <ResultDate>
        <div>{moment(result.caught_at).format('YYYY.MM.DD')}</div>
        <div>{result.fishinglog_published_results_count}釣果</div>
      </ResultDate>
      {result.publish_comment && (
        <PublishComment>
          <p>{result.publish_comment}</p>
        </PublishComment>
      )}
      <ActionButtons>
        <LikeButton>
          <img src={LikeIcon} />
          {result.cached_votes_up}
        </LikeButton>
        <CommentButton>
          <img src={CommetIcon} />
          {result.publish_comments_count}
        </CommentButton>
      </ActionButtons>
    </Container>
  );
}

const Container = styled.div`
  margin-top: 20px;
  height: inherit;
  width: 100%;

  a {
    color: #2b2b2b;
    text-decoration: none;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  padding-top: 100%;
  margin-right: 0px;
  min-width: 100%;
  height: auto;
  width: 100%;
`;

const ResultImageContainer = styled.div`
  overflow: hidden;

  &::before {
    content: '';
    border-radius: 0px 0px 12px 12px;
    bottom: 0;
    left: 0;
    position: absolute;
    mix-blend-mode: multiply;
    min-height: 50%;
    height: 50%;
    min-width: 100%;
    width: 100%;
    background: transparent
      linear-gradient(180deg, transparent 0%, #808080 100%) 0% 0% no-repeat
      padding-box;
    z-index: 1;
  }
`;

const ResultImage = styled.img`
  position: absolute;
  top: 0;
  border-radius: 12px;
  object-fit: cover;
  height: 100%;
  width: 100%;
`;

const ResultUser = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 12px;
  left: 12px;
  z-index: 1;

  img {
    border: 2px solid #eeeeee;
    border-radius: 50%;
    height: 24px;
    width: 24px;
  }
`;

const UserName = styled.div`
  color: white;
  font-size: 12px;
  font-weight: bold;
  margin-left: 8px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 130px;

  ${viewSp} {
    width: 25vw;
  }
`;

const ResultFish = styled.div`
  color: #2b2b2b;
  font-size: 15px;
  font-weight: bold;
  margin-top: 10px;

  small {
    font-weight: bold !important;
  }
`;

const FishDetail = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ResultDate = styled.div`
  color: #a0a0a0;
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PublishComment = styled.div`
  background: #f8f8f8;
  border-radius: 8px;
  font-size: 13px;
  margin: 6px 0 11px;
  padding: 12px 10px;
  height: 59px;
  display: flex;
  align-items: center;

  ${viewSp} {
    background: #ffffff;
    font-size: 11px;
    margin: 6px 0 7px;
    padding: 10px 10px;
    height: 51px;
  }

  p {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin: 0;
    padding: 0;
  }
`;

const ActionButtons = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: bold;

  img {
    height: 13px;
    margin-right: 5px;
  }
`;

const LikeButton = styled(ActionButtons)`
  color: #0877bc;
`;

const CommentButton = styled(ActionButtons)`
  color: #2b2b2b;
  margin-left: 20px;
`;

export default React.memo<Props>(ResultListItem);
