// @flow
import type { Maker } from '../models/makers/Maker';
import type { MyRod } from '../models/my_rods/MyRod';
import type { MyReel } from '../models/my_reels/MyReel';
import type { MyReelForm } from '../models/my_reels/MyReelForm';

export default class ProductHelper {
  static lure_details(maker: ?Maker, lure_name: ?string, lure_color: ?string) {
    if (!lure_name && !lure_color) {
      return maker && !maker.other ? maker.name : '';
    }

    if (lure_name && lure_color) {
      return `${lure_name} - ${lure_color}`;
    }

    return lure_name || lure_color;
  }

  static rod_details_without_maker(rod: ?MyRod) {
    if (!rod) {
      return '';
    }

    if (!rod.product.other || rod.name) {
      const name = rod.product.other ? rod.name || '' : rod.product.name || '';
      return `${rod.series.name} / ${name}`;
    }

    return rod.series.name;
  }

  static reel_details_without_maker(reel: ?MyReel) {
    if (!reel) {
      return '';
    }

    if (!reel.reel.other || reel.name) {
      const name = reel.reel.other ? reel.name || '' : reel.reel.name || '';
      return `${reel.series.name} / ${name}`;
    }

    return reel.series.name;
  }

  static line_details_without_maker(reel: ?MyReel) {
    if (!reel || !reel.line) {
      return '';
    }

    if (!reel.line.other || reel.line_name) {
      const name = reel.line.other
        ? reel.line_name || ''
        : reel.line.name || '';
      return `${reel.line_series ? reel.line_series.name : ''} / ${name}`;
    }

    return reel.line_series ? reel.line_series.name : '';
  }

  static enableFreeInputReel(form: MyReelForm) {
    return (
      (form.maker && form.maker.other) ||
      (form.series && form.series.other) ||
      (form.reel && form.reel.other)
    );
  }

  static enableFreeInputLine(form: MyReelForm) {
    return (
      (form.line_maker && form.line_maker.other) ||
      (form.line_series && form.line_series.other) ||
      (form.line && form.line.other)
    );
  }

  static pathFromType(type: string): string {
    switch (type) {
      case 'Rod':
        return 'rods';
      case 'Reel':
        return 'reels';
      case 'Lure':
        return 'lures';
      case 'Line':
        return 'lines';
      default:
        return '';
    }
  }
}
