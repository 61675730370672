// @flow
import * as React from 'react';

type Props = {
  children: React.Node,
};

export function Text({ children, ...props }: Props) {
  return <span {...props}>{children}</span>;
}
