// @flow
import React from 'react';
import type { UserWithDetails } from '../../../models/users/User';

type Props = {
  user: UserWithDetails,
};

export function ProfileComp({ user }: Props) {
  return (
    <div className="users profile ml-4">
      <div className="list-hover">
        <a href={`/users/${user.id}`} className="body-color">
          <div className="media mb-20">
            <div className="pull-left">
              <img
                src={user.thumb_url}
                className="rounded-circle"
                alt={user.name}
                width={64}
              />
            </div>
            <div className="media-body">
              <h4 className="media-heading">{user.name}</h4>
            </div>
          </div>
        </a>
        <a href={`/users/${user.id}`} className="body-color">
          <div className="mb-30 details">{user.profile_comment}</div>
        </a>
      </div>
      <hr />
      <div className="analytics mb-30">
        <div className="row mb-20">
          <a href={`/users/${user.id}/catches`} className="col-6 list-hover">
            <div className="mb-5">公開釣果</div>
            <b className="c-blue">{user.publish_results_size}</b>
            <small>釣果</small>
          </a>
          <a href={`/users/${user.id}/fishings`} className="col-6 list-hover">
            <div className="mb-5">年間釣行数</div>
            <b className="c-blue">{user.yearly_fishinglogs_size}</b>
            <small>釣行</small>
          </a>
        </div>
        <div className="row mb-20">
          <a href={`/users/${user.id}/follows`} className="col-6 list-hover">
            <div className="mb-5">フォロー</div>
            <b className="c-blue">{user.followings_size}</b>
            <small>人</small>
          </a>
          <a href={`/users/${user.id}/followers`} className="col-6 list-hover">
            <div className="mb-5">フォロワー</div>
            <b className="c-blue">{user.followers_size}</b>
            <small>人</small>
          </a>
        </div>
      </div>
    </div>
  );
}
