// @flow
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Col, Row } from 'reactstrap';
import moment from 'moment';
import type { BaseProps } from '../../../../application';
import type { Account } from '../../../models/accounts/Account';
import type { MyLure } from '../../../models/my_lures/MyLure';
import type { MyLureForm } from '../../../models/my_lures/MyLureForm';
import { ActivityIndicator } from '../../commons/react-native';
import { View } from '../../commons/native-base';
import {
  displayMessage,
  displayAxiosError,
  displayEntityError,
} from '../../commons/Toast';
import { updateMyLure, updateMyLures } from '../../../actions/myLures';
import MyLureClient from '../../../models/my_lures/MyLureClient';
import { FormComp } from './FormComp';
import { csrfToken } from '../../../helpers/fetch';

type Props = BaseProps & {
  account: Account,
  updateMyLure: (myLure: MyLure) => void,
  updateMyLures: (myLures: Array<MyLure>) => void,
};

type State = {
  id: number,
  form: ?MyLureForm,
  loading: boolean,
};

class EditView extends React.Component<Props, State> {
  myLureClient = new MyLureClient();

  constructor(props: Props) {
    super(props);
    const myLure = props.location.state && props.location.state.myLure;
    this.state = {
      id: myLure != null ? myLure.id : parseInt(props.match.params.id),
      form: myLure != null ? this.buildForm(myLure) : null,
      loading: myLure == null,
    };
  }

  componentDidMount() {
    const { account } = this.props;
    const { id, form } = this.state;

    if (form == null) {
      this.myLureClient
        .fetchMyLure(id, account)
        .then(response => {
          this.setState({
            loading: false,
            form: this.buildForm(response.data),
          });
        })
        .catch(error => {
          this.setState({ loading: false });

          if (error.response.status !== 404) {
            displayAxiosError(error);
          }
        });
    }
  }

  buildForm(myLure: MyLure): MyLureForm {
    return {
      maker: myLure.maker,
      name: myLure.name,
      color: myLure.color,
      memo: myLure.memo != null ? myLure.memo : '',
      get_date: myLure.get_date != null ? moment(myLure.get_date) : null,
      lost_date: myLure.lost_date != null ? moment(myLure.lost_date) : null,
      images: myLure.images.map(image => {
        return {
          id: image.id,
          image: null,
          url: image.image.thumb.url,
          _destroy: false,
        };
      }),
    };
  }

  onSubmit(form: MyLureForm) {
    const { history, account, updateMyLure, updateMyLures } = this.props;
    const { id } = this.state;

    this.setState({ loading: true });
    this.myLureClient
      .update(id, form, account, csrfToken())
      .then(response => {
        displayMessage('更新が完了しました');

        let myLure = null;

        response.data.find(lure => {
          myLure =
            lure.colors != null && lure.colors.find(color => color.id === id);
          return myLure != null;
        });

        if (myLure != null) {
          updateMyLure(myLure);
        }

        updateMyLures(response.data);
        history.goBack();
      })
      .catch(error => {
        if (error.response.status === 422) {
          displayEntityError(error.response.data, {
            maker: 'メーカー',
            series: 'シリーズ',
            product_rod: 'モデル',
            lurenm: 'ルアー名',
            colornm: 'カラー名',
          });
        } else {
          displayAxiosError(error);
        }
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  render() {
    const { account } = this.props;
    const { loading, form } = this.state;

    if (loading) {
      return (
        <View
          style={{
            paddingVertical: 20,
            borderTopWidth: 1,
            borderColor: '#CED0CE',
          }}
        >
          <ActivityIndicator animating size="large" />
        </View>
      );
    } else if (form != null) {
      return (
        <React.Fragment>
          <div style={{ marginBottom: 30 }}>
            <FormComp
              form={form}
              account={account}
              shorted={false}
              onChangeForm={(form, callback) => {
                this.setState(
                  { form: form },
                  callback != null ? callback : undefined
                );
              }}
              displayAxiosError={error => displayAxiosError(error)}
            />
          </div>
          <Row>
            <Col sm={3} xs={6}>
              <Button
                block
                color="outline-primary"
                onClick={() => this.props.history.goBack()}
              >
                キャンセル
              </Button>
            </Col>
            <Col sm={3} xs={6}>
              <Button block color="primary" onClick={() => this.onSubmit(form)}>
                完了
              </Button>
            </Col>
          </Row>
        </React.Fragment>
      );
    } else {
      return <div>ルアーが見つかりませんでした</div>;
    }
  }
}

export default connect(
  state => ({
    account: state.account,
  }),
  dispatch => bindActionCreators({ updateMyLure, updateMyLures }, dispatch)
)(EditView);
