// @flow
import JQueryView from '../../../common/views/JQueryView';
import moment from 'moment';
import TideGraph from '../venues/TideGraph';
import type { Venue } from '../../models/venues/Venue';

export default class ShowView extends JQueryView {
  displayTime: string;
  venue: ?Venue;

  constructor(displayTime: string, venue: ?Venue) {
    super('.content');
    this.displayTime = displayTime;
    this.venue = venue;
    this.render();
  }

  render() {
    if (this.venue != null && this.displayTime != null) {
      const graph = new TideGraph(this.venue.id, '#tide_graph');
      graph.show(moment(this.displayTime));
    }
  }
}
