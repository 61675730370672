// @flow
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import GoogleMap from 'google-map-react';
import { viewSp } from '../../../lib/Mixins';
import NoImage from '../../../assets/images/views/ships/noimage.png';
import UpIcon from '../../../assets/images/views/ships/up.png';
import ListIcon from '../../../assets/images/views/ships/searches/list.png';
import LocateIcon from '../../../assets/images/views/ships/searches/map/locate.png';
import HybridIcon from '../../../assets/images/views/ships/searches/map/hybrid-map.png';
import type { ShipWithDetails } from '../../../models/ships/Ship';
import type { Position } from '../../../models/positions/Position';
import Breadcrumbs from '../../../lib/Breadcrumbs';

type Props = {
  apiKey: string,
  ships: Array<ShipWithDetails>,
  defaultLocation: { lat: number, lng: number },
};

function MapViewRoot(props: Props) {
  // ReactOnRailsの不具合で直接Hooksを使ってるコンポーネントをexportできない
  return <MapView {...props} />;
}

function MapView({ apiKey, ships, defaultLocation }: Props) {
  const [googleApiMap, setGoogleApiMap] = useState(null);
  const [googleApiMaps, setGoogleApiMaps] = useState(null);
  const [selectedShip, setSelectedShip] = useState<?ShipWithDetails>(null);

  const handleApiLoaded = (map, maps) => {
    setGoogleApiMap(map);
    setGoogleApiMaps(maps);
  };

  const onClickMarker = useCallback(ship => {
    setSelectedShip(ship);
  }, []);

  const onClickCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const pos = ((position: any): Position);
          if (googleApiMap === null) {
            return;
          }

          googleApiMap.panTo({
            lat: pos.coords.latitude,
            lng: pos.coords.longitude,
          });
        },
        error => {
          console.warn(error);
        },
        {
          enableHighAccuracy: true,
          timeout: 20000,
          maximumAge: 1000,
        }
      );
    }
  };

  const onClickHybrid = maps => {
    if (googleApiMap === null) {
      return;
    }

    if (googleApiMap.mapTypeId === maps.MapTypeId.ROADMAP) {
      googleApiMap.setMapTypeId(maps.MapTypeId.HYBRID);
    } else {
      googleApiMap.setMapTypeId(maps.MapTypeId.ROADMAP);
    }
  };

  const createMapOptions = maps => {
    return {
      maxZoom: 18,
      fullscreenControl: false,
      mapTypeId: maps.MapTypeId.ROADMAP,
      zoomControlOptions: {
        position: maps.ControlPosition.RIGHT_CENTER,
      },
    };
  };

  useEffect(() => {
    if (ships && ships.length > 0) {
      if (googleApiMap === null) {
        return;
      }
      googleApiMap.panTo({ lat: ships[0].lat, lng: ships[0].lng });
    }
  }, [ships, googleApiMap]);

  return (
    <>
      <Breadcrumbs
        items={[
          { title: 'トップ', href: '/' },
          { title: '釣船・船宿トップ', href: '/ships' },
          { title: '釣船・船宿一覧', href: '/ships/search' },
          { title: 'マップで表示する' },
        ]}
      />
      <SearchMapContainer>
        <GoogleMap
          bootstrapURLKeys={{ key: apiKey }}
          defaultCenter={[defaultLocation.lat, defaultLocation.lng]}
          defaultZoom={11}
          options={createMapOptions}
          onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        >
          {ships &&
            ships.length > 0 &&
            ships.map(ship => (
              <MemoizedShipMarker
                key={ship.id}
                ship={ship}
                lat={ship.lat}
                lng={ship.lng}
                isSelected={!!(selectedShip && selectedShip.id === ship.id)}
                onSelected={onClickMarker}
              />
            ))}
        </GoogleMap>

        <MyLocationButton onClick={onClickCurrentLocation}>
          <img src={LocateIcon} />
        </MyLocationButton>

        {googleApiMaps !== null && (
          <HybridMapButton onClick={() => onClickHybrid(googleApiMaps)}>
            <img src={HybridIcon} />
          </HybridMapButton>
        )}
      </SearchMapContainer>
      <MoveToList href={`/ships/search${location.search}`}>
        <img src={ListIcon} />
        リストで表示する
      </MoveToList>
    </>
  );
}

type ShipMarkerProp = {
  ship: ShipWithDetails,
  isSelected: boolean,
  onSelected: (ship: ?ShipWithDetails) => void,
};

function ShipMarker({ ship, isSelected, onSelected }: ShipMarkerProp) {
  const onClickMarker = () => {
    if (isSelected) {
      // すでに選択済みのものだった場合は吹き出しを消す
      onSelected(null);
    } else {
      onSelected(ship);
    }
  };

  return (
    <>
      <MarkerWrapper
        alt={ship.name}
        onClick={onClickMarker}
        style={{
          backgroundColor: isSelected ? '#0877bc' : '#ffffff',
          color: isSelected ? '#ffffff' : '#000000',
          zIndex: isSelected ? '100' : '',
        }}
      >
        <MarkerShipName>{ship.name}</MarkerShipName>
      </MarkerWrapper>
      {isSelected && <ShipInfo ship={ship} />}
    </>
  );
}
const MemoizedShipMarker = React.memo(ShipMarker);

function ShipInfo({ ship }: { ship: ShipWithDetails }) {
  const detailUrl = `/ships/${ship.id}`;

  return (
    <MoveToDetail href={detailUrl} target="_blank" rel="noopener">
      <ShipInfoCard>
        <ShipInfoImage>
          {ship.thumbnails && ship.thumbnails.length > 0 && (
            <img src={ship.thumbnails[0]} alt={ship.name} />
          )}
          {ship.thumbnails && ship.thumbnails.length === 0 && (
            <img src={NoImage} />
          )}
        </ShipInfoImage>
        <ShipInfoDetail>
          {ship.port_name ? (
            <PortName>{`${ship.prefecture.name} / ${ship.port_name}`}</PortName>
          ) : (
            <PortName>{ship.prefecture.name}</PortName>
          )}
          <ShipName>{ship.name}</ShipName>
          <FollowContainer>
            <div>
              <strong>{ship.followers_size}</strong>
              <span>名がフォロー中</span>
            </div>
          </FollowContainer>
          {ship.fishes && ship.fishes.length > 0 && (
            <Fishes>
              {ship.fishes.map(fish => (
                <Fish key={fish.id}>
                  {fish.name}
                  <img src={UpIcon} />
                </Fish>
              ))}
            </Fishes>
          )}
        </ShipInfoDetail>
      </ShipInfoCard>
    </MoveToDetail>
  );
}

const MoveToDetail = styled.a`
  &:hover,
  &:focus {
    text-decoration: none;
  }
`;

const MoveToList = styled.a`
  background-color: #2b2b2b;
  border-radius: 20px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: bold;
  margin-bottom: env(safe-area-inset-bottom);
  position: fixed;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  height: 40px;
  width: 192px;
  z-index: 1;

  ${viewSp} {
    font-size: 12px;
    height: 36px;
    width: 151px;
  }

  &:hover {
    color: white;
    text-decoration: none;
  }

  img {
    margin-right: 8px;
    height: 24px;
    width: 24px;

    ${viewSp} {
      margin-right: 4px;
      height: 20px;
      width: 20px;
    }
  }
`;

const SearchMapContainer = styled.div`
  /* タイトルとヘッダーの高さを引いて期待するマップの高さを算出してる */
  max-height: calc(100vh - 50px - 60px);
  height: 100vh;
  width: 100%;
  position: relative;
`;

const MarkerShipName = styled.p`
  font-size: 14px;
  font-weight: bold;
  white-space: nowrap;
  line-height: inherit;
  padding: 0 10px;

  ${viewSp} {
    font-size: 10px;
  }
`;

const MarkerWrapper = styled.div`
  position: absolute;
  display: inline-block;
  height: 34px;
  line-height: 34px;
  border-radius: 17px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  user-select: none;
  cursor: pointer;
  background: #fff;

  &:hover {
    z-index: 150;
  }

  ${viewSp} {
    height: 27px;
    line-height: 27px;
    border-radius: 14px;
  }
`;

const PortName = styled.div`
  color: #a0a0a0;
  font-size: 13px;
  line-height: 1;

  ${viewSp} {
    font-size: 10px;
  }
`;

const ShipName = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-top: 7px;
  line-height: 1;

  ${viewSp} {
    font-size: 14px;
  }
`;

const eyecatchSizePc = '150px';
const eyecatchSizeSp = '105px';
const ShipInfoImage = styled.div`
  img {
    height: 113px;
    width: ${eyecatchSizePc};
    object-fit: cover;
    border-radius: 6px;
    vertical-align: bottom;

    ${viewSp} {
      border-radius: 8px;
      height: 80px;
      width: ${eyecatchSizeSp};
    }
  }
`;

const FollowContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 7px;
  line-height: 1;

  ${viewSp} {
    margin-top: 5px;
  }

  strong {
    font-size: 15px;
    margin-right: 4px;

    ${viewSp} {
      margin-right: 2px;
      font-size: 10px;
    }
  }

  span {
    font-size: 12px;

    ${viewSp} {
      font-size: 9px;
    }
  }
`;

const Fishes = styled.div`
  display: flex;
  align-items: center;
  margin-top: 9px;
  overflow: auto;
  line-height: 1;

  ${viewSp} {
    margin-top: 7px;
  }
`;

const Fish = styled.div`
  border: 1px solid #0000001a;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
  height: 26px;
  margin-left: 10px;
  padding: 0 8px;
  white-space: nowrap;

  &:first-child {
    margin-left: 0;
  }

  img {
    width: 15px;
  }

  ${viewSp} {
    font-size: 10px;
    border-radius: 4px;
    margin-left: 5px;
    height: 23px;

    img {
      width: 12px;
    }
  }
`;

const ShipInfoDetail = styled.div`
  margin-left: 15px;
  width: calc(100% - 15px - ${eyecatchSizePc});

  ${viewSp} {
    margin-left: 12px;
    width: calc(100% - 12px - ${eyecatchSizeSp});
  }
`;

const ShipInfoCard = styled.div`
  position: relative;
  display: flex;
  bottom: 170px;
  left: -160px;
  width: 424px;
  min-height: 143px;
  color: #2b2b2b;
  background-color: white;
  filter: drop-shadow(0 3px 6px rgba(0, 0, 0, 0.16));
  border-radius: 12px;
  font-size: 14px;
  z-index: 100;
  align-items: center;
  padding: 15px;

  &:before {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    left: 0;
    right: 0;
    bottom: -15px;
    margin: 0 auto;
    border-top: 15px solid #fff;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
  }

  ${viewSp} {
    position: fixed;
    width: 320px;
    min-height: 109px;
    /* 起点が画面の高さの半分の地点なので、そこから50vh(画面の半分の高さ)を減算する */
    /* そこから上に移動したい分のpxを加算すれば期待した位置に表示できる */
    bottom: calc(-50vh + 130px);
    left: 0;
    transform: translateX(-50%);
    padding: 12px;

    &:before {
      bottom: 0;
      border: none;
    }
  }
`;

const MyLocationButton = styled.button`
  background-color: #fff;
  border-radius: 24px;
  border: #fff;
  width: 48px;
  height: 48px;
  position: absolute;
  top: 20px;
  right: 8px;
  justify-content: center;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

  img {
    width: 24px;
    height: auto;
  }
`;

const HybridMapButton = styled.button`
  background-color: #fff;
  border-radius: 24px;
  border: #fff;
  width: 48px;
  height: 48px;
  position: absolute;
  top: 90px;
  right: 8px;
  justify-content: center;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

  img {
    width: 24px;
    height: auto;
  }
`;

export default MapViewRoot;
