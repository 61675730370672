// @flow
import axios from 'axios';
import { mapKeys, snakeCase, camelCase, split } from 'lodash';
import type { Axios } from 'axios';
import type { Account } from './accounts/Account';
import { deepMapKeys } from '../utils/commonUtils';

export default class CamelAxiosClient {
  client: Axios;

  buildClient(token?: string): Axios {
    let headers = {
      'Content-Type': 'application/json',
    };

    if (token != null) {
      headers = {
        ...headers,
        'X-CSRF-Token': token,
      };
    }

    this.client = axios.create({
      baseURL: '/api/v2',
      timeout: 60000,
      headers: headers,
    });
    this._requestInterceptor();
    this._responseInterceptor();
    return this.client;
  }

  buildAuthClient(account: Account, token?: string): Axios {
    let headers = {
      'Content-Type': 'application/json',
      'X-Account-Email': account.email,
      'X-Account-Token': account.authentication_token,
    };

    if (token != null) {
      headers = {
        ...headers,
        'X-CSRF-Token': token,
      };
    }

    this.client = axios.create({
      baseURL: '/api/v2',
      timeout: 60000,
      headers: headers,
    });
    this._requestInterceptor();
    this._responseInterceptor();
    return this.client;
  }

  _requestInterceptor() {
    this.client.interceptors.request.use(config => {
      if (!this._isFormDataRequest(config)) {
        const returnKey = key => (key === '_destroy' ? key : snakeCase(key));
        config.data = deepMapKeys(config.data, returnKey);
      }
      return config;
    });
  }

  _isFormDataRequest(config: Object) {
    return config.data && config.data.toString().includes('FormData');
  }

  _responseInterceptor() {
    this.client.interceptors.response.use(
      response => {
        if (response.data) {
          response.data = deepMapKeys(response.data, key => camelCase(key));
        }
        return response;
      },
      (error: any) => {
        error.response.data = this._camelCaseErrorResponse(error.response.data);
        throw error;
      }
    );
  }

  _camelCaseErrorResponse(error: any) {
    if (error.details) {
      error.details = mapKeys(error.details, (value, key) =>
        camelCase(split(key, '.').pop())
      );
    }
    return mapKeys(error, (value, key) => camelCase(key));
  }
}
