// @flow

export function displayMessage(message: string) {
  // $FlowFixMe
  notify(message, 'success');
}

export function displayError(message: any) {
  if (typeof message === 'string') {
    // $FlowFixMe
    notify(message, 'danger');
  } else {
    console.warn(message);
  }
}

export function displayAxiosError(error: any) {
  if (error.response) {
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);

    const maybeNull = error.hasOwnProperty('message')
      ? error.message
      : '不明なエラーが発生しました';

    displayError(
      error.response.data && error.response.data.hasOwnProperty('message')
        ? error.response.data.message
        : maybeNull
    );

    return;
  } else if (error.request) {
    console.log(error.request);
  } else {
    console.log('Error', error.message);
  }

  displayError(
    error.hasOwnProperty('message')
      ? error.message
      : '不明なエラーが発生しました'
  );
}
