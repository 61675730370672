// @flow
import React from 'react';

export default function Loader() {
  return (
    <div className="loader">
      <div id="circle_1" className="circle" />
      <div id="circle_2" className="circle" />
      <div id="circle_3" className="circle" />
      <div className="clearfix" />
    </div>
  );
}
