// @flow
import React from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import moment from 'moment';

import { FlatList } from '../../commons/react-native';
import { Icon, Body, Right, Text, ListItem } from '../../commons/native-base';
import OrderModal from './OrderModal';
import MyLureClient from '../../../models/my_lures/MyLureClient';
import { displayAxiosError } from '../../commons/Toast';

import type { BaseProps } from '../../../../application';
import type { Account } from '../../../models/accounts/Account';
import type { MyLure } from '../../../models/my_lures/MyLure';

type Props = BaseProps & {
  account: Account,
};

type State = {
  id: ?number,
  sortState: string,
  myLures: Array<MyLure>,
  modalVisible: boolean,
  loading: boolean,
};

class ColorsView extends React.Component<Props, State> {
  myLureClient = new MyLureClient();

  constructor(props: Props) {
    super(props);

    const myLures =
      props.location.state != null && props.location.state.myLures != null
        ? props.location.state.myLures
        : [];

    this.state = {
      id: parseInt(props.match.params.id),
      sortState: 'maker',
      myLures: myLures,
      modalVisible: false,
      loading: false,
    };
  }

  // componentWillReceiveProps(nextProps: Props) {
  //   const myLure = nextProps.screenProps.myLures.find(lure => {
  //     return (
  //       lure.colors && lure.colors.find(c => c.id === this.state.rootMyLureId)
  //     );
  //   });

  //   this.setState({ myLures: myLure ? myLure.colors || [] : [] });
  // }

  componentDidMount() {
    const { account } = this.props;
    const { id } = this.state;

    if (id != null) {
      this.myLureClient
        .fetchMyLure(id, account)
        .then(response => {
          const myLure = response.data;
          this.setState({
            loading: false,
            myLures: myLure.colors != null ? myLure.colors : [],
          });
        })
        .catch(error => {
          this.setState({ loading: false });

          if (error.response.status !== 404) {
            displayAxiosError(error);
          }
        });
    }
  }

  onChangeOrder(sortType: string) {
    const { myLures } = this.state;

    switch (sortType) {
      case 'updatedAsc':
        this.setState({
          myLures: myLures.sort((a, b) => {
            if (a.updated_at < b.updated_at) {
              return -1;
            } else if (a.updated_at > b.updated_at) {
              return 1;
            } else {
              return 0;
            }
          }),
          sortState: sortType,
          modalVisible: false,
        });
        break;
      case 'updatedDesc':
        this.setState({
          myLures: myLures.sort((a, b) => {
            if (a.updated_at < b.updated_at) {
              return 1;
            } else if (a.updated_at > b.updated_at) {
              return -1;
            } else {
              return 0;
            }
          }),
          sortState: sortType,
          modalVisible: false,
        });
        break;
      case 'getdateAsc':
        this.setState({
          myLures: myLures.sort((a, b) => {
            if (b.get_date && a.get_date < b.get_date) {
              return -1;
            } else if (b.get_date && a.get_date > b.get_date) {
              return 1;
            } else {
              return 0;
            }
          }),
          sortState: sortType,
          modalVisible: false,
        });
        break;
      case 'getdateDesc':
        this.setState({
          myLures: myLures.sort((a, b) => {
            if (b.get_date && a.get_date < b.get_date) {
              return 1;
            } else if (b.get_date && a.get_date > b.get_date) {
              return -1;
            } else {
              return 0;
            }
          }),
          sortState: sortType,
          modalVisible: false,
        });
        break;
      default:
        this.setState({
          myLures: myLures.sort((a, b) => {
            if (
              a.maker &&
              b.maker &&
              a.maker.name.toLowerCase() < b.maker.name.toLowerCase()
            ) {
              return -1;
            } else if (
              a.maker &&
              b.maker &&
              a.maker.name.toLowerCase() > b.maker.name.toLowerCase()
            ) {
              return 1;
            } else {
              return 0;
            }
          }),
          sortState: sortType,
          modalVisible: false,
        });
        break;
    }
  }

  onClickMyLure(myLure: MyLure) {
    this.props.history.push(`/my/lures/${myLure.id}`, { myLure: myLure });
  }

  render() {
    const { myLures } = this.state;

    return (
      <React.Fragment>
        <FlatList
          data={myLures}
          extraData={this.state.sortState}
          renderItem={item => (
            <ListItem
              key={item.id}
              style={{ backgroundColor: '#fff', cursor: 'pointer' }}
              onClick={() => this.onClickMyLure(item)}
            >
              <Body style={{ flex: 3 }}>
                <Text>{item.color ? item.color : 'カラー名未入力'}</Text>
                <Row>
                  <Col>
                    <Text className="text-muted">
                      {item.name ? item.name : 'ルアー名未入力'}
                    </Text>
                  </Col>
                  <Col>
                    <Text className="text-muted">
                      {item.get_date != null
                        ? moment(item.get_date).format('YYYY年MM月DD日')
                        : ''}
                      {item.get_date != null || item.lost_date != null
                        ? ' 〜 '
                        : ''}
                      {item.lost_date != null
                        ? moment(item.lost_date).format('YYYY年MM月DD日')
                        : ''}
                    </Text>
                  </Col>
                </Row>
              </Body>
              <Right>
                <Icon name="arrow-forward" />
              </Right>
            </ListItem>
          )}
        />
        <OrderModal
          visible={this.state.modalVisible}
          type={this.state.sortState}
          onApply={type => this.onChangeOrder(type)}
          onClose={() => this.setState({ modalVisible: false })}
        />
      </React.Fragment>
    );
  }
}

export default connect(state => ({
  account: state.account,
}))(ColorsView);
