// @flow
import React from 'react';
import type { Series } from '../../models/series/Series';
import { ActivityIndicator, View } from '../commons/react-native';

type Props = {
  filteredSeries: Array<Series>,
  isLoading: boolean,
  makerName: ?string,
};

export function SelectFooter({
  filteredSeries,
  isLoading,
  makerName,
}: Props): React$Node {
  function buildContactLink() {
    return (
      <a
        href={`/contact/tacklebox?maker_name=${makerName ? makerName : ''}`}
        className="btn btn-outline-primary btn-block"
        target="_blank"
        rel="noopener noreferrer"
      >
        シリーズを登録する
      </a>
    );
  }

  if (isLoading) {
    return (
      <View
        style={{
          paddingVertical: 20,
          borderTopWidth: 1,
          borderColor: '#CED0CE',
        }}
      >
        <ActivityIndicator />
      </View>
    );
  } else {
    if (filteredSeries.length === 0) {
      return (
        <View
          style={{
            flex: 1,
            alignItems: 'center',
            paddingVertical: 20,
          }}
        >
          <div className="mb-4">シリーズは見つかりませんでした</div>
          {buildContactLink()}
        </View>
      );
    } else {
      return <div className="mt-4">{buildContactLink()}</div>;
    }
  }
}
