// @flow
import React from 'react';
import type { Area } from '../../../models/areas/Area';

type Props = {
  area: Area,
};

export default class AreaCard extends React.PureComponent<Props> {
  onClick() {
    // $FlowFixMe
    gtag('event', 'click', {
      event_category: 'ToArea',
      event_label: 'area_card',
    });
  }

  render() {
    const { area } = this.props;
    const areaPath = `/areas/${area.id}`;

    return (
      <div className="col-5 col-md-3 areas carousel-thumb" key={area.id}>
        <div className="areas shadow-card thumb">
          <a
            href={areaPath}
            onClick={this.onClick.bind(this)}
            // eslint-disable-next-line react/jsx-no-target-blank
            target="_blank"
          >
            <div className="image">
              <img src={area.latest_result_small_photo_url} alt={area.name} />
            </div>
          </a>
          <div className="shadow-card-body">
            <a
              href={areaPath}
              onClick={this.onClick.bind(this)}
              // eslint-disable-next-line react/jsx-no-target-blank
              target="_blank"
            >
              <div className="name">
                <h6 className="c-text">{area.name}の釣り場速報</h6>
              </div>
            </a>
            {area.children != null && area.children.length > 0 && (
              <div className="tags">
                {area.children.map((child, index) => {
                  return (
                    <a
                      key={index}
                      href={`/areas/${child.id}`}
                      className="badge badge-light mr-1"
                    >
                      {child.name}
                    </a>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
