// @flow
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Col, Row } from 'reactstrap';
import moment from 'moment';
import type { BaseProps } from '../../../../application';
import type { Account } from '../../../models/accounts/Account';
import type { MyLure } from '../../../models/my_lures/MyLure';
import type { MyLureForm } from '../../../models/my_lures/MyLureForm';
import { ActivityIndicator } from '../../commons/react-native';
import { View } from '../../commons/native-base';
import {
  displayMessage,
  displayAxiosError,
  displayEntityError,
} from '../../commons/Toast';
import { updateMyLures } from '../../../actions/myLures';
import MyLureClient from '../../../models/my_lures/MyLureClient';
import { FormComp } from './FormComp';
import { csrfToken } from '../../../helpers/fetch';

type Props = BaseProps & {
  account: Account,
  updateMyLures: (myLures: Array<MyLure>) => void,
};

type State = {
  form: MyLureForm,
  loading: boolean,
};

class NewView extends React.Component<Props, State> {
  myLureClient = new MyLureClient();

  constructor(props: Props) {
    super(props);

    const myLure = props.location.state && props.location.state.myLure;

    this.state = {
      form: {
        maker: myLure != null && myLure.maker ? myLure.maker : null,
        name: myLure != null ? myLure.name : null,
        color: null,
        memo: '',
        get_date: moment(),
        lost_date: null,
        images: [],
      },
      loading: false,
    };
  }

  onSubmit() {
    const { history, account, updateMyLures } = this.props;
    const { form } = this.state;

    this.setState({ loading: true });
    this.myLureClient
      .create(form, account, csrfToken())
      .then(response => {
        displayMessage('登録が完了しました');
        updateMyLures(response.data);
        history.goBack();
      })
      .catch(error => {
        if (error.response.status === 422) {
          displayEntityError(error.response.data, {
            maker: 'メーカー',
            lurenm: 'ルアー名',
            colornm: 'カラー名',
          });
        } else {
          displayAxiosError(error);
        }
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  render() {
    const { account } = this.props;
    const { form, loading } = this.state;

    if (loading) {
      return (
        <View
          style={{
            paddingVertical: 20,
            borderTopWidth: 1,
            borderColor: '#CED0CE',
          }}
        >
          <ActivityIndicator animating size="large" />
        </View>
      );
    } else {
      return (
        <React.Fragment>
          <div style={{ marginBottom: 30 }}>
            <FormComp
              form={form}
              account={account}
              shorted={false}
              onChangeForm={form => this.setState({ form: form })}
              displayAxiosError={error => displayAxiosError(error)}
            />
          </div>
          <Row>
            <Col sm={3} xs={6}>
              <Button
                block
                color="outline-primary"
                onClick={() => this.props.history.goBack()}
              >
                キャンセル
              </Button>
            </Col>
            <Col sm={3} xs={6}>
              <Button block color="primary" onClick={() => this.onSubmit()}>
                完了
              </Button>
            </Col>
          </Row>
        </React.Fragment>
      );
    }
  }
}

export default connect(
  state => ({
    account: state.account,
  }),
  dispatch => bindActionCreators({ updateMyLures }, dispatch)
)(NewView);
