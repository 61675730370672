// @flow
import React from 'react';
import { Col, Row } from 'reactstrap';

import { Left, ListItem, Right, Text, View } from '../../commons/native-base';
import { ListContent } from '../../commons/list';
import VenueHelper from '../../../helpers/VenueHelper';
import TideInfo from './TideInfo';

import type { VenueWithConditions } from '../../../models/venues/VenueWithConditions';

type Props = {
  venue: ?VenueWithConditions,
};

export default function VenueDetails({ venue }: Props) {
  if (venue != null && VenueHelper.isLand(venue)) {
    return (
      <React.Fragment>
        <ListItem>
          <ListContent>
            <Row>
              <Col xs={3}>観測所</Col>
              <Col xs={9}>{venue.name}</Col>
            </Row>
          </ListContent>
        </ListItem>
        <ListItem>
          <ListContent>
            <Row>
              <Col xs={3}>水位</Col>
              <Col xs={9}>{VenueHelper.suii(venue)}</Col>
            </Row>
          </ListContent>
        </ListItem>
        <ListItem>
          <ListContent>
            <Row>
              <Col xs={3}>前日雨量</Col>
              <Col xs={9}>{VenueHelper.zenuryo(venue)}</Col>
            </Row>
          </ListContent>
        </ListItem>
        {!!VenueHelper.hosuiryo(venue) && (
          <ListItem>
            <ListContent>
              <Row>
                <Col xs={3}>放水量</Col>
                <Col xs={9}>{VenueHelper.hosuiryo(venue)}</Col>
              </Row>{' '}
            </ListContent>
          </ListItem>
        )}
      </React.Fragment>
    );
  } else if (venue != null && VenueHelper.isSea(venue)) {
    return (
      <React.Fragment>
        <ListItem>
          <ListContent>
            <Row>
              <Col xs={3}>観測所</Col>
              <Col xs={9}>{venue.name}</Col>
            </Row>
          </ListContent>
        </ListItem>
        {false && venue.perdays != null && (
          <TideInfo tidePerDays={venue.perdays} />
        )}
      </React.Fragment>
    );
  } else {
    return (
      <ListItem>
        <ListContent>
          <Row>
            <Col xs={3}>観測所</Col>
            <Col xs={9}>未登録</Col>
          </Row>
        </ListContent>
      </ListItem>
    );
  }
}
