// @flow
import React from 'react';
import styled from 'styled-components';
import { breakPoint, viewSp } from '../lib/Mixins';

type BreadItem = {
  title: string,
  href?: string,
};

type Props = {
  items: Array<BreadItem>,
  title?: string,
};

function Breadcrumbs({ items, title }: Props) {
  const list = items.map((item, i) => {
    const breadIndex = i + 1;
    return (
      <li
        key={breadIndex.toString()}
        className="breadcrumb-item"
        itemProp="itemListElement"
        itemScope
        itemType="https://schema.org/ListItem"
      >
        {(() => {
          if (breadIndex !== items.length && item.href) {
            return (
              <a href={`${item.href}`} itemProp="item">
                <span itemProp="name">{item.title}</span>
              </a>
            );
          } else {
            return <span itemProp="name">{item.title}</span>;
          }
        })()}
        <meta itemProp="position" content={breadIndex} />
      </li>
    );
  });

  return (
    <>
      {items.length > 0 && (
        <BreadcrumbWrapper className="breadcrumb-wrapper">
          <Container>
            <div>
              <ol
                className="breadcrumb"
                itemScope
                itemType="https://schema.org/BreadcrumbList"
              >
                {list}
              </ol>
            </div>
            <div>
              <h1>{title ? title : items[items.length - 1].title}</h1>
            </div>
          </Container>
        </BreadcrumbWrapper>
      )}
    </>
  );
}

const BreadcrumbWrapper = styled.div`
  background-color: white;
  width: 100%;

  ol {
    background-color: transparent !important;
  }
`;

const Container = styled.div`
  max-width: ${breakPoint}px;
  margin: 0 auto;

  ${viewSp} {
    padding: 0 20px;
  }

  h1 {
    margin-top: 6px;
  }
`;

export default Breadcrumbs;
