// @flow
import AxiosClient from '../AxiosClient';
import type { ShipWithDetails } from './Ship.js';
import type { AxiosPromise } from 'axios';

export default class ShipClient extends AxiosClient {
  fetchAll(
    page: number,
    perPage: number,
    keyword: ?string,
    prefectureIds: ?Array<number>,
    fishIds: ?Array<number>,
    dreamCupOnly: boolean
  ): AxiosPromise<Array<ShipWithDetails>> {
    return this.buildClient().get(`/api/v2/ships/search`, {
      params: {
        page: page,
        per_page: perPage,
        keyword: keyword,
        prefecture_id_in: prefectureIds,
        fishes_id_in: fishIds,
        dream_cup_only: dreamCupOnly,
      },
    });
  }

  follow(shipId: number, token: string): AxiosPromise<any> {
    return this.buildClient(token).post(`/api/v2/ships/${shipId}/follows.json`);
  }

  stopFollow(shipId: number, token: string): AxiosPromise<any> {
    return this.buildClient(token).delete(
      `/api/v2/ships/${shipId}/follows.json`
    );
  }

  fetchFollowingsByUser(
    page: number,
    id: number
  ): AxiosPromise<Array<ShipWithDetails>> {
    return this.buildClient().get(`/api/v2/users/${id}/followings/ships.json`, {
      params: {
        page: page,
      },
    });
  }
}
