// @flow
import { UPDATE_MY_REEL } from '../actions/types';
import type { MyReel } from '../models/my_reels/MyReel';

const initialState = null;

export default (state: ?MyReel = initialState, action: any): ?MyReel => {
  switch (action.type) {
    case UPDATE_MY_REEL:
      return action.payload;
    default:
      return state;
  }
};
