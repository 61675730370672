// @flow
import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import CloseImage from '../../assets/images/views/ships/close.png';
import styled from 'styled-components';
import { viewPc, viewSp } from '../../lib/Mixins';
import type { Node } from 'react';

type Props = {
  visible: boolean,
  onClose: (status: boolean) => void,
  onClear: () => void,
  title: string,
  onApply: () => void,
  children: Node,
};

function SelectModalRoot(props: Props) {
  // ReactOnRailsの不具合で直接Hooksを使ってるコンポーネントをexportできない
  return <SelectModal {...props} />;
}

function SelectModal({
  visible,
  onClose,
  onClear,
  title,
  onApply,
  children,
}: Props) {
  return (
    <StyledModal isOpen={visible}>
      <ModalHeader>
        <ModalHeaderInnerContainer>
          <Clear onClick={() => onClear()}>クリア</Clear>
          <Title>{title}</Title>
          <Close onClick={() => onClose(false)}>
            <img src={CloseImage} />
          </Close>
        </ModalHeaderInnerContainer>
      </ModalHeader>
      <ModalBody>{children}</ModalBody>
      <ModalFooter>
        <Apply type="button" onClick={() => onApply()}>
          決定
        </Apply>
      </ModalFooter>
    </StyledModal>
  );
}

const modalPaddingSp = '20px';
const modalXPaddingPc = '30px';
const modalYPaddingPc = '24px';

// StyledModalでreactstrapが自動的に生成するスタイルを上書きしてる
const StyledModal = styled(Modal)`
  margin: 15vh auto;
  max-height: 70vh;
  height: 70vh;
  min-width: 650px;
  width: 650px;

  ${viewSp} {
    margin: 0;
    max-height: 100%;
    height: 100%;
    min-width: 100%;
    width: 100%;
  }

  h5 {
    width: 100%;
  }

  .modal-content {
    border: none;
    border-radius: 24px;
    overflow: hidden;
    max-height: inherit;
    min-height: 70vh;
    width: inherit;

    ${viewSp} {
      border-radius: 0;
      min-height: 100%;
    }
  }

  .modal-header {
    background-color: white;
    border: none;
    display: flex;
    align-items: center;
    min-height: 69px;
    height: 69px;
    padding: ${modalYPaddingPc} ${modalXPaddingPc};
    width: 100%;

    ${viewSp} {
      font-size: 16px;
      min-height: 55px;
      height: 55px;
      padding: ${modalPaddingSp};
    }
  }

  .modal-body {
    background-color: #f8f8f8;
    overflow: scroll;
    padding: 0 /* region毎に設定 */ ${modalXPaddingPc} 80px;
    position: relative;

    ${viewSp} {
      padding: 0 /* region毎に設定 */ ${modalPaddingSp} 80px;
    }
  }

  .modal-footer {
    background-color: transparent;
    border: none;
    display: flex;
    justify-content: center;
    padding: ${modalYPaddingPc} ${modalXPaddingPc};
    position: fixed;
    left: 0;
    width: 100%;
    z-index: 2;

    ${viewPc} {
      bottom: 15vh;
    }

    ${viewSp} {
      background-color: white;
      bottom: env(safe-area-inset-bottom);
      padding: ${modalPaddingSp};
    }
  }
`;

const ModalHeaderInnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Clear = styled.div`
  cursor: pointer;
  color: #a0a0a0;
  font-size: 14px;
  font-weight: bold;
  line-height: 1;

  ${viewSp} {
    font-size: 13px;
  }
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: bold;

  ${viewSp} {
    font-size: 16px;
  }
`;

const Close = styled.div`
  img {
    cursor: pointer;
    height: 28px;
    width: 28px;

    ${viewSp} {
      height: 24px;
      width: 24px;
    }
  }
`;

const Apply = styled.button`
  background-color: #0877bc;
  border: none;
  border-radius: 24px;
  box-shadow: 0px 0px 12px #00000029;
  color: white;
  font-size: 15px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  height: 48px;
  width: 290px;

  ${viewSp} {
    width: 100%;
  }
`;

export default SelectModalRoot;
