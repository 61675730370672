// @flow
import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import {
  Icon,
  Body,
  Right,
  Text,
  ListItem,
  List,
} from '../../commons/native-base';

type Props = {
  visible: boolean,
  type: string,
  onApply: (type: string) => void,
  onClose: () => void,
};

export default function OrderModal({ type, visible, onApply, onClose }: Props) {
  return (
    <Modal isOpen={visible} toggle={onClose}>
      <ModalHeader toggle={onClose}>並び順</ModalHeader>
      <ModalBody>
        <List style={{ backgroundColor: '#fff' }}>
          <ListItem onClick={() => onApply('maker')}>
            <Body>
              <Text>メーカー名順</Text>
            </Body>
            {type === 'maker' && (
              <Right>
                <Icon name="checkmark" />
              </Right>
            )}
          </ListItem>
          <ListItem onClick={() => onApply('updatedDesc')}>
            <Body>
              <Text>登録更新順(降順)</Text>
            </Body>
            {type === 'updatedDesc' && (
              <Right>
                <Icon name="checkmark" />
              </Right>
            )}
          </ListItem>
          <ListItem onClick={() => onApply('updatedAsc')}>
            <Body>
              <Text>登録更新順(昇順)</Text>
            </Body>
            {type === 'updatedAsc' && (
              <Right>
                <Icon name="checkmark" />
              </Right>
            )}
          </ListItem>
          <ListItem onClick={() => onApply('getdateDesc')}>
            <Body>
              <Text>使用開始日順(降順)</Text>
            </Body>
            {type === 'getdateDesc' && (
              <Right>
                <Icon name="checkmark" />
              </Right>
            )}
          </ListItem>
          <ListItem onClick={() => onApply('getdateAsc')}>
            <Body>
              <Text>使用開始日順(昇順)</Text>
            </Body>
            {type === 'getdateAsc' && (
              <Right>
                <Icon name="checkmark" />
              </Right>
            )}
          </ListItem>
        </List>
      </ModalBody>
    </Modal>
  );
}
