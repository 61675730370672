// @flow
import React from 'react';

export default function GoogleAdsenseIns() {
  return (
    <div className="mb-20 advertisements">
      <div className="catches_infeed">
        <div className="card">
          <ins
            className="adsbygoogle"
            style={{ display: 'block' }}
            data-ad-format="fluid"
            data-ad-layout-key="-7h+e6+2n-1e-4h"
            data-ad-client="ca-pub-6284018108500346"
            data-ad-slot="5693967272"
          />
          <div className="text-muted text-right card-body">
            <small>プロモーション</small>
          </div>
        </div>
      </div>
    </div>
  );
}
