// @flow
import * as React from 'react';

type Props = {
  children: React.Node,
};

export function ListItem({ children, ...props }: Props) {
  return (
    <div className="listview-item" {...props}>
      {children}
    </div>
  );
}
