// @flow
import React from 'react';
import type { Fish } from '../../models/fishes/Fish';
import { Card, CardBody, CardTitle } from 'reactstrap';

type Props = {
  fish: Fish,
};

export default function FishCard({ fish }: Props) {
  return (
    <Card className="card shadow-card fishes">
      <div className="fish_image_96">
        <img src={fish.image} alt={fish.name} />
      </div>
      <CardBody className="shadow-card-body">
        <CardTitle>{fish.name}</CardTitle>
      </CardBody>
    </Card>
  );
}
