// @flow
import React from 'react';
import { Input, Col, Row } from 'reactstrap';

import { ListItem } from './native-base';
import { ListActions, ListContent } from './list';

type Props = {
  label: ?string,
  type?: string,
  value: any,
  placeholder?: string,
  clearable: boolean,
  onChangeText: string => void,
};

type State = {
  value: any,
};

export default class InputListItem extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      value: props.value != null ? props.value : '',
    };
  }

  static defaultProps = {
    type: 'text',
  };

  // static getDerivedStateFromProps(nextProps: Props, prevState: State) {
  //   if (nextProps.value != prevState.value) {
  //     return {
  //       value: nextProps.value,
  //     };
  //   }
  //   return null;
  // }

  render() {
    const { label, type, placeholder, clearable, onChangeText } = this.props;
    const { value } = this.state;

    return (
      <ListItem>
        <ListContent>
          <Row>
            <Col xs={4}>{label}</Col>
            <Col xs={8}>
              <Input
                type={type}
                name="search"
                value={value}
                placeholder={placeholder}
                onChange={e => {
                  const value = e.target.value;
                  this.setState({ value: value }, () => onChangeText(value));
                }}
              />
            </Col>
          </Row>
        </ListContent>
        <ListActions>
          {clearable && (
            <i
              className="listview-actions-item ion-ios-close-outline"
              onClick={() => {
                this.setState({ value: '' }, () => onChangeText(''));
              }}
            />
          )}
        </ListActions>
      </ListItem>
    );
  }
}
