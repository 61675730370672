// @flow
import React from 'react';
import type { Node } from 'react';
import { FormGroup, Label, Input, Row, Col } from 'reactstrap';
import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';
import type { Account } from '../../../models/accounts/Account';
import type { MyLureForm } from '../../../models/my_lures/MyLureForm';
import type { ImageForm } from '../../../models/images/ImageForm';
import { List, ListItem, Text } from '../../commons/native-base';
import { ListContent, ListActions } from '../../commons/list';
import { SelectMakerModal } from '../../makers/SelectMakerModal';
import InputListItem from '../../commons/InputListItem';
import { ImageList } from '../../commons/ImageList';

const MOMENT_FORMAT = 'YYYY-MM-DD';

type Props = {
  form: MyLureForm,
  account: Account,
  shorted: boolean,
  onChangeForm: (form: MyLureForm, callback: ?() => void) => void,
  displayAxiosError: (error: any) => void,
};

type State = {
  form: MyLureForm,
  visibleMakerModal: boolean,
  startDateFocused: boolean,
  endDateFocused: boolean,
};

export class FormComp extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      form: props.form,
      visibleMakerModal: false,
      startDateFocused: false,
      endDateFocused: false,
    };
  }

  // https://flow.org/en/docs/types/utilities/#toc-shape
  // version次第でPartialに移行すること
  static getDerivedStateFromProps(nextProps: Props): $Shape<State> {
    return {
      form: nextProps.form,
    };
  }

  onRemoveImage(image: ImageForm, index: number) {
    const { onChangeForm } = this.props;
    const { form } = this.state;

    if (image.id != null) {
      form.images[index]._destroy = true;
    } else {
      form.images.splice(index, 1);
    }

    onChangeForm(form);
  }

  onAppendImage(acceptedFiles: Array<any>) {
    const { onChangeForm } = this.props;
    const { form } = this.state;

    form.images.push({
      id: null,
      image: acceptedFiles[0],
      url: URL.createObjectURL(acceptedFiles[0]),
      _destroy: false,
    });

    onChangeForm(form);
  }

  render(): Node {
    const { account, onChangeForm } = this.props;
    const { form, startDateFocused, endDateFocused } = this.state;

    return (
      <React.Fragment>
        <List style={{ backgroundColor: '#fff' }}>
          <ListItem>
            <ListContent
              onClick={() => this.setState({ visibleMakerModal: true })}
              style={{ cursor: 'pointer' }}
            >
              <Row>
                <Col xs={4}>メーカー</Col>
                <Col xs={8}>
                  {form.maker ? (
                    <Text>{form.maker.name}</Text>
                  ) : (
                    <Text className="text-muted">メーカーを選択</Text>
                  )}
                </Col>
              </Row>
            </ListContent>
            <ListActions>
              <i
                onClick={() => {
                  onChangeForm({
                    ...form,
                    maker: null,
                  });
                }}
                className="listview-actions-item ion-ios-close-outline"
              />
            </ListActions>
          </ListItem>
          <InputListItem
            label="ルアー名"
            value={form.name}
            clearable={true}
            onChangeText={text => {
              onChangeForm({
                ...form,
                name: text !== '' ? text : null,
              });
            }}
          />
          <InputListItem
            label="カラー名"
            value={form.color}
            clearable={true}
            onChangeText={text => {
              onChangeForm({
                ...form,
                color: text !== '' ? text : null,
              });
            }}
          />
          {!this.props.shorted && (
            <React.Fragment>
              <ListItem>
                <ListContent>
                  <Row>
                    <Col xs={4}>使用開始日</Col>
                    <Col xs={8}>
                      <SingleDatePicker
                        id="get_date"
                        date={form.get_date}
                        onDateChange={date => {
                          onChangeForm({
                            ...form,
                            get_date: date,
                          });
                        }}
                        focused={startDateFocused}
                        onFocusChange={({ focused }) =>
                          this.setState({ startDateFocused: focused })
                        }
                        numberOfMonths={1}
                        displayFormat={MOMENT_FORMAT}
                        transitionDuration={0}
                        isOutsideRange={() => false}
                        placeholder="使用開始日を指定"
                      />
                    </Col>
                  </Row>
                </ListContent>
                <ListActions>
                  <i
                    onClick={() => {
                      this.props.onChangeForm({
                        ...form,
                        get_date: null,
                      });
                    }}
                    className="listview-actions-item ion-ios-close-outline"
                  />
                </ListActions>
              </ListItem>
              <ListItem>
                <ListContent>
                  <Row>
                    <Col xs={4}>使用終了日</Col>
                    <Col xs={8}>
                      <SingleDatePicker
                        id="lost_date"
                        date={form.lost_date}
                        onDateChange={date =>
                          onChangeForm({
                            ...form,
                            lost_date: date,
                          })
                        }
                        focused={endDateFocused}
                        onFocusChange={({ focused }) =>
                          this.setState({ endDateFocused: focused })
                        }
                        numberOfMonths={1}
                        displayFormat={MOMENT_FORMAT}
                        transitionDuration={0}
                        isOutsideRange={() => false}
                        placeholder="使用終了日を指定"
                      />
                    </Col>
                  </Row>
                </ListContent>
                <ListActions>
                  <i
                    onClick={() => {
                      onChangeForm({
                        ...form,
                        lost_date: null,
                      });
                    }}
                    className="listview-actions-item ion-ios-close-outline"
                  />
                </ListActions>
              </ListItem>
            </React.Fragment>
          )}
        </List>
        <FormGroup
          style={{
            borderTop: '1px solid #f0f0f0',
            paddingTop: 16,
            paddingBottom: 32,
          }}
        >
          <Label>メモ</Label>
          <Input
            type="textarea"
            placeholder="このルアーに関するメモを入力"
            value={form.memo}
            onChange={e => {
              this.props.onChangeForm({
                ...form,
                memo: e.target.value,
              });
            }}
          />
        </FormGroup>

        <FormGroup className="mb-20">
          <Label>画像</Label>
          <ImageList
            images={this.state.form.images}
            onRemoveImage={(image, index) => this.onRemoveImage(image, index)}
            onAppendImage={acceptedFiles => this.onAppendImage(acceptedFiles)}
          />
        </FormGroup>

        <SelectMakerModal
          visible={this.state.visibleMakerModal}
          productType="Lure"
          account={account}
          selectedMaker={form.maker}
          onApply={maker => {
            console.log('onApply', maker);
            onChangeForm({
              ...form,
              maker: maker,
            });
            this.setState({ visibleMakerModal: false });
          }}
          onClose={() => this.setState({ visibleMakerModal: false })}
        />
      </React.Fragment>
    );
  }
}
