// @flow
import * as types from './types';
import type { MyRod } from '../models/my_rods/MyRod';

export function updateMyRods(myRods: Array<MyRod>): any {
  return {
    type: types.UPDATE_MY_RODS,
    payload: myRods,
  };
}

export function updateMyRod(myRod: MyRod): any {
  return {
    type: types.UPDATE_MY_ROD,
    payload: myRod,
  };
}
