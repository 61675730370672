// @flow
import { UPDATE_MY_RODS } from '../actions/types';
import type { MyRod } from '../models/my_rods/MyRod';

const initialState = [];

export default (
  state: Array<MyRod> = initialState,
  action: any
): Array<MyRod> => {
  switch (action.type) {
    case UPDATE_MY_RODS:
      return action.payload;
    default:
      return state;
  }
};
