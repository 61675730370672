// @flow
import React from 'react';
import styled from 'styled-components';
import { viewSp } from '../../../lib/Mixins';

type Props = {
  isSignedIn: boolean,
  isFollowing: boolean,
  onPress: () => void,
};

function FollowButtonRoot(props: Props) {
  // ReactOnRailsの不具合で直接Hooksを使ってるコンポーネントをexportできない
  return <FollowButton {...props} />;
}

function FollowButton({ isSignedIn, isFollowing, onPress }: Props) {
  return (
    <Container>
      {isSignedIn && (
        <>
          {isFollowing && (
            <UnFollow onClick={() => onPress()}>フォロー中</UnFollow>
          )}
          {!isFollowing && (
            <Follow onClick={() => onPress()}>
              <Plus>＋</Plus>フォロー
            </Follow>
          )}
        </>
      )}
      {!isSignedIn && (
        <a href="/users/sign_in">
          <Follow>
            <Plus>＋</Plus>フォロー
          </Follow>
        </a>
      )}
    </Container>
  );
}

const Container = styled.div`
  a {
    &:hover {
      text-decoration: none;
    }
  }
`;

const Follow = styled.div`
  cursor: pointer;
  background-color: #0877bc;
  border: none;
  border-radius: 16px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  height: 32px;
  width: 100px;

  ${viewSp} {
    font-size: 12px;
    height: 28px;
    height: 28px;
    width: 90px;
  }

  img {
    margin-right: 3px;
    width: 16px;

    ${viewSp} {
      width: 14px;
    }
  }
`;

const UnFollow = styled(Follow)`
  box-sizing: border-box;
  background-color: white;
  border: 1.5px solid #0877bc;
  color: #0877bc;
`;

const Plus = styled.span`
  margin-right: 2px;
`;

export default FollowButtonRoot;
