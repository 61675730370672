// @flow
import React from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState } from 'draft-js';
import SummernoteClient from '../../models/summernotes/SummernoteClient';

type Props = {
  editorState: EditorState,
  onEditorStateChange: EditorState => void,
};

export default function WysiwygEditor({
  editorState,
  onEditorStateChange,
}: Props) {
  const uploadCallback = (file: File) => {
    return new Promise((resolve, reject) => {
      const client = new SummernoteClient();
      client
        .create(file)
        .then(response => resolve({ data: { link: response.data.url } }))
        .catch(error => reject(error));
    });
  };

  return (
    <Editor
      editorState={editorState}
      onEditorStateChange={onEditorStateChange}
      localization={{ locale: 'ja' }}
      wrapperClassName="react-draft-wysiwyg-wrapper"
      editorClassName="react-draft-wysiwyg-editor"
      toolbarClassName="react-draft-wysiwyg-toolbar"
      toolbar={{
        options: [
          'inline',
          'blockType',
          'fontSize',
          'list',
          'textAlign',
          'colorPicker',
          'link',
          'image',
        ],
        inline: { inDropdown: true },
        blockType: {
          options: [
            'Normal',
            'H1',
            'H2',
            'H3',
            'H4',
            'H5',
            'H6',
            'Blockquote',
            'Code',
          ],
        },
        list: { inDropdown: true },
        textAlign: { inDropdown: true },
        link: { inDropdown: true },
        image: {
          uploadCallback: uploadCallback,
          previewImage: true,
        },
      }}
    />
  );
}
