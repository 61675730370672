// @flow
import { useState, useEffect } from 'react';
import ShipClient from '../../../models/ships/ShipClient.js';
import type { ShipWithDetails } from '../../../models/ships/Ship.js';
import type { Prefecture } from '../../../models/prefectures/Prefecture.js';
import type { FishWithDetails } from '../../../models/fishes/Fish.js';
import { hasNextPageByHeaders } from '../../../utils/commonUtils';

export type Params = {
  page: number,
  perPage: number,
  keyword: string,
  prefectures: Array<Prefecture>,
  dreamCupOnly: boolean,
  fishes: Array<FishWithDetails>,
  refreshing: boolean,
};

export type State = [
  {
    ships: Array<ShipWithDetails>,
    totalCount: number,
    isLoading: boolean,
    hasNextPage: boolean,
    params: Params,
  },
  (Params) => void
];

export function useFetchApi(initialParams: ?Params): State {
  const [isLoading, setIsLoading] = useState(true);
  const [ships, setShips] = useState<Array<ShipWithDetails>>([]);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [params, setParams] = useState<Params>({
    page: 1,
    perPage: 20,
    keyword: '',
    prefectures: [],
    fishes: [],
    dreamCupOnly: false,
    refreshing: true,
    ...initialParams,
  });

  useEffect(() => {
    let canceled = false;
    const {
      page,
      perPage,
      keyword,
      prefectures,
      fishes,
      dreamCupOnly,
      refreshing,
    } = params;

    setIsLoading(true);
    if (refreshing) {
      // あらかじめクリアにしておかないとローディング画像が見えづらい
      setShips([]);
    }

    new ShipClient()
      .fetchAll(
        page,
        perPage,
        keyword,
        prefectures.map(p => p.id),
        fishes.map(f => f.id),
        dreamCupOnly
      )
      .then(response => {
        if (canceled) return;

        // unique
        setShips(ships =>
          [...ships, ...response.data].filter(
            (s, i, self) => self.map(s2 => s2.id).indexOf(s.id) === i
          )
        );
        setTotalCount(
          Number(response.headers ? Number(response.headers.total) : 0)
        );
        setHasNextPage(hasNextPageByHeaders(response.headers));
      })
      .finally(() => setIsLoading(false));

    return () => {
      canceled = true;
    };
  }, [
    params.page,
    params.keyword,
    params.refreshing,
    params.prefectures,
    params.fishes,
    params.dreamCupOnly,
  ]);

  return [
    {
      ships,
      totalCount,
      isLoading,
      hasNextPage,
      params,
    },
    setParams,
  ];
}
