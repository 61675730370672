// @flow
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';

import { updateMyFields } from '../../../actions/fields';
import FieldListItem from './FieldListItem';
import { FlatList, ActivityIndicator } from '../../commons/react-native';
import { View, Text } from '../../commons/native-base';
import FieldClient from '../../../models/fields/FieldClient';
import { displayAxiosError } from '../../commons/Toast';

import type { BaseProps } from '../../../../application';
import type { Field } from '../../../models/fields/Field';
import type { Account } from '../../../models/accounts/Account';

type Props = BaseProps & {
  account: Account,
  myField: Field,
  myFields: Array<Field>,
  updateMyFields: (myFields: Array<Field>) => void,
};

type State = {
  fields: Array<Field>,
  isLoading: boolean,
};

class IndexView extends React.Component<Props, State> {
  fieldClient = new FieldClient();

  constructor(props: Props) {
    super(props);

    this.state = {
      fields: [],
      isLoading: true,
    };
  }

  componentDidMount() {
    this.fetchMyFields();
  }

  fetchMyFields() {
    const { account, updateMyFields } = this.props;

    this.fieldClient
      .fetchMyFields(account)
      .then(response => {
        this.setState({ isLoading: false, fields: response.data }, () =>
          updateMyFields(response.data)
        );
      })
      .catch(error => {
        this.setState({ isLoading: false });
        displayAxiosError(error);
      });
  }

  renderFooter = () => {
    const { fields, isLoading } = this.state;

    if (isLoading) {
      return (
        <View
          style={{
            paddingVertical: 20,
            borderTopWidth: 1,
            borderColor: '#CED0CE',
          }}
        >
          <ActivityIndicator animating size="large" />
        </View>
      );
    } else if (fields.length === 0) {
      return (
        <View
          style={{
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 10,
          }}
        >
          <Text>良く行くフィールドを登録してみましょう。</Text>
          <Text>いつでもコンディションをチェックできます。</Text>
        </View>
      );
    } else {
      return (
        <Row>
          <Col md={6}>
            <Link to="/my/fields/new" className="btn btn-block btn-primary">
              フィールドを登録する
            </Link>
          </Col>
        </Row>
      );
    }
  };

  render() {
    const { history } = this.props;
    const { fields } = this.state;

    return (
      <React.Fragment>
        {false && (
          <div className="listview bordered mb-20">
            {fields.map(field => (
              <FieldListItem key={field.id} history={history} field={field} />
            ))}
          </div>
        )}
        <FlatList
          data={fields}
          renderItem={item => (
            <FieldListItem key={item.id} history={history} field={item} />
          )}
          ListFooterComponent={this.renderFooter}
        />
      </React.Fragment>
    );
  }
}

export default connect(
  state => ({
    account: state.account,
    myField: state.myField,
    myFields: state.myFields,
  }),
  dispatch => bindActionCreators({ updateMyFields }, dispatch)
)(IndexView);
