// @flow
import { UPDATE_POSITION } from '../actions/types';
import type { Position } from '../models/positions/Position';

export const initialState: Position = {
  // TODO
  coords: {
    latitude: 34.992877,
    longitude: 135.907033,
    accuracy: null,
  },
  timestamp: null,
};

export default function position(
  state: Position = initialState,
  action: any
): Position {
  switch (action.type) {
    case UPDATE_POSITION:
      return action.payload;
    default:
      return state;
  }
}
