// @flow
import type { Venue } from '../venues/Venue';

// 編集モード
export const M_EDIT = 1;

export type FieldForm = {
  name: ?string,
  lat: ?number,
  lng: ?number,
  venue: ?Venue,
};

export const initialValues: FieldForm = {
  name: '',
  lat: null,
  lng: null,
  venue: null,
};
