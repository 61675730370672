// @flow
import React from 'react';
import AreaClient from '../../models/areas/AreaClient';
import type { Area } from '../../models/areas/Area';
import type { Position } from '../../models/positions/Position';
import * as linkHeaderParser from 'linkheader-parser/dist/linkheader-parser-node';
import { computeDistanceBetween } from '../../lib/Geometory';
import { STYLE_OPTIONS } from '../commons/GoogleMaps';
import PositionRepository from '../../models/positions/PositionRepository';

export type Props = {
  apiKey: string,
  backendApiKey: string,
  lat: number,
  lng: number,
  centerAreaId: ?number,
  userId: ?number,
  userName: ?string,
};

export type State = {
  areas: Array<Area>,
  activeArea: ?Area,
  position: Position,
  isLoading: boolean,
  page: number,
  hasNextPage: boolean,
  address: string,
};

export class IndexBase<Props: Props, State: State> extends React.Component<
  Props,
  State
> {
  defaultPosition: Position;
  positionRepository: PositionRepository;
  areaClient = new AreaClient();

  constructor(props: Props) {
    super(props);

    this.positionRepository = new PositionRepository();

    if (props.centerAreaId != null) {
      this.defaultPosition = {
        coords: {
          latitude: props.lat,
          longitude: props.lng,
        },
      };
    } else {
      this.defaultPosition = this.positionRepository.load() || {
        coords: {
          latitude: props.lat,
          longitude: props.lng,
        },
      };
    }
  }

  onChangeAddress = (lat: number, lng: number) => {
    const position = {
      coords: {
        latitude: lat,
        longitude: lng,
      },
    };
    this.setState(
      {
        areas: [],
        activeArea: null,
        page: 1,
        isLoading: true,
        hasNextPage: true,
        position: position,
      },
      () => {
        this.positionRepository.save(position);
        this.fetchAreas();
      }
    );
  };

  mapOptions = () => {
    return {
      maxZoom: 13,
      styles: STYLE_OPTIONS,
    };
  };

  onChangeMap = (e: any) => {
    setTimeout(() => {
      const { latitude, longitude } = this.state.position.coords;
      const { lat, lng } = e.center;
      const distance = computeDistanceBetween(latitude, longitude, lat, lng);

      // Don't retrieve areas unless 5Km
      if (distance > 5) {
        this.onChangeAddress(lat, lng);
      }
    }, 1000);
  };

  componentDidMount() {
    const { lat, lng, centerAreaId } = this.props;
    const { latitude, longitude } = this.state.position.coords;

    if (centerAreaId == null && lat === latitude && lng === longitude) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const pos = ((position: any): Position);

          this.setState({ position: pos }, () => {
            const distance = computeDistanceBetween(
              latitude,
              longitude,
              pos.coords.latitude,
              pos.coords.longitude
            );

            // Don't retrieve areas unless 5Km
            if (distance > 5) {
              this.onChangeAddress(pos.coords.latitude, pos.coords.longitude);
            }
          });
          this.positionRepository.save(pos);
        },
        error => {
          console.warn(error);
        },
        {
          enableHighAccuracy: true,
          timeout: 20000,
          maximumAge: 1000,
        }
      );
    }
    this.fetchAreas();
  }

  fetchAreas() {
    const { page } = this.state;
    const { latitude, longitude } = this.state.position.coords;

    this.areaClient
      .fetchAllByLocation(page, latitude, longitude)
      .then(response => {
        const hasNextPage =
          response.headers &&
          response.headers.hasOwnProperty('link') &&
          linkHeaderParser.parse(response.headers.link).hasOwnProperty('next');
        this.setState({
          areas: [...this.state.areas, ...response.data],
          hasNextPage: hasNextPage,
          isLoading: false,
        });
      })
      .catch(error => {
        console.warn(error);
      });
  }
}
