// @flow
import React from 'react';
import moment from 'moment';
import { Carousel, CarouselControl, CarouselIndicators } from 'reactstrap';
import VenueHelper from '../../../helpers/VenueHelper';
import type { TidePerDay } from '../../../models/venues/TidePerDay';
import { ListItem, Text, View } from '../../commons/native-base';
import IcoMoon from '../../commons/IcoMoon';
import TideGraphReact from '../../venues/TideGraphReact';

type Props = {
  tidePerDays: Array<TidePerDay>,
};

type State = {
  carouselIndex: number,
};

export default class TideInfo extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      carouselIndex: 0,
    };
  }

  next() {
    const { tidePerDays } = this.props;
    const { carouselIndex } = this.state;
    const nextIndex =
      carouselIndex === tidePerDays.length - 1 ? 0 : carouselIndex + 1;
    this.setState({ carouselIndex: nextIndex });
  }

  previous() {
    const { tidePerDays } = this.props;
    const { carouselIndex } = this.state;
    const nextIndex =
      carouselIndex === 0 ? tidePerDays.length - 1 : carouselIndex - 1;
    this.setState({ carouselIndex: nextIndex });
  }

  timeFormat(time: ?string) {
    return time != null ? moment(time, 'HHmm').format('HH:mm') : '';
  }

  renderTideHours(
    tide1time: ?string,
    tide2time: ?string,
    tide3time: ?string,
    tide4time: ?string
  ) {
    return (
      <View>
        {[tide1time, tide2time, tide3time, tide4time].map((tidetime, index) => {
          if (tidetime != null) {
            return <Text key={index}>{this.timeFormat(tidetime)}</Text>;
          }
        })}
      </View>
    );
  }

  renderTides(tide1: ?number, tide2: ?number, tide3: ?number, tide4: ?number) {
    return (
      <View>
        {[tide1, tide2, tide3, tide4].map((tide, index) => {
          if (tide != null) {
            return <Text key={index}>{VenueHelper.tide({ tide: tide })}</Text>;
          }
        })}
      </View>
    );
  }

  render() {
    const { tidePerDays } = this.props;
    const { carouselIndex } = this.state;

    return (
      <React.Fragment>
        <Carousel
          inactiveSlideScale={1.0}
          onSnapToItem={index => this.setState({ carouselIndex: index })}
        >
          <CarouselIndicators
            items={tidePerDays}
            activeIndex={carouselIndex}
            onClickHandler={newIndex =>
              this.setState({ carouselIndex: newIndex })
            }
          />
          {tidePerDays.map((tidePerDay, i) => (
            <TideGraphReact
              key={i}
              tidePerDay={tidePerDay}
              datetime={i === 0 ? moment() : null}
            />
          ))}
          <CarouselControl
            direction="prev"
            directionText="Previous"
            onClickHandler={this.previous}
          />
          <CarouselControl
            direction="next"
            directionText="Next"
            onClickHandler={this.next}
          />
        </Carousel>
        <ListItem>
          <Text style={{ flex: 1 }}>満潮</Text>
          <View style={{ flex: 1, flexDirection: 'column' }}>
            {this.renderTideHours(
              tidePerDays[carouselIndex].hightide1time,
              tidePerDays[carouselIndex].hightide2time,
              tidePerDays[carouselIndex].hightide3time,
              tidePerDays[carouselIndex].hightide4time
            )}
          </View>
          <View style={{ flex: 1, flexDirection: 'column' }}>
            {this.renderTides(
              tidePerDays[carouselIndex].hightide1,
              tidePerDays[carouselIndex].hightide2,
              tidePerDays[carouselIndex].hightide3,
              tidePerDays[carouselIndex].hightide4
            )}
          </View>
        </ListItem>
        <ListItem>
          <Text style={{ flex: 1 }}>干潮</Text>
          <View style={{ flex: 1, flexDirection: 'column' }}>
            {this.renderTideHours(
              tidePerDays[carouselIndex].lowtide1time,
              tidePerDays[carouselIndex].lowtide2time,
              tidePerDays[carouselIndex].lowtide3time,
              tidePerDays[carouselIndex].lowtide4time
            )}
          </View>
          <View style={{ flex: 1, flexDirection: 'column' }}>
            {this.renderTides(
              tidePerDays[carouselIndex].lowtide1,
              tidePerDays[carouselIndex].lowtide2,
              tidePerDays[carouselIndex].lowtide3,
              tidePerDays[carouselIndex].lowtide4
            )}
          </View>
        </ListItem>
        <ListItem>
          <IcoMoon name="icon-sunriseset" style={{ flex: 1 }} />
          <Text style={{ flex: 1 }}>
            出）
            {this.timeFormat(tidePerDays[carouselIndex].sunrise)}
          </Text>
          <Text style={{ flex: 1 }}>
            入）
            {this.timeFormat(tidePerDays[carouselIndex].sunset)}
          </Text>
        </ListItem>
        <ListItem>
          <IcoMoon name="icon-moonriseset" style={{ flex: 1 }} />
          <Text style={{ flex: 1 }}>
            出）
            {this.timeFormat(tidePerDays[carouselIndex].moonrise)}
          </Text>
          <Text style={{ flex: 1 }}>
            入）
            {this.timeFormat(tidePerDays[carouselIndex].moonset)}
          </Text>
        </ListItem>
        <ListItem>
          <Text style={{ flex: 1 }}>潮名</Text>
          <Text style={{ flex: 2 }}>{tidePerDays[carouselIndex].shionm}</Text>
        </ListItem>
        <ListItem>
          <Text style={{ flex: 1 }}>月齢</Text>
          <Text style={{ flex: 2 }}>{tidePerDays[carouselIndex].moonage}</Text>
        </ListItem>
      </React.Fragment>
    );
  }
}
