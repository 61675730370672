// @flow
import React from 'react';
import type { Area } from '../../models/areas/Area';
import type { Fish } from '../../models/fishes/Fish';
import FishClient from '../../models/fishes/FishClient';
import { displayAxiosError } from '../commons/messages';

type Props = {
  area: Area,
  excludeFishId?: number,
};

type State = {
  fishes: Array<Fish>,
};

export default class ExpandableFishes extends React.PureComponent<
  Props,
  State
> {
  fishClient = new FishClient();

  constructor(props: Props) {
    super(props);

    this.state = {
      fishes: [],
    };
  }

  componentDidMount() {
    const { area, excludeFishId } = this.props;

    this.fishClient
      .fetchAllByArea(area.id)
      .then(response => {
        this.setState({
          fishes: response.data
            .filter(f => f.id !== excludeFishId)
            .splice(0, 5),
        });
      })
      .catch(error => {
        displayAxiosError(error);
      });
  }

  render() {
    const { area, excludeFishId } = this.props;
    const { fishes } = this.state;

    if (fishes == null || fishes.length < 1) {
      return null;
    }

    return (
      <div className="accordion" id="accordionFishes">
        <h2 className="mb-0">
          <button
            className="btn btn-lg collapsed h2 font-weight-bold text-left"
            type="button"
            data-toggle="collapse"
            data-target="#collapseFishes"
            aria-expanded="false"
            aria-controls="collapseFishes"
          >
            {excludeFishId != null
              ? `${area.name}で釣れている他の魚`
              : `${area.name}で釣れている魚`}
          </button>
        </h2>
        <div
          id="collapseFishes"
          className="collapse"
          data-parent="#accordionFishes"
        >
          {fishes.map(f => (
            <div key={f.id} className="row">
              <div className="h5 col">
                <a
                  className="badge badge-light"
                  href={`/areas/${area.id}/fishes/${f.id}`}
                >
                  {f.name}
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
