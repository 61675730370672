// @flow
import { UPDATE_MY_ROD } from '../actions/types';
import type { MyRod } from '../models/my_rods/MyRod';

const initialState = null;

export default (state: ?MyRod = initialState, action: any): ?MyRod => {
  switch (action.type) {
    case UPDATE_MY_ROD:
      return action.payload;
    default:
      return state;
  }
};
