// @flow
import { UPDATE_MY_LURES } from '../actions/types';
import type { MyLure } from '../models/my_lures/MyLure';

const initialState = [];

export default (
  state: Array<MyLure> = initialState,
  action: any
): Array<MyLure> => {
  switch (action.type) {
    case UPDATE_MY_LURES:
      return action.payload;
    default:
      return state;
  }
};
