// @flow
import { UPDATE_MY_FIELD } from '../actions/types';
import type { Field } from '../models/fields/Field';

export const initialState = null;

export default (state: ?Field = initialState, action: any): ?Field => {
  if (action.type === UPDATE_MY_FIELD) {
    return action.payload;
  } else {
    return state;
  }
};
