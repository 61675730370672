// @flow
import JQueryView from '../../../common/views/JQueryView';
import { STYLE_OPTIONS } from '../commons/GoogleMaps';
import type { Prefecture } from '../../models/prefectures/Prefecture';

declare var google: any;

export default class ShowView extends JQueryView {
  prefecture: Prefecture;

  constructor(prefecture: Prefecture) {
    super('.content');
    this.prefecture = prefecture;
    this.render();
  }

  renderGoogleMap() {
    const latLng = new google.maps.LatLng(
      this.prefecture.lat,
      this.prefecture.lng
    );
    console.log(this.prefecture.lat);

    const map = new google.maps.Map(document.getElementById('area_map'), {
      zoom: 9,
      center: latLng,
      mapTypeControl: false,
      streetViewControl: false,
      mapTypeControlOptions: {
        style: google.maps.MapTypeControlStyle.DEFAULT,
      },
      mapTypeId: google.maps.MapTypeId.ROADMAP,
    });

    map.mapTypes.set(
      'angelers',
      new google.maps.StyledMapType(STYLE_OPTIONS, { name: 'angelers' })
    );
    map.setMapTypeId('angelers');
  }

  render() {
    // this.renderGoogleMap();
    return this;
  }
}
