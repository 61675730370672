// @flow
import React from 'react';
import type { Node } from 'react';
import { Provider } from 'react-redux';
import { Link, Route, Router, Switch } from 'react-router-dom';
import type { Location, Match, RouterHistory } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import classNames from 'classnames';
import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga4';
import createApplicationStore from '../../store/applicationStore';
import { Toast } from '../commons/Toast';
import IndexView from './timelines/IndexView';
import { default as MyFollowingsIndexView } from './followings/IndexView';
import { default as MyUsersBlocksView } from './users/BlocksView';
import { default as MyAreasIndexView } from './areas/IndexView';
import { default as MyFieldsIndexView } from './fields/IndexView';
import { default as MyFieldsShowView } from './fields/ShowView';
import { default as MyFieldsNewView } from './fields/NewView';
import { default as MyFieldsEditView } from './fields/EditView';
import { default as MyRodsIndexView } from './rods/IndexView';
import { default as MyRodsShowView } from './rods/ShowView';
import { default as MyRodsNewView } from './rods/NewView';
import { default as MyRodsEditView } from './rods/EditView';
import { default as MyReelsIndexView } from './reels/IndexView';
import { default as MyReelsShowView } from './reels/ShowView';
import { default as MyReelsNewView } from './reels/NewView';
import { default as MyReelsEditView } from './reels/EditView';
import { default as MyLuresIndexView } from './lures/IndexView';
import { default as MyLuresShowView } from './lures/ShowView';
import { default as MyLuresNewView } from './lures/NewView';
import { default as MyLuresEditView } from './lures/EditView';
import { default as MyLuresColorsView } from './lures/ColorsView';
import { default as MyProductReviewsIndexView } from './product_reviews/IndexView';
import { default as MyShipsIndexView } from './ships/IndexView';
import { ProfileComp } from './users/ProfileComp';
import { default as MagazineListComp } from '../magazines/MagazineListComp';
import { BuildSubDomainURL } from '../../lib/BuildSubDomainURL';

// ライブラリなのでanyで型を無視する
export const history: any = createBrowserHistory();

export type BaseProps = {
  location: Location,
  history: RouterHistory,
  match: Match,
};

type Props = {
  account: any,
  mobile: boolean,
  settings: {
    googleAnalyticsTrackingID: string,
    googleMapsApiKey: string,
    baseUrl: string,
  },
  railsEnv: string,
};

export default function Application({
  account,
  mobile,
  settings,
  railsEnv,
}: Props): Node {
  ReactGA.initialize(settings.googleAnalyticsTrackingID);

  history.listen(location => {
    if (railsEnv !== 'development') {
      ReactGA.set({ page_path: location.pathname });
      ReactGA.send({ hitType: 'pageview', page: location.pathname });
    }
  });

  const store = createApplicationStore({ account, mobile, settings, railsEnv });
  const user = account.user;

  return (
    <Provider store={store}>
      <Router history={history}>
        <Switch>
          <Route
            path="/"
            render={({ location }: any) => (
              <Row>
                {!mobile && (
                  <Col md={3}>
                    <div className="my menu">
                      <div className="list-group">
                        <Link
                          to="/my"
                          className={classNames(
                            'list-group-item list-group-item-action d-flex justify-content-between align-items-center',
                            {
                              active:
                                location.pathname === '/' ||
                                location.pathname === '/my',
                            }
                          )}
                        >
                          タイムライン
                          <i className="fa fa-angle-right text-muted" />
                        </Link>
                        <a
                          href={`/users/${user.id}`}
                          className="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
                        >
                          プロフィール
                          <i className="fa fa-angle-right text-muted" />
                        </a>
                        <a
                          href={BuildSubDomainURL('tacklebox', 'favorites')}
                          className="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
                        >
                          お気に入り（タックルボックス）
                          <i className="fa fa-angle-right text-muted" />
                        </a>
                        <Link
                          to="/my/followings"
                          className={classNames(
                            'list-group-item list-group-item-action d-flex justify-content-between align-items-center',
                            {
                              active: location.pathname.includes(
                                '/my/followings'
                              ),
                            }
                          )}
                        >
                          フォロー中のユーザー
                          <i className="fa fa-angle-right text-muted" />
                        </Link>
                        <Link
                          to="/my/users/blocks"
                          className={classNames(
                            'list-group-item list-group-item-action d-flex justify-content-between align-items-center',
                            {
                              active: location.pathname.includes(
                                '/my/users/blocks'
                              ),
                            }
                          )}
                        >
                          ブロック中のユーザー
                          <i className="fa fa-angle-right text-muted" />
                        </Link>
                        <Link
                          to="/my/ships"
                          className={classNames(
                            'list-group-item list-group-item-action d-flex justify-content-between align-items-center',
                            {
                              active: location.pathname.includes('/my/ships'),
                            }
                          )}
                        >
                          フォロー中の船宿・釣船
                          <i className="fa fa-angle-right text-muted" />
                        </Link>
                        <Link
                          to="/my/areas"
                          className={classNames(
                            'list-group-item list-group-item-action d-flex justify-content-between align-items-center',
                            {
                              active: location.pathname.includes('/my/areas'),
                            }
                          )}
                        >
                          お気に入りのエリア
                          <i className="fa fa-angle-right text-muted" />
                        </Link>
                        <Link
                          to="/my/fields"
                          className={classNames(
                            'list-group-item list-group-item-action d-flex justify-content-between align-items-center',
                            {
                              active: location.pathname.includes('/my/fields'),
                            }
                          )}
                        >
                          フィールド
                          <i className="fa fa-angle-right text-muted" />
                        </Link>
                        <Link
                          to="/my/rods"
                          className={classNames(
                            'list-group-item list-group-item-action d-flex justify-content-between align-items-center',
                            {
                              active: location.pathname.includes('/my/rods'),
                            }
                          )}
                        >
                          ロッド
                          <i className="fa fa-angle-right text-muted" />
                        </Link>
                        <Link
                          to="/my/reels"
                          className={classNames(
                            'list-group-item list-group-item-action d-flex justify-content-between align-items-center',
                            {
                              active: location.pathname.includes('/my/reels'),
                            }
                          )}
                        >
                          リール
                          <i className="fa fa-angle-right text-muted" />
                        </Link>
                        <Link
                          to="/my/lures"
                          className={classNames(
                            'list-group-item list-group-item-action d-flex justify-content-between align-items-center',
                            {
                              active: location.pathname.includes('/my/lures'),
                            }
                          )}
                        >
                          ルアー
                          <i className="fa fa-angle-right text-muted" />
                        </Link>
                        <Link
                          to="/my/product_reviews"
                          className={classNames(
                            'list-group-item list-group-item-action d-flex justify-content-between align-items-center',
                            {
                              active: location.pathname.includes(
                                '/my/product_reviews'
                              ),
                            }
                          )}
                        >
                          レビュー
                          <i className="fa fa-angle-right text-muted" />
                        </Link>
                      </div>
                    </div>
                  </Col>
                )}
                <Col md={5}>
                  <Switch>
                    <Route path="/" component={IndexView} exact />
                    <Route path="/my" component={IndexView} exact />
                    <Route
                      path="/my/followings"
                      component={MyFollowingsIndexView}
                      exact
                    />
                    <Route
                      path="/my/users/blocks"
                      component={MyUsersBlocksView}
                      exact
                    />
                    <Route
                      path="/my/areas"
                      component={MyAreasIndexView}
                      exact
                    />
                    <Route
                      path="/my/fields"
                      component={MyFieldsIndexView}
                      exact
                    />
                    <Route
                      path="/my/fields/new"
                      component={MyFieldsNewView}
                      exact
                    />
                    <Route
                      path="/my/fields/:id"
                      component={MyFieldsShowView}
                      exact
                    />
                    <Route
                      path="/my/fields/:id/edit"
                      component={MyFieldsEditView}
                      exact
                    />
                    <Route path="/my/rods" component={MyRodsIndexView} exact />
                    <Route
                      path="/my/rods/new"
                      component={MyRodsNewView}
                      exact
                    />
                    <Route
                      path="/my/rods/:id"
                      component={MyRodsShowView}
                      exact
                    />
                    <Route
                      path="/my/rods/:id/edit"
                      component={MyRodsEditView}
                      exact
                    />
                    <Route
                      path="/my/reels"
                      component={MyReelsIndexView}
                      exact
                    />
                    <Route
                      path="/my/reels/new"
                      component={MyReelsNewView}
                      exact
                    />
                    <Route
                      path="/my/reels/:id"
                      component={MyReelsShowView}
                      exact
                    />
                    <Route
                      path="/my/reels/:id/edit"
                      component={MyReelsEditView}
                      exact
                    />
                    <Route
                      path="/my/lures"
                      component={MyLuresIndexView}
                      exact
                    />
                    <Route
                      path="/my/lures/new"
                      component={MyLuresNewView}
                      exact
                    />
                    <Route
                      path="/my/lures/:id"
                      component={MyLuresShowView}
                      exact
                    />
                    <Route
                      path="/my/lures/:id/edit"
                      component={MyLuresEditView}
                      exact
                    />
                    <Route
                      path="/my/lures/:id/colors"
                      component={MyLuresColorsView}
                      exact
                    />
                    <Route
                      path="/my/product_reviews"
                      component={MyProductReviewsIndexView}
                      exact
                    />
                    <Route
                      path="/my/ships"
                      component={MyShipsIndexView}
                      exact
                    />
                  </Switch>
                </Col>
                {!mobile && (
                  <Col md={4}>
                    <ProfileComp user={user} />
                    <MagazineListComp />
                  </Col>
                )}
              </Row>
            )}
          />
        </Switch>
        <Toast />
      </Router>
    </Provider>
  );
}
