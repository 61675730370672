// @flow
import React from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import GoogleMap from 'google-map-react';
import { STYLE_OPTIONS } from '../../commons/GoogleMaps';
import LocationSearchInput from '../../areas/LocationSearchInput';
import type { Venue } from '../../../models/venues/Venue';
import VenueClient from '../../../models/venues/VenueClient';
import VenueMarker from './VenueMarker';
import supercluster from 'points-cluster';
import ClusterMarker from '../../commons/ClusterMarker';
import { Text } from '../../commons/react-native';
import { List, ListItem, Body, Right, Icon } from '../../commons/native-base';
import moment from 'moment';
import VenueHelper from '../../../helpers/VenueHelper';

const DEFAULT_ZOOM = 8;

type Props = {
  position: Position,
  visible: boolean,
  apiKey: string,
  onApply: (venue: Venue) => void,
  onClose: () => void,
};

type State = {
  lat: number,
  lng: number,
  venues: Array<Venue>,
  venue: ?Venue,
  isLoading: boolean,
  searching: boolean,
  searchText: ?string,
  address: string,
  clusters: Array<any>,
  mapOptions: {
    center: {
      lat: number,
      lng: number,
    },
    zoom: number,
    bounds: any,
  },
};

class MapModal extends React.Component<Props, State> {
  googleMap: GoogleMap;
  venueClient = new VenueClient();

  constructor(props: Props) {
    super(props);

    this.state = {
      lat: props.position.coords.latitude,
      lng: props.position.coords.longitude,
      venues: [],
      venue: null,
      isLoading: true,
      searching: false,
      searchText: null,
      address: '',
      clusters: [],
      mapOptions: {
        center: {
          lat: props.position.coords.latitude,
          lng: props.position.coords.longitude,
        },
        zoom: DEFAULT_ZOOM,
        bounds: 0,
      },
    };
  }

  componentDidMount() {
    this.venueClient
      .fetchAll()
      .then(response => {
        this.setState({
          venues: [...response.data],
          isLoading: false,
        });
      })
      .catch(error => {
        this.setState({ isLoading: false });
        // this.displayError(error);
        console.warn(error);
      });
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const { latitude, longitude } = this.props.position.coords;

    if (
      latitude != nextProps.position.coords.latitude ||
      longitude != nextProps.position.coords.longitude
    ) {
      this.setState({
        lat: nextProps.position.coords.latitude,
        lng: nextProps.position.coords.longitude,
      });
    }
  }

  onChangeMap = ({ center, zoom, bounds }) => {
    this.setState(
      {
        mapOptions: {
          center,
          zoom,
          bounds,
        },
      },
      () => {
        this.createClusters();
      }
    );
  };

  onApply = () => {
    // const { marker } = this.state;
    // this.props.onApply(marker);
  };

  onSearch = (lat: number, lng: number) => {
    // this.onChangeAddress(lat, lng);
    this.googleMap.map_.panTo({ lat: lat, lng: lng });
  };

  onClickVenue(venue: Venue) {
    this.venueClient
      .fetchVenueWithConditions(venue.id, moment(new Date()))
      .then(response => {
        this.setState({ venue: response.data });
      })
      .catch(error => {
        // this.displayError(error);
        console.warn(error);
      });
  }

  getClusters() {
    const { venues } = this.state;
    const clusters = supercluster(venues, {
      minZoom: 0,
      maxZoom: 16,
      radius: 60,
    });

    console.log(clusters);

    return clusters(this.state.mapOptions);
  }

  createClusters() {
    const { mapOptions } = this.state;

    if (mapOptions.bounds) {
      const clusters = this.getClusters().map(
        ({ wx, wy, numPoints, points }) => ({
          lat: wy,
          lng: wx,
          numPoints,
          id: `${numPoints}_${points[0].id}`,
          points,
        })
      );
      this.setState({ clusters: clusters });
    } else {
      this.setState({ clusters: [] });
    }
  }

  renderConditions(venue: Venue) {
    if (VenueHelper.isLand(venue)) {
      return (
        <Row>
          <Col>
            <Text>{VenueHelper.datetime(venue)}</Text>
          </Col>
          <Col>
            <Text>
              水位：
              {VenueHelper.suii(venue)}
            </Text>
          </Col>
          <Col>
            <Text>
              前日雨量：
              {VenueHelper.zenuryo(venue)}
            </Text>
          </Col>
        </Row>
      );
    } else {
      return (
        <Row>
          <Col>
            <Text>{VenueHelper.datetime(venue)}</Text>
          </Col>
          <Col>
            <Text>
              潮位：
              {VenueHelper.tide(venue)}
            </Text>
          </Col>
          <Col>
            <Text>
              潮名：
              {VenueHelper.shionm(venue)}
            </Text>
          </Col>
        </Row>
      );
    }
  }

  render() {
    const { position, apiKey, onClose } = this.props;
    const { venue, clusters } = this.state;

    return (
      <Modal isOpen={this.props.visible} toggle={onClose} className="">
        <ModalHeader toggle={onClose}>
          <LocationSearchInput
            address={this.state.address}
            onSearch={this.onSearch}
            onSearchClose={() => console.warn('search closed')}
          />
        </ModalHeader>
        <ModalBody style={{ height: 400, padding: 0 }}>
          <GoogleMap
            ref={googleMap => (this.googleMap = googleMap)}
            bootstrapURLKeys={{ key: apiKey }}
            defaultZoom={DEFAULT_ZOOM}
            defaultCenter={[
              position.coords.latitude,
              position.coords.longitude,
            ]}
            options={{
              maxZoom: 13,
              styles: STYLE_OPTIONS,
            }}
            onChange={this.onChangeMap}
          >
            {clusters.map(cluster => {
              if (cluster.numPoints === 1) {
                return (
                  <VenueMarker
                    key={cluster.id}
                    lat={cluster.points[0].lat}
                    lng={cluster.points[0].lng}
                    venue={cluster.points[0]}
                    onClick={() => this.onClickVenue(cluster.points[0])}
                  />
                );
              } else {
                return (
                  <ClusterMarker
                    key={cluster.id}
                    lat={cluster.lat}
                    lng={cluster.lng}
                    points={cluster.points}
                    text={`${cluster.points.length}`}
                  />
                );
              }
            })}
          </GoogleMap>
        </ModalBody>
        <ModalFooter>
          <div>
            {venue != null && (
              <List>
                <ListItem
                  style={{ backgroundColor: '#fff' }}
                  onClick={() => this.props.onApply(venue)}
                >
                  <Body>
                    <Text>{venue.name}</Text>
                    {this.renderConditions(venue)}
                  </Body>
                  <Right>
                    <Icon name="arrow-forward" />
                  </Right>
                </ListItem>
              </List>
            )}
          </div>
          <div>
            <Button color="outline-primary" onClick={onClose}>
              キャンセル
            </Button>
            {venue != null && (
              <Button color="primary" onClick={() => this.props.onApply(venue)}>
                決定
              </Button>
            )}
            {venue == null && (
              <Button color="primary" disabled>
                決定
              </Button>
            )}
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

export default connect(state => ({
  position: state.position,
  apiKey: state.railsContext.settings.googleMapsApiKey,
}))(MapModal);
