// @flow
import type { ReviewForm } from '../../../models/products/reviews/Review';
import JQueryView, { on } from '../../../../common/views/JQueryView';

declare var $: any;

export default class FormView extends JQueryView {
  constructor(form: ReviewForm) {
    super('.simple_form');
    this.initRaty(form);
  }

  initRaty(form: ReviewForm) {
    $('#total_score').raty({
      starType: 'i',
      scoreName: 'product_review[total_score]',
      score: form.total_score,
    });
    $('#ease_score').raty({
      starType: 'i',
      scoreName: 'product_review[ease_score]',
      score: form.ease_score,
    });
    $('#toughness_score').raty({
      starType: 'i',
      scoreName: 'product_review[toughness_score]',
      score: form.toughness_score,
    });
    $('#cost_score').raty({
      starType: 'i',
      scoreName: 'product_review[cost_score]',
      score: form.cost_score,
    });
  }

  @on('click .remove_fields')
  onClickRemoveFields(event: any) {
    $(event.target)
      .prev('input[type=hidden]')
      .val('1');
    $(event.target)
      .closest('fieldset')
      .hide();
    event.preventDefault();
  }

  @on('click .add_fields')
  onClickAddFields(event: any) {
    const time = new Date().getTime();
    const regexp = new RegExp($(event.target).data('id'), 'g');
    $(event.target).before(
      $(event.target)
        .data('fields')
        .replace(regexp, time)
    );
    event.preventDefault();
  }
}
